import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import {
  UpdateRedaction,
  UpdateRedactionVariables,
} from "../__generated__/gql-types/UpdateRedaction";

const UseUpdateRedactions = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: UpdateRedactionVariables) => {
      return await client.mutate<UpdateRedaction>({
        mutation: UPDATE_REDACTIONS,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default UseUpdateRedactions;

const UPDATE_REDACTIONS = gql`
  mutation UpdateRedaction($redactionId: ID!, $redactionInput: RedactionInput) {
    updateRedaction(
      redactionId: $redactionId
      redactionInput: $redactionInput
    ) {
      title
      rubrics {
        rubric
        order
      }
      question
      id
      updated_at
      deleted_at
      created_at
    }
  }
`;
