import { AIConversationsInput } from "../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: AIConversationsInput = {
  title: "",
  avatar_image: "",
  character_for_role_play: "",
  cover_image: "",
  level_cerf: "",
  publish: 1,
  section_1: [],
  section_2: [],
};
