import { FC, useEffect, useState } from 'react';
import styles from "./newExams.module.css"
import { Buttons } from "../../Buttons"
import { InputField } from '../../InputField';
import courses from "./courses-seed"
import { Select } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from 'formik';
import { NewExamsSchema, NewExamsProps, initialValuesObj } from "./NewExams.types"

const { Option } = Select;

const children: React.ReactNode[] = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const handleChange = (value: string) => {
  // console.log(`selected ${value}`);
};

const NewExams: FC = () => {
  const [initialValues, setInitialValues] = useState(initialValuesObj)

  const location = useLocation();
  const navigate = useNavigate()
  const data: any = location.state;

  useEffect(() => {
    if (data && data.id) {
      setInitialValues({
        title: data.title ?? "",
        description: data.description ?? "",
        eje: data.eje ?? "",
        theme: data.theme ?? [],
        course: data.course ?? [],
        grade: data.grade ?? [],
        published: data.published ?? false,
        initialExam: data.initialExam ?? false,
        freemium: data.freemium ?? false,
        destacado: data.destacado ?? false,
        steps: data.steps ?? [],
      })
    }
  }, [])


  const handleSubmit = (
    values: NewExamsProps,
    { setSubmitting }: FormikHelpers<NewExamsProps>
  ) => {
    setTimeout(() => {
      alert(JSON.stringify(values, null, 2));
      setSubmitting(false);
    }, 500);
  }



  return (
    <div className={styles.container}>
      <h1>Nuevo Examen </h1>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={NewExamsSchema}
        onSubmit={handleSubmit}

      >
        {({ errors, handleReset, touched, setSubmitting }) => {
          return (
            <Form className={styles.formContainer} >
              {/* Title */}
              <InputField errors={errors} touched={touched} name="title" title="Título" type="text" />
              {/* Description */}
              <InputField errors={errors} touched={touched} name="description" title="Descripción" type="text" />

              <label htmlFor="eje">Eje(s)</label>
              <Select mode="tags" className={styles.tags} onChange={handleChange} >
                {children}
              </Select>

              <label htmlFor="theme">Tema(s)</label>
              <Select mode="tags" className={styles.tags} onChange={handleChange} >
                {children}
              </Select>


              {/* School Select */}
              <label htmlFor="course">Asignatura</label>
              <Field id="course" name="course" as="select" className={errors.course && touched.course ? styles.formFieldsError : styles.formFieldSelect} >
                <option disabled value=""> Seleccionar </option>
                {courses.map((course, index) => {
                  return <option value={course.name} key={index}>{course.name}</option>
                })}
              </Field>
              <ErrorMessage name="school" component="div" className={styles.error} />

              {/* School Select */}
              <label htmlFor="grade">Grado</label>
              <Field id="grade" name="grade" as="select" className={errors.course && touched.course ? styles.formFieldsError : styles.formFieldSelect} >
                <option disabled value=""> Seleccionar </option>
                {courses.map((course, index) => {
                  return <option value={course.name} key={index}>{course.name}</option>
                })}
              </Field>
              <ErrorMessage name="school" component="div" className={styles.error} />


              {/* Checkbox */}
              <div style={{ padding: "10px 5px" }}>
                <Field type="checkbox" name="published" />
                <label htmlFor="published" style={{ marginLeft: "10px" }}>Publicado</label>
              </div>


              {/* Checkbox */}
              <div style={{ padding: "10px 5px" }}>
                <Field type="checkbox" name="initialExam" />
                <label htmlFor="initialExam" style={{ marginLeft: "10px" }}>Evaluación inciial</label>
              </div>

              {/* Checkbox */}
              <div style={{ padding: "10px 5px" }}>
                <Field type="checkbox" name="freemium" />
                <label htmlFor="freemium" style={{ marginLeft: "10px" }}>Freemium</label>
              </div>

              {/* Checkbox */}
              <div style={{ padding: "10px 5px" }}>
                <Field type="checkbox" name="destacado" />
                <label htmlFor="destacado" style={{ marginLeft: "10px" }}>Destacado</label>
              </div>

              {/* School Select */}
              <label htmlFor="steps">Paso #</label>
              <Field id="steps" name="steps" as="select" className={errors.course && touched.course ? styles.formFieldsError : styles.formFieldSelect} >
                <option disabled value=""> Seleccionar </option>
                {courses.map((course, index) => {
                  return <option value={course.name} key={index}>{course.name}</option>
                })}
              </Field>
              <ErrorMessage name="school" component="div" className={styles.error} />

              {/* Buttons */}
              <Buttons handleClick={() => {
                handleReset()
                navigate(-1)
              }} />

            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default NewExams