import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UpdateSchoolVariables } from "../__generated__/gql-types/UpdateSchool";

const UseUpdateSchool = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: UpdateSchoolVariables) => {
      return await client.mutate({
        mutation: UPDATE_SCHOOL,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseUpdateSchool;

const UPDATE_SCHOOL = gql`
  mutation UpdateSchool($updateSchoolId: ID!, $schoolInput: SchoolInput) {
    updateSchool(id: $updateSchoolId, schoolInput: $schoolInput) {
      cct
      name
      city
      id
      demo
    }
  }
`;
