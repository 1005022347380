import { notification } from "antd";

export const successNotification = () =>
  notification["success"]({
    message: "Creación Exitosa",
    description: "El Usuario fue creado con éxito!",
    placement: "top",
    duration: 3,
  });

export const errorNotification = (error?: string) =>
  notification["error"]({
    message: "Error",
    description:
      error && error.length > 0
        ? error
        : "Se ha producido un error, vuelva a intentar!",
    placement: "top",
    duration: 3,
  });

export const updatedNotification = () =>
  notification["success"]({
    message: "Modificación Exitosa",
    description: "El Usuario fue modificado con éxito!",
    placement: "top",
    duration: 3,
  });
