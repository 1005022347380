import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetGrade } from "../__generated__/gql-types/GetGrade";

const useGetGrade = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetGrade> = await client.query({
      query: GET_GRADE,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getGrades;
  }, [client]);
};

export default useGetGrade;

const GET_GRADE = gql`
  query GetGrade {
    getGrades {
      id
      grade
      name
    }
  }
`;
