import { CheckCircleTwoTone, DeleteTwoTone } from "@ant-design/icons";
import { Popover } from "antd";
import { Field } from "formik";
import { FC, useEffect, useState } from "react";
import { GetQuizzes_getQuizzes } from "../../../__generated__/gql-types/GetQuizzes";
import styles from "./newVideos.module.css";
import { IQuizVideo } from "./NewVideosForm";

interface QuizToVideoFormProps {
  handleRemove: (index: number) => void;
  handleQuizChange: (e: any, index: number) => void;
  handleTimeChange: (e: any, index: number) => void;
  handleTimeOutChange: (e: any, index: number) => void;
  handleSubmit: (index: number) => void;
  quizVideos: IQuizVideo[];
  quizVideosList: IQuizVideo[];
  index: number;
  quizzes: GetQuizzes_getQuizzes[];
  quizVideo: any;
}

export const QuizToVideoForm: FC<QuizToVideoFormProps> = ({
  handleRemove,
  handleQuizChange,
  handleTimeChange,
  handleTimeOutChange,
  handleSubmit,
  quizVideos,
  quizVideosList,
  index,
  quizzes,
  quizVideo,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [quizAlreadyAdded, setQuizAlreadyAdded] = useState(false);

  const doNothing = () => void 0;

  const sendQuiz = (index: any) => {
    const quizAdded = quizVideosList.some(
      (quiz) => quiz.quiz_id === quizVideos[index].quiz_id
    );
    if (quizAdded) {
      setQuizAlreadyAdded(true);
      handleSubmit(index);
    } else {
      handleSubmit(index);
      setButtonDisabled(true);
    }
  };

  useEffect(() => {}, [buttonDisabled, quizAlreadyAdded, quizzes]);

  return (
    <>
      <div className={styles.dynamicInput} key={quizVideo.id}>
        <label htmlFor="quiz">{`Quiz ${index}`} </label>
        <Field
          as="select"
          onChange={(value: any) => {
            handleQuizChange(value, index);
          }}
          className={styles.formFieldSelect}
          id={quizVideo.id}
          name="quiz"
          value={quizVideo.quiz_id}
          disabled={buttonDisabled}
        >
          <option value="">
            {quizVideo?.quiz?.id ? quizVideo.quiz.id : "Seleccione el quiz"}{" "}
            {quizVideo?.quiz?.title ? quizVideo.quiz.title : ""}
          </option>
          {quizzes ? (
            quizzes.map((quiz, index: number) => {
              return (
                <option value={quiz.id} key={index}>
                  {quiz.id} - {quiz.title}
                </option>
              );
            })
          ) : (
            <option value="0">No existen quizzes</option>
          )}
        </Field>
        <label htmlFor="time">Tiempo de inicio (en segundos)</label>
        <Field
          key={quizVideo.id}
          title="Tiempo de inicio"
          id={quizVideo.id}
          name="time"
          onChange={(e: any) => {
            handleTimeChange(e, index);
          }}
          className={styles.formFieldSelect}
          disabled={buttonDisabled}
          value={quizVideo.time}
        ></Field>
        <label htmlFor="timeOut">Tiempo de finalización (en segundos)</label>
        <Field
          key={quizVideo.id + 1}
          title="Tiempo de finalización"
          id={quizVideo.id}
          name="timeOut"
          onChange={(e: any) => {
            handleTimeOutChange(e, index);
          }}
          className={styles.formFieldSelect}
          disabled={buttonDisabled}
          value={quizVideo.time_out}
        ></Field>
        <Popover
          title={buttonDisabled ? "Quiz Agregado" : "Guardar Quiz"}
          placement="bottom"
        >
          <CheckCircleTwoTone
            style={
              buttonDisabled ? { cursor: "not-allowed" } : { cursor: "pointer" }
            }
            twoToneColor={buttonDisabled ? "#d9d9d9" : "#52c41a"}
            onClick={(e) => {
              buttonDisabled ? doNothing() : sendQuiz(index);
            }}
          />
        </Popover>
        <Popover title="Eliminar Quiz" placement="bottom">
          <span>
            <DeleteTwoTone
              className={styles.icons}
              twoToneColor="red"
              onClick={() => {
                handleRemove(quizVideo.id);
                setButtonDisabled(false);
              }}
            />
          </span>
        </Popover>
      </div>
    </>
  );
};
