import React, { useEffect } from "react";
import { Layout } from "antd";
import HeaderLuca from "./Header/HeaderLuca";
import SiderLuca from "./Sider/SiderLuca";
import styles from "./layout.module.css";
import { useData } from "../../hooks/useData";

const { Content, Footer } = Layout;
interface LayoutProps {
  children: React.ReactNode;
}

const LayoutLuca: React.FC<LayoutProps> = ({ children }) => {
  const {
    videos,
    axes,
    classrooms,
    courses,
    lessons,
    programs,
    quizzes,
    schools,
    subjects,
    tags,
    themes,
    users,
  } = useData();

  useEffect(() => {}, [
    axes,
    courses,
    videos,
    lessons,
    programs,
    quizzes,
    schools,
    subjects,
    tags,
    classrooms,
    themes,
    users,
  ]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <SiderLuca></SiderLuca>
      <Layout style={{ background: "#fff" }}>
        <HeaderLuca></HeaderLuca>
        <Content style={{ margin: "0 16px", background: "none !important" }}>
          <div className={styles.siteLayout}>{children}</div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Ant Design ©2018 Created by Ant UED
        </Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutLuca;
