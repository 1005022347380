import { FC, useEffect } from "react";
import { Field } from "formik";
import styles from "./dynamicSteps.module.css";
import { DeleteTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { GetQuizzes_getQuizzes } from "./../../__generated__/gql-types/GetQuizzes";
import { PropsStep } from "../Forms/LessonsForm/LessonsForm";
import { GetVideosForLesson_getVideos } from "../../__generated__/gql-types/GetVideosForLesson";
import { GetRedactions_getRedactions } from "../../__generated__/gql-types/GetRedactions";
import { getLiteracies_getLiteracies } from "../../__generated__/gql-types/getLiteracies";
import { getAIConversations_getAIConversations } from "../../__generated__/gql-types/getAIConversations";
import { getListenings_getListenings } from "../../__generated__/gql-types/getListenings";

interface DynamicStepsProps {
  handleAdd: () => void;
  handleChange: (
    e: { target: { value: string; name: string } },
    index: number
  ) => void;
  handleRemove: (index: number) => void;
  quiz: GetQuizzes_getQuizzes[] | null;
  videos?: GetVideosForLesson_getVideos[] | null;
  haveVideos?: boolean;
  elements: PropsStep[];
  lesson_type: string;
  essays?: GetRedactions_getRedactions[] | null;
  literacies?: getLiteracies_getLiteracies[] | null;
  listenings?: getListenings_getListenings[] | null;
  aiConversations?: getAIConversations_getAIConversations[] | null;
  setRedactionId: React.Dispatch<React.SetStateAction<number | null>>;
  setLiteracyId: React.Dispatch<React.SetStateAction<number | null>>;
  setListeningId: React.Dispatch<React.SetStateAction<number | null>>
  setConversationId: React.Dispatch<React.SetStateAction<number | null>>;
}

const DynamicSteps: FC<DynamicStepsProps> = ({
  videos = null,
  elements,
  handleAdd,
  handleRemove,
  handleChange,
  haveVideos = true,
  quiz,
  essays,
  aiConversations,
  lesson_type,
  setRedactionId,
  literacies,
  listenings,
  setLiteracyId,
  setListeningId,
  setConversationId,
}) => {
  useEffect(() => {}, [videos, quiz, essays, literacies, aiConversations]);
  return (
    <>
      {elements?.map(
        (elem: { step: string; video?: string }, index: number) => {
          return (
            <div className={styles.dynamicInput} key={index}>
              <label htmlFor="step">Paso # {index}</label>
              <span>
                {elements.length - 1 === index &&
                  elements.length < 5 &&
                  lesson_type !== "redaction" &&
                  lesson_type !== "conversation" &&
                  lesson_type !== "audio_exam" &&
                  lesson_type !== "listening" && (
                    <span>
                      <PlusCircleTwoTone
                        className={styles.icons}
                        twoToneColor="blue"
                        onClick={handleAdd}
                      />{" "}
                    </span>
                  )}
                {elements.length > 1 && lesson_type !== "listening" && (
                  <span>
                    <DeleteTwoTone
                      className={styles.icons}
                      twoToneColor="red"
                      onClick={() => handleRemove(index)}
                    />{" "}
                  </span>
                )}
                {lesson_type !== "redaction" &&
                  lesson_type !== "conversation" && (
                    <>
                      {haveVideos && lesson_type !== "evaluation" && (
                        <>
                          <span className={styles.radioPasos}>
                            {lesson_type === "audio_exam" ? "Audio" : "Video"}
                          </span>
                          <input
                            type="radio"
                            name={`video-${index}`}
                            onChange={(e: {
                              target: { value: string; name: string };
                            }) => {
                              handleChange(e, index);
                            }}
                            defaultChecked={elem.video === "video"}
                            value={"video"}
                            id=""
                          />
                        </>
                      )}
                      {lesson_type !== "audio_exam" &&
                        lesson_type !== "listening" && (
                          <>
                            <span className={styles.radioPasos}>Quiz</span>
                            <input
                              type="radio"
                              name={`video-${index}`}
                              onChange={(e: {
                                target: { value: string; name: string };
                              }) => {
                                handleChange(e, index);
                              }}
                              defaultChecked={elem.video === "quiz"}
                              value={"quiz"}
                              id=""
                            />
                          </>
                        )}

                      {lesson_type === "literacy" && (
                        <>
                          <span className={styles.radioPasos}>Lectura</span>
                          <input
                            type="radio"
                            name={`video-${index}`}
                            onChange={(e: {
                              target: { value: string; name: string };
                            }) => {
                              handleChange(e, index);
                            }}
                            defaultChecked={elem.video === "literacy"}
                            value={"literacy"}
                            id=""
                          />
                        </>
                      )}

                      {lesson_type === "listening" && (
                        <>
                          <span className={styles.radioPasos}>Listening</span>
                          <input
                            type="radio"
                            name={`video-${index}`}
                            onChange={(e: {
                              target: { value: string; name: string };
                            }) => {
                              handleChange(e, index);
                            }}
                            defaultChecked={elem.video === "listening"}
                            value={"listening"}
                            id=""
                          />
                        </>
                      )}
                    </>
                  )}
              </span>

              {lesson_type !== "redaction" &&
                lesson_type !== "conversation" && (
                  <div style={{ display: "flex" }}>
                    <Field
                      id="step"
                      name="step"
                      as="select"
                      className={styles.formFieldSelect}
                      onChange={(e: {
                        target: { value: string; name: string };
                      }) => {
                        handleChange(e, index);
                      }}
                      value={elem.step}
                    >
                      <option disabled value="">
                        Seleccionar
                      </option>

                      {haveVideos && elements[index].video === "video"
                        ? videos?.map((elemento, index: number) => {
                            return (
                              <option value={elemento.id} key={index}>
                                {elemento.id} - {elemento.title}
                              </option>
                            );
                          })
                        : haveVideos && elements[index].video === "quiz"
                        ? quiz?.map((elemento, index: number) => {
                            return (
                              <option value={elemento.id} key={index}>
                                {elemento.id} - {elemento.title}
                              </option>
                            );
                          })
                        : undefined}

                      {!haveVideos &&
                        elements[index].video === "quiz" &&
                        quiz?.map((elemento, index: number) => {
                          return (
                            <option value={elemento.id} key={index}>
                              {elemento.id} - {elemento.title}
                            </option>
                          );
                        })}

                      {!haveVideos &&
                        elements[index].video === "literacy" &&
                        literacies?.map((elemento, index: number) => {
                          return (
                            <option value={elemento.id ?? ""} key={index}>
                              {elemento.id} - {elemento.title}
                            </option>
                          );
                        })}

                      {!haveVideos &&
                        elements[index].video === "listening" &&
                        listenings?.map((elemento, index: number) => {
                          return (
                            <option value={elemento.id ?? ""} key={index}>
                              {elemento.id} - {elemento.title}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                )}

              {lesson_type === "redaction" && (
                <div style={{ display: "flex" }}>
                  <Field
                    id="step"
                    name="step"
                    as="select"
                    className={styles.formFieldSelect}
                    onChange={(e: {
                      target: { value: string; name: string };
                    }) => {
                      handleChange(e, index);
                      setRedactionId(Number(e.target.value));
                    }}
                    value={elem.step}
                  >
                    <option disabled value="">
                      Seleccionar
                    </option>

                    {essays?.map((elemento, index: number) => {
                      return (
                        <option value={elemento.id} key={index}>
                          {elemento.id} - {elemento.title}
                        </option>
                      );
                    })}
                  </Field>
                </div>
              )}

              {lesson_type === "conversation" && (
                <div style={{ display: "flex" }}>
                  <Field
                    id="step"
                    name="step"
                    as="select"
                    className={styles.formFieldSelect}
                    onChange={(e: {
                      target: { value: string; name: string };
                    }) => {
                      handleChange(e, index);
                      setConversationId(Number(e.target.value));
                    }}
                    value={elem.step}
                  >
                    <option disabled value="">
                      Seleccionar
                    </option>

                    {aiConversations?.map((elemento, index: number) => {
                      return (
                        <option value={elemento.id?.toString()} key={index}>
                          {elemento.id} - {elemento.title}
                        </option>
                      );
                    })}
                  </Field>
                </div>
              )}
            </div>
          );
        }
      )}
    </>
  );
};

export default DynamicSteps;
