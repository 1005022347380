import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { ListFolders } from "../__generated__/gql-types/ListFolders";

const useGetListFolder = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<ListFolders> = await client.query({
      query: GET_LIST_FOLDER,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.listFolders;
  }, [client]);
};

export default useGetListFolder;

const GET_LIST_FOLDER = gql`
  query ListFolders {
    listFolders {
      name
      id
    }
  }
`;
