import React, { ChangeEvent, useEffect } from "react";
import { DAndDQuestionInput } from "../../../../../__generated__/gql-types/globalTypes";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import styles from "./dad.module.css";
import { Row } from "antd";
import { Field } from "formik";
import { UploadDADQ } from "../../../../UploadFile/UploadDADQ";
import { UploadGroupQuestion } from "../../../../UploadFile/UploadGroupQuestion";

interface GroupBlocksProps {
  dadQuestion: DAndDQuestionInput | null;
  indexDADQuestion: number;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => void;
  fileImageGroupContainer: string[];
  setFileImageGroupContainer: React.Dispatch<React.SetStateAction<string[]>>;
  handleAddGroupWithImage: () => void;
  handleRemoveGroupWithImage: () => void;
  handleImageGroupDADQuestions: (image: string, index: number) => void;
}

export const GroupBlocks = ({
  dadQuestion,
  indexDADQuestion,
  fileImageGroupContainer,
  handleChange,
  handleAddGroupWithImage,
  handleRemoveGroupWithImage,
  handleImageGroupDADQuestions,
  setFileImageGroupContainer,
}: GroupBlocksProps) => {
  useEffect(() => {}, [
    dadQuestion,
    handleChange,
    handleAddGroupWithImage,
    handleRemoveGroupWithImage,
  ]);

  const AddButton = ({ index }: { index: number }) => {
    if (
      dadQuestion?.blockItems &&
      index === dadQuestion.blockItems.length - 1 &&
      dadQuestion?.blockItems.length < 8
    ) {
      return (
        <span>
          <a
            style={{ fontSize: 22, padding: 10 }}
            onClick={(e: any) => {
              handleAddGroupWithImage();
            }}
          >
            <PlusCircleOutlined></PlusCircleOutlined>
          </a>
        </span>
      );
    } else {
      return null;
    }
  };

  const DeleteButton = ({ index }: { index: number }) => {
    if (
      dadQuestion?.blockItems &&
      dadQuestion?.blockItems.length - 1 === index &&
      index !== 0
    ) {
      return (
        <span>
          <a
            style={{ fontSize: 22, padding: 10 }}
            onClick={() => {
              handleRemoveGroupWithImage();
            }}
          >
            <DeleteOutlined></DeleteOutlined>
          </a>
        </span>
      );
    } else {
      return null;
    }
  };

  if (!dadQuestion) {
    return null;
  }

  return (
    <div className={styles.containerGroupColumn}>
      <div className={styles.rowBlockGroup}>
        {dadQuestion.blockContainer?.map((bc, indexbc) => {
          return (
            <div className={styles.titleBlcokContainer}>
              <h1 className={styles.textTitle}>Grupo {indexbc}</h1>
              <Field
                id={`group_${indexbc}`}
                name={`group_${indexbc}`}
                placeholder={`Título #${indexbc}`}
                type="text"
                className={styles.formFieldSelect}
                defaultValue={bc?.text ?? ""}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChange(event, indexbc);
                }}
              ></Field>
            </div>
          );
        })}
      </div>
      <div className={styles.columGroup}>
        <div className={styles.columGroupSeparate}>
          {dadQuestion.blockItems?.map((bi, index) => {
            if (index % 2 === 0) {
              return (
                <div className={styles.rowInBlockItem}>
                  <div className={styles.containerBlockGroup}>
                    <Field
                      id={`group_block_item_${index}`}
                      name={`group_block_item_${index}`}
                      placeholder={`Palabra #${index} Grupo #0`}
                      type="text"
                      className={styles.formFieldSelect}
                      defaultValue={bi?.text ?? ""}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, index);
                      }}
                    ></Field>
                    <UploadGroupQuestion
                      index={index}
                      folder="answers"
                      fileImageGroupContainer={fileImageGroupContainer}
                      setFileImageGroupContainer={setFileImageGroupContainer}
                      handleImageGroupDADQuestions={
                        handleImageGroupDADQuestions
                      }
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className={styles.columGroupSeparate}>
          {dadQuestion.blockItems?.map((bi, index) => {
            if (index % 2 !== 0) {
              return (
                <div className={styles.rowInBlockItem}>
                  <div className={styles.containerBlockGroup}>
                    <Field
                      id={`group_block_item_${index}`}
                      name={`group_block_item_${index}`}
                      placeholder={`Palabra #${index} Grupo #1`}
                      type="text"
                      className={styles.formFieldSelect}
                      defaultValue={bi?.text ?? ""}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, index);
                      }}
                    ></Field>
                    <UploadGroupQuestion
                      index={index}
                      folder="answers"
                      fileImageGroupContainer={fileImageGroupContainer}
                      setFileImageGroupContainer={setFileImageGroupContainer}
                      handleImageGroupDADQuestions={
                        handleImageGroupDADQuestions
                      }
                    />
                    <AddButton index={index} />
                    <DeleteButton index={index} />
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};
