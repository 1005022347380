import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { createLiteracyVariables } from "../__generated__/gql-types/createLiteracy";

const UseCreateLiteracy = () => {
  const client = useApolloClient();
  return useCallback(
    async (literacyInput: createLiteracyVariables) => {
      return await client.mutate({
        mutation: CREATE_LITERACY,
        fetchPolicy: "no-cache",
        variables: literacyInput,
      });
    },
    [client]
  );
};

export default UseCreateLiteracy;

const CREATE_LITERACY = gql`
  mutation createLiteracy($literacyInput: LiteracyInput) {
    createLiteracy(literacyInput: $literacyInput) {
      content {
        image
        order
        text
        type
      }
      cover
      id
      publish
      title
    }
  }
`;
