/* eslint-disable no-console */
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import reducer from "./reducers";

const env = { name: "development" };

export default function configureStore(initialState = {}) {
  const localStore = createStore(
    reducer,
    initialState,
    env.name === "development" || true
      ? composeWithDevTools(applyMiddleware(thunk))
      : applyMiddleware(thunk)
  );

  return localStore;
}
