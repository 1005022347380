import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getClothesAndAccessoriesById,
  getClothesAndAccessoriesByIdVariables,
} from "../__generated__/gql-types/getClothesAndAccessoriesById";

const UseGetClothesAndAccessoriesById = () => {
  const client = useApolloClient();

  return useCallback(
    async (variables: getClothesAndAccessoriesByIdVariables) => {
      const result: ApolloQueryResult<getClothesAndAccessoriesById> =
        await client.query({
          query: GET_CLOTHES_AND_ACCESSORIES_BY_ID,
          fetchPolicy: "no-cache",
          variables,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getClothesAndAccessoriesById;
    },
    [client]
  );
};

export default UseGetClothesAndAccessoriesById;

const GET_CLOTHES_AND_ACCESSORIES_BY_ID = gql`
  query getClothesAndAccessoriesById($getClothesAndAccessoriesByIdId: ID) {
    getClothesAndAccessoriesById(id: $getClothesAndAccessoriesByIdId) {
      id
      name
      type
      clasification
      z_index
      price
      publish
      rigel_item_img
      rigel_thumbnail_img
      spica_item_img
      spica_thumbnail_img
      colmillu_item_img
      colmillu_thumbnail_img
      polux_item_img
      polux_thumbnail_img
      shaula_item_img
      shaula_thumbnail_img
      tatooine_item_img
      tatooine_thumbnail_img
    }
  }
`;
