import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./uploadFile.module.css";
import { environment } from "../../configuration/environment";
import { useAuth } from "../../hooks/useAuth";
import { notification } from "antd";

interface UploadDADQProps {
  index: number;
  fileImageBlockItems: string[];
  folder: string;
  setFileImageBlockItems: React.Dispatch<React.SetStateAction<string[]>>;
  fileImageBlockContainer: string[];
  setFileImageBlockContainer: React.Dispatch<React.SetStateAction<string[]>>;
  handleImageDADQuestionRelationWithImage: (
    image: string,
    index: number
  ) => void;
}

export const UploadDADQ = ({
  index,
  folder,
  fileImageBlockItems,
  setFileImageBlockItems,
  fileImageBlockContainer,
  setFileImageBlockContainer,
  handleImageDADQuestionRelationWithImage,
}: UploadDADQProps) => {
  const { token } = useAuth();
  const [file, setFile] = useState<string>("");

  const completeUpload = async (file: File) => {
    try {
      const response = await axios.post(
        `${environment.backendUrl}/urlimage`,
        {
          folder,
          extension: file.type.split("/")[1],
        },
        {
          headers: {
            token,
          },
        }
      );

      let id: string = response.data.url
        .split("/")
        [response.data.url.split("/").length - 1].split("?X-Amz-Algorithm")[0]
        .split(".")[0];
      let ext: string = response.data.url
        .split("/")
        [response.data.url.split("/").length - 1].split("?X-Amz-Algorithm")[0]
        .split(".")[1];

      await axios.put(response.data.url, file, {
        headers: {
          "content-type": file.type,
        },
      });

      console.log({ file: `${id}.${ext}` });

      fileImageBlockContainer[index] = `${id}.${ext}`;
      setFileImageBlockContainer &&
        setFileImageBlockContainer([...fileImageBlockContainer]);

      handleImageDADQuestionRelationWithImage &&
        handleImageDADQuestionRelationWithImage(`${id}.${ext}`, index);
    } catch (error: any) {
      notification["error"]({
        message: "Error",
        description: "La imagen no pudo ser subida, por favor, reintente.",
        placement: "top",
        duration: 3,
      });
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    let file: File = e.target.files[0] as unknown as File;
    setFile(file.name);

    if (file.size > 0) {
      await completeUpload(file);
    } else {
      notification["error"]({
        message: "Error",
        description: "La imagen no pudo ser subida, por favor, reintente.",
        placement: "top",
        duration: 3,
      });
    }
  };

  const sortInfo = async () => {};

  useEffect(() => {
    sortInfo();
  }, []);

  return (
    <div className={styles.containerDADQ}>
      {/* +imagen #{index} */}
      <input onChange={handleChange} type="file" />
      <div style={{ marginLeft: 10 }}>
        {"   "}
        {fileImageBlockContainer[index].length > 0
          ? fileImageBlockContainer[index]
          : "Sube una imágen!"}
      </div>
    </div>
  );
};
