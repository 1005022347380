import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UpdateSubjectVariables } from "../__generated__/gql-types/UpdateSubject";

const UseUpdateSubject = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: UpdateSubjectVariables) => {
      return await client.mutate({
        mutation: UPDATE_SUBJECT,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseUpdateSubject;

const UPDATE_SUBJECT = gql`
  mutation UpdateSubject($updateSubjectId: ID!, $subjectInput: SubjectInput) {
    updateSubject(id: $updateSubjectId, subjectInput: $subjectInput) {
      id
      code
      name
      color_class
      publish
    }
  }
`;
