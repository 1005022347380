import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { MutationBulkInfoVideosVariables } from "../__generated__/gql-types/MutationBulkInfoVideos";

const useBulkInfoVideos = () => {
  const client = useApolloClient();

  return useCallback(
    async (bulkCreateInput: MutationBulkInfoVideosVariables) => {
      return await client.mutate({
        mutation: BULK_INFO_VIDEOS,
        fetchPolicy: "no-cache",
        variables: bulkCreateInput,
      });
    },
    [client]
  );
};

export default useBulkInfoVideos;

const BULK_INFO_VIDEOS = gql`
  mutation MutationBulkInfoVideos($sheetUrl: String) {
    bulkInfoVideos(sheetURL: $sheetUrl)
  }
`;
