import { notification } from "antd";
import { GetQuizById_getQuizById_questions } from "../../../__generated__/gql-types/GetQuizById";
import {
  AnswerInput,
  DAndDQuestionInput,
  QuestionInput,
  RelationQuestionInput,
} from "../../../__generated__/gql-types/globalTypes";
export interface FileImageCoverProps {
  id: string;
  ext: string;
}

export const successNotification = () =>
  notification["success"]({
    message: "Creación Exitosa",
    description: "El Ensayo fue creado con éxito!",
    placement: "top",
    duration: 3,
  });

export const errorNotification = () =>
  notification["error"]({
    message: "Error",
    description: "Se ha producido un error, vuelva a intentar!",
    placement: "top",
    duration: 3,
  });

export const updatedNotification = () =>
  notification["success"]({
    message: "Modificación Exitosa",
    description: "El Ensayo fue modificado con éxito!",
    placement: "top",
    duration: 3,
  });

export const sortQuestionsAndAnswers = async (
  questions: GetQuizById_getQuizById_questions[] | null,
  setQuestions: React.Dispatch<React.SetStateAction<QuestionInput[]>>
) => {
  return new Promise((resolve, reject) => {
    try {
      let dataQuestionsWithoutURLs = questions?.map((question) => {
        let { url, ...questionRest } = question;
        let questionsArrayRest: QuestionInput = questionRest;

        let answerArrayRest: AnswerInput[] = [];
        if (questionRest.answers && questionRest.answers.length > 0)
          answerArrayRest = questionRest.answers.map((answer) => {
            let { url, created_at, updated_at, deleted_at, ...answerRest } =
              answer;
            return answerRest;
          });

        let dadQuestionsArrayRest: DAndDQuestionInput[] = [];
        if (
          questionRest.dAndDQuestion &&
          questionRest.dAndDQuestion.length > 0
        ) {
          dadQuestionsArrayRest = questionRest.dAndDQuestion.map((dad) => {
            let { created_at, updated_at, ...dadRest } = dad;
            return dadRest;
          });
        }

        let relationQuestionArrayRest: RelationQuestionInput[] = [];
        if (
          questionRest.relationQuestion &&
          questionRest.relationQuestion.length > 0
        ) {
          relationQuestionArrayRest = questionRest.relationQuestion.map(
            (rq) => {
              let { created_at, updated_at, ...rqRest } = rq;
              return rqRest;
            }
          );
        }

        questionsArrayRest.relationQuestion = relationQuestionArrayRest;
        questionsArrayRest.answers = answerArrayRest;
        questionsArrayRest.dAndDQuestion = dadQuestionsArrayRest;
        return questionsArrayRest;
      });
      dataQuestionsWithoutURLs &&
        setQuestions &&
        setQuestions(dataQuestionsWithoutURLs);

      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateFilnameQuestionsAndAnswers = async (
  questions: GetQuizById_getQuizById_questions[] | null,
  filenameQuestions: FileImageCoverProps[],
  filenameAnswers: FileImageCoverProps[][],
  setfilenameQuestions: React.Dispatch<
    React.SetStateAction<FileImageCoverProps[]>
  >,
  setfilenameAnswers: React.Dispatch<
    React.SetStateAction<FileImageCoverProps[][]>
  >,
  setQuestions: React.Dispatch<React.SetStateAction<QuestionInput[]>>
) => {
  return new Promise((resolve, reject) => {
    if (questions && questions.length > 0) {
      let questionsForFilenames = questions;
      questionsForFilenames.forEach((question, indexQuestion) => {
        let id: string = question.image ? question.image.split(".")[0] : "";
        let ext: string = question.image ? question.image.split(".")[1] : "";
        filenameQuestions.push({ id, ext });
        filenameAnswers.push([]);

        question.answers?.forEach((answer) => {
          let idAnswer: string = answer.image ? answer.image.split(".")[0] : "";
          let extAnswer: string = answer.image
            ? answer.image.split(".")[1]
            : "";
          filenameAnswers[indexQuestion].push({
            id: idAnswer,
            ext: extAnswer,
          });
        });
      });

      setfilenameQuestions && setfilenameQuestions([...filenameQuestions]);
      setfilenameAnswers && setfilenameAnswers([...filenameAnswers]);

      let dataQuestionsWithoutURLs = questions.map<QuestionInput>(
        (question) => {
          let { url, ...questionRest } = question;
          let questionsArrayRest: QuestionInput = questionRest;

          let answerArrayRest: AnswerInput[] = [];
          if (questionRest.answers && questionRest.answers.length > 0)
            answerArrayRest = questionRest.answers.map((answer) => {
              let { url, created_at, updated_at, deleted_at, ...answerRest } =
                answer;
              return answerRest;
            });

          questionsArrayRest.answers = answerArrayRest;

          return questionsArrayRest;
        }
      );

      dataQuestionsWithoutURLs &&
        setQuestions &&
        setQuestions(dataQuestionsWithoutURLs);
    } else {
      setfilenameQuestions &&
        setfilenameQuestions([...filenameQuestions, { id: "", ext: "" }]);
      setfilenameAnswers &&
        setfilenameAnswers([...filenameAnswers, [{ id: "", ext: "" }]]);
    }

    resolve(true);
  });
};

export const setQuestionsWithFilenameAndOrder = (
  questions: QuestionInput[],
  filenameQuestions: FileImageCoverProps[],
  filenameAnswers: FileImageCoverProps[][],
  setQuestions: React.Dispatch<React.SetStateAction<QuestionInput[]>>
) => {
  if (filenameQuestions && filenameQuestions.length > 0) {
    filenameQuestions.map((fileq, indexq) => {
      if (fileq.id !== "") {
        questions[indexq].order = indexq;
        questions[indexq].image = `${fileq.id}.${fileq.ext}`;
      }

      if (filenameAnswers && filenameAnswers[indexq].length > 0) {
        filenameAnswers[indexq].map((file_a, index_a) => {
          if (file_a?.id !== "") {
            questions[indexq].answers![
              index_a
            ].image = `${file_a.id}.${file_a.ext}`;
            questions[indexq].answers![index_a].order = index_a;
          }
        });
      }
    });
    setQuestions && setQuestions(questions);
  }
};

export const setQuestionsWithOrder = (
  questions: QuestionInput[],
  setQuestions: React.Dispatch<React.SetStateAction<QuestionInput[]>>
) => {
  questions &&
    questions.forEach((question, indexq) => {
      question.order = indexq;
      question.answers?.forEach((answer: any, indexa: number) => {
        answer.order = indexa;
      });
    });
  setQuestions && setQuestions(questions);
};
