import { FC } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useAuth } from "../../hooks/useAuth";
import logo from "../../assets/icons/logo_blue.svg";
import styles from "./login.module.css";
import { LoginFormSchema, initialValuesObj } from "./LoginForm.types";
import { ActivityIndicator } from "../ActivityIndicator";

const LoginComponent: FC = () => {
  const { login, loading } = useAuth();
  const handleSubmit = async (values: any) => {
    await login(values.emailOrUsername, values.password);
  };

  if (loading) {
    return <ActivityIndicator />
  }

  return (
    <div className={styles.container}>
      <img src={logo} alt="" className={styles.logo} />
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>¡Bienvenido a Luca!</h3>
        <h3 className={styles.subTitle}>
          Introduce tu email de la escuela y tu contraseña.
        </h3>
        {/* <h3 className={styles.subTitle}>
          Acceso exclusivo para profesores usuarios de la Guia
        </h3>
        <h3 className={styles.subTitle}>Santillana en escuelas públicas.</h3> */}
      </div>

      <div className={styles.formContainer}>
        <Formik
          initialValues={initialValuesObj}
          onSubmit={handleSubmit}
          validationSchema={LoginFormSchema}
        >
          <Form>
            <h3 className={styles.formText}>Usuario *</h3>
            <Field
              id="emailOrUsername"
              name="emailOrUsername"
              className={styles.formFields}
            />
            <ErrorMessage
              name="emailOrUsername"
              component="div"
              className={styles.error}
            />

            <h3 className={styles.formText}>Contraseña *</h3>
            <Field
              id="password"
              name="password"
              className={styles.formFields}
              type="password"
            />
            <ErrorMessage
              name="password"
              component="div"
              className={styles.error}
            />

            <h3 className={styles.forgotPasswordText}>
              ¿Olvidaste tu contraseña?
            </h3>

            <div>
              <button type="submit" className={styles.submitButton}>
                Entrar a Luca
              </button>
            </div>
          </Form>
        </Formik>
      </div>
      <div className={styles.clientContainer}>
        {/* <h3 className={styles.clientTitle}>
          *Escribe los mismos datos que usaste para ingresar a Gavi.
        </h3>
        <h3 className={styles.clientTitleTwo}>
          **Para actualizar tu contraseña.ccede a
        </h3>
        <a className={styles.linkText} href="http://gavi.santillana.com.mx">
          http://gavi.santillana.com.mx
        </a> */}
        <div className={styles.helpContainer}>
          <h3 className={styles.helpTitle}>¿Necesitas ayuda?</h3>
          <h3 className={styles.helpSubTitle}>
            Mándanos un correo a{" "}
            <a className={styles.helpSubTitleBlue} href="!#">
              soporte@lucaedu.com
            </a>{" "}
            o escríbenos por Whatsapp al{" "}
            <a className={styles.helpSubTitleBlue} href="!#">
              +52 55 4169 1994
            </a>
            .
          </h3>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
