import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { updateListeningVariables } from "../__generated__/gql-types/updateListening";

const UseUpdateListening = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: updateListeningVariables) => {
      return await client.mutate({
        mutation: UPDATE_LISTENING,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseUpdateListening;

const UPDATE_LISTENING = gql`
  mutation updateListening($listeningId: ID!, $listeningInput: ListeningInput) {
    updateListening(
      listening_id: $listeningId
      listeningInput: $listeningInput
    ) {
      id
      title
    }
  }
`;
