import { useDispatch, useSelector } from "react-redux";
import { LESSONS } from "../store/selectors";
import { useEffect, useState } from "react";
import { GetLessons_getLessons } from "../__generated__/gql-types/GetLessons";
import useDeleteLessons from "../api/useDeleteLessons";
import { notification } from "antd";
import UseGetLessonsListBackOffice from "../api/useGetLessonsListBackOffice";

// date format: MM/DD/YYYY
export const useLessons = () => {
  const dispatch = useDispatch();
  const lessonsSelector = useSelector(LESSONS);
  const [loading, setLoading] = useState(false);
  const [lessons, setLessons] = useState<GetLessons_getLessons[]>([]);

  const deleteLessonMutation = useDeleteLessons();
  const getLessonsListBackOffice = UseGetLessonsListBackOffice();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (lessonsSelector?.length > 0) {
        setLessons(lessonsSelector);
      } else {
        const lessonsRes = await getLessonsListBackOffice();
        const lessonsData = lessonsRes
          ?.filter((lesson) => lesson?.deleted_at === null)
          .sort((a, b) => Number(b.id) - Number(a.id));

        lessonsData && setLessons(lessonsData);
        dispatch({ type: "data/setLessons", payload: lessonsData });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
    }
  };

  const deleteLesson = async (idLesson: string) => {
    setLoading(true);
    try {
      const response = await deleteLessonMutation({
        deleteLessonId: idLesson,
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "La leccion fue eliminada con exito!",
          placement: "top",
          duration: 3,
        });
        let updateLessons = lessons.filter((lesson) => lesson.id !== idLesson);
        setLessons(updateLessons);
        dispatch({ type: "data/setLessons", payload: updateLessons });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return {
    loading,
    lessons,
    deleteLesson,
  };
};
