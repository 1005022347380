import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { assignSchoolClassroomsInput } from "../__generated__/gql-types/globalTypes";

const useAssignClassroom = () => {
  const client = useApolloClient();

  return useCallback(
    async (assignSchoolClassroomsInput: assignSchoolClassroomsInput) => {
      return await client.mutate({
        mutation: ASSIGN_CLASSROOM,
        fetchPolicy: "no-cache",
        variables: { assignSchoolClassroomsInput },
      });
    },
    [client]
  );
};

export default useAssignClassroom;

const ASSIGN_CLASSROOM = gql`
  mutation Mutation($assignSchoolClassroomsInput: assignSchoolClassroomsInput) {
    assignSchoolClassrooms(
      assignSchoolClassroomsInput: $assignSchoolClassroomsInput
    ) {
      classrooms {
        id
        name
        school {
          id
          name
        }
        program {
          id
          title
        }
      }
    }
  }
`;
