import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UpdateLessonVariables } from "../__generated__/gql-types/UpdateLesson";

const UseUpdateLessons = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: UpdateLessonVariables) => {
      return await client.mutate({
        mutation: UPDATE_LESSON,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseUpdateLessons;

const UPDATE_LESSON = gql`
  mutation UpdateLesson($updateLessonId: ID!, $lessonInput: LessonInput) {
    updateLesson(id: $updateLessonId, lessonInput: $lessonInput) {
      id
      title
      deleted_at
      course_id
      nem
      grade {
        name
        id
      }
      tags {
        name
        id
      }
    }
  }
`;
