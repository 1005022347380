import React, { ChangeEvent, useState } from "react";
import styles from "./bulkCreate.module.css";
import { Field, Form, Formik, FormikProps } from "formik";
import { Buttons } from "../../components";
import { errorNotification, successNotification } from "./utils";
import { ActivityIndicator } from "../../components/ActivityIndicator";
import useBulkCreate from "../../api/useBulkCreate";
import { Button } from "antd";
import UseGetResultsBulkCreate from "../../api/useGetLogsBulkCreate";

const BulkCreate = () => {
  const [initialValues, setInitialValues] = useState({
    link: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [resultsBulkCreate, setResultsBulkCreate] = useState("");
  const bulkCreate = useBulkCreate();
  const resultsBulkCreateService = UseGetResultsBulkCreate();

  const handleSubmit = async (formValues: any) => {
    setLoading(true);
    try {
      const response = await bulkCreate({
        sheetUrl: formValues.link,
      });

      response.data && successNotification();
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setLoading(false);
      errorNotification();
    }
  };

  const handleClickSearchResults = async () => {
    setLoadingLogs(true);
    try {
      const results = await resultsBulkCreateService({
        fileName: "bulkCreate",
      });

      if (results) setResultsBulkCreate(results);
      setLoadingLogs(false);
    } catch (error) {
      console.log("handleClickSearchResults error: ", error);
      setLoadingLogs(false);
    }
  };

  if (loading) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      <h1>Bulk Create</h1>
      <div className={styles.lessonsContent}></div>
      <div className={styles.inputGenerate}>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ errors, handleReset, touched, values }: FormikProps<any>) => {
            return (
              <Form className={styles.formContainer}>
                <label htmlFor=""> Ingrese el link de spreadsheet</label>
                <Field
                  errors={errors}
                  touched={touched}
                  value={initialValues.link}
                  className={styles.formFields}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInitialValues({
                      ...initialValues,
                      link: e.target.value,
                    });
                  }}
                  name="link"
                  title="Link"
                  type="text"
                />

                <div
                  style={{
                    margin: 20,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p style={{ fontSize: 18, color: "black" }}>
                    La carga de contenido demora mucho tiempo, porque vamos a
                    tener que crear en primer lugar las lecciones (orientación
                    didáctica, páginas del libro y guías de clase).
                    <b> ATENCIÓN!!!!!</b> Los links que se carguen del drive que
                    sean pdf o slide para las lecciones deben ser SI O SI
                    enlaces
                    <b> PÚBLICOS!!!!!!</b>
                  </p>
                  <p style={{ fontSize: 18, color: "black" }}>
                    Recomendamos esperar a que se visualice todo el contenido en
                    las diferentes secciones de la plataforma para recién
                    después volver a usar ésta sección. Verificar que las
                    lecciones fueron creadas exitosamente y que al ingresar, no
                    se presentan errores. La disposición de la información tal
                    cuál está en el excel de ejemplo es de{" "}
                    <b>SUMA IMPORTANCIA</b>, ya que si no pasamos la
                    información, el contenido va a presentar errores.
                  </p>
                  <p style={{ fontSize: 18, color: "black" }}>
                    Por último, revise el curso, ingrese al curso que fue creado
                    y contemple que posea los datos que ingresó junto con las
                    lecciones asociadas con los id's que se visualizan en la
                    sección de Lecciones del Back Office.
                  </p>
                  <p style={{ fontSize: 18, color: "black" }}></p>
                </div>

                <Buttons
                  handleClick={() => {
                    handleReset();
                  }}
                />

                <div
                  style={{
                    marginTop: 50,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ fontSize: 18, color: "black" }}>
                    Puedes verificar el estado de la creación del contenido y
                    los procesos de creación de lecciones y cursos, podes
                    seleccionar éste botón y ver la información tanto fecha,
                    como tipo de lección y el título de la lección. Se muestran
                    las lecciones y cursos que tuvieron errores, pero no el por
                    qué. Consultar con tech team ya que puede que haya algún
                    archivo corrupto, o no sea de acceso público, o el archivo
                    es demasiado pesado. Tener en cuenta que la información que
                    se muestra aquí corresponde a la última vez que se generó
                    información. Cuando se utiliza el bulk, la información
                    "nueva" pisa la "anterior".{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="button"
                      className={styles.btn}
                      onClick={handleClickSearchResults}
                    >
                      Consultar
                    </Button>
                  </div>
                </div>
                {loadingLogs ? (
                  <ActivityIndicator size="small" />
                ) : (
                  <div
                    style={{
                      marginTop: 20,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {resultsBulkCreate.split("\n\n").map((log) => {
                      return (
                        <p key={log} style={{ fontSize: 14, color: "black" }}>
                          {log}
                        </p>
                      );
                    })}
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default BulkCreate;
