import {
  Space,
  Table,
  Button,
  notification,
  Modal,
  Input,
} from "antd";
import type { ColumnsType } from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "./listening.module.css";
import { FC, useEffect, useState } from "react";
import { useListening } from "../../hooks/useListening";
import { getListenings_getListenings } from "../../__generated__/gql-types/getListenings";

const { Search } = Input;

const Listening: FC = () => {
  const { listenings, loading: isLoading, deleteListening } = useListening();
  const [dataSource, setdataSource] = useState<
    getListenings_getListenings[] | null
  >([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [idListening, setIdListening] = useState<string>("");

  useEffect(() => {
    setdataSource(listenings);
  }, [listenings]);

  const showModal = (id: string) => {
    setIsModalVisible(true);
    setIdListening(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      await deleteListening(idListening);
      setIsModalVisible(false);
    } catch {
      notification["error"]({
        message: "Eliminación Erronea",
        description: "El audio no pudo ser eliminado!",
        placement: "top",
        duration: 3,
      });
    }
  };

  const columns: ColumnsType<getListenings_getListenings> = [
    {
      align: "center",
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    { align: "center", title: "Título", dataIndex: "title", key: "title" },
    {
      align: "center",
      title: "Acciones",
      key: "acciones",
      render: (_, record) => {
        return (
          <Space size={15} style={{ fontSize: 19 }}>
            <Link to={`/listening/${record.id}`} state={record.id}>
              <Button icon={<SearchOutlined />}>Editar</Button>
            </Link>
            <Button
              onClick={() => {
                record && record.id && showModal(record.id);
              }}
              danger
            >
              Eliminar
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    if (value === "") {
      setdataSource(listenings);
    } else {
      if (dataSource) {
        const filteredData: getListenings_getListenings[] = dataSource.filter(
          (data) => data.title?.includes(value)
        );
        setdataSource(filteredData);
      }
    }
  };

  const onSearchId = (value: string) => {
    if (value === "") {
      setdataSource(listenings);
    } else {
      if (dataSource) {
        const filteredData: getListenings_getListenings[] = dataSource.filter(
          (data) => data.id === value
        );
        setdataSource(filteredData);
      }
    }
  };

  return (
    <div className={styles.container}>
      <h1>Audios</h1>
      <div className={styles.newQuizzesContainer}>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por id"
            onSearch={onSearchId}
            enterButton
          />
        </Space>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por título"
            onSearch={onSearch}
            enterButton
          />
        </Space>
        <Button type="primary" danger>
          <Link to="/listening/new">Nuevo Audio</Link>
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource ?? []}
        loading={isLoading}
        rowKey={(record) => (record && record.id ? record.id : "")}
      />
      <Modal
        title="Eliminar Audio"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>¿Desea eliminar el audio?</p>
      </Modal>
    </div>
  );
};

export default Listening;
