import { ChangeEvent, useMemo } from "react";
import { Col, Form, InputNumber, Row } from "antd";
import styles from "./listeningForm.module.css";
import { AudioData } from "./ListeningForm.types";
import { UploadAudio } from "../../UploadFile/UploadAudio";

interface FileAudioProps {
  id: string;
  ext: string;
}

interface ContentIterationComponentProps {
  type: string;
  content: {
    audioData: AudioData;
    file: FileAudioProps;
  };
  setContent: React.Dispatch<
    React.SetStateAction<{
      audioData: AudioData;
      file: FileAudioProps;
    }>
  >;
}

export const ContentIterationComponent = ({
  type,
  content,
  setContent,
}: ContentIterationComponentProps) => {
  const label = useMemo(() => {
    if (type === "listening1") return "Listening 1";
    if (type === "listening2") return "Listening 2";
    if (type === "listening3") return "Listening 3";
    return "Canción";
  }, [type]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: 20,
        padding: 20,
        border: "1px solid gray",
        borderRadius: 20,
      }}
    >
      <Row style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
        <label htmlFor="theme">{label}</label>
      </Row>

      <Row>
        <Col>
          <div>Actividad número</div>
        </Col>
        <Col span={4}>
          <Form>
            <InputNumber
              value={content.audioData.activity_number}
              style={{ marginLeft: 30, marginBottom: 10, borderRadius: 5 }}
              onChange={(e) => {
                content.audioData.activity_number = e as number;
                setContent({ ...content });
              }}
            />
          </Form>
        </Col>

        <Col span={8}>
          <UploadAudio
            folder="audios"
            content={content}
            setContent={setContent}
          />
        </Col>
      </Row>

      <div>
        <textarea
          name="content"
          rows={10}
          className={styles.formFieldSelect}
          defaultValue={content.audioData.text || ""}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            if (content) {
              content.audioData.text = e.target.value;
              setContent({ ...content });
            }
          }}
        />
      </div>
    </div>
  );
};
