import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UserInput } from "../__generated__/gql-types/globalTypes";

const UseCreateUser = () => {
  const client = useApolloClient();

  return useCallback(
    async (userInput: UserInput) => {
      return await client.mutate({
        mutation: CREATE_USER,
        fetchPolicy: "no-cache",
        variables: { userInput },
      });
    },
    [client]
  );
};

export default UseCreateUser;

const CREATE_USER = gql`
  mutation CreateUser($userInput: UserInput!) {
    createUser(userInput: $userInput) {
      id
      email
      first_name
      last_name
      role
      created_at
      schools {
        name
        id
      }
    }
  }
`;
