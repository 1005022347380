import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetUserBySchoolId } from "../__generated__/gql-types/GetUserBySchoolId";

const UseGetUsersClassroomBySchoolId = () => {
  const client = useApolloClient();

  return useCallback(
    async (schoolId: number) => {
      const result: ApolloQueryResult<GetUserBySchoolId> = await client.query({
        query: GET_USERS,
        fetchPolicy: "no-cache",
        variables: {
          schoolId,
        },
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }
      return result.data.getUserBySchoolId;
    },
    [client]
  );
};

export default UseGetUsersClassroomBySchoolId;

const GET_USERS = gql`
  query GetUserBySchoolId($schoolId: Int) {
    getUserBySchoolId(schoolId: $schoolId) {
      id
      first_name
      last_name
      role
    }
  }
`;
