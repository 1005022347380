import React, { useEffect, useState } from "react";
import { Button, Space, Table, Modal } from "antd";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/lib/table";
import { InputSearch } from "../../components";
import styles from "./user.module.css";
import {
  GetUsers_getUsers,
  GetUsers_getUsers_schools,
} from "./../../__generated__/gql-types/GetUsers";
import { Roles } from "../../__generated__/gql-types/globalTypes";
import { useUsers } from "../../hooks/useUsers";

const Users: React.FC = () => {
  const { deleteUser, loading: isLoading, users } = useUsers();
  const [dataSource, setdataSource] = useState<GetUsers_getUsers[] | null>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [idUser, setIdUser] = useState<string>("");

  const showModal = (id: string) => {
    setIsModalVisible(true);
    setIdUser(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setdataSource(users);
  }, [users]);

  const handleOk = async () => {
    await deleteUser(idUser);
    setIsModalVisible(false);
  };

  const columns: ColumnsType<GetUsers_getUsers> = [
    {
      align: "center",
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      align: "center",
      title: "First Name",
      dataIndex: "first_name",
      key: "firstname",
    },
    {
      align: "center",
      title: "Last Name",
      dataIndex: "last_name",
      key: "lastname",
    },
    {
      align: "center",
      title: "Rol",
      dataIndex: "role",
      key: "role",
      render: (text) => {
        switch (text) {
          case Roles.teacher:
            return "Profesor";
          case Roles.school:
            return "Escuela";
          case Roles.admin:
            return "Admin";
          case Roles.coordinator:
            return "Coordinador";
          default:
            return "Alumno";
        }
      },
    },
    {
      align: "center",
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      align: "center",
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => text.split("T")[0],
    },
    {
      align: "center",
      title: "Schools",
      dataIndex: "schools",
      key: "schools",
      render: (text) => {
        return text.map((school: GetUsers_getUsers_schools, index: number) => {
          return (
            <div className={styles.textDraw} key={index}>
              {index + 1}. {school.name}
            </div>
          );
        });
      },
    },
    {
      align: "center",
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size={15} style={{ fontSize: 19 }}>
            <Link to={`/users/${record.id}`} state={record.id}>
              <Button icon={<SearchOutlined />}>Editar</Button>{" "}
            </Link>
            <Button
              onClick={() => {
                showModal(record.id);
              }}
              danger
            >
              Eliminar
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div className={styles.container}>
      <h1>Usuarios</h1>
      <div className={styles.userContent}>
        <InputSearch
          data={users ?? []}
          dataSource={dataSource ?? []}
          setdataSource={setdataSource}
        />
        <Button type="primary" danger>
          <Link to="/users/new">Nuevo Usuario</Link>
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource ?? []}
        loading={isLoading}
        rowKey={(record) => record.id}
      />
      <Modal
        title="Eliminar Usuario"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>¿Desea eliminar el usuario?</p>
      </Modal>
    </div>
  );
};

export default Users;
