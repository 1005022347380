import * as Yup from "yup";
import { ProgramInput } from "../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: ProgramInput = {
  title: "",
  description: "",
  subject_id: 2,
  grade_id: 1,
};

export const NewProgramsSchema = Yup.object().shape({
  title: Yup.string().required("El título es requerido."),
  description: Yup.string().required("La descripción es requerida."),
});
