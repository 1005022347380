import { notification } from "antd";

export const successNotification = () =>
  notification["success"]({
    message: "Creación Exitosa",
    description:
      "Generando usuarios, clases, eventos, promedios y puntos!! Esperá por favor a que todo se cree correctamente.",
    placement: "top",
    duration: 3,
  });

export const errorNotification = () =>
  notification["error"]({
    message: "Error",
    description:
      "Se ha producido un error, por favor revise la sección de usuarios para comprobar la creación. Si usaste el usuario de Brenda, deberías revisar los últimos 20 usuarios.",
    placement: "top",
    duration: 3,
  });

export const updatedNotification = () =>
  notification["success"]({
    message: "Modificación Exitosa",
    description: "La Lección fue modificada con éxito!",
    placement: "top",
    duration: 3,
  });
