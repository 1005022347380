import { Layout, Menu, MenuProps } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import styles from "./HeaderLuca.module.css";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";

const { Header } = Layout;
type MenuItem = Required<MenuProps>["items"][number];
const HeaderLuca = () => {
  function getItem(
    label?: React.ReactNode,
    key?: React.Key,
    icon?: React.ReactNode,
    item?: React.ReactNode
  ): MenuItem {
    return {
      key,
      icon,
      item,
      label,
    } as MenuItem;
  }
  const { logout } = useAuth();

  const menuItems: MenuItem[] = [
    getItem(
      "Mi perfil",
      "1",
      <Link to="/perfil">
        <UserOutlined />
      </Link>
    ),
    getItem(
      " Salir",
      "2",
      <a
        onClick={async () => {
          await logout();
        }}
      >
        <LogoutOutlined />
      </a>
    ),
  ];

  return (
    <Header className={styles.header}>
      <Menu
        mode="horizontal"
        style={{ float: "right", borderBottom: "1px solid black" }}
        items={menuItems}
      ></Menu>
    </Header>
  );
};

export default HeaderLuca;
