import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { deleteClothesAndAccessoriesVariables } from "../__generated__/gql-types/deleteClothesAndAccessories";

const useDeleteClothesAndAccessories = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: deleteClothesAndAccessoriesVariables) => {
      return await client.mutate({
        mutation: DLETE_CLOTHES_AND_ACCESSORIES,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useDeleteClothesAndAccessories;

const DLETE_CLOTHES_AND_ACCESSORIES = gql`
  mutation deleteClothesAndAccessories($deleteClothesAndAccessoriesId: ID!) {
    deleteClothesAndAccessories(id: $deleteClothesAndAccessoriesId) {
      id
    }
  }
`;
