import React, { ChangeEvent } from "react";
import { Col, Row } from "antd";
import { ErrorMessage, Field } from "formik";
import { QuestionInput } from "../../../../../__generated__/gql-types/globalTypes";
import AnswerFillTheBlankComponent from "./AnswerFillTheBlankDragAndDropComponent";
import styles from "../../quizzForm.module.css";
import { UploadImageFile } from "../../../../UploadFile";

interface FillTheBlankDragAndDropQuestionProps {
  index: number;
  question: QuestionInput;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => void;
  handleAddAnswer: () => Promise<void>;
  handleRemoveAnswer: () => void;
  loadingLoadImage: boolean;
  setLoadingLoadImage: React.Dispatch<React.SetStateAction<boolean>>;
  urlImageQuestion: string;
  setUrlImageQuestion: React.Dispatch<React.SetStateAction<string>>;
  handleImageQuestion: (newImage: string) => void;
}

export const FillTheBlankDragAndDropQuestion = ({
  index,
  question,
  handleChange,
  handleAddAnswer,
  handleRemoveAnswer,
  loadingLoadImage,
  setLoadingLoadImage,
  urlImageQuestion,
  setUrlImageQuestion,
  handleImageQuestion,
}: FillTheBlankDragAndDropQuestionProps) => {
  return (
    <Col
      key={`${index}_${new Date()}`}
      style={{
        backgroundColor: "#E6E7EF",
        borderRadius: 20,
        paddingRight: 30,
        paddingLeft: 30,
        paddingTop: 10,
      }}
    >
      <div style={{ marginBottom: 10, marginTop: 5 }}>
        <label htmlFor={`questions_${index}`}>Pregunta {index}</label>
      </div>
      <Row>
        <Field
          id={`questions_${index}`}
          name="question"
          type="text"
          className={styles.formFieldSelect}
          defaultValue={question?.question ?? ""}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleChange(event, index);
          }}
        ></Field>
        <ErrorMessage
          name="question"
          component="div"
          className={styles.error}
        />
      </Row>
      <Row>
        <UploadImageFile
          title={`Imagen de pregunta ${index}`}
          className={styles.uploadFile}
          questionTypeId={question.type_id}
          typeFile="questions"
          folder="questions"
          widthCover={300}
          heightCover={200}
          index={index}
          image={question.image ?? ""}
          urlImageQuestion={urlImageQuestion}
          loadingLoadImage={loadingLoadImage}
          setLoadingLoadImage={setLoadingLoadImage}
          handleImageQuestion={handleImageQuestion}
          setUrlImageQuestion={setUrlImageQuestion}
        />
      </Row>
      <AnswerFillTheBlankComponent
        handleAddAnswer={handleAddAnswer}
        handleChange={handleChange}
        handleRemoveAnswer={handleRemoveAnswer}
        question={question}
      />
    </Col>
  );
};
