import { combineReducers } from "redux";
import auth, { AuthState } from "./slices/auth";
import data, { DataState } from "./slices/data";

export type RootState = {
  auth: AuthState;
  data: DataState;
};

const reducer = combineReducers({
  auth: auth.reducer,
  data: data.reducer,
});

export default reducer;
