import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { CreateManyUsersInput } from "../__generated__/gql-types/globalTypes";

const UseCreateManyUsers = () => {
  const client = useApolloClient();

  return useCallback(
    async (createManyUsersInput: CreateManyUsersInput) => {
      return await client.mutate({
        mutation: CREATE_MANY_USERS,
        fetchPolicy: "no-cache",
        variables: { createManyUsersInput },
      });
    },
    [client]
  );
};

export default UseCreateManyUsers;

const  CREATE_MANY_USERS = gql`
 mutation CreateManyUsers($createManyUsersInput: CreateManyUsersInput!) {
  createManyUsers(createManyUsersInput: $createManyUsersInput) {
    success
  }
}
`;
