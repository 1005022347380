import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { DeleteLessonsVariables } from "../__generated__/gql-types/DeleteLessons";

const useDeleteLessons = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: DeleteLessonsVariables) => {
      return await client.mutate({
        mutation: DELETE_LESSONS,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useDeleteLessons;

const DELETE_LESSONS = gql`
  mutation DeleteLessons($deleteLessonId: ID!) {
    deleteLesson(id: $deleteLessonId) {
      title
    }
  }
`;
