import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetPrograms } from "../__generated__/gql-types/GetPrograms";

const UseGetPrograms = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetPrograms> = await client.query({
      query: GET_PROGRAMS,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getPrograms;
  }, [client]);
};

export default UseGetPrograms;

const GET_PROGRAMS = gql`
  query GetPrograms {
    getPrograms {
      id
      title
      description
      subject {
        name
        id
      }
      grade {
        name
        id
      }
      deleted_at
    }
  }
`;
