import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetUsers } from "../__generated__/gql-types/GetUsers";

const UseGetUsers = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetUsers> = await client.query({
      query: GET_USERS,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }
    return result.data.getUsers;
  }, [client]);
};

export default UseGetUsers;

const GET_USERS = gql`
  query GetUsers {
    getUsers {
      id
      email
      first_name
      last_name
      role
      created_at
      schools {
        name
        id
      }
    }
  }
`;
