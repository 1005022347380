import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetClassrooms } from "../__generated__/gql-types/GetClassrooms";

const UseGetClassroom = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetClassrooms> = await client.query({
      query: GET_CLASSROOM,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getClassrooms;
  }, [client]);
};

export default UseGetClassroom;

const GET_CLASSROOM = gql`
  query GetClassrooms {
    getClassrooms {
      id
      name
      program {
        id
        title
        grade {
          id
          name
        }
      }
      group {
        id
        name
      }
      deleted_at
    }
  }
`;
