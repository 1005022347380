import { useEffect, useState } from "react";
import axios from "axios";
import jwt from "jwt-decode";
import { GetUserByEmail_getUserByEmail } from "../__generated__/gql-types/GetUserByEmail";
import { notification } from "antd";
import { apiLuca } from "../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { TOKEN, USER_AUTH } from "../store/selectors";

interface UseAuthType {
  user: GetUserByEmail_getUserByEmail | null;
  loading: boolean;
  error?: any;
  isAuthenticated: boolean;
  token: string;
  login: (emailOrUsername: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

export const useAuth = (): UseAuthType => {
  const dispatch = useDispatch();
  const user = useSelector(USER_AUTH);
  const token = useSelector(TOKEN);
  const [error, setError] = useState<any>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchInfo = async () => {
    setLoading(true);
    let localToken = localStorage.getItem("token");
    try {
      if (!localToken) {
        setError(
          "Oops... Ocurrió un error inesperado, por favor, inicia sesión!"
        );
        clearAuthStorage();
      } else {
        setError(null);
        setIsAuthenticated(true);
        const foundUser: GetUserByEmail_getUserByEmail | null =
          await getUserWithToken(localToken);
        foundUser &&
          dispatch({
            type: "auth/setAuth",
            payload: {
              token: localToken,
              user: foundUser,
            },
          });
      }
    } catch (error) {
      setIsAuthenticated(false);
      setError(
        "Oops... Ocurrió un error inesperado, por favor, inicia sesión!"
      );
      clearAuthStorage();
    } finally {
      setLoading(false);
    }
  };

  const getUserWithToken = async (
    token: string
  ): Promise<GetUserByEmail_getUserByEmail | null> => {
    if (!token) return null;
    const user: GetUserByEmail_getUserByEmail = await jwt(token);

    return user;
  };

  const login = async (emailOrUsername: string, password: string) => {
    setLoading(true);
    try {
      const { data } = await axios.post(`${apiLuca}/login`, {
        emailOrUsername,
        password,
      });
      localStorage.setItem("token", data.token);
      setIsAuthenticated(true);
      const foundUser: GetUserByEmail_getUserByEmail | null =
        await getUserWithToken(data.token);
      foundUser &&
        dispatch({
          type: "auth/setAuth",
          payload: {
            token: data.token,
            user: foundUser,
          },
        });
    } catch (error) {
      setIsAuthenticated(false);
      notification["error"]({
        message: "Error",
        description: "Usuario o contraseña incorrectos!!",
        placement: "top",
        duration: 3,
      });
    } finally {
      setLoading(false);
    }
  };

  const clearAuthStorage = () => {
    localStorage.setItem("token", "");
    dispatch({
      type: "auth/clearAuth",
    });
  };

  const logout = async () => {
    setLoading(true);
    try {
      clearAuthStorage();
      setIsAuthenticated(false);
    } catch (error) {
      setIsAuthenticated(false);
      notification["error"]({
        message: "Error",
        description: "Error al desloguearse, por favor reintente!",
        placement: "top",
        duration: 3,
      });
    } finally {
      setError("");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, [token]);

  return {
    user,
    loading,
    error,
    isAuthenticated,
    token,
    login,
    logout,
  };
};
