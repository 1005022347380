import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { SubjectInput } from "../__generated__/gql-types/globalTypes";

const UseCreateSubject = () => {
  const client = useApolloClient();

  return useCallback(
    async (subjectInput: SubjectInput) => {
      return await client.mutate({
        mutation: CREATE_SUBJECT,
        fetchPolicy: "no-cache",
        variables: { subjectInput },
      });
    },
    [client]
  );
};

export default UseCreateSubject;

const CREATE_SUBJECT = gql`
  mutation CreateSubject($subjectInput: SubjectInput) {
    createSubject(subjectInput: $subjectInput) {
      id
      code
      name
      color_class
      publish
    }
  }
`;
