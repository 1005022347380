import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./generate.module.css";
import { Field, Form, Formik, FormikProps } from "formik";
import useGetListFolder from "../../api/useGetListFolder";
import { ListFolders_listFolders } from "../../__generated__/gql-types/ListFolders";
import { Button, Col, Row, Select } from "antd";
import { Buttons } from "../../components";
import useGenerateDocs from "../../api/useGenerateDocs";
import { errorNotification, successNotification } from "./utils";
import { ActivityIndicator } from "../../components/ActivityIndicator";
import UseCreateFolder from "../../api/useCreateFolder";

const GenerateContent = () => {
  const [initialValues, setInitialValues] = useState({
    link: "",
    folderId: "",
    newFolder: "",
  });
  const [loading, setLoading] = useState(false);
  const [listFolders, setListFolders] = useState<
    (ListFolders_listFolders | null)[] | null
  >([]);
  const getListFolder = useGetListFolder();
  const generateDocs = useGenerateDocs();
  const createFolder = UseCreateFolder();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      const listsFolderData = await getListFolder();
      setListFolders(listsFolderData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const listsFolderData = await getListFolder();
      setListFolders(listsFolderData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const handleSubmit = async (formValues: any) => {
    setLoading(true);
    try {
      const response = await generateDocs({
        sheetUrl: formValues.link,
        folder: formValues.folderId,
      });

      response.data && successNotification();
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setLoading(false);
      errorNotification();
    }
  };

  const createNewFolder = async () => {
    try {
      await createFolder({
        newFolderName: initialValues.newFolder,
      });
    } catch (error) {
      console.log("error creating folder: ", error);
    }
  };

  if (loading) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      <h1>Generar Contenido</h1>
      <div className={styles.lessonsContent}></div>
      <div className={styles.inputGenerate}>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ errors, handleReset, touched, values }: FormikProps<any>) => {
            return (
              <Form className={styles.formContainer}>
                <label htmlFor=""> Ingrese el link de spreadsheet</label>
                <Field
                  errors={errors}
                  touched={touched}
                  value={initialValues.link}
                  className={styles.formFields}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInitialValues({
                      ...initialValues,
                      link: e.target.value,
                    });
                  }}
                  name="link"
                  title="Link"
                  type="text"
                />

                <Col>
                  <Row>
                    <label htmlFor="">
                      {" "}
                      Si desea crear una carpeta, ingrese el nombre de la nueva
                      carpeta
                    </label>
                    <Field
                      errors={errors}
                      touched={touched}
                      value={initialValues.newFolder}
                      className={styles.formFields}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setInitialValues({
                          ...initialValues,
                          newFolder: e.target.value,
                        });
                      }}
                      name="link"
                      title="Link"
                      type="text"
                    />
                    <Button type="primary" onClick={createNewFolder}>
                      Crear
                    </Button>
                  </Row>
                </Col>

                <label htmlFor="">
                  {" "}
                  Seleccione una carpeta dónde guardar los documentos
                </label>
                <Select
                  value={initialValues.folderId ?? ""}
                  className={styles.formFields}
                  onChange={(value: string) => {
                    console.log({ value });
                    setInitialValues({
                      ...initialValues,
                      folderId: value,
                    });
                  }}
                  onClick={fetchData}
                  options={listFolders?.map((lf) => ({
                    value: lf!.id!,
                    label: lf!.name!,
                  }))}
                />

                <div
                  style={{
                    margin: 60,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p style={{ fontSize: 18, color: "black" }}>
                    Una vez ejecutada la función, no se puede cortar su
                    ejecución haciendo varias cosas al mismo tiempo. Lo que se
                    debe hacer es utilizarla de a una vez esperando a que
                    termine la ejecución actual y verificando que los archivos
                    fueron creados en la carpeta específica.
                  </p>
                  <p style={{ fontSize: 18, color: "black" }}>
                    Para crear una carpeta, debemos primero ingresar el nombre
                    de la carpeta en el input que te pide que ingreses una
                    carpeta y luego debes seleccionarla en la lista de carpetas
                    del input de abajo, ¡¡¡ATENCIÓN!!! Si no se selecciona
                    ninguna carpeta, se van a crear en la carpeta base que es la
                    siguiente:{" "}
                    <a
                      href="https://drive.google.com/drive/folders/1euvBw1QB5FEBEgjaHx0iuenG39vCX4ti"
                      style={{ color: "blue" }}
                    >
                      enlace
                    </a>
                  </p>
                  <p style={{ fontSize: 18, color: "black" }}>
                    RECOMENDACIÓN: Si desea cargar contenido aquí y a su vez
                    utilizar otras funcionalidades del backoffice, utilizar otra
                    pestaña diferente para el resto de las acciones que no
                    tengan que ver con la generación de contenido.
                  </p>
                  <p style={{ fontSize: 18, color: "black" }}>
                    La función no cuenta con una verificación de resultado por
                    cuestiones técnicas, lo que se debe hacer es ir a revisar a
                    mano que todos los archivos se hayan generado correctamente
                    en la carpeta seleccionada.
                  </p>
                </div>

                <Buttons
                  handleClick={() => {
                    handleReset();
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default GenerateContent;
