import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetRedactions } from "../__generated__/gql-types/GetRedactions";

const UseGetRedactions = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetRedactions> = await client.query({
      query: GET_REDACTIONS,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getRedactions;
  }, [client]);
};

export default UseGetRedactions;

const GET_REDACTIONS = gql`
  query GetRedactions {
    getRedactions {
      id
      title
      created_at
    }
  }
`;
