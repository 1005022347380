import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetSubjects } from "../__generated__/gql-types/GetSubjects";

const UseGetSubjects = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetSubjects> = await client.query({
      query: GET_SUBJECTS,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }
    return result.data.getSubjects;
  }, [client]);
};

export default UseGetSubjects;

const GET_SUBJECTS = gql`
  query GetSubjects {
    getSubjects {
      id
      code
      name
      color_class
      publish
      deleted_at
    }
  }
`;
