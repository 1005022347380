import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { createNotificationVariables } from "../__generated__/gql-types/createNotification";

const UseCreateNotification = () => {
  const client = useApolloClient();

  return useCallback(
    async (notificationInput: createNotificationVariables) => {
      return await client.mutate({
        mutation: CREATE_NOTIFICATION,
        fetchPolicy: "no-cache",
        variables: notificationInput,
      });
    },
    [client]
  );
};

export default UseCreateNotification;

const CREATE_NOTIFICATION = gql`
  mutation createNotification($notificationInput: NotificationInput) {
    createNotification(notificationInput: $notificationInput) {
      id
      title
      message
      media
      role
      school_id
      created_at
      updated_at
      deleted_at
    }
  }
`;
