import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UpdateProgramVariables } from "../__generated__/gql-types/UpdateProgram";

const UseUpdatePrograms = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: UpdateProgramVariables) => {
      return await client.mutate({
        mutation: UPDATE_PROGRAMS,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseUpdatePrograms;
const UPDATE_PROGRAMS = gql`
  mutation UpdateProgram($updateProgramId: ID!, $programInput: ProgramInput) {
    updateProgram(id: $updateProgramId, programInput: $programInput) {
      id
      title
      description
      subject {
        name
        id
      }
      grade {
        name
        id
      }
      deleted_at
    }
  }
`;
