import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getLiteracyById,
  getLiteracyByIdVariables,
} from "../__generated__/gql-types/getLiteracyById";

const UseGetLiteracyById = () => {
  const client = useApolloClient();

  return useCallback(
    async (variables: getLiteracyByIdVariables) => {
      const result: ApolloQueryResult<getLiteracyById> = await client.query({
        query: GET_LITERACY_BY_ID,
        fetchPolicy: "no-cache",
        variables,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getLiteracyById;
    },
    [client]
  );
};

export default UseGetLiteracyById;

const GET_LITERACY_BY_ID = gql`
  query getLiteracyById($literacyId: ID!) {
    getLiteracyById(literacy_id: $literacyId) {
      content {
        type
        image
        contentType
        contentLeft
        contentRigth
        text
        order
      }
      cover
      header_color
      copyright
      id
      publish
      title
    }
  }
`;
