import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { createListeningVariables } from "../__generated__/gql-types/createListening";

const UseCreateListening = () => {
  const client = useApolloClient();
  return useCallback(
    async (listeningInput: createListeningVariables) => {
      return await client.mutate({
        mutation: CREATE_LISTENING,
        fetchPolicy: "no-cache",
        variables: listeningInput,
      });
    },
    [client]
  );
};

export default UseCreateListening;

const CREATE_LISTENING = gql`
  mutation createListening($listeningInput: ListeningInput) {
    createListening(listeningInput: $listeningInput) {
      id
      title
    }
  }
`;
