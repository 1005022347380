import { useCallback } from "react";
import { getClassroomByIdBackOffice } from "../__generated__/gql-types/getClassroomByIdBackOffice";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

const UseGetClassroomById = (classroomId: string) => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getClassroomByIdBackOffice> = await client.query({
      query: GET_CLASSROOM_BY_ID,
      fetchPolicy: "no-cache",
      variables: {
        classroomId,
      },
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getClassroomByIdBackOffice;
  }, [client]);
};

export default UseGetClassroomById;

const GET_CLASSROOM_BY_ID = gql`
  query getClassroomByIdBackOffice($classroomId: ID!) {
    getClassroomByIdBackOffice(classroomId: $classroomId) {
      id
      name
      program {
        id
        title
      }
      program_id
      group {
        id
        name
        users {
          id
          first_name
          last_name
        }
        school {
          id
          name
        }
        grade {
          id
          name
        }
      }
      group_id
      school {
        id
        name
        cct
      }
      school_id
      teachers {
        id
        first_name
        last_name
      }
    }
  }
`;
