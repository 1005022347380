import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { updateAIConversationVariables } from "../__generated__/gql-types/updateAIConversation";

const UseUpdateAIConversations = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: updateAIConversationVariables) => {
      return await client.mutate<any>({
        mutation: UPDATE_AICONVERSATIONS,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default UseUpdateAIConversations;

const UPDATE_AICONVERSATIONS = gql`
  mutation updateAIConversation(
    $aiconversationId: Int
    $aiconversationInput: AIConversationsInput
  ) {
    updateAIConversation(
      aiconversation_id: $aiconversationId
      aiconversationInput: $aiconversationInput
    ) {
      id
      title
      publish
      level_cerf
      character_for_role_play
      card_image
      cover_image
      avatar_image
      section_1 {
        question
        order
      }
      section_2 {
        question
        order
      }
      created_at
      updated_at
      deleted_at
    }
  }
`;
