import React, { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./lessonsForm.module.css";
import { Buttons } from "../..";
import { Select, Row, Col, notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, FormikProps } from "formik";
import UseCreateLesson from "../../../api/useCreateLessons";
import UseUpdateLesson from "../../../api/useUpdateLessons";
import { DynamicSteps } from "../../DynamicSteps";
import { initialValuesObj } from "./lessonsForm.types";

// API QUERIES
import {
  LessonContentInput,
  LessonInput,
} from "./../../../__generated__/gql-types/globalTypes";
import { GetAxes_getAxes } from "./../../../__generated__/gql-types/GetAxes";
import { GetThemes_getThemes } from "./../../../__generated__/gql-types/GetThemes";
import { GetTags_getTags } from "./../../../__generated__/gql-types/GetTags";
import {
  errorNotification,
  errorNotificationVideoQuiz,
  successNotification,
  updatedNotification,
} from "./utils";
import { ActivityIndicator } from "../../ActivityIndicator";
import { UploadPdfFile } from "../../UploadFile/UploadPdfFile";
import UseGetLessonById from "../../../api/useGetLessonById";
import {
  GetLessonById_getLessonById,
  GetLessonById_getLessonById_lesson_content,
} from "../../../__generated__/gql-types/GetLessonById";
import { useTags } from "../../../hooks/useTags";
import { useThemes } from "../../../hooks/useThemes";
import { useVideos } from "../../../hooks/useVideos";
import { useAxes } from "../../../hooks/useAxes";
import { useSubject } from "../../../hooks/useSubject";
import { useQuizzes } from "../../../hooks/useQuizzes";
import { useGrades } from "../../../hooks/useGrades";
import { useLessons } from "../../../hooks/useLessons";
import { GetLessons_getLessons } from "../../../__generated__/gql-types/GetLessons";
import { useDispatch } from "react-redux";
import { DividerComponent } from "../../Divider/Divider";
import { useEssays } from "../../../hooks/useEssays";
import { useLiteracy } from "../../../hooks/useLiteracy";
import { useAIConversations } from "../../../hooks/useAIConversations";
import { useListening } from "../../../hooks/useListening";

const { Option } = Select;

export interface PropsStep {
  step: string;
  video: string;
}

const LessonsForm: FC = () => {
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] =
    useState<LessonInput>(initialValuesObj);
  const [typeLessonSelected, setTypeLessonSelected] =
    useState<string>("videolesson");

  const createLesson = UseCreateLesson();
  const updateLesson = UseUpdateLesson();
  const getLessonById = UseGetLessonById();

  const { tags, loading: loadingTags } = useTags();
  const { themes, loading: loadingThemes } = useThemes();
  const { videos, loading: loadingVideos } = useVideos();
  const { axes, loading: loadingAxes } = useAxes();
  const { subjects, loading: loadingSubjects } = useSubject();
  const { quizzes, loading: loadingQuizzes } = useQuizzes();
  const { essays, loading: loadingEssays } = useEssays();
  const { literacies, loading: loadingLiteracy } = useLiteracy();
  const { listenings, loading: loadingListening } = useListening();
  const { grades, loading: loadingGrades } = useGrades();
  const { lessons, loading: loadingLessons } = useLessons();
  const { aiConversations, loading: loadingAIConversations } =
    useAIConversations();

  const [lessonById, setLessonById] =
    useState<GetLessonById_getLessonById | null>(null);
  const [steps, setSteps] = useState<PropsStep[]>([]);
  const [redactionId, setRedactionId] = useState<number | null>(null);
  const [literacyId, setLiteracyId] = useState<number | null>(null);
  const [listeningId, setListeningId] = useState<number | null>(null);
  const [conversationId, setConversationId] = useState<number | null>(null);
  const [publish, setPublish] = useState<boolean>(false);
  const [filename, setFilename] = useState({
    id: "",
    ext: "",
  });
  const [slideFile, setSlideFile] = useState({
    id: "",
    ext: "",
  });
  const [responsesPdf, setResponsesPdf] = useState({
    id: "",
    ext: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const data = location?.state as GetLessonById_getLessonById;

  const resolveSteps = async (
    lesson_content: GetLessonById_getLessonById_lesson_content,
    lesson_type: string
  ) => {
    setSteps([]);
    return new Promise((resolve, reject) => {
      let response: PropsStep[] = [];
      try {
        if (lesson_content) {
          if (lesson_content?.quizzes && lesson_content?.quizzes?.length > 0) {
            lesson_content!.quizzes!.forEach((value) => {
              response.push({ step: value.id!, video: "quiz" });
            });
          } else {
            if (
              lesson_type !== "redaction" &&
              lesson_type !== "literacy" &&
              lesson_type !== "listening" &&
              lesson_type !== "conversation" &&
              lesson_type !== "audio_exam"
            ) {
              response.push({ step: "", video: "quiz" });
            }
          }

          if (
            lesson_type === "videolesson" ||
            lesson_type === "audio" ||
            lesson_type === "audio_exam"
          ) {
            if (lesson_content?.videos && lesson_content?.videos?.length > 0) {
              lesson_content!.videos!.forEach((value) => {
                response.push({ step: value.id!, video: "video" });
              });
            } else {
              response.push({ step: "", video: "video" });
            }
          }

          if (lesson_type === "redaction") {
            if (lesson_content?.redaction) {
              response.push({
                step: lesson_content?.redaction.id,
                video: "essay",
              });
            } else {
              response.push({ step: "", video: "essay" });
            }
          }

          if (lesson_type === "literacy") {
            if (lesson_content?.literacy) {
              lesson_content?.literacy &&
                lesson_content?.literacy.id &&
                response.push({
                  step: lesson_content?.literacy.id,
                  video: "literacy",
                });
            } else {
              response.push({ step: "", video: "literacy" });
            }
          }

          if (lesson_type === "listening") {
            if (lesson_content?.listening) {
              lesson_content?.listening &&
                lesson_content?.listening.id &&
                response.push({
                  step: lesson_content?.listening.id,
                  video: "listening",
                });
            } else {
              response.push({ step: "", video: "listening" });
            }
          }

          if (lesson_type === "conversation") {
            if (
              lesson_content?.aiconversations &&
              lesson_content?.aiconversations.id
            ) {
              response.push({
                step: lesson_content?.aiconversations.id?.toString(),
                video: "conversation",
              });
            } else {
              response.push({ step: "", video: "conversation" });
            }
          }

          if (lesson_content) setSteps(response);
        }
        resolve(true);
      } catch (error) {
        reject([]);
      }
    });
  };

  async function fetchInfo() {
    try {
      setloading(true);
      setSteps([...steps, { step: "", video: "" }]);

      if (data?.id) {
        let lessonByIdInfo = await getLessonById({ lessonId: data.id });
        setLessonById(lessonByIdInfo);

        setPublish(data.publish ? true : false);
        lessonByIdInfo &&
          setInitialValues({
            lesson_content: lessonByIdInfo?.lesson_content ?? {},
            title: lessonByIdInfo?.title ?? "",
            description: lessonByIdInfo?.description ?? "",
            axes: lessonByIdInfo?.axes ?? [],
            lesson_type: lessonByIdInfo?.lesson_type ?? "videolesson",
            themes: lessonByIdInfo?.themes ?? [],
            nem: lessonByIdInfo.nem ?? false,
            drive_link: lessonByIdInfo?.drive_link ?? "",
            tags: lessonByIdInfo?.tags ?? [],
            learnings: lessonByIdInfo?.learnings ?? "",
            subject_id: Number(lessonByIdInfo?.subject!.id) ?? 1,
            grade_id: Number(lessonByIdInfo?.grade!.id) ?? 1,
            publish: lessonByIdInfo?.publish ? 1 : 0,
            sep_book_pages: lessonByIdInfo?.sep_book_pages ?? "",
            sep_name: lessonByIdInfo?.sep_name ?? "",
            order_reading: lessonByIdInfo?.order_reading,
            week: lessonByIdInfo?.week ?? 1,
          });

        await resolveSteps(
          lessonByIdInfo?.lesson_content!,
          lessonByIdInfo?.lesson_type!
        );
        setTypeLessonSelected(lessonByIdInfo?.lesson_type!);
      }
      setloading(false);
    } catch (error: any) {
      setloading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  }
  useEffect(() => {
    fetchInfo();
  }, []);

  const handleChangeAxes = (values: string[]) => {
    const axesValues = values!.map((value) => {
      let id = value.split("-")[1] !== undefined ? value.split("-")[0] : null;
      let name =
        value.split("-")[1] !== undefined ? value.split("-")[1] : value;

      const axe = axes!.find((axis) => axis?.id === id);

      if (axe) {
        return axe;
      } else {
        return {
          id,
          name,
        };
      }
    });

    initialValues.axes = axesValues;
    setInitialValues(initialValues);
  };

  const handleChangeThemes = (values: string[]) => {
    const themesValues = values!.map((value) => {
      let id = value.split("-")[1] !== undefined ? value.split("-")[0] : null;
      let name =
        value.split("-")[1] !== undefined ? value.split("-")[1] : value;

      const selectedTheme = themes!.find((th) => th?.id === value);

      if (selectedTheme) {
        return selectedTheme;
      } else {
        return {
          id,
          name,
        };
      }
    });

    initialValues.themes = themesValues;
    setInitialValues(initialValues);
  };

  const handleChangeTags = (values: string[]) => {
    const tagsValues = values!.map((value) => {
      let id = value.split("-")[1] !== undefined ? value.split("-")[0] : null;
      let name =
        value.split("-")[1] !== undefined ? value.split("-")[1] : value;

      const tag = tags!.find((tag) => tag?.id === value);

      if (tag) {
        return tag;
      } else {
        return {
          id,
          name,
        };
      }
    });

    initialValues.tags = tagsValues;
    setInitialValues(initialValues);
  };

  const handleAdd = () => {
    setSteps([...steps, { step: "", video: "" }]);
  };

  const handleRemove = (index: number) => {
    const list = [...steps];
    list.splice(index, 1);
    setSteps(list);
  };

  const handleChangeSteps = (
    e: { target: { value: string; name: string } },
    index: number
  ) => {
    const { value, name } = e.target;

    const list = [...steps];
    if (name === "step") {
      list[index][name] = value;
    } else {
      list[index]["video"] = value;
    }
    setSteps(list);
  };

  const handleSubmit = async (formValues: LessonInput) => {
    const lessonContentInput: LessonContentInput = {
      quizzes: [],
      videos: [],
      redaction_id: redactionId ?? null,
      aiconversations_id: conversationId ?? null,
      literacy_id: literacyId ?? null,
      listening_id: listeningId ?? null,
      pdf: filename.id ? `${filename.id}.${filename.ext}` : "",
      slide: slideFile.id ? `${slideFile.id}.${slideFile.ext}` : "",
      responses_pdf: responsesPdf.id
        ? `${responsesPdf.id}.${responsesPdf.ext}`
        : "",
    };

    for (let index = 0; index < steps.length; index++) {
      if (steps[index].video === "video" && steps[index].step.length > 0) {
        lessonContentInput.videos?.push({ id: steps[index].step });
      }
      if (steps[index].video === "quiz" && steps[index].step.length > 0) {
        lessonContentInput?.quizzes?.push({ id: steps[index].step });
      }
      if (steps[index].video === "essay" && steps[index].step.length > 0) {
        lessonContentInput.redaction_id = Number(steps[index].step);
      }
      if (steps[index].video === "literacy" && steps[index].step.length > 0) {
        lessonContentInput.literacy_id = Number(steps[index].step);
      }
      if (steps[index].video === "listening" && steps[index].step.length > 0) {
        lessonContentInput.listening_id = Number(steps[index].step);
      }
      if (
        steps[index].video === "conversation" &&
        steps[index].step.length > 0
      ) {
        lessonContentInput.aiconversations_id = Number(steps[index].step);
      }
    }

    if (
      (typeLessonSelected === "videolesson" ||
        typeLessonSelected === "audio") &&
      lessonContentInput.quizzes &&
      lessonContentInput.videos &&
      (lessonContentInput.quizzes?.length === 0 ||
        lessonContentInput.videos.length === 0)
    ) {
      setloading(false);
      errorNotificationVideoQuiz();
    } else {
      let formInputs: LessonInput = {
        ...formValues,
        drive_link: initialValues.drive_link,
        lesson_type: initialValues.lesson_type,
        axes: initialValues.axes,
        themes: initialValues.themes,
        tags: initialValues.tags,
        subject_id: Number(formValues.subject_id),
        grade_id: Number(formValues.grade_id),
        lesson_content: lessonContentInput,
        publish: publish ? 1 : 0,
        learnings: initialValues.learnings,
        order_reading: initialValues.order_reading,
      };

      // console.log({ formInputs });
      // return;
      setloading(true);
      try {
        if (data?.id) {
          const response = await updateLesson({
            lessonInput: formInputs,
            updateLessonId: data.id.toString(),
          });
          response.data && updatedNotification();
          let index = lessons.findIndex((lesson) => lesson.id === data.id);
          let lessonsUpdated = [...lessons];
          lessonsUpdated[index] = response.data
            .updateLesson as unknown as GetLessons_getLessons;

          dispatch({ type: "data/setLessons", payload: lessonsUpdated });
          setloading(false);
          return navigate(-1);
        } else {
          const createLessonRes = await createLesson(formInputs);
          createLessonRes && successNotification();

          let newLessons = [];
          let newLesson = createLessonRes.data
            .createLesson as unknown as GetLessons_getLessons;
          newLessons = [newLesson, ...lessons];
          dispatch({ type: "data/setLessons", payload: newLessons });

          setloading(false);
          return navigate(-1);
        }
      } catch (error) {
        console.log(error);
        errorNotification();
        setloading(false);
      }
    }
  };

  const axeSelectChildren: React.ReactNode[] = [];
  axes?.forEach((axe: GetAxes_getAxes, index: number) => {
    axeSelectChildren.push(
      <Option value={`${axe?.id}-${axe.name}`} key={index + `${new Date()}`}>
        {axe?.name}
      </Option>
    );
  });

  const themeSelectChildren: React.ReactNode[] = [];
  themes?.forEach((theme: GetThemes_getThemes, index: number) => {
    themeSelectChildren.push(
      <Option
        value={`${theme?.id}-${theme.name}`}
        key={index + `${new Date()}`}
      >
        {theme?.name}
      </Option>
    );
  });

  const tagSelectChildren: React.ReactNode[] = [];
  tags?.forEach((tag: GetTags_getTags, index: number) => {
    tagSelectChildren.push(
      <Option value={`${tag?.id}-${tag.name}`} key={index + `${new Date()}`}>
        {tag?.name}
      </Option>
    );
  });

  if (
    loading ||
    loadingAxes ||
    loadingGrades ||
    loadingQuizzes ||
    loadingSubjects ||
    loadingTags ||
    loadingThemes ||
    loadingVideos ||
    loadingLessons ||
    loadingEssays ||
    loadingLiteracy ||
    loadingListening ||
    loadingAIConversations
  ) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      <h1>Nueva Lección </h1>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ errors, handleReset, touched, values }: FormikProps<any>) => {
          return (
            <Form className={styles.formContainer}>
              <label htmlFor=""> Título</label>
              <Field
                errors={errors}
                touched={touched}
                value={initialValues.title}
                className={styles.formFields}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setInitialValues({
                    ...initialValues,
                    title: e.target.value,
                  });
                }}
                name="title"
                title="Título"
                type="text"
              />

              <Row>
                <label htmlFor=""> Descripcion</label>
                <Field
                  style={{ width: "100%" }}
                  rows="4"
                  as="textarea"
                  name="description"
                  id="description"
                  value={initialValues.description}
                  className={styles.formFields}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInitialValues({
                      ...initialValues,
                      description: e.target.value,
                    });
                  }}
                ></Field>
              </Row>
              <ErrorMessage
                name="description"
                component="div"
                className={styles.error}
              />

              <Row>
                <Col span={8}>
                  {/* Tipo Select */}
                  <label htmlFor="theme" style={{ marginTop: 10 }}>
                    Tipo
                  </label>
                  <Select
                    value={initialValues.lesson_type ?? "videolesson"}
                    className={styles.formFieldSelect}
                    style={{ marginBottom: 20 }}
                    onChange={(value: string) => {
                      setInitialValues({
                        ...initialValues,
                        lesson_type: value,
                      });
                      setTypeLessonSelected(value);
                    }}
                    options={[
                      { value: "videolesson", label: "Video Lección" },
                      { value: "audio", label: "Audio" },
                      { value: "classroomguide", label: "Guía de Clase" },
                      { value: "evaluation", label: "Evaluación" },
                      {
                        value: "manipulative_dynamics",
                        label: "Dinámicas Manipulativos",
                      },
                      { value: "slides", label: "Diapositivas" },
                      { value: "book_pages", label: "Páginas de Libro Luca" },
                      { value: "redaction", label: "Redacción" },
                      { value: "literacy", label: "Lectoescritura" },
                      { value: "listening", label: "Listening" },
                      { value: "conversation", label: "Conversacion IA" },
                      { value: "audio_exam", label: "Examen de Audio" },
                      { value: "writing_exam", label: "Examen de Escritura" },
                    ]}
                  />
                </Col>
                <Col offset={1}>
                  <div style={{ marginTop: 25 }}>
                    <Field
                      type="checkbox"
                      name="published"
                      checked={initialValues.nem}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInitialValues({
                          ...initialValues,
                          nem: e.target.checked,
                        });
                      }}
                    />
                    <label htmlFor="nem" style={{ marginLeft: "10px" }}>
                      NEM
                    </label>
                  </div>
                </Col>
                <Col offset={1} span={13}>
                  <label htmlFor=""> Link de drive</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    name="drive_link"
                    title="Link de drive"
                    type="text"
                    value={initialValues.drive_link}
                    className={styles.formFields}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        drive_link: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row>
                {initialValues.lesson_type === "literacy" && (
                  <>
                    <Col span={11}>
                      <label htmlFor=""> Orden</label>
                      <Field
                        errors={errors}
                        touched={touched}
                        placeholder="Ej: 1"
                        name="order_reading"
                        title="Orden"
                        type="number"
                        value={initialValues.order_reading}
                        className={styles.formFields}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setInitialValues({
                            ...initialValues,
                            order_reading: Number(e.target.value),
                          });
                        }}
                      />
                    </Col>
                    <Col span={1}></Col>
                  </>
                )}
                <Col span={11}>
                  <label htmlFor=""> Páginas SEP</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    placeholder="Ej: 1 - 2 - 3 ..."
                    name="sep_book_pages"
                    title="Páginas del libro"
                    type="text"
                    value={initialValues.sep_book_pages}
                    className={styles.formFields}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        sep_book_pages: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col span={1}></Col>
                <Col span={12}>
                  <label htmlFor=""> Dosificación por semana</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    name="week"
                    title="Dosificación por semana"
                    type="number"
                    value={initialValues.week}
                    className={styles.formFields}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        week: Number(e.target.value),
                      });
                    }}
                  />
                </Col>
              </Row>

              <Col>
                <label htmlFor=""> Nombre de la lección SEP</label>
                <Field
                  errors={errors}
                  touched={touched}
                  name="sep_name"
                  title="Nombre de lección SEP"
                  type="text"
                  value={initialValues.sep_name}
                  className={styles.formFields}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInitialValues({
                      ...initialValues,
                      sep_name: e.target.value,
                    });
                  }}
                />
              </Col>

              <Col>
                <label htmlFor=""> Aprendizaje esperado</label>
                <Field
                  errors={errors}
                  touched={touched}
                  name="learnings"
                  title="Aprendizaje esperado"
                  type="text"
                  value={initialValues.learnings}
                  className={styles.formFields}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInitialValues({
                      ...initialValues,
                      learnings: e.target.value,
                    });
                  }}
                />
              </Col>

              <Col>
                <label htmlFor="axes">Eje(s)</label>
                <Select
                  mode="tags"
                  defaultValue={
                    lessonById && lessonById?.id
                      ? lessonById.axes?.map((axe) => {
                          return `${axe.name}`;
                        })
                      : undefined
                  }
                  className={styles.formFieldSelect}
                  onChange={handleChangeAxes}
                >
                  {axeSelectChildren}
                </Select>
              </Col>

              <Col style={{ marginTop: 10 }}>
                <label htmlFor="theme">Tema(s)</label>
                <Select
                  mode="tags"
                  className={styles.formFieldSelect}
                  defaultValue={
                    lessonById && lessonById?.id
                      ? lessonById.themes?.map((theme) => {
                          return `${theme.name}`;
                        })
                      : undefined
                  }
                  onChange={handleChangeThemes}
                >
                  {themeSelectChildren}
                </Select>
              </Col>

              <Col style={{ marginTop: 10 }}>
                <label htmlFor="tag">Tag(s)</label>
                <Select
                  mode="tags"
                  className={styles.tags}
                  defaultValue={
                    lessonById && lessonById?.id
                      ? lessonById.tags?.map((tag) => {
                          return `${tag.name}`;
                        })
                      : undefined
                  }
                  onChange={handleChangeTags}
                >
                  {tagSelectChildren}
                </Select>
              </Col>

              <label htmlFor="course">Asignatura</label>
              <Field
                id="subject_id"
                name="subject_id"
                as="select"
                value={values.subject_id}
                className={
                  errors.subject_id && touched.subject_id
                    ? styles.formFieldsError
                    : styles.formFieldSelect
                }
              >
                <option disabled value="">
                  Seleccionar
                </option>
                {subjects?.map((subject, index: number) => {
                  return (
                    <option value={subject?.id} key={index}>
                      {subject?.name}
                    </option>
                  );
                })}
              </Field>

              <label htmlFor="grade_id">Grado</label>
              <Field
                id="grade_id"
                name="grade_id"
                as="select"
                value={values.grade_id}
                className={
                  errors.grade_id && touched.grade_id
                    ? styles.formFieldsError
                    : styles.formFieldSelect
                }
              >
                <option disabled value="">
                  Seleccionar
                </option>
                {grades?.map((grade, index: number) => {
                  return (
                    <option value={grade?.id} key={grade?.id}>
                      {grade?.name}
                    </option>
                  );
                })}
              </Field>
              <Row>
                <Col span={8}>
                  <div style={{ padding: "10px 5px" }}>
                    <Field
                      type="checkbox"
                      name="published"
                      checked={publish}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPublish(e.target.checked);
                      }}
                    />
                    <label htmlFor="published" style={{ marginLeft: "10px" }}>
                      Publicado
                    </label>
                  </div>
                </Col>
              </Row>

              {(initialValues.lesson_type === "classroomguide" ||
                initialValues.lesson_type === "manipulative_dynamics" ||
                initialValues.lesson_type === "writing_exam" ||
                initialValues.lesson_type === "audio_exam" ||
                initialValues.lesson_type === "literacy") && (
                <>
                  <UploadPdfFile
                    uploadType={
                      initialValues.lesson_type === "classroomguide" ||
                      initialValues.lesson_type === "manipulative_dynamics"
                        ? "Agregar el Guión en clase"
                        : initialValues.lesson_type === "literacy"
                        ? "Agregar Actividad"
                        : "Agregar el Examen"
                    }
                    className={styles.uploadFile}
                    typeFile="lesson"
                    folder="pdf"
                    existsPdfUrl={
                      initialValues.lesson_content?.pdf ? true : false
                    }
                    pdfCoverExists={initialValues.lesson_content?.pdf ?? ""}
                    widthCover={300}
                    heightCover={200}
                    filenameCover={filename}
                    setfilenameCover={setFilename}
                  />
                  <DividerComponent />
                </>
              )}

              {(initialValues.lesson_type === "slides" ||
                initialValues.lesson_type === "literacy") && (
                <>
                  <UploadPdfFile
                    uploadType={
                      initialValues.lesson_type === "literacy"
                        ? "Agregar Lectura"
                        : "Agregar Diapositivas"
                    }
                    className={styles.uploadFile}
                    typeFile="lesson"
                    folder="pdf"
                    existsPdfUrl={
                      initialValues.lesson_content?.slide ? true : false
                    }
                    pdfCoverExists={initialValues.lesson_content?.slide ?? ""}
                    widthCover={300}
                    heightCover={200}
                    filenameCover={slideFile}
                    setfilenameCover={setSlideFile}
                  />
                  <DividerComponent />
                </>
              )}

              {(initialValues.lesson_type === "book_pages" ||
                initialValues.lesson_type === "writing_exam" ||
                initialValues.lesson_type === "audio_exam") && (
                <>
                  {initialValues.lesson_type === "book_pages" && (
                    <>
                      <UploadPdfFile
                        uploadType="Agregar páginas de Libro Luca"
                        className={styles.uploadFile}
                        typeFile="lesson"
                        folder="pdf"
                        existsPdfUrl={
                          initialValues.lesson_content?.slide ? true : false
                        }
                        pdfCoverExists={
                          initialValues.lesson_content?.slide ?? ""
                        }
                        widthCover={300}
                        heightCover={200}
                        filenameCover={slideFile}
                        setfilenameCover={setSlideFile}
                      />
                      <DividerComponent />
                    </>
                  )}

                  <br />
                  <UploadPdfFile
                    uploadType={
                      initialValues.lesson_type === "writing_exam" ||
                      initialValues.lesson_type === "audio_exam"
                        ? "Agregar solucionario de Examen"
                        : "Agregar páginas de respuestas de Libro Luca"
                    }
                    className={styles.uploadFile}
                    typeFile="lesson"
                    folder="pdf"
                    existsPdfUrl={
                      initialValues.lesson_content?.responses_pdf ? true : false
                    }
                    pdfCoverExists={
                      initialValues.lesson_content?.responses_pdf ?? ""
                    }
                    widthCover={300}
                    heightCover={200}
                    filenameCover={responsesPdf}
                    setfilenameCover={setResponsesPdf}
                  />
                  <DividerComponent />
                </>
              )}

              {initialValues.lesson_type !== "classroomguide" &&
                initialValues.lesson_type !== "manipulative_dynamics" &&
                initialValues.lesson_type !== "slides" &&
                initialValues.lesson_type !== "book_pages" &&
                initialValues.lesson_type !== "writing_exam" && (
                  <DynamicSteps
                    lesson_type={initialValues.lesson_type ?? "videolesson"}
                    elements={steps}
                    handleAdd={handleAdd}
                    handleRemove={handleRemove}
                    handleChange={handleChangeSteps}
                    setRedactionId={setRedactionId}
                    setLiteracyId={setLiteracyId}
                    setListeningId={setListeningId}
                    setConversationId={setConversationId}
                    quiz={quizzes}
                    literacies={literacies}
                    listenings={listenings}
                    essays={essays}
                    aiConversations={aiConversations}
                    haveVideos={
                      initialValues.lesson_type === "videolesson" ||
                      initialValues.lesson_type === "audio" ||
                      initialValues.lesson_type === "audio_exam"
                    }
                    videos={videos}
                  />
                )}

              <Buttons
                handleClick={() => {
                  handleReset();
                  navigate(-1);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LessonsForm;
