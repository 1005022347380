import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getLiteracies } from "../__generated__/gql-types/getLiteracies";

const UseGetLiteracies = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getLiteracies> = await client.query({
      query: GET_LITERACIES,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getLiteracies;
  }, [client]);
};

export default UseGetLiteracies;

const GET_LITERACIES = gql`
  query getLiteracies {
    getLiteracies {
      content {
        order
        image
        text
        type
      }
      cover
      created_at
      id
      publish
      title
    }
  }
`;
