import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getAIConversationsById,
  getAIConversationsByIdVariables,
} from "../__generated__/gql-types/getAIConversationsById";

const UseGetAIConversatiosnById = () => {
  const client = useApolloClient();

  return useCallback(
    async (variables: getAIConversationsByIdVariables) => {
      const result: ApolloQueryResult<getAIConversationsById> =
        await client.query({
          query: GET_AICONVERSATIONS_BY_ID,
          fetchPolicy: "no-cache",
          variables,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getAIConversationsById;
    },
    [client]
  );
};

export default UseGetAIConversatiosnById;

const GET_AICONVERSATIONS_BY_ID = gql`
  query getAIConversationsById($aiconversationId: Int) {
    getAIConversationsById(aiconversation_id: $aiconversationId) {
      id
      title
      publish
      level_cerf
      character_for_role_play
      card_image
      cover_image
      voice
      avatar_image
      section_1 {
        question
        order
      }
      section_2 {
        question
        order
      }
      created_at
      updated_at
      deleted_at
    }
  }
`;
