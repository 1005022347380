import React, { ChangeEvent, useEffect } from "react";
import { DAndDQuestionInput } from "../../../../../__generated__/gql-types/globalTypes";
import { Col, Row } from "antd";
import styles from "./dad.module.css";
import { Field } from "formik";
import { UploadDADQ } from "../../../../UploadFile/UploadDADQ";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

interface BlocksComponentProps {
  dadQuestion: DAndDQuestionInput | null;
  indexDADQuestion: number;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => void;
  handleAddBlockDADWithImage: () => void;
  handleRemoveBlockDADWithImage: () => void;
  fileImageBlockItems: string[];
  setFileImageBlockItems: React.Dispatch<React.SetStateAction<string[]>>;
  fileImageBlockContainer: string[];
  setFileImageBlockContainer: React.Dispatch<React.SetStateAction<string[]>>;
  handleImageDADQuestionRelationWithImage: (
    image: string,
    index: number
  ) => void;
}

export const BlocksComponent = ({
  dadQuestion,
  indexDADQuestion,
  handleChange,
  handleAddBlockDADWithImage,
  handleRemoveBlockDADWithImage,
  fileImageBlockItems,
  setFileImageBlockItems,
  fileImageBlockContainer,
  setFileImageBlockContainer,
  handleImageDADQuestionRelationWithImage,
}: BlocksComponentProps) => {
  useEffect(() => {}, [
    dadQuestion,
    handleChange,
    handleAddBlockDADWithImage,
    handleRemoveBlockDADWithImage,
  ]);

  const AddButton = ({ index }: { index: number }) => {
    if (
      dadQuestion?.blockItems &&
      index === dadQuestion.blockItems.length - 1 &&
      dadQuestion?.blockItems.length < 4
    ) {
      return (
        <span>
          <a
            style={{ fontSize: 22, padding: 10 }}
            onClick={(e: any) => {
              handleAddBlockDADWithImage();
            }}
          >
            <PlusCircleOutlined></PlusCircleOutlined>
          </a>
        </span>
      );
    } else {
      return null;
    }
  };

  const DeleteButton = ({ index }: { index: number }) => {
    if (
      dadQuestion?.blockItems &&
      dadQuestion?.blockItems.length - 1 === index &&
      index !== 0
    ) {
      return (
        <span>
          <a
            style={{ fontSize: 22, padding: 10 }}
            onClick={() => {
              handleRemoveBlockDADWithImage();
            }}
          >
            <DeleteOutlined></DeleteOutlined>
          </a>
        </span>
      );
    } else {
      return null;
    }
  };
  if (!dadQuestion) {
    return null;
  }

  return (
    <div className={styles.containerDADColumn}>
      <Col className={styles.columBlockItems}>
        {dadQuestion.blockItems?.map((blockItem, index) => {
          return (
            <div className={styles.rowColumnBlockItem}>
              <Field
                id="drag_and_drop"
                name="drag_and_drop_block_container"
                placeholder={`Palabra #${index}`}
                type="text"
                className={styles.formFieldSelect}
                defaultValue={blockItem?.text ?? ""}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChange(event, index);
                }}
              ></Field>
              <AddButton index={index} />
              <DeleteButton index={index} />
            </div>
          );
        })}
      </Col>
      <Col className={styles.columnBlockContainer}>
        {dadQuestion.blockContainer?.map((blockCont, index) => {
          return (
            <div className={styles.containerBlockItem}>
              <h1
                style={{
                  width: "20%",
                  borderBottom: "1px solid #5067DC",
                  height: "1%",
                  marginRight: 10,
                  marginLeft: 10,
                  paddingTop: 20,
                }}
              ></h1>
              <div style={{ width: "80%" }}>
                <UploadDADQ
                  index={index}
                  folder="answers"
                  fileImageBlockItems={fileImageBlockItems}
                  setFileImageBlockItems={setFileImageBlockItems}
                  fileImageBlockContainer={fileImageBlockContainer}
                  setFileImageBlockContainer={setFileImageBlockContainer}
                  handleImageDADQuestionRelationWithImage={
                    handleImageDADQuestionRelationWithImage
                  }
                />
              </div>
            </div>
          );
        })}
      </Col>
    </div>
  );
};
