import { useCallback } from "react";
import {
  useApolloClient,
  gql,
  ApolloQueryResult,
  ApolloError,
} from "@apollo/client";
import { updateGroupClassroomVariables } from "../__generated__/gql-types/updateGroupClassroom";
import {
  ResultsBulkCreate,
  ResultsBulkCreateVariables,
} from "../__generated__/gql-types/ResultsBulkCreate";

const UseGetResultsBulkCreate = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: ResultsBulkCreateVariables) => {
      const result: ApolloQueryResult<ResultsBulkCreate> = await client.query({
        query: GET_RESULTS_BULK_CREATE,
        fetchPolicy: "no-cache",
        variables: input,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.resultsBulkCreate;
    },
    [client]
  );
};

export default UseGetResultsBulkCreate;

const GET_RESULTS_BULK_CREATE = gql`
  query ResultsBulkCreate($fileName: String) {
    resultsBulkCreate(fileName: $fileName)
  }
`;
