import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { VideoInput } from "../__generated__/gql-types/globalTypes";

const UseCreateVideos = () => {
  const client = useApolloClient();

  return useCallback(
    async (videoInput: VideoInput) => {
      return await client.mutate({
        mutation: CREATE_VIDEO,
        fetchPolicy: "no-cache",
        variables: { videoInput },
      });
    },
    [client]
  );
};

export default UseCreateVideos;

const CREATE_VIDEO = gql`
  mutation CreateVideos($videoInput: VideoInput) {
    createVideo(videoInput: $videoInput) {
      id
      title
      description
      publish
      themes {
        name
        id
      }
      axes {
        id
        name
      }
      metadata
      url
      file_id
      imageUrl
      created_at
      updated_at
      deleted_at
      quizzes {
        quiz {
          id
          title
          filename
          questions {
            id
            question
            answers {
              id
              answer
            }
          }
        }
      }
    }
  }
`;
