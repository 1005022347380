import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { DeleteSubjectVariables } from "../__generated__/gql-types/DeleteSubject";

const useDeleteSubject = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: DeleteSubjectVariables) => {
      return await client.mutate({
        mutation: DELETE_SUBJECT,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useDeleteSubject;

const DELETE_SUBJECT = gql`
  mutation DeleteSubject($deleteSubjectId: ID!) {
    deleteSubject(id: $deleteSubjectId) {
      id
      name
      code
      deleted_at
    }
  }
`;
