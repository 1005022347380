import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { MutationBulkCreateVariables } from "../__generated__/gql-types/MutationBulkCreate";

const useBulkCreate = () => {
  const client = useApolloClient();

  return useCallback(
    async (bulkCreateInput: MutationBulkCreateVariables) => {
      return await client.mutate({
        mutation: BULK_CREATE,
        fetchPolicy: "no-cache",
        variables: bulkCreateInput,
      });
    },
    [client]
  );
};

export default useBulkCreate;

const BULK_CREATE = gql`
  mutation MutationBulkCreate($sheetUrl: String) {
    bulkCreate(sheetURL: $sheetUrl)
  }
`;
