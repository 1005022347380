import React, { ChangeEvent, useEffect } from "react";
import { QuestionInput } from "../../../../../__generated__/gql-types/globalTypes";
import { Col, Row } from "antd";
import styles from "./dad.module.css";
import { ErrorMessage, Field } from "formik";
import { GroupBlocks } from "./GroupBlocks";

interface GroupQuestionProps {
  index: number;
  question: QuestionInput;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => void;
  loadingLoadImage: boolean;
  setLoadingLoadImage: React.Dispatch<React.SetStateAction<boolean>>;
  urlImageQuestion: string;
  setUrlImageQuestion: React.Dispatch<React.SetStateAction<string>>;
  handleImageQuestion: (newImage: string) => void;
  fileImageGroupContainer: string[];
  setFileImageGroupContainer: React.Dispatch<React.SetStateAction<string[]>>;
  fileImageBlockContainer: string[];
  setFileImageBlockContainer: React.Dispatch<React.SetStateAction<string[]>>;
  handleAddGroupWithImage: () => void;
  handleRemoveGroupWithImage: () => void;
  handleImageGroupDADQuestions: (image: string, index: number) => void;
}

export const GroupQuestion = ({
  index,
  question,
  loadingLoadImage,
  urlImageQuestion,
  fileImageBlockContainer,
  fileImageGroupContainer,
  handleChange,
  setLoadingLoadImage,
  setUrlImageQuestion,
  handleImageQuestion,
  setFileImageBlockContainer,
  setFileImageGroupContainer,
  handleAddGroupWithImage,
  handleImageGroupDADQuestions,
  handleRemoveGroupWithImage,
}: GroupQuestionProps) => {
  useEffect(() => {}, [question]);

  return (
    <Col
      key={`${index}_${new Date()}`}
      style={{
        backgroundColor: "#E6E7EF",
        borderRadius: 20,
        paddingRight: 30,
        paddingLeft: 30,
        paddingTop: 10,
      }}
    >
      <div style={{ marginBottom: 10, marginTop: 5 }}>
        <label htmlFor={`questions_${index}`}>Pregunta {index}</label>
      </div>
      <Row>
        <Field
          id={`questions_${index}`}
          name="question"
          type="text"
          className={styles.formFieldSelect}
          defaultValue={question?.question ?? ""}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleChange(event, index);
          }}
        ></Field>
        <ErrorMessage
          name="question"
          component="div"
          className={styles.error}
        />
      </Row>
      {question.dAndDQuestion?.map((dadQuestion, indexDADQuestion) => {
        return (
          <GroupBlocks
            dadQuestion={dadQuestion}
            indexDADQuestion={indexDADQuestion}
            handleChange={handleChange}
            fileImageGroupContainer={fileImageGroupContainer}
            setFileImageGroupContainer={setFileImageGroupContainer}
            handleAddGroupWithImage={handleAddGroupWithImage}
            handleRemoveGroupWithImage={handleRemoveGroupWithImage}
            handleImageGroupDADQuestions={handleImageGroupDADQuestions}
          />
        );
      })}
    </Col>
  );
};
