import React, { useState } from "react";
import { Layout, Menu, Image } from "antd";
import {
  VideoCameraAddOutlined,
  FormOutlined,
  BookOutlined,
  ApartmentOutlined,
  UserOutlined,
  UnorderedListOutlined,
  BankOutlined,
  LogoutOutlined,
  ReadOutlined,
  UsergroupAddOutlined,
  CloudUploadOutlined,
  ShopOutlined,
  AudioFilled,
  NotificationFilled
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import type { MenuProps } from "antd";
import image from "../../../assets/icons/logo_white.svg";
import { useAuth } from "../../../hooks/useAuth";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

const SiderLuca = () => {
  const { logout } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  function getItem(
    label: React.ReactNode,
    key: React.Key | null,
    icon?: React.ReactNode,
    item?: React.ReactNode
  ): MenuItem {
    return {
      key,
      icon,
      item,
      label,
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem(
      "Videos",
      "1",
      <Link to="/videos">
        <VideoCameraAddOutlined />
      </Link>
    ),
    getItem(
      "Ensayos",
      "2",
      <Link to="/essays">
        <FormOutlined />
      </Link>
    ),
    getItem(
      "Shop",
      "21",
      <Link to="/clothes-and-accessories">
        <ShopOutlined />{" "}
      </Link>
    ),
    getItem(
      "Listening",
      "22",
      <Link to="/listening">
        <AudioFilled />{" "}
      </Link>
    ),
    getItem(
      "Notificaciones",
      "23",
      <Link to="/notifications">
        <NotificationFilled />{" "}
      </Link>
    ),
    getItem(
      "Quizzes",
      "3",
      <Link to="/quizzes">
        <FormOutlined />
      </Link>
    ),
    getItem(
      "Lecturas",
      "4",
      <Link to="/literacy">
        <FormOutlined />
      </Link>
    ),
    getItem(
      "Conversaciones",
      "5",
      <Link to="/aiConversation">
        <FormOutlined />
      </Link>
    ),
    getItem(
      "Cursos",
      "6",
      <Link to="/courses">
        <BookOutlined />{" "}
      </Link>
    ),
    getItem(
      "Lecciones",
      "7",
      <Link to="/lessons">
        <ReadOutlined />{" "}
      </Link>
    ),
    getItem(
      "Programas",
      "9",
      <Link to="/programs">
        {" "}
        <ApartmentOutlined />{" "}
      </Link>
    ),
    getItem(
      "User",
      "10",
      <Link to="/users">
        {" "}
        <UserOutlined />{" "}
      </Link>
    ),
    getItem(
      "Asignar Clases",
      "11",
      <Link to="/assignClassroom">
        {" "}
        <UsergroupAddOutlined />{" "}
      </Link>
    ),
    getItem(
      "Asignatura",
      "12",
      <Link to="/subjects">
        <UnorderedListOutlined />{" "}
      </Link>
    ),
    getItem(
      "Escuelas",
      "13",
      <Link to="/schools">
        <BankOutlined />{" "}
      </Link>
    ),
    getItem(
      "GPT",
      "14",
      <Link to="/generate-content">
        <CloudUploadOutlined />{" "}
      </Link>
    ),
    getItem(
      "Super Demo",
      "15",
      <Link to="/super-demo">
        <CloudUploadOutlined />{" "}
      </Link>
    ),
    getItem(
      "Bulk Create",
      "16",
      <Link to="/bulk-create">
        <CloudUploadOutlined />{" "}
      </Link>
    ),
    getItem(
      "Info Videos",
      "17",
      <Link to="/bulk-info-videos">
        <CloudUploadOutlined />{" "}
      </Link>
    ),
    getItem(
      "Update Book",
      "18",
      <Link to="/bulk-update-book-pages">
        <CloudUploadOutlined />{" "}
      </Link>
    ),
    getItem(
      "Bulk Redactions",
      "19",
      <Link to="/bulk-redactions-create">
        <CloudUploadOutlined />{" "}
      </Link>
    ),
    getItem(
      "Salir",
      "20",
      <a
        onClick={async () => {
          await logout();
        }}
      >
        <LogoutOutlined style={{ fontSize: "20px" }} />
      </a>
    ),
  ];

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      {collapsed === false && (
        <Image
          width={100}
          src={image}
          style={{
            height: "32px",
            margin: "16px",
          }}
        />
      )}

      <Menu
        style={{ fontSize: "20px" }}
        mode="inline"
        theme="dark"
        defaultSelectedKeys={["1"]}
        items={items}
      ></Menu>
    </Sider>
  );
};
export default SiderLuca;
