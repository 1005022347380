import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getListenings } from "../__generated__/gql-types/getListenings";

const UseGetListenings = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getListenings> = await client.query({
      query: GET_LISTENINGS,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getListenings;
  }, [client]);
};

export default UseGetListenings;

const GET_LISTENINGS = gql`
  query getListenings {
    getListenings {
      id
      title
    }
  }
`;
