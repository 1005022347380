import { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./literacyForm.module.css";
import { Buttons } from "../..";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Col, notification, Row } from "antd";
import { ContentLiteracy, initialValuesObj } from "./LiteracyForm.types";
import { LiteracyInput } from "../../../__generated__/gql-types/globalTypes";
import {
  errorNotification,
  successNotification,
  updatedNotification,
} from "./utils";
import { ActivityIndicator } from "../../ActivityIndicator";
import { UploadImageFile } from "../../UploadFile";
import { ContentTypeSelect } from "./ContentComponents/ContentTypeSelect";
import { FileImageCoverProps } from "../QuizzesForm/utils";
import { useDispatch } from "react-redux";
import UseGetLiteracyById from "../../../api/useGetLiteracyById";
import UseUpdateLiteracy from "../../../api/useUpdateLiteracy";
import UseCreateLiteracy from "../../../api/useCreateLiteracy";
import { useLiteracy } from "../../../hooks/useLiteracy";
import { getLiteracies_getLiteracies } from "../../../__generated__/gql-types/getLiteracies";

const NewLiteracyForm: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { literacies, loading: isLoadingLiteracy } = useLiteracy();
  const literacy_id = location?.state as string;
  const getLiteracyById = UseGetLiteracyById();
  const createLiteracy = UseCreateLiteracy();
  const updateLiteracy = UseUpdateLiteracy();
  const [loading, setloading] = useState(true);
  const [initialValues, setInitialValues] =
    useState<LiteracyInput>(initialValuesObj);

  const [publish, setPublish] = useState<boolean>(false);
  const [filenameCover, setfilenameCover] = useState<FileImageCoverProps>({
    id: "",
    ext: "",
  });
  const [urlImageCover, setUrlImageCover] = useState<string>("");
  const [content, setContent] = useState<ContentLiteracy[]>([]);

  const fetchLiteracyInfo = async () => {
    try {
      if (literacy_id) {
        const data = await getLiteracyById({ literacyId: literacy_id });

        if (data?.id) {
          setPublish(data.publish ? true : false);
          setInitialValues({
            title: data.title ?? "",
            content: data.content ?? [],
            cover: data.cover ?? "",
            header_color: data.header_color ?? "",
            copyright: data.copyright ?? "",
            publish: data.publish ?? 0,
          });

          setfilenameCover({
            id: data.cover!.split(".")[0],
            ext: data.cover!.split(".")[1],
          });

          const filteredContent: ContentLiteracy[] = data?.content?.map(
            (cont, index) => cont
              ? cont
              : {
                image: "",
                order: index,
                contentType: 0,
                contentLeft: "text",
                contentRigth: "image",
                text: "",
                type: "",
              }
          ) as unknown as ContentLiteracy[];
          filteredContent &&
            filteredContent.length > 0 &&
            setContent(filteredContent);
        }
      }

      setTimeout(() => {
        setloading(false);
      }, 1000);
    } catch (error: any) {
      setloading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchLiteracyInfo();
  }, [loading, isLoadingLiteracy]);

  const handleSubmit = async (formValues: any) => {
    let literacy: LiteracyInput = {
      cover:
        filenameCover && filenameCover.id !== ""
          ? `${filenameCover.id}.${filenameCover.ext}`
          : "",
      title: initialValues.title,
      publish: publish ? 1 : 0,
      header_color: initialValues.header_color,
      copyright: initialValues.copyright,
      content: content
        .sort((cont1, cont2) => cont1.order - cont2.order)
        .map((cont, index) => {
          cont.order = index;
          return cont;
        }),
    };
    // console.log({ literacy });
    // return;
    setloading(true);
    try {
      if (literacy_id && literacy_id.length > 0) {
        const updateResponse = await updateLiteracy({
          literacyInput: literacy,
          literacyId: `${literacy_id}`,
        });
        updateResponse && updatedNotification();

        let index = literacies.findIndex(
          (literacy) => literacy.id === updateResponse.data.updateLiteracy.id
        );
        let literacyUpdated = [...literacies];

        literacyUpdated[index] = updateResponse.data
          .updateLiteracy as unknown as getLiteracies_getLiteracies;
        dispatch({ type: "data/setLiteracies", payload: literacyUpdated });
        setloading(false);
        return navigate(-1);
      } else {
        const createResponse = await createLiteracy({
          literacyInput: literacy,
        });
        createResponse && successNotification();
        let newLiteracies = [];
        let newLiteracy = createResponse.data
          .createLiteracy as unknown as getLiteracies_getLiteracies;
        newLiteracies = [newLiteracy, ...literacies];
        dispatch({ type: "data/setLiteracies", payload: newLiteracies });

        setloading(false);
        return navigate(-1);
      }
    } catch (error) {
      console.log({ error });
      setloading(false);
      errorNotification();
    }
  };

  if (loading || isLoadingLiteracy) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      {literacy_id && literacy_id.length > 0 ? (
        <h1>Editar Lectoescritura</h1>
      ) : (
        <h1>Nueva Lectoescritura</h1>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={() => {
          const errors: any = {};
          return errors;
        }}
      >
        {({ errors, handleReset, touched, values }) => {
          return (
            <Form className={styles.formContainer}>
              <Row>
                <Col span={7}>
                  <label htmlFor="asignatura">Título</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    name="title"
                    title="Titulo"
                    type="text"
                    className={styles.formFields}
                    value={initialValues.title}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        title: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col offset={1} span={4}>
                  <div style={{ marginTop: 25, fontSize: 18 }}>
                    <label htmlFor="publish">Publicado:</label>
                    <input
                      type="checkbox"
                      name="publish"
                      style={{ marginLeft: 10 }}
                      checked={publish}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPublish(e.target.checked);
                      }}
                    />
                  </div>
                </Col>
                <Col span={7}>
                  <label htmlFor="color">Color del header</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    name="header_color"
                    title="header_color"
                    type="textarea"
                    className={styles.formFields}
                    value={initialValues.header_color}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        header_color: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col span={7}>
                  <label htmlFor="copy">Copyright</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    name="copyright"
                    title="copyright"
                    type="text"
                    className={styles.formFields}
                    value={initialValues.copyright}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        copyright: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>

              <UploadImageFile
                title="Cover"
                className={`${styles.uploadFile}`}
                typeFile="quiz"
                folder="covers"
                widthCover={300}
                heightCover={200}
                image={initialValues.cover ?? ""}
                filenameCover={filenameCover}
                setfilenameCover={setfilenameCover}
                urlImageCover={urlImageCover}
                setUrlImageCover={setUrlImageCover}
              />

              <ContentTypeSelect
                literacy_id={literacy_id}
                content={content}
                setContent={setContent}
              />

              <Buttons
                handleClick={() => {
                  handleReset();
                  navigate(-1);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NewLiteracyForm;
