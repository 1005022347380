import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { CreateFolderVariables } from "../__generated__/gql-types/CreateFolder";

const UseCreateFolder = () => {
  const client = useApolloClient();

  return useCallback(
    async (folderInput: CreateFolderVariables) => {
      return await client.mutate({
        mutation: CREATE_FOLDER,
        fetchPolicy: "no-cache",
        variables: folderInput,
      });
    },
    [client]
  );
};

export default UseCreateFolder;

const CREATE_FOLDER = gql`
  mutation CreateFolder($newFolderName: String) {
    createFolder(newFolderName: $newFolderName)
  }
`;
