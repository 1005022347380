import {
  Space,
  Table,
  Button,
  notification,
  Modal,
  Row,
  Typography,
  Input,
} from "antd";
import type { ColumnsType } from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "./literacy.module.css";
import { FC, useEffect, useState } from "react";
import { useLiteracy } from "../../hooks/useLiteracy";
import { getLiteracies_getLiteracies } from "../../__generated__/gql-types/getLiteracies";

const { Text } = Typography;
const { Search } = Input;

const Literacy: FC = () => {
  const { literacies, loading: isLoading, deleteLiteracy } = useLiteracy();
  const [dataSource, setdataSource] = useState<
    getLiteracies_getLiteracies[] | null
  >([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [idLiteracy, setIdLiteracy] = useState<string>("");

  useEffect(() => {
    setdataSource(literacies);
  }, [literacies]);

  const showModal = (id: string) => {
    setIsModalVisible(true);
    setIdLiteracy(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      await deleteLiteracy(idLiteracy);
      setIsModalVisible(false);
    } catch {
      notification["error"]({
        message: "Eliminación Erronea",
        description: "El quiz no pudo ser eliminado!",
        placement: "top",
        duration: 3,
      });
    }
  };

  const columns: ColumnsType<getLiteracies_getLiteracies> = [
    {
      align: "center",
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    { align: "center", title: "Título", dataIndex: "title", key: "title" },
    {
      title: "Publicado",
      dataIndex: "publish",
      key: "publish",
      render: (record) => {
        return (
          <>
            <Row style={{ marginTop: 5 }}>
              {record === 1 ? (
                <Text type="success">Publicado</Text>
              ) : (
                <Text type="danger">No publicado</Text>
              )}
            </Row>
          </>
        );
      },
    },
    {
      align: "center",
      title: "Acciones",
      key: "acciones",
      render: (_, record) => {
        return (
          <Space size={15} style={{ fontSize: 19 }}>
            <Link to={`/literacy/${record.id}`} state={record.id}>
              <Button icon={<SearchOutlined />}>Editar</Button>
            </Link>
            <Button
              onClick={() => {
                record && record.id && showModal(record.id);
              }}
              danger
            >
              Eliminar
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    if (value === "") {
      setdataSource(literacies);
    } else {
      if (dataSource) {
        const filteredData: getLiteracies_getLiteracies[] = dataSource.filter(
          (data) => data.title?.includes(value)
        );
        setdataSource(filteredData);
      }
    }
  };

  const onSearchId = (value: string) => {
    if (value === "") {
      setdataSource(literacies);
    } else {
      if (dataSource) {
        const filteredData: getLiteracies_getLiteracies[] = dataSource.filter(
          (data) => data.id === value
        );
        setdataSource(filteredData);
      }
    }
  };

  return (
    <div className={styles.container}>
      <h1>Lectoescrituras</h1>
      <div className={styles.newQuizzesContainer}>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por id"
            onSearch={onSearchId}
            enterButton
          />
        </Space>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por título"
            onSearch={onSearch}
            enterButton
          />
        </Space>
        <Button type="primary" danger>
          <Link to="/literacy/new">Nueva Lectoescritura</Link>
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource ?? []}
        loading={isLoading}
        rowKey={(record) => (record && record.id ? record.id : "")}
      />
      <Modal
        title="Eliminar Lectoescritura"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>¿Desea eliminar la lectoescritura?</p>
      </Modal>
    </div>
  );
};

export default Literacy;
