import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Col, notification, Row } from "antd";
import { ActivityIndicator } from "../ActivityIndicator";
import { VideoPlayer } from "../VideoPlayer";
import styles from "./uploadFile.module.css";
import axios from "axios";
import { environment } from "../../configuration/environment";
import { useAuth } from "../../hooks/useAuth";
import { Progress } from "antd";

interface UploadFileVideoProps {
  uploadType: string;
  className: string;
  typeFile: string;
  folder: string;
  videoTitle: string;
  videoUrl: string;
  videoFile?: string;
  setVideoFile?: React.Dispatch<React.SetStateAction<string>>;
  size?: number;
  setSize?: React.Dispatch<React.SetStateAction<number>>;
}

export const UploadVideoFile: FC<UploadFileVideoProps> = ({
  uploadType,
  className,
  typeFile,
  folder,
  videoTitle,
  videoUrl,
  videoFile,
  setVideoFile,
  size,
  setSize,
}) => {
  const { token } = useAuth();
  const [loading, setloading] = useState(false);
  const [url, seturl] = useState("");
  const [uploadPercent, setUploadPercent] = useState(0);

  useEffect(() => {
    if (videoUrl && videoUrl.length > 0) {
      seturl(videoUrl);
      setSize && setSize(size ? size : 0);
      let file_id: string = videoUrl
        .split("/")
        [videoUrl.split("/").length - 1].split(".")[0];
      setVideoFile && setVideoFile(file_id);
    }
    setUploadPercent(0);
  }, []);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    let file: File = e.target.files[0] as unknown as File;
    console.log({ file });

    setloading(true);
    try {
      await completeUpload(file);
      setTimeout(() => {
        setloading(false);
      }, 1000);
    } catch (error) {
      notification["error"]({
        message: "Error",
        description: "El video no pudo ser subido, por favor, reintente.",
        placement: "top",
        duration: 3,
      });
    }
  };

  const completeUpload = async (file: File) => {
    try {
      const response = await axios.post(
        `${environment.backendUrl}/urlvideo`,
        {
          folder,
          extension: file.type.split("/")[1],
        },
        {
          headers: {
            token,
          },
        }
      );

      let id: string = response.data.url
        .split("/")
        [response.data.url.split("/").length - 1].split("?X-Amz-Algorithm")[0]
        .split(".")[0];
      let ext: string = response.data.url
        .split("/")
        [response.data.url.split("/").length - 1].split("?X-Amz-Algorithm")[0]
        .split(".")[1];

      await axios.put(response.data.url, file, {
        headers: {
          "content-type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          console.log(`${loaded}kb of ${total}kb | ${percent}%`);

          if (percent < 100) {
            setUploadPercent(percent);
          }
        },
      });

      videoFile = id;
      setVideoFile && setVideoFile(id);
      setSize && setSize(file.size);
      setUploadPercent(100);
      uploadPercent === 100 && seturl(URL.createObjectURL(file));
    } catch (error) {
      notification["error"]({
        message: "Error",
        description: "El video no pudo ser subido, por favor, reintente.",
        placement: "top",
        duration: 3,
      });
    }
  };

  return (
    <div className={styles.container}>
      <label htmlFor={`url_${typeFile}`}>{uploadType}</label>
      <input type="file" onChange={handleFileChange} />
      <p>Formato soportado: .mp4 - Peso máximo: 500 MB</p>
      <Col
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {uploadPercent > 0 ? (
          <React.Fragment>
            <Progress
              percent={uploadPercent}
              status={uploadPercent === 100 ? "success" : "active"}
            />
          </React.Fragment>
        ) : null}
        {loading ? (
          <ActivityIndicator size="small" classSmall={styles.activity} />
        ) : (
          <Row style={{ padding: 20 }}>
            <React.Fragment>
              {videoUrl ? <VideoPlayer url={url} title={videoTitle} /> : null}
            </React.Fragment>
          </Row>
        )}
      </Col>
    </div>
  );
};
