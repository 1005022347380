interface EnvironmentType {
  apiGraphQLURL: string;
  urlImages: string;
  urlVideos: string;
  backendUrl: string;
}

const resolveEnvironment = (): string => {
  if (`${process.env.REACT_APP_NODE_ENV}` === "local") {
    return `${process.env.REACT_APP_API_GRAPHQL_URL_LOCAL}`;
  }
  if (`${process.env.REACT_APP_NODE_ENV}` === "development") {
    return `${process.env.REACT_APP_API_GRAPHQL_URL_DEV}`;
  }
  if (`${process.env.REACT_APP_NODE_ENV}` === "production") {
    return `${process.env.REACT_APP_API_GRAPHQL_URL_PROD}`;
  }

  return "";
};

const resolveBackendUrl = (): string => {
  if (`${process.env.REACT_APP_NODE_ENV}` === "local") {
    return `${process.env.REACT_APP_LUCAEDU_BACKEND_URL_LOCAL}`;
  }
  if (`${process.env.REACT_APP_NODE_ENV}` === "development") {
    return `${process.env.REACT_APP_LUCAEDU_BACKEND_URL_DEV}`;
  }
  if (`${process.env.REACT_APP_NODE_ENV}` === "production") {
    return `${process.env.REACT_APP_LUCAEDU_BACKEND_URL_PROD}`;
  }

  return "";
}

export const environment: EnvironmentType = {
  apiGraphQLURL: resolveEnvironment(),
  urlImages: `${process.env.REACT_APP_URL_IMAGES}` || "",
  urlVideos: `${process.env.REACT_APP_URL_VIDEOS}` || "",
  backendUrl: resolveBackendUrl(),
};
