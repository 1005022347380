import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { SchoolInput } from "../__generated__/gql-types/globalTypes";

const UseCreateSchool = () => {
  const client = useApolloClient();

  return useCallback(
    async (schoolInput: SchoolInput) => {
      return await client.mutate({
        mutation: CREATE_SCHOOL,
        fetchPolicy: "no-cache",
        variables: { schoolInput },
      });
    },
    [client]
  );
};

export default UseCreateSchool;

const CREATE_SCHOOL = gql`
  mutation CreateSchool($schoolInput: SchoolInput) {
    createSchool(schoolInput: $schoolInput) {
      id
      name
      cct
      city
      demo
    }
  }
`;
