import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UpdateQuizVariables } from "../__generated__/gql-types/UpdateQuiz";

const UseUpdateQuizz = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: UpdateQuizVariables) => {
      return await client.mutate({
        mutation: UPDATE_QUIZZ,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseUpdateQuizz;
const UPDATE_QUIZZ = gql`
  mutation UpdateQuiz($updateQuizId: ID!, $quizInput: QuizInput) {
    updateQuiz(id: $updateQuizId, quizInput: $quizInput) {
      id
      title
      deleted_at
      publish
      url
    }
  }
`;
