import React, { ChangeEvent, useEffect, useState } from "react";
import { QuestionInput } from "../../../../../__generated__/gql-types/globalTypes";
import { FileImageCoverProps } from "../../utils";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { Field } from "formik";
import styles from "../../quizzForm.module.css";
import { UploadImageFile } from "../../../../UploadFile";

interface AnswerComponentProps {
  question: QuestionInput;
  handleRemoveAnswer: () => void;
  handleAddAnswer: () => Promise<void>;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => void;
  urlImageAnswers: string[];
  setUrlImageAnswers: React.Dispatch<React.SetStateAction<string[]>>;
  imageAnswers: string[];
  setimageAnswers: React.Dispatch<React.SetStateAction<string[]>>;
  handleImageAnswers: (newImage: string, indexAnswer: number) => void;
}

const AnswerComponent = ({
  question,
  imageAnswers,
  urlImageAnswers,
  handleAddAnswer,
  handleRemoveAnswer,
  handleChange,
  setUrlImageAnswers,
  setimageAnswers,
  handleImageAnswers
}: AnswerComponentProps) => {
  const [imagesAnswer, setImagesAnswer] = useState<FileImageCoverProps[]>([]);

  const sortInfo = () => {
    if (question.answers && question.answers.length > 0) {
      question.answers.map((answer, index) => {
        if (answer.image && answer.image.length > 0) {
          setImagesAnswer([
            ...imagesAnswer,
            {
              id: answer?.image?.split(".")[0],
              ext: answer?.image?.split(".")[1],
            },
          ]);
        } else {
          setImagesAnswer([
            ...imagesAnswer,
            {
              id: "",
              ext: "",
            },
          ]);
        }
      });
    }
  };

  useEffect(() => {
    sortInfo();
  }, [question, handleAddAnswer, handleChange, handleAddAnswer]);

  const AddButton = ({ i }: { i: number }) => {
    if (question?.answers && question?.answers?.length - 1 === i) {
      return (
        <span>
          <a
            style={{ fontSize: 22, padding: 10 }}
            onClick={(e: any) => {
              handleAddAnswer();
            }}
          >
            <PlusCircleOutlined></PlusCircleOutlined>
          </a>
        </span>
      );
    } else {
      return null;
    }
  };

  const DeleteButton = ({ i }: { i: number }) => {
    if (question.answers!.length - 1 === i && i !== 0) {
      return (
        <span>
          <a
            style={{ fontSize: 22, padding: 10 }}
            onClick={() => {
              handleRemoveAnswer();
            }}
          >
            <DeleteOutlined></DeleteOutlined>
          </a>
        </span>
      );
    } else {
      return null;
    }
  };
  return (
    <>
      {question?.answers?.map((answer, index: number) => {
        return (
          <Col key={`${index}_${new Date()}`}>
            <Row style={{ marginLeft: 20, marginTop: 10 }}>
              <label htmlFor="steps">Respuesta # {index}</label>
              <AddButton i={index} />
              <DeleteButton i={index} />
            </Row>
            <Row style={{ marginLeft: 20 }}>
              <Col span={10}>
                <Field
                  id="answer"
                  name="answer"
                  type="text"
                  defaultValue={answer?.answer ?? ""}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleChange(event, index);
                  }}
                  className={styles.formFieldSelect}
                ></Field>
              </Col>

              <Col span={6} offset={1}>
                <UploadImageFile
                  title={`Imagen de respuesta ${index}`}
                  className={styles.uploadFile}
                  typeFile="answers"
                  folder="answers"
                  widthCover={300}
                  heightCover={200}
                  index={index}
                  image={answer?.image ?? ""}
                  imageAnswers={imageAnswers}
                  setimageAnswers={setimageAnswers}
                  urlImageAnswers={urlImageAnswers}
                  setUrlImageAnswers={setUrlImageAnswers}
                  handleImageAnswers={handleImageAnswers}
                />
              </Col>
              <Col span={4} offset={1}>
                <label htmlFor="">Correcta</label>
                <input
                  style={{ fontSize: 60, marginLeft: 20 }}
                  type="checkbox"
                  defaultChecked={
                    question
                      ? question.answers![index].correct
                        ? true
                        : false
                      : undefined
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e, index);
                  }}
                  name="checkAnswer"
                />
              </Col>
            </Row>
          </Col>
        );
      })}
    </>
  );
};

export default AnswerComponent;
