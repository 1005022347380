import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes";
import { LayoutLuca } from "./components/Layout";
import { useAuth } from "./hooks/useAuth";
import "antd/dist/antd.css";

const App = () => {
  const { token } = useAuth();

  useEffect(() => {
    caches.delete(window.location.href);
  }, []);

  useEffect(() => {}, [token]);

  return (
    <BrowserRouter>
      {token ? (
        <LayoutLuca>
          <Routes />
        </LayoutLuca>
      ) : (
        <Routes />
      )}
    </BrowserRouter>
  );
};

export default App;
