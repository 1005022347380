import React, { FC } from "react";
import { Spin } from "antd";
import styles from "./activityIndicator.module.css";

interface Props {
  size?: "large" | "small" | "default" | undefined;
  classSmall?: string;
}

const ActivityIndicator: FC<Props> = ({ size = "large", classSmall }) => {
  return (
    <div className={size === "small" ? classSmall : styles.activityContainer}>
      <Spin size={size} className={styles.spin} />
    </div>
  );
};

export default ActivityIndicator;
