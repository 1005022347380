import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  GetTeacherTypeAndStatus,
  GetTeacherTypeAndStatusVariables,
} from "../__generated__/gql-types/GetTeacherTypeAndStatus";

const UseGetTeacherTypeAndStatus = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: GetTeacherTypeAndStatusVariables) => {
      const result: ApolloQueryResult<GetTeacherTypeAndStatus> =
        await client.query({
          query: GET_TAG,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getTeacherTypeAndStatus;
    },
    [client]
  );
};

export default UseGetTeacherTypeAndStatus;

const GET_TAG = gql`
  query GetTeacherTypeAndStatus($userId: ID!) {
    getTeacherTypeAndStatus(userId: $userId) {
      type
      active
    }
  }
`;
