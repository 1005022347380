import * as Yup from 'yup';

export interface NewExamsProps {
  title: string;
  description: string;
  eje: string[];
  theme: string[],
  course: string,
  grade: string[],
  published: boolean,
  initialExam:boolean,
  freemium: boolean,
  destacado: boolean,
  steps: string[],
  setSubmitting?: () => void
}

export const initialValuesObj = {
  title: "",
  description: "",
  eje: [],
  theme: [],
  course: "",
  grade: [],
  published: false,
  initialExam: false,
  freemium: false,
  destacado: false,
  steps: []
}

export const NewExamsSchema = Yup.object().shape({
  title: Yup.string().required("El título es requerido."),
  description: Yup.string().required("La descripción es requerida."),
  eje: Yup.array().min(1, "Seleccione una opción"),
  theme: Yup.array().min(1, "Seleccione una opción"),
});