import {
  BaseSyntheticEvent,
  ChangeEvent,
  FC,
  JSXElementConstructor,
  MouseEvent,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import styles from "./usersForm.module.css";
import roles from "./roles-seed";
import { Buttons, InputField } from "../..";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { UsersFormSchema, initialValuesObj } from "./UsersForm.types";
import UseCreateManyUsers from "../../../api/useCreateManyUsers";
import UseCreateUser from "../../../api/useCreateUser";
import UseUpdateUser from "../../../api/useUpdateUser";
import {
  errorNotification,
  successNotification,
  updatedNotification,
} from "./utils";
import { GetSchools_getSchools } from "../../../__generated__/gql-types/GetSchools";
import { GetUsers_getUsers } from "../../../__generated__/gql-types/GetUsers";
import { Roles, UserInput } from "../../../__generated__/gql-types/globalTypes";
import {
  Button,
  Col,
  Row,
  Select,
  notification,
  Checkbox,
  Radio,
  RadioChangeEvent,
} from "antd";
import { ActivityIndicator } from "../../ActivityIndicator";
import { Table, Tag } from "antd";
import { QRCodeCanvas } from "qrcode.react";
import UseGetTeacherTypeAndStatus from "../../../api/useGetTeacherTypeAndStatus";
import UseUpdateTypeAndStatus from "../../../api/useUpdateTypeAndStatus";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useUsers } from "../../../hooks/useUsers";
import { useDispatch } from "react-redux";
import { useSchools } from "../../../hooks/useSchools";
import UseGetUserByIdBackOffice from "../../../api/useGetUserByIdBackOffice";

const { Option } = Select;

const UsersForm: FC = () => {
  const location = useLocation();
  const user_id = location.state as string;
  const dispatch = useDispatch();
  const { users, loading: loadingUsers } = useUsers();
  const { schools, loading: loadingSchools } = useSchools();
  const [initialValues, setInitialValues] =
    useState<UserInput>(initialValuesObj);
  const [bigQueryState, setBigQueryState] = useState({
    active: true,
  });
  const [loading, setLoading] = useState(false);
  const [schoolsData, setSchoolsData] = useState<
    GetSchools_getSchools[] | null
  >([]);
  const [newPassword, setnewPassword] = useState("");

  // query's
  const getUserByIdBackOffice = UseGetUserByIdBackOffice();
  const createUser = UseCreateUser();
  const updateUser = UseUpdateUser();
  const getUserBGStatus = UseGetTeacherTypeAndStatus();
  const navigate = useNavigate();
  const [urlQr, setUrlQr] = useState("");

  const gradesDefault: number[] = [1, 2, 3, 4, 5, 6];
  const [grades, setGrades] = useState<number[]>([]);

  const handleCheckboxChange = (grado: number, isTeacher: boolean) => {
    if (isTeacher) {
      setGrades((prevSelectedGrados) => {
        // Si el grado ya está seleccionado, lo eliminamos del array
        if (prevSelectedGrados.includes(grado)) {
          return prevSelectedGrados.filter((item) => item !== grado);
        } else {
          // Si no está seleccionado, lo añadimos
          return [...prevSelectedGrados, grado];
        }
      });
    } else {
      setGrades((prevState) => {
        return [grado];
      });
    }
  };

  const fetchInfo = async () => {
    setLoading(true);
    setSchoolsData(schools ?? []);
    try {
      if (user_id) {
        const data = await getUserByIdBackOffice({ userId: user_id });

        setInitialValues({
          first_name: data?.first_name ?? "",
          last_name: data?.last_name ?? "",
          email: data?.email ?? "",
          schools: data?.schools ?? [],
          email_parent: data?.email_parent ?? "",
          role: data?.role ?? Roles.student,
          visible_english_language: data?.visible_english_language ?? false,
          visible_spanish_language: data?.visible_spanish_language ?? false,
          username: data?.username ? data?.username : data?.email,
        });
        data?.urlQR && setUrlQr(data?.urlQR);
        data?.user_grade && setGrades(data.user_grade);

        const result = await getUserBGStatus({ userId: user_id }); //ESTO AHORA SOLO PIDE EL STATUS (TYPE YA NO SE USA)
        setBigQueryState({
          active:
            result?.active !== null && result?.active !== undefined
              ? result?.active
              : true,
        });
      } else {
        initialValues.role === "coordinator" &&
          setInitialValues({
            ...initialValues,
            visible_english_language: true,
            visible_spanish_language: true,
          });
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };
  /**CSV file uploader */
  const createManyUsers = UseCreateManyUsers();
  const [file, setFile] = useState();
  const [array, setArray] = useState([{}]);
  const [isTableVisible, setIsTableVisible] = useState(false);

  const csvFileToArray = async (string: string) => {
    const csvHeader = string.slice(0, string.indexOf("\r\n")).split(",");
    const csvRows = string.slice(string.indexOf("\r\n")).trim().split("\r\n");
    const array = csvRows.map((row) => {
      const values = row.split(",");
      const obj = csvHeader.reduce(
        (
          object: { [x: string]: any },
          header: string | number,
          index: number
        ) => {
          object[header] = values[index];
          object.schools = [{ id: Number(values[index]) }];
          return object;
        },
        {}
      );
      return obj;
    });

    setArray(array);
  };

  const fileReader = new FileReader();

  const handleOnChange = (e: BaseSyntheticEvent) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = async (
    e: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    e.preventDefault();

    try {
      setLoading(true);
      const createManyUsersResponse = await createManyUsers({ input: array });
      createManyUsersResponse && successNotification();
      setArray([]);
      setLoading(false);
      navigate(-1);
    } catch (error: any) {
      errorNotification("Esos usuarios ya existen");
      setArray([]);
      setLoading(false);
      navigate(-1);
    }
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "first_name",
      key: "first_name",
      render: (
        text:
          | string
          | number
          | boolean
          | ReactElement<any, string | JSXElementConstructor<any>>
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <a>{text}</a>,
    },
    {
      title: "Apellido",
      dataIndex: "last_name",
      key: "last_name",
      render: (
        text:
          | string
          | number
          | boolean
          | ReactElement<any, string | JSXElementConstructor<any>>
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <a>{text}</a>,
    },
    {
      title: "Nombre de Usuario",
      dataIndex: "username",
      key: "username",
      render: (
        text:
          | string
          | number
          | boolean
          | ReactElement<any, string | JSXElementConstructor<any>>
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <a>{text}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (
        text:
          | string
          | number
          | boolean
          | ReactElement<any, string | JSXElementConstructor<any>>
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <a>{text}</a>,
    },
    {
      title: "Rol",
      dataIndex: "role",
      key: "role",
      render: (
        text:
          | string
          | number
          | boolean
          | ReactElement<any, string | JSXElementConstructor<any>>
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <a>{text}</a>,
    },
    {
      title: "Contraseña",
      dataIndex: "password",
      key: "password",
      render: (
        text:
          | string
          | number
          | boolean
          | ReactElement<any, string | JSXElementConstructor<any>>
          | ReactFragment
          | ReactPortal
          | null
          | undefined
      ) => <a>{text}</a>,
    },
    {
      title: "Escuela",
      key: "schools",
      dataIndex: "schools",
      render: (schools: any) => (
        <>
          {schools?.map((schoolId: any) => {
            return (
              <Tag color={"geekblue"} key={schoolId.id}>
                {schoolId.id}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchInfo();
  }, [loadingUsers, loadingSchools, schools]);

  const handleSubmit = async (values: UserInput) => {
    let User: UserInput = {
      ...initialValues,
      first_name: initialValues.first_name,
      last_name: initialValues.last_name,
      username: initialValues.username?.trim(),
      email: initialValues.email,
      email_parent: initialValues.email_parent,
      password: newPassword?.length > 0 ? newPassword : initialValues.password,
      role: initialValues.role,
      schools: initialValues.schools,
      visible_english_language:
        initialValues.role === "coordinator"
          ? true
          : initialValues.visible_english_language,
      visible_spanish_language:
        initialValues.role === "coordinator"
          ? true
          : initialValues.visible_spanish_language,
      user_grade: grades,
    };
    setLoading(true);
    try {
      if (file && array && !initialValues.first_name?.length) {
        const createManyUsersResponse = await createManyUsers({ input: array });
        createManyUsersResponse && setLoading(false);
        successNotification();
      }
      if (user_id) {
        const response = await updateUser({
          userInput: User,
          updateUserId: user_id,
          active: bigQueryState.active,
        });
        response.data.updateUser && updatedNotification();

        let index = users.findIndex(
          (user) => user.id === response.data.updateUser.id
        );
        let usersUpdated = [...users];
        usersUpdated[index] = response.data
          .updateUser as unknown as GetUsers_getUsers;
        dispatch({ type: "data/setUsers", payload: usersUpdated });

        setLoading(false);
        return navigate(-1);
      } else {
        const createResponse = await createUser(User);
        createResponse && successNotification();

        let newUsers = [];
        let newUser = createResponse.data
          .createUser as unknown as GetUsers_getUsers;
        newUsers = [newUser, ...users];
        dispatch({ type: "data/setUsers", payload: newUsers });

        setLoading(false);
        return navigate(-1);
      }
    } catch (error: any) {
      setLoading(false);
      errorNotification(error.message.split(": ")[1]);
    }
  };

  const handleChangeSchool = (value: string[]) => {
    let arr = [];
    for (let i = 0; i < value.length; i++) {
      arr.push({ id: `${value[i].split("-")[0]}` });
    }
    initialValues.schools = arr;
    setInitialValues(initialValues);
  };

  const defaultValueSchool = () => {
    let values: string[] = [];
    if (user_id) {
      initialValues &&
      initialValues?.schools &&
      initialValues?.schools.length > 0
        ? (values = initialValues?.schools.map(
            (school) => `${school.id}-${school.name}`
          ))
        : (values = []);
      return values;
    } else {
      return undefined;
    }
  };

  if (loading) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      {user_id ? <h1>Editar usuario </h1> : <h1>Nuevo usuario </h1>}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={UsersFormSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleReset, touched }) => {
          return (
            <Form className={styles.formContainer}>
              {/* FirstName */}
              <InputField
                errors={errors}
                touched={touched}
                value={initialValues.first_name}
                onChange={(e) =>
                  setInitialValues({
                    ...initialValues,
                    first_name: e.target.value,
                  })
                }
                name="first_name"
                title="Nombre"
                type="text"
              />

              {/* LastName */}
              <InputField
                errors={errors}
                touched={touched}
                value={initialValues.last_name}
                onChange={(e) =>
                  setInitialValues({
                    ...initialValues,
                    last_name: e.target.value,
                  })
                }
                name="last_name"
                title="Apellido"
                type="text"
              />

              {/* School Select */}
              <label htmlFor="school">Escuela (sólo 1)</label>
              <Select
                mode="multiple"
                className={styles.formFieldSelect}
                onChange={handleChangeSchool}
                defaultValue={defaultValueSchool()}
              >
                {schoolsData?.map(
                  (school: GetSchools_getSchools, index: number) => {
                    return (
                      <Option
                        value={`${school.id}-${school.name}`}
                        key={`${index}-${new Date()}`}
                      >
                        {school.name}
                      </Option>
                    );
                  }
                )}
              </Select>

              <Row style={{ marginTop: 10, marginBottom: 10 }}>
                <Col span={10}>
                  {/* Rol Select */}
                  <label htmlFor="rol">Rol</label>
                  <Field
                    id="role"
                    name="role"
                    as="select"
                    className={styles.formFieldSelect}
                    value={initialValues.role}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const newInitialValues = {
                        ...initialValues,
                        role: e.target.value as Roles,
                      };
                      if (initialValues.role === "coordinator") {
                        newInitialValues.visible_english_language = true;
                        newInitialValues.visible_spanish_language = true;
                      }
                      setInitialValues(newInitialValues);
                    }}
                  >
                    <option disabled value="">
                      Seleccionar
                    </option>
                    {roles?.map((rol, index: number) => {
                      return (
                        <option value={rol.value} key={index}>
                          {rol.name}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    name="role"
                    component="div"
                    className={styles.error}
                  />
                </Col>

                <Col span={6} offset={1} style={{ marginTop: 30 }}>
                  <div>
                    <Field
                      type="checkbox"
                      name="published"
                      checked={bigQueryState.active}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setBigQueryState({
                          ...bigQueryState,
                          active: e.target.checked,
                        });
                      }}
                    />
                    <label htmlFor="nem" style={{ marginLeft: "10px" }}>
                      ACTIVO
                    </label>
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 20, marginBottom: 20 }}>
                {initialValues.role !== "coordinator" && (
                  <Col span={9} offset={1}>
                    <div style={{ marginBottom: 20 }}>
                      <label>Grado</label>
                    </div>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {gradesDefault.map((grade) => {
                        return (
                          <Row
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 15,
                            }}
                          >
                            <Checkbox
                              name={`${grade} °`}
                              checked={grades.includes(grade)}
                              onChange={(e: CheckboxChangeEvent) => {
                                if (initialValues.role !== "student") {
                                  handleCheckboxChange(grade, true);
                                } else {
                                  handleCheckboxChange(grade, false);
                                }
                              }}
                            />
                            <p>{grade} °</p>
                          </Row>
                        );
                      })}
                    </Row>
                  </Col>
                )}

                {initialValues.role === "teacher" && (
                  <Col span={6} offset={1}>
                    <label style={{ marginBottom: 20 }}>Idioma</label>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 20,
                      }}
                    >
                      <Checkbox
                        name="Español"
                        defaultChecked={initialValues.visible_spanish_language!}
                        onChange={(e: CheckboxChangeEvent) => {
                          setInitialValues({
                            ...initialValues,
                            visible_spanish_language: e.target.checked,
                          });
                        }}
                      />
                      <p>Español</p>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 20,
                      }}
                    >
                      <Checkbox
                        name="Inglés"
                        defaultChecked={initialValues.visible_english_language!}
                        onChange={(e: CheckboxChangeEvent) => {
                          setInitialValues({
                            ...initialValues,
                            visible_english_language: e.target.checked,
                          });
                        }}
                      />
                      <p>Inglés</p>
                    </Row>
                  </Col>
                )}
              </Row>

              {/* Username */}
              <label htmlFor="username">Nombre de Usuario</label>
              <Field
                errors={errors}
                touched={touched}
                value={initialValues.username}
                className={styles.formFields}
                autoComplete="off"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setInitialValues({
                    ...initialValues,
                    username: e.target.value,
                  })
                }
                name="username"
                title="Nombre de usuario"
                type="text"
              />

              {/* Email */}
              <label htmlFor="email">Email</label>
              <Field
                errors={errors}
                touched={touched}
                value={initialValues.email}
                className={styles.formFields}
                autoComplete="off"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setInitialValues({
                    ...initialValues,
                    email: e.target.value,
                    username: e.target.value,
                  })
                }
                name="email"
                title="Correo Electrónico"
                type="email"
              />

              {/* New Password */}
              <label htmlFor="school">Nueva Contraseña</label>
              <Field
                errors={errors}
                touched={touched}
                value={newPassword}
                className={styles.formFields}
                autoComplete="off"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setnewPassword(e.target.value)
                }
                name="newPassword"
                title="Nueva Contraseña"
                type="password"
              />

              {/* Parent Email */}
              <label htmlFor="school">Email del padre/madre</label>
              <Field
                errors={errors}
                touched={touched}
                value={initialValues.email_parent}
                className={styles.formFields}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setInitialValues({
                    ...initialValues,
                    email_parent: e.target.value,
                  })
                }
                name="email_parent"
                title="Correo Electrónico (padre/madre)"
                type="email"
              />

              {/* QR URL */}
              {initialValues && initialValues.role === "student" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 30,
                  }}
                >
                  <label htmlFor="qr">URL QR</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    value={urlQr}
                    className={styles.formFields}
                    name="url_qr"
                    title="URL QR"
                    type="text"
                  />
                  <QRCodeCanvas value={urlQr} size={150} />
                </div>
              )}

              {/* CSV Uploader */}
              <div style={{ textAlign: "left" }}>
                <h1>Cargar Archivo CSV </h1>
                <input
                  type={"file"}
                  id={"csvFileInput"}
                  accept={".csv"}
                  onChange={handleOnChange}
                  className={styles.csvInput}
                />
                <Button
                  onClick={(e) => {
                    if (file) {
                      fileReader.onload = function (event) {
                        const text = event?.target?.result as any;
                        csvFileToArray(text);
                      };
                      fileReader.readAsText(file);
                    }
                    setIsTableVisible(true);
                  }}
                  className={styles.btn}
                >
                  Verificar Usuarios
                </Button>
                <Button
                  onClick={(e) => {
                    setArray([]);
                    setIsTableVisible(false);
                    navigate(1);
                  }}
                  className={styles.removeBtn}
                >
                  Quitar archivo
                </Button>
                <Button
                  onClick={(e) => {
                    handleOnSubmit(e);
                    navigate(1);
                  }}
                  className={styles.removeBtn}
                  disabled={array.length <= 1 ? true : false}
                >
                  Guardar Usuarios CSV
                </Button>
                {isTableVisible ? (
                  <div>
                    {" "}
                    <Table columns={columns} dataSource={array} />
                  </div>
                ) : null}
              </div>
              {/* Buttons */}
              {array.length <= 1 ? (
                <Buttons
                  handleClick={() => {
                    handleReset();
                    navigate(-1);
                  }}
                />
              ) : null}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UsersForm;
