import * as Yup from "yup";
import { VideoInput } from "../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: VideoInput = {
  title: "",
  description: "",
  publish: 0,
  themes: [],
  axes: [],
  quizzes: [],
  microcontent: '',
  key_learnings: '',
};

export const NewVideosSchema = Yup.object().shape({
  title: Yup.string().required("El titulo requerido."),
});

export interface AxesProps {
  id: string;
  name: string;
}

export interface ThemesProps {
  id: string;
  name: string;
}
