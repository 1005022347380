import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { MutationGenerateDocsVariables } from "../__generated__/gql-types/MutationGenerateDocs";

const useGenerateDocs = () => {
  const client = useApolloClient();

  return useCallback(
    async (generateDocsVariables: MutationGenerateDocsVariables) => {
      return await client.mutate({
        mutation: GENERATE_DOCS,
        fetchPolicy: "no-cache",
        variables: generateDocsVariables,
      });
    },
    [client]
  );
};

export default useGenerateDocs;

const GENERATE_DOCS = gql`
  mutation MutationGenerateDocs($sheetUrl: String, $folder: String) {
    generateDocFiles(sheetURL: $sheetUrl, folder: $folder)
  }
`;
