import { createSlice } from "@reduxjs/toolkit";
import { GetUserByEmail_getUserByEmail } from "../../__generated__/gql-types/GetUserByEmail";

export type AuthState = {
  token: string;
  user: GetUserByEmail_getUserByEmail | null;
};

const initialState: AuthState = {
  token: "",
  user: null,
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth: () => {
      return {
        token: "",
        user: null,
      };
    },
    setAuth: (state, action) => {
      return {
        token: action.payload.token,
        user: action.payload.user,
      };
    },
  },
});

export default auth;
