import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LITERACIES } from "../store/selectors";
import { notification } from "antd";
import { getLiteracies_getLiteracies } from "../__generated__/gql-types/getLiteracies";
import UseGetLiteracies from "../api/useGetLiteracies";
import useDeleteLiteracy from "../api/useDeleteLiteracy";

export const useLiteracy = () => {
  const dispatch = useDispatch();
  const literaciesSelector = useSelector(LITERACIES);
  const [loading, setLoading] = useState(false);
  const [literacies, setLiteracies] = useState<getLiteracies_getLiteracies[]>(
    []
  );

  const deleteLiteracyMutation = useDeleteLiteracy();
  const getLiteracies = UseGetLiteracies();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (literaciesSelector?.length > 0) {
        setLiteracies(literaciesSelector);
      } else {
        const literaciesResponse = await getLiteracies();
        const literaciesData = literaciesResponse?.sort(
          (a, b) => Number(b?.id) - Number(a?.id)
        );

        literaciesData && setLiteracies(literaciesData);
        dispatch({ type: "data/setLiteracies", payload: literaciesData });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const deleteLiteracy = async (idLiteracy: string) => {
    setLoading(true);
    try {
      const response = await deleteLiteracyMutation({
        literacyId: idLiteracy.toString(),
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "La lectoescritura fue eliminado con exito!",
          placement: "top",
          duration: 3,
        });
        let updatedLiteracies = literacies.filter((literacy) => literacy.id !== idLiteracy);
        setLiteracies(updatedLiteracies);
        dispatch({ type: "data/setLiteracies", payload: updatedLiteracies });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInfo();
    };
    fetchData();

    return () => {};
  }, []);

  return { loading, literacies, deleteLiteracy };
};
