import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";

const useDeleteListening = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: any) => {
      return await client.mutate({
        mutation: DLETE_LISTENING,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useDeleteListening;

const DLETE_LISTENING = gql`
  mutation deleteListening($listeningId: ID!) {
    deleteListening(listening_id: $listeningId) {
      id
    }
  }
`;
