import { useDispatch, useSelector } from "react-redux";
import { COURSES } from "../store/selectors";
import { useEffect, useState } from "react";
import useDeleteCourse from "../api/useDeleteCourse";
import UseGetCoursesListBackOffice from "../api/useGetCoursesListBackOffice";
import { notification } from "antd";
import { GetCoursesListBackOffice_getCoursesListBackOffice } from "../__generated__/gql-types/GetCoursesListBackOffice";

export const useCourses = () => {
  const dispatch = useDispatch();
  const coursesSelector = useSelector(COURSES);
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState<
    GetCoursesListBackOffice_getCoursesListBackOffice[]
  >([]);

  const deleteCourseMutation = useDeleteCourse();
  const getCourses = UseGetCoursesListBackOffice();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (coursesSelector?.length > 0) {
        setCourses(coursesSelector);
      } else {
        const courseResponse = await getCourses();
        const courseData = courseResponse?.sort(
          (a, b) => Number(b.id) - Number(a.id)
        );

        courseData && setCourses(courseData);
        dispatch({ type: "data/setCourses", payload: courseData });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
    }
  };

  const deleteCourse = async (idCourse: string) => {
    try {
      setLoading(true);
      const response = await deleteCourseMutation({
        deleteCourseId: idCourse,
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "El Curso fue eliminado con exito!",
          placement: "top",
          duration: 3,
        });
        let updatedCourses = courses.filter((course) => course.id !== idCourse);
        setCourses(updatedCourses);
        dispatch({ type: "data/setCourses", payload: updatedCourses });
        setLoading(false);
      }
    } catch {
      setLoading(false);
      notification["error"]({
        message: "Eliminación Erronea",
        description: "El Curso no pudo ser eliminado!",
        placement: "top",
        duration: 3,
      });
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return {
    loading,
    courses,
    deleteCourse,
  };
};
