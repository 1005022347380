import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getAIConversations } from "../__generated__/gql-types/getAIConversations";

const UseAIConversations = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getAIConversations> = await client.query({
      query: GET_AICONVERSATIONS,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getAIConversations;
  }, [client]);
};

export default UseAIConversations;

const GET_AICONVERSATIONS = gql`
  query getAIConversations {
    getAIConversations {
      id
      title
      publish
      level_cerf
      voice
      character_for_role_play
      cover_image
      avatar_image
      section_1 {
        order
        question
      }
      section_2 {
        order
        question
      }
      created_at
      updated_at
      deleted_at
    }
  }
`;
