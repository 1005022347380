import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UpdateVideoVariables } from "../__generated__/gql-types/UpdateVideo";

const UseUpdateVideos = () => {
  const client = useApolloClient();
  return useCallback(
    async (videoInput: UpdateVideoVariables) => {
      return await client.mutate({
        mutation: UPDATE_VIDEO,
        fetchPolicy: "no-cache",
        variables: videoInput,
      });
    },
    [client]
  );
};

export default UseUpdateVideos;

const UPDATE_VIDEO = gql`
  mutation UpdateVideo($videoInput: VideoInput, $videoId: ID!) {
    updateVideo(videoInput: $videoInput, videoId: $videoId) {
      id
      title
      description
      publish
      themes {
        name
        id
      }
      axes {
        id
        name
      }
      metadata
      url
      file_id
      imageUrl
      created_at
      updated_at
      deleted_at
      quizzes {
        quiz {
          id
          title
          filename
          questions {
            id
            question
            answers {
              id
              answer
            }
          }
        }
      }
    }
  }
`;
