import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetTags } from "../__generated__/gql-types/GetTags";

const UseGetTags = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetTags> = await client.query({
      query: GET_TAG,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getTags;
  }, [client]);
};

export default UseGetTags;

const GET_TAG = gql`
  query GetTags {
    getTags {
      id
      name
    }
  }
`;
