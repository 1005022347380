import { FC, useEffect, useState } from "react";
import {
  Space,
  Table,
  Button,
  notification,
  Modal,
  Row,
  Input,
} from "antd";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/lib/table";
import styles from "./notification.module.css";
import { useNotifications } from "../../hooks/useNotifications";
import { getNotifications_getNotifications } from '../../__generated__/gql-types/getNotifications';

const { Search } = Input;

const Notification: FC = () => {
  const { notifications, loading: isLoading, deleteNotification } = useNotifications();
  const [dataSource, setdataSource] = useState<
    getNotifications_getNotifications[] | null
  >([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [idNotification, setIdNotification] = useState<string>("");

  useEffect(() => {
    setdataSource(notifications);
  }, [notifications]);

  const showModal = (id: string) => {
    setIsModalVisible(true);
    setIdNotification(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      await deleteNotification(idNotification);
      setIsModalVisible(false);
    } catch {
      notification["error"]({
        message: "Eliminación Erronea",
        description: "La notificación no pudo ser eliminada!",
        placement: "top",
        duration: 3,
      });
    }
  };

  const columns: ColumnsType<getNotifications_getNotifications> = [
    {
      align: "center",
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    { align: "center", title: "Título", dataIndex: "title", key: "title" },
    { align: "center", title: "Escuela", dataIndex: "school_id", key: "school_id" },
    { align: "center", title: "Rol", dataIndex: "role", key: "role" },
    {
      align: "center",
      title: "Acciones",
      key: "acciones",
      render: (_, record) => {
        return (
          <Space size={15} style={{ fontSize: 19 }}>
            <Link to={`/notifications/${record.id}`} state={record.id}>
              <Button icon={<SearchOutlined />}>Editar</Button>
            </Link>
            <Button
              onClick={() => {
                record && record.id && showModal(record.id);
              }}
              danger
            >
              Eliminar
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    if (value === "") {
      setdataSource(notifications);
    } else {
      if (dataSource) {
        const filteredData: getNotifications_getNotifications[] = dataSource.filter(
          (data) => data.title?.includes(value)
        );
        setdataSource(filteredData);
      }
    }
  };

  const onSearchId = (value: string) => {
    if (value === "") {
      setdataSource(notifications);
    } else {
      if (dataSource) {
        const filteredData: getNotifications_getNotifications[] = dataSource.filter(
          (data) => data.id === value
        );
        setdataSource(filteredData);
      }
    }
  };

  return (
    <div className={styles.container}>
      <h1>Notificaciones</h1>
      <div className={styles.newQuizzesContainer}>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por id"
            onSearch={onSearchId}
            enterButton
          />
        </Space>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por título"
            onSearch={onSearch}
            enterButton
          />
        </Space>
        <Button type="primary" danger>
          <Link to="/notifications/new">Nueva Notificación</Link>
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource ?? []}
        loading={isLoading}
        rowKey={(record) => (record && record.id ? record.id : "")}
      />
      <Modal
        title="Eliminar Notificación"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>¿Desea eliminar la notificación?</p>
      </Modal>
    </div>
  );
};

export default Notification;
