import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { bulkRedactionCreateVariables } from "../__generated__/gql-types/bulkRedactionCreate";

const useBulkRedactionsCreate = () => {
  const client = useApolloClient();

  return useCallback(
    async (bulkCreateInput: bulkRedactionCreateVariables) => {
      return await client.mutate({
        mutation: BULK_CREATE,
        fetchPolicy: "no-cache",
        variables: bulkCreateInput,
      });
    },
    [client]
  );
};

export default useBulkRedactionsCreate;

const BULK_CREATE = gql`
  mutation bulkRedactionCreate($sheetUrl: String) {
    bulkRedactionCreate(sheetURL: $sheetUrl)
  }
`;
