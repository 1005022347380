import React, { useEffect, useState } from "react";
import { Select } from "antd";
import styles from "../../quizzForm.module.css";
import {
  DAndDQuestionInput,
  QuestionInput,
  RelationQuestionInput,
} from "../../../../../__generated__/gql-types/globalTypes";

// COMPONENTS
import { SingleQuestion } from "../SingleQuestion/SingleQuestion";
import { AIQuestion } from "../AIQuestion/AIQuestion";
import { DragAndDropQuestion } from "../DragAndDropQuestion/DragAndDropQuestion";
import { RelationQuestion } from "../RelationQuestion/RelationQuestion";
import { FillTheBlankDragAndDropQuestion } from "../FillTheBlankDragAndDropQuestion/FillTheBlankDragAndDropQuestion";
import { FillTheBlankQuestion } from "../FillTheBlankQuestion/FillTheBlankQuestion";
import { GroupQuestion } from "../DragAndDropQuestion/GroupQuestion";

interface QuestionIterationComponentProps {
  keyGenerator: string;
  quiz_id: string | undefined;
  buttonAddNewQuestion: JSX.Element;
  buttonDeleteQuestion: JSX.Element;
  question: QuestionInput;
  questions: QuestionInput[];
  indexQuestion: number;
  setQuestions: React.Dispatch<React.SetStateAction<QuestionInput[]>>;
}

export const QuestionIterationComponent = ({
  keyGenerator,
  quiz_id,
  buttonAddNewQuestion,
  buttonDeleteQuestion,
  question,
  questions,
  setQuestions,
  indexQuestion,
}: QuestionIterationComponentProps) => {
  const [questionType, setQuestionType] = useState(["0"]);
  // LOADING UPLOADIMAGE
  const [loadingLoadImage, setLoadingLoadImage] = useState<boolean>(false);

  // QUESTIONS
  const [urlImageQuestion, setUrlImageQuestion] = useState<string>("");

  // ANSWERS
  const [urlImageAnswers, setUrlImageAnswers] = useState<string[]>([]);
  const [imageAnswers, setimageAnswers] = useState<string[]>([]);

  // DRAG AND DROP - RELACIONA CON LA IMAGEN
  const [fileImageBlockItems, setFileImageBlockItems] = useState<string[]>([]);
  const [fileImageBlockContainer, setFileImageBlockContainer] = useState<
    string[]
  >([]);

  const [fileImageGroupContainer, setFileImageGroupContainer] = useState<
    string[]
  >([]);

  const [selectContent, setSelectContent] = useState("1");

  const handleRemoveAnswer = () => {
    const answers = [...question?.answers!];
    answers.splice(question.answers!.length - 1, 1);
    question.answers = answers;
    questions[indexQuestion] = question;
    setQuestions([...questions]);
  };

  const handleAddAnswer = async () => {
    let answers = [];
    let newQuestion: QuestionInput;
    if (!quiz_id && !question?.id) {
      let orderQuestion =
        question?.answers && question?.answers.length > 0
          ? question?.answers[question?.answers.length - 1].order! + 1
          : 0;
      answers = [
        ...question?.answers!,
        {
          answer: "",
          correct: 0,
          image: "",
          image_aspect: "",
          order: orderQuestion,
        },
      ];
    } else if (quiz_id && !question?.id) {
      let orderQuestion =
        question?.answers && question?.answers.length > 0
          ? question?.answers[question?.answers.length - 1].order! + 1
          : 0;
      answers = [
        ...question?.answers!,
        {
          id: null,
          answer: "",
          correct: 0,
          image: "",
          image_aspect: "",
          order: orderQuestion,
        },
      ];
    } else {
      let orderQuestion =
        question?.answers && question?.answers.length > 0
          ? question?.answers[question?.answers.length - 1].order! + 1
          : 0;
      answers = [
        ...question?.answers!,
        {
          id: null,
          answer: "",
          correct: 0,
          image: "",
          image_aspect: "",
          order: orderQuestion,
        },
      ];
    }
    newQuestion = {
      ...question,
      answers: answers,
    };
    questions[indexQuestion] = newQuestion;
    setQuestions([...questions]);
  };

  const handleImageAnswers = (newImage: string, indexAnswer: number) => {
    let splitImage = newImage.split("/");
    let newAnswers = questions[indexQuestion].answers;
    if (newAnswers) {
      newAnswers[indexAnswer].image = splitImage[splitImage.length - 1];
    }
    let newQuesrion: QuestionInput = {
      ...question,
      answers: newAnswers,
    };
    questions[indexQuestion] = newQuesrion;
    setQuestions([...questions]);
  };

  // DRAG AND DROP
  const handleAddBlockDADWithImage = () => {
    let updatedQuestion: DAndDQuestionInput[] = [];
    let newQuestion: QuestionInput;
    if (!quiz_id && !question?.id) {
      if (question.dAndDQuestion && question.dAndDQuestion[0] !== null) {
        updatedQuestion.push({ ...question.dAndDQuestion[0] });
        let coincidence = updatedQuestion[0].blockContainer!.length + 1;
        updatedQuestion[0].blockContainer = [
          ...updatedQuestion[0].blockContainer!,
          {
            coincidence,
            image: "",
            text: "",
            totalItems: 1,
          },
        ];
        updatedQuestion[0].blockItems = [
          ...updatedQuestion[0].blockItems!,
          {
            coincidence,
            image: "",
            text: "",
          },
        ];
      }
    } else if (quiz_id && !question?.id) {
      if (question.dAndDQuestion && question.dAndDQuestion[0] !== null) {
        updatedQuestion.push({ id: null, ...question.dAndDQuestion[0] });
        let coincidence = updatedQuestion[0].blockContainer!.length + 1;
        updatedQuestion[0].blockContainer = [
          ...updatedQuestion[0].blockContainer!,
          {
            coincidence,
            image: "",
            text: "",
            totalItems: 1,
          },
        ];
        updatedQuestion[0].blockItems = [
          ...updatedQuestion[0].blockItems!,
          {
            coincidence,
            image: "",
            text: "",
          },
        ];
      }
    } else {
      if (question.dAndDQuestion && question.dAndDQuestion[0] !== null) {
        updatedQuestion.push({ id: null, ...question.dAndDQuestion[0] });
        let coincidence = updatedQuestion[0].blockContainer!.length + 1;
        updatedQuestion[0].blockContainer = [
          ...updatedQuestion[0].blockContainer!,
          {
            coincidence,
            image: "",
            text: "",
            totalItems: 1,
          },
        ];
        updatedQuestion[0].blockItems = [
          ...updatedQuestion[0].blockItems!,
          {
            coincidence,
            image: "",
            text: "",
          },
        ];
      }
    }
    setFileImageBlockContainer([...fileImageBlockContainer, ""]);
    newQuestion = {
      ...question,
      dAndDQuestion: updatedQuestion,
    };
    questions[indexQuestion] = newQuestion;
    setQuestions([...questions]);
  };

  const handleRemoveBlockDADWithImage = () => {
    const dAdQuestions = [...question?.dAndDQuestion!];
    dAdQuestions[0].blockContainer?.splice(
      dAdQuestions[0].blockContainer?.length - 1,
      1
    );
    dAdQuestions[0].blockItems?.splice(
      dAdQuestions[0].blockItems?.length - 1,
      1
    );

    question.dAndDQuestion = dAdQuestions;
    questions[indexQuestion] = question;
    setQuestions([...questions]);
  };

  const handleImageDADQuestionRelationWithImage = (
    image: string,
    index: number
  ) => {
    let newDADQuestion: DAndDQuestionInput[] =
      questions[indexQuestion].dAndDQuestion!;
    if (
      newDADQuestion?.length > 0 &&
      newDADQuestion[0] !== null &&
      newDADQuestion[0].blockContainer &&
      newDADQuestion[0].blockContainer !== null &&
      newDADQuestion[0].blockContainer?.length > 0
    ) {
      newDADQuestion[0].blockContainer[index].image = image;
    }

    question.dAndDQuestion = newDADQuestion;
    questions[indexQuestion] = question;
    setQuestions([...questions]);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => {
    const { name, value, checked } = e.target;
    if (name === "question") {
      question.question = value;
    }
    if (name === "paragraph") {
      question.paragraph = value;
    }
    if (index !== undefined) {
      if (name === "checkAnswer") {
        question.answers![index].correct = checked ? 1 : 0;
        const updatedAnswes = question.answers!.map((ans, index) => ({
          ...ans,
          order: index,
        }));
        question.answers = updatedAnswes;
      } else if (name === "answer") {
        question.answers![index].answer = value;
      } else if (name === "drag_and_drop_block_container") {
        if (
          question.dAndDQuestion &&
          question.dAndDQuestion.length > 0 &&
          question.dAndDQuestion[0] !== null &&
          question.dAndDQuestion[0].blockItems &&
          question.dAndDQuestion[0].blockItems.length > 0 &&
          question.dAndDQuestion[0].blockItems[index] !== null &&
          question.dAndDQuestion[0].blockItems[index] !== undefined
        ) {
          question.dAndDQuestion![0].blockItems[index]!.text = value;
        }
      } else if (name === `block1_${index}`) {
        if (
          question.relationQuestion &&
          question.relationQuestion.length > 0 &&
          question.relationQuestion[0] !== null &&
          question.relationQuestion[0].block1 &&
          question.relationQuestion[0].block1.length > 0 &&
          question.relationQuestion[0].block1[index] !== null &&
          question.relationQuestion[0].block1[index] !== undefined
        ) {
          question.relationQuestion![0].block1[index]!.text = value;
        }
      } else if (name === `block2_${index}`) {
        if (
          question.relationQuestion &&
          question.relationQuestion.length > 0 &&
          question.relationQuestion[0] !== null &&
          question.relationQuestion[0].block2 &&
          question.relationQuestion[0].block2.length > 0 &&
          question.relationQuestion[0].block2[index] !== null &&
          question.relationQuestion[0].block2[index] !== undefined
        ) {
          question.relationQuestion![0].block2[index]!.text = value;
        }
      } else if (name === `group_${index}`) {
        if (
          question.dAndDQuestion &&
          question.dAndDQuestion.length > 0 &&
          question.dAndDQuestion[0] !== null &&
          question.dAndDQuestion[0].blockContainer &&
          question.dAndDQuestion[0].blockContainer.length > 0 &&
          question.dAndDQuestion[0].blockContainer[index] !== null &&
          question.dAndDQuestion[0].blockContainer[index] !== undefined
        ) {
          question.dAndDQuestion![0].blockContainer[index]!.text = value;
        }
      } else if (name === `group_block_item_${index}`) {
        if (
          question.dAndDQuestion &&
          question.dAndDQuestion.length > 0 &&
          question.dAndDQuestion[0] !== null &&
          question.dAndDQuestion[0].blockItems &&
          question.dAndDQuestion[0].blockItems.length > 0 &&
          question.dAndDQuestion[0].blockItems[index] !== null &&
          question.dAndDQuestion[0].blockItems[index] !== undefined
        ) {
          question.dAndDQuestion![0].blockItems[index]!.text = value;
        }
      }
    }
  };

  // GROUP DRAG AND DROP
  const handleAddGroupWithImage = () => {
    let updatedQuestion: DAndDQuestionInput[] = [];
    let newQuestion: QuestionInput;
    if (!quiz_id && !question?.id) {
      if (question.dAndDQuestion && question.dAndDQuestion[0] !== null) {
        updatedQuestion.push({ ...question.dAndDQuestion[0] });
        // def totalItems
        updatedQuestion[0].blockContainer![0].totalItems =
          updatedQuestion[0].blockContainer![0].totalItems! + 1;
        updatedQuestion[0].blockContainer![1].totalItems =
          updatedQuestion[0].blockContainer![1].totalItems! + 1;
        // add item in specific block container
        updatedQuestion[0].blockItems = [
          ...updatedQuestion[0].blockItems!,
          {
            coincidence: 1,
            image: "",
            text: "",
          },
          {
            coincidence: 2,
            image: "",
            text: "",
          },
        ];
      }
    } else if (quiz_id && !question?.id) {
      if (question.dAndDQuestion && question.dAndDQuestion[0] !== null) {
        updatedQuestion.push({ id: null, ...question.dAndDQuestion[0] });
        // def totalItems
        updatedQuestion[0].blockContainer![0].totalItems =
          updatedQuestion[0].blockContainer![0].totalItems! + 1;
        updatedQuestion[0].blockContainer![1].totalItems =
          updatedQuestion[0].blockContainer![1].totalItems! + 1;
        // add item in specific block container
        updatedQuestion[0].blockItems = [
          ...updatedQuestion[0].blockItems!,
          {
            coincidence: 1,
            image: "",
            text: "",
          },
          {
            coincidence: 2,
            image: "",
            text: "",
          },
        ];
      }
    } else {
      if (question.dAndDQuestion && question.dAndDQuestion[0] !== null) {
        updatedQuestion.push({ id: null, ...question.dAndDQuestion[0] });
        // def totalItems
        updatedQuestion[0].blockContainer![0].totalItems =
          updatedQuestion[0].blockContainer![0].totalItems! + 1;
        updatedQuestion[0].blockContainer![1].totalItems =
          updatedQuestion[0].blockContainer![1].totalItems! + 1;
        // add item in specific block container
        updatedQuestion[0].blockItems = [
          ...updatedQuestion[0].blockItems!,
          {
            coincidence: 1,
            image: "",
            text: "",
          },
          {
            coincidence: 2,
            image: "",
            text: "",
          },
        ];
      }
    }
    fileImageGroupContainer.push("");
    fileImageGroupContainer.push("");
    setFileImageGroupContainer([...fileImageGroupContainer]);
    newQuestion = {
      ...question,
      dAndDQuestion: updatedQuestion,
    };
    questions[indexQuestion] = newQuestion;
    setQuestions([...questions]);
  };

  const handleRemoveGroupWithImage = () => {
    const dAdQuestions = [...question?.dAndDQuestion!];
    dAdQuestions[0].blockItems?.splice(
      dAdQuestions[0].blockItems?.length - 1,
      1
    );
    dAdQuestions[0].blockItems?.splice(
      dAdQuestions[0].blockItems?.length - 1,
      1
    );
    dAdQuestions[0].blockContainer![0].totalItems =
      dAdQuestions![0].blockContainer![0].totalItems! - 1;
    dAdQuestions[0].blockContainer![1].totalItems =
      dAdQuestions![0].blockContainer![1].totalItems! - 1;
    question.dAndDQuestion = dAdQuestions;
    questions[indexQuestion] = question;
    setQuestions([...questions]);
  };

  const handleImageGroupDADQuestions = (image: string, index: number) => {
    let newDADQuestion: DAndDQuestionInput[] =
      questions[indexQuestion].dAndDQuestion!;
    if (
      newDADQuestion?.length > 0 &&
      newDADQuestion[0] !== null &&
      newDADQuestion[0].blockItems &&
      newDADQuestion[0].blockItems !== null &&
      newDADQuestion[0].blockItems.length > 0
    ) {
      newDADQuestion[0].blockItems[index].image = image;
    }
    let newQuestion: QuestionInput = {
      ...question,
      dAndDQuestion: newDADQuestion,
    };
    questions[indexQuestion] = newQuestion;
    setQuestions([...questions]);
  };

  // RELATION
  const handleAddRelationBlockQuestions = () => {
    let updatedQuestion: RelationQuestionInput[] = [];
    let newQuestion: QuestionInput;
    if (!quiz_id && !question?.id) {
      if (question?.relationQuestion && question?.relationQuestion.length > 0) {
        updatedQuestion.push({ ...question.relationQuestion[0] });
        let coincidence = question.relationQuestion[0].block1!.length + 1;
        updatedQuestion[0].block1 = [
          ...updatedQuestion[0].block1!,
          {
            coincidence,
            image: "",
            text: "",
          },
        ];
        updatedQuestion[0].block2 = [
          ...updatedQuestion[0].block2!,
          {
            coincidence,
            image: "",
            text: "",
          },
        ];
      }
    } else if (quiz_id && !question?.id) {
      if (question?.relationQuestion && question?.relationQuestion.length > 0) {
        updatedQuestion.push({ id: null, ...question.relationQuestion[0] });
        let coincidence = question.relationQuestion[0].block1!.length + 1;
        updatedQuestion[0].block1 = [
          ...updatedQuestion[0].block1!,
          {
            coincidence,
            image: "",
            text: "",
          },
        ];
        updatedQuestion[0].block2 = [
          ...updatedQuestion[0].block2!,
          {
            coincidence,
            image: "",
            text: "",
          },
        ];
      }
    } else {
      if (question?.relationQuestion && question?.relationQuestion.length > 0) {
        updatedQuestion.push({ id: null, ...question.relationQuestion[0] });
        let coincidence = question.relationQuestion[0].block1!.length + 1;
        updatedQuestion[0].block1 = [
          ...updatedQuestion[0].block1!,
          {
            coincidence,
            image: "",
            text: "",
          },
        ];
        updatedQuestion[0].block2 = [
          ...updatedQuestion[0].block2!,
          {
            coincidence,
            image: "",
            text: "",
          },
        ];
      }
    }

    newQuestion = {
      ...question,
      relationQuestion: updatedQuestion,
    };
    questions[indexQuestion] = newQuestion;
    setQuestions([...questions]);
  };

  const handleRemoveRelationBlockQuestions = () => {
    const relationQuestion = [...question?.relationQuestion!];
    relationQuestion[0].block1?.splice(
      relationQuestion[0].block1.length - 1,
      1
    );
    relationQuestion[0].block2?.splice(
      relationQuestion[0].block2.length - 1,
      1
    );
    question.relationQuestion = relationQuestion;
    questions[indexQuestion] = question;
    setQuestions([...questions]);
  };

  const fetchInfo = () => {
    let questionTypeSelected = [
      typeof question?.type_id === "number"
        ? question?.type_id.toString()
        : "0",
    ];
    if (question.answers && question.answers.length > 0) {
      let newAnswers: string[] = [];
      let newUrlAnswers: string[] = [];
      question.answers.map((answer) => {
        newAnswers.push(answer.image ?? "");
        newUrlAnswers.push("");
      });
      setimageAnswers([...newAnswers]);
      setUrlImageAnswers([...newUrlAnswers]);
    }

    if (question.paragraph && question.paragraph.length > 0) {
      setSelectContent("2");
    }

    if (
      question.dAndDQuestion &&
      question.dAndDQuestion.length > 0 &&
      questionTypeSelected[0] === "5"
    ) {
      let newFileImageBlockContainer: string[] = [];
      let newFileImageBlockItems: string[] = [];
      let newDadQ = question.dAndDQuestion[0];
      const {} = newDadQ;
      newDadQ.blockContainer?.map((bc) => {
        if (bc?.image) {
          newFileImageBlockContainer.push(bc?.image);
        } else {
          newFileImageBlockContainer.push("");
        }
      });
      newDadQ.blockItems?.map((bi) => {
        if (bi?.image) {
          newFileImageBlockItems.push(bi.image);
        } else {
          newFileImageBlockItems.push("");
        }
      });

      setFileImageBlockContainer([...newFileImageBlockContainer]);
      setFileImageBlockItems([...newFileImageBlockItems]);
    }

    if (
      question.dAndDQuestion &&
      question.dAndDQuestion.length > 0 &&
      questionTypeSelected[0] === "9"
    ) {
      let newFileImageGroupItems: string[] = [];
      let newDadQ = question.dAndDQuestion[0];
      newDadQ.blockItems?.map((bi) => {
        if (bi?.image) {
          newFileImageGroupItems.push(bi.image);
        } else {
          newFileImageGroupItems.push("");
        }
      });

      setFileImageGroupContainer([...newFileImageGroupItems]);
    }
    setQuestionType(questionTypeSelected);
  };

  const handleChangeSingleMultipleFillTheBlankFTBDAGQuestion = (
    quiz_id: string | undefined,
    question: QuestionInput
  ) => {
    if (quiz_id && question?.id) {
      console.log("tiene quiz id y tiene question id");
      question.answers = [
        {
          id: null,
          correct: 0,
          image: "",
          answer: "",
          order: 0,
          image_aspect: "",
        },
      ];
    } else if (quiz_id && !question?.id) {
      console.log("tiene quiz id y no tiene question id");
      question.answers = [
        {
          id: null,
          correct: 0,
          image: "",
          answer: "",
          order: 0,
          image_aspect: "",
        },
      ];
    } else {
      console.log("no tiene nada");
      question.answers = [
        {
          correct: 0,
          image: "",
          answer: "",
          order: 0,
          image_aspect: "",
        },
      ];
    }
  };

  const handleChangeDragAndDropQuestion = (
    quiz_id: string | undefined,
    question: QuestionInput
  ) => {
    if (quiz_id && question?.id) {
      question.dAndDQuestion = [
        {
          id: null,
          blockContainer: [
            {
              image: "",
              text: "",
              totalItems: 1,
              coincidence: 1,
            },
          ],
          blockItems: [
            {
              coincidence: 1,
              text: "",
              image: "",
            },
          ],
        },
      ];
    } else if (quiz_id && !question?.id) {
      question.dAndDQuestion = [
        {
          id: null,
          blockContainer: [
            {
              image: "",
              text: "",
              totalItems: 1,
              coincidence: 1,
            },
          ],
          blockItems: [
            {
              coincidence: 1,
              text: "",
              image: "",
            },
          ],
        },
      ];
    } else {
      question.dAndDQuestion = [
        {
          blockContainer: [
            {
              image: "",
              text: "",
              totalItems: 1,
              coincidence: 1,
            },
          ],
          blockItems: [
            {
              coincidence: 1,
              text: "",
              image: "",
            },
          ],
        },
      ];
    }

    setFileImageBlockContainer([...fileImageBlockContainer, ""]);
  };

  const handleChangeRelationQuestion = (
    quiz_id: string | undefined,
    question: QuestionInput
  ) => {
    if (quiz_id && question?.id) {
      question.relationQuestion = [
        {
          id: null,
          block1: [
            {
              image: "",
              text: "",
              coincidence: 1,
            },
          ],
          block2: [
            {
              coincidence: 1,
              text: "",
              image: "",
            },
          ],
        },
      ];
    } else if (quiz_id && !question?.id) {
      question.relationQuestion = [
        {
          id: null,
          block1: [
            {
              image: "",
              text: "",
              coincidence: 1,
            },
          ],
          block2: [
            {
              coincidence: 1,
              text: "",
              image: "",
            },
          ],
        },
      ];
    } else {
      question.relationQuestion = [
        {
          block1: [
            {
              image: "",
              text: "",
              coincidence: 1,
            },
          ],
          block2: [
            {
              coincidence: 1,
              text: "",
              image: "",
            },
          ],
        },
      ];
    }

    console.log({ rq: question?.relationQuestion });
  };

  const handleChangeGroupQuestion = (
    quiz_id: string | undefined,
    question: QuestionInput
  ) => {
    if (quiz_id && question?.id) {
      question.dAndDQuestion = [
        {
          id: null,
          blockContainer: [
            {
              image: "",
              text: "",
              totalItems: 1,
              coincidence: 1,
            },
            {
              image: "",
              text: "",
              totalItems: 1,
              coincidence: 2,
            },
          ],
          blockItems: [
            {
              coincidence: 1,
              text: "",
              image: "",
            },
            {
              coincidence: 2,
              text: "",
              image: "",
            },
          ],
        },
      ];
    } else if (quiz_id && !question?.id) {
      question.dAndDQuestion = [
        {
          id: null,
          blockContainer: [
            {
              image: "",
              text: "",
              totalItems: 1,
              coincidence: 1,
            },
            {
              image: "",
              text: "",
              totalItems: 1,
              coincidence: 2,
            },
          ],
          blockItems: [
            {
              coincidence: 1,
              text: "",
              image: "",
            },
            {
              coincidence: 2,
              text: "",
              image: "",
            },
          ],
        },
      ];
    } else {
      question.dAndDQuestion = [
        {
          blockContainer: [
            {
              image: "",
              text: "",
              totalItems: 1,
              coincidence: 1,
            },
            {
              image: "",
              text: "",
              totalItems: 1,
              coincidence: 2,
            },
          ],
          blockItems: [
            {
              coincidence: 1,
              text: "",
              image: "",
            },
            {
              coincidence: 2,
              text: "",
              image: "",
            },
          ],
        },
      ];
    }

    setFileImageGroupContainer([...fileImageGroupContainer, ""]);
  };

  const handleTypeSelected = async (values: string[]) => {
    setQuestionType(values);
    console.log({ values });
    question.type_id = Number(values[0]);
    if (values[0] === "1" || values[0] === "2" || values[0] === "3") {
      // SINGLE AND MULTIPLE CHOICE
      handleChangeSingleMultipleFillTheBlankFTBDAGQuestion(quiz_id, question);
      questions[indexQuestion] = question;
    } else if (values[0] === "7" || values[0] === "4") {
      // FILL THE BLANK DRAG AND DROP
      handleChangeSingleMultipleFillTheBlankFTBDAGQuestion(quiz_id, question);
      questions[indexQuestion] = question;
    } else if (values[0] === "5") {
      // DRAG AND DROP
      handleChangeDragAndDropQuestion(quiz_id, question);
      questions[indexQuestion] = question;
    } else if (values[0] === "6" || values[0] === "8") {
      // DRAG AND DROP
      handleChangeRelationQuestion(quiz_id, question);
      questions[indexQuestion] = question;
    } else if (values[0] === "9") {
      // DRAG AND DROP GROUP
      handleChangeGroupQuestion(quiz_id, question);
      questions[indexQuestion] = question;
    } else {
      question.type_id = Number(values[0]);
      questions[indexQuestion] = question;
    }
    setQuestions([...questions]);
  };

  const handleImageQuestion = (newImage: string) => {
    let splitImage = newImage.split("/");
    let newQuestion: QuestionInput = {
      ...question,
      image: splitImage[splitImage.length - 1],
    };
    questions[indexQuestion] = newQuestion;
    setQuestions([...questions]);
  };

  useEffect(() => {
    fetchInfo();
  }, [setQuestionType, questions, setQuestions]);

  return (
    <div
      key={keyGenerator}
      style={{
        display: "flex",
        flexDirection: "column",
        margin: 20,
        padding: 20,
        border: "1px solid gray",
        borderRadius: 20,
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
        <label htmlFor="theme">
          Seleciona el tipo de Pregunta #{indexQuestion + 1}
        </label>
        {questions.length - 1 === indexQuestion && buttonAddNewQuestion}
        {questions.length > 1 && buttonDeleteQuestion}
      </div>

      <Select
        className={styles.formFieldSelect}
        style={{
          marginBottom: 15,
          padding: 5,
        }}
        value={questionType}
        onChange={handleTypeSelected}
        options={[
          { value: "0", label: "Seleccionar" },
          { value: "1", label: "Opción simple" },
          { value: "2", label: "Opción múltiple" },
          { value: "4", label: "Frase incompleta" },
          { value: "5", label: "Relaciona con su imagen" },
          { value: "6", label: "Une elementos" },
          { value: "7", label: "Arrastra la frase" },
          { value: "8", label: "Ordena la frase" },
          { value: "3", label: "Respuesta Abierta AI" },
          // { value: "9", label: "Agrupación" },
        ]}
      />

      {(questionType[0] === "1" || questionType[0] === "2") && (
        <SingleQuestion
          question={question}
          index={indexQuestion}
          handleChange={handleChange}
          handleAddAnswer={handleAddAnswer}
          handleRemoveAnswer={handleRemoveAnswer}
          handleImageQuestion={handleImageQuestion}
          handleImageAnswers={handleImageAnswers}
          loadingLoadImage={loadingLoadImage}
          setLoadingLoadImage={setLoadingLoadImage}
          urlImageQuestion={urlImageQuestion}
          setUrlImageQuestion={setUrlImageQuestion}
          imageAnswers={imageAnswers}
          setimageAnswers={setimageAnswers}
          urlImageAnswers={urlImageAnswers}
          setUrlImageAnswers={setUrlImageAnswers}
          selectContent={selectContent}
          setSelectContent={setSelectContent}
        />
      )}
      {questionType[0] === "7" && (
        <FillTheBlankDragAndDropQuestion
          question={question}
          index={indexQuestion}
          handleImageQuestion={handleImageQuestion}
          handleChange={handleChange}
          handleAddAnswer={handleAddAnswer}
          handleRemoveAnswer={handleRemoveAnswer}
          loadingLoadImage={loadingLoadImage}
          setLoadingLoadImage={setLoadingLoadImage}
          urlImageQuestion={urlImageQuestion}
          setUrlImageQuestion={setUrlImageQuestion}
        />
      )}
      {questionType[0] === "3" && (
        <AIQuestion
          question={question}
          index={indexQuestion}
          handleChange={handleChange}
          handleAddAnswer={handleAddAnswer}
          handleRemoveAnswer={handleRemoveAnswer}
          handleImageQuestion={handleImageQuestion}
          handleImageAnswers={handleImageAnswers}
          loadingLoadImage={loadingLoadImage}
          setLoadingLoadImage={setLoadingLoadImage}
          urlImageQuestion={urlImageQuestion}
          setUrlImageQuestion={setUrlImageQuestion}
          imageAnswers={imageAnswers}
          setimageAnswers={setimageAnswers}
          urlImageAnswers={urlImageAnswers}
          setUrlImageAnswers={setUrlImageAnswers}
          selectContent={selectContent}
          setSelectContent={setSelectContent}
        />
      )}
      {questionType[0] === "5" && (
        <DragAndDropQuestion
          question={question}
          index={indexQuestion}
          handleImageQuestion={handleImageQuestion}
          handleChange={handleChange}
          loadingLoadImage={loadingLoadImage}
          setLoadingLoadImage={setLoadingLoadImage}
          urlImageQuestion={urlImageQuestion}
          setUrlImageQuestion={setUrlImageQuestion}
          handleAddBlockDADWithImage={handleAddBlockDADWithImage}
          handleRemoveBlockDADWithImage={handleRemoveBlockDADWithImage}
          fileImageBlockItems={fileImageBlockItems}
          setFileImageBlockItems={setFileImageBlockItems}
          fileImageBlockContainer={fileImageBlockContainer}
          setFileImageBlockContainer={setFileImageBlockContainer}
          handleImageDADQuestionRelationWithImage={
            handleImageDADQuestionRelationWithImage
          }
        />
      )}
      {questionType[0] === "6" && (
        <RelationQuestion
          question={question}
          typeId={Number(questionType[0])}
          index={indexQuestion}
          handleChange={handleChange}
          handleAddRelationBlockQuestions={handleAddRelationBlockQuestions}
          handleRemoveRelationBlockQuestions={
            handleRemoveRelationBlockQuestions
          }
        />
      )}
      {(questionType[0] === "4" || questionType[0] === "8") && (
        <FillTheBlankQuestion
          question={question}
          index={indexQuestion}
          type={Number(questionType[0])}
          handleImageQuestion={handleImageQuestion}
          handleChange={handleChange}
          loadingLoadImage={loadingLoadImage}
          setLoadingLoadImage={setLoadingLoadImage}
          urlImageQuestion={urlImageQuestion}
          setUrlImageQuestion={setUrlImageQuestion}
        />
      )}
      {questionType[0] === "9" && (
        <GroupQuestion
          question={question}
          index={indexQuestion}
          handleImageQuestion={handleImageQuestion}
          handleChange={handleChange}
          loadingLoadImage={loadingLoadImage}
          setLoadingLoadImage={setLoadingLoadImage}
          urlImageQuestion={urlImageQuestion}
          setUrlImageQuestion={setUrlImageQuestion}
          handleAddGroupWithImage={handleAddGroupWithImage}
          handleRemoveGroupWithImage={handleRemoveGroupWithImage}
          handleImageGroupDADQuestions={handleImageGroupDADQuestions}
          fileImageGroupContainer={fileImageGroupContainer}
          setFileImageGroupContainer={setFileImageGroupContainer}
          fileImageBlockContainer={fileImageBlockContainer}
          setFileImageBlockContainer={setFileImageBlockContainer}
        />
      )}
    </div>
  );
};
