import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { MutationBulkUpdateBookPagesVariables } from "../__generated__/gql-types/MutationBulkUpdateBookPages";

const useBulkUpdateBookPages = () => {
  const client = useApolloClient();

  return useCallback(
    async (bulkCreateInput: MutationBulkUpdateBookPagesVariables) => {
      return await client.mutate({
        mutation: BULK_UPDATE_BOOK_PAGES,
        fetchPolicy: "no-cache",
        variables: bulkCreateInput,
      });
    },
    [client]
  );
};

export default useBulkUpdateBookPages;

const BULK_UPDATE_BOOK_PAGES = gql`
  mutation MutationBulkUpdateBookPages($sheetUrl: String) {
    bulkUpdateBookPages(sheetURL: $sheetUrl)
  }
`;
