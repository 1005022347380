import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UpdateCourseVariables } from "../__generated__/gql-types/UpdateCourse";

const UseUpdateCourses = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: UpdateCourseVariables) => {
      return await client.mutate({
        mutation: UPDATE_COURSE,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseUpdateCourses;

const UPDATE_COURSE = gql`
  mutation UpdateCourse($updateCourseId: ID!, $courseInput: CourseInput) {
    updateCourse(id: $updateCourseId, courseInput: $courseInput) {
      id
      lessons {
        title
        id
        order_by
      }
      sep_book_pages
      week
      threemester
      month
      nem
      axes {
        name
        id
      }
      themes {
        name
        id
      }
      deleted_at
      title
      grade {
        id
      }
      grade_id
      subject {
        id
      }
      subject_id
      description
      hide_course_for_students
      type
      publish
      subject {
        name
        id
      }
      grade {
        name
        id
      }
    }
  }
`;
