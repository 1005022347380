import { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./notificationForm.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, notification, Row, Form, Select, Radio } from "antd";
import { initialValuesObj } from "./NotificationForm.types";
import { NotificationInput } from "../../../__generated__/gql-types/globalTypes";
import {
  errorNotification,
  successNotification,
  updatedNotification,
} from "./utils";
import { ActivityIndicator } from "../../ActivityIndicator";
import { useDispatch } from "react-redux";
import Input from "antd/lib/input/Input";
import { ButtonsCustom } from "../../Buttons/Buttons";
import { useNotifications } from "../../../hooks/useNotifications";
import UseGetNotificationById from "../../../api/useGetNotificationById";
import UseCreateNotification from "../../../api/useCreateNotification";
import UseUpdateNotification from "../../../api/useUpdateNotification";
import { getNotifications_getNotifications } from "../../../__generated__/gql-types/getNotifications";
import TextArea from "antd/lib/input/TextArea";
import { useSchools } from "../../../hooks/useSchools";
import { UploadImage } from "../../UploadFile/UploadImage";

interface FileProps {
  id: string;
  ext: string;
}

const NewNotificationsForm: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notifications, loading: isLoadingNotification } = useNotifications();
  const { schools, loading: isLoadingSchools } = useSchools();
  const notification_id = location?.state as string;
  const getNotificationById = UseGetNotificationById();
  const createNotification = UseCreateNotification();
  const updateNotification = UseUpdateNotification();
  const [loading, setloading] = useState(true);
  const [mediaType, setMediaType] = useState<string>("image");
  const [filenameCover, setFilenameCover] = useState<FileProps>({
    ext: "",
    id: "",
  });
  const [urlImage, setUrlImage] = useState<string>("");
  const [initialValues, setInitialValues] =
    useState<NotificationInput>(initialValuesObj);

  const fetchNotificationInfo = async () => {
    try {
      if (notification_id) {
        const data = await getNotificationById({
          getNotificationByIdId: notification_id,
        });
        if (data?.id) {
          setInitialValues({
            title: data?.title ?? "",
            message: data?.message ?? "",
            media: data?.media ?? "",
            role: data?.role ?? undefined,
            school_id: data?.school_id ?? null,
            fileId: data?.fileId ?? "",
          });
          data?.media && setMediaType(data.media);
          data?.fileId &&
            setFilenameCover({
              id: data?.fileId?.split(".")[0],
              ext: data?.fileId?.split(".")[1],
            });
          data?.url && setUrlImage(data.url);
        }
      }

      setTimeout(() => {
        setloading(false);
      }, 1000);
    } catch (error: any) {
      setloading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchNotificationInfo();
  }, [
    loading,
    isLoadingNotification,
    notification_id,
    getNotificationById,
    setInitialValues,
    setMediaType,
    setFilenameCover,
    setUrlImage,
  ]);

  const handleSubmit = async () => {
    setloading(true);
    let notificationInput: NotificationInput = {
      title: initialValues.title,
      message: initialValues.message,
      media: initialValues.media,
      role: initialValues.role,
      school_id: initialValues.school_id,
      fileId: filenameCover.id
        ? `${filenameCover.id}.${filenameCover.ext}`
        : null,
    };

    // console.log(notificationInput)
    // setloading(false);
    // return;

    try {
      if (notification_id && notification_id.length > 0) {
        const updateResponse = await updateNotification({
          notificationInput: notificationInput,
          updateNotificationId: `${notification_id}`,
        });
        updateResponse && updatedNotification();

        let index = notifications.findIndex(
          (notif) => notif.id === updateResponse.data.updateNotification.id
        );
        let notificationsUpdated = [...notifications];

        notificationsUpdated[index] = updateResponse.data
          .updateNotification as unknown as getNotifications_getNotifications;
        dispatch({
          type: "data/setNotifications",
          payload: notificationsUpdated,
        });
        setloading(false);
        return navigate(-1);
      } else {
        const createResponse = await createNotification({
          notificationInput: notificationInput,
        });
        createResponse && successNotification();
        let newNotifications = [];
        let newNotification = createResponse.data
          .createNotification as unknown as getNotifications_getNotifications;
        newNotifications = [newNotification, ...notifications];
        dispatch({ type: "data/setNotifications", payload: newNotifications });

        setloading(false);
        return navigate(-1);
      }
    } catch (error) {
      console.log({ error });
      setloading(false);
      errorNotification();
    }
  };

  if (loading || isLoadingNotification || isLoadingSchools) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      {notification_id && notification_id.length > 0 ? (
        <h1>Editar Notificación</h1>
      ) : (
        <h1>Nueva Notificación</h1>
      )}
      <Form className={styles.formContainer}>
        <Row>
          <Col span={12} style={{ marginTop: 10, marginLeft: 30 }}>
            <label htmlFor="asignatura">Título</label>
            <Input
              name="title"
              title="Titulo"
              type="text"
              style={{ borderRadius: 5 }}
              value={initialValues.title!}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInitialValues({
                  ...initialValues,
                  title: e.target.value,
                });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ marginTop: 10, marginLeft: 30 }}>
            <label htmlFor="asignatura">Mensaje</label>
            <TextArea
              name="message"
              title="Mensaje"
              rows={4}
              style={{ borderRadius: 5 }}
              value={initialValues.message!}
              onChange={(e) => {
                setInitialValues({
                  ...initialValues,
                  message: e.target.value,
                });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ marginTop: 30, marginLeft: 30 }}>
            <div>Seleccionar escuela (dejar vacío para enviar a todas)</div>
            <Select
              defaultValue={initialValues.school_id}
              showSearch
              style={{ width: "100%" }}
              placeholder="Seleccionar una escuela"
              optionFilterProp="label"
              allowClear
              onChange={(value) => {
                setInitialValues({
                  ...initialValues,
                  school_id: Number(value),
                });
              }}
              onSearch={(value) => {
                setInitialValues({
                  ...initialValues,
                  school_id: Number(value),
                });
              }}
              options={schools.map((school) => ({
                value: school.id,
                label: `${school.id} - ${school.name}`,
              }))}
            />
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ marginTop: 30, marginLeft: 30 }}>
            <div>Seleccionar rol (dejar vacío para enviar a todos)</div>
            <Select
              defaultValue={initialValues.role ? initialValues.role : undefined}
              style={{ width: "100%" }}
              allowClear
              placeholder="Selecciona un rol"
              onChange={(value) => {
                setInitialValues({
                  ...initialValues,
                  role: value,
                });
              }}
              options={[
                { value: "student", label: "Estudiante" },
                { value: "teacher", label: "Profesor" },
                { value: "coordinator", label: "Coordinador" },
              ]}
            />
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ marginTop: 30, marginLeft: 30 }}>
            <div>Seleccionar imagen</div>
            <Select
              style={{ width: "100%" }}
              defaultValue={initialValues.media}
              placeholder="Selecciona el tipo de contenido"
              onChange={(value) => {
                setInitialValues({
                  ...initialValues,
                  media: value,
                });
              }}
              options={[
                { value: "image", label: "Imagen" },
                { value: "audio", label: "Audio" },
                { value: "video", label: "Video" },
                { value: "pdf", label: "PDF" },
              ]}
            />
            {initialValues.media === "image" && (
              <Row>
                <Col span={12} style={{ marginTop: 30, marginLeft: 30 }}>
                  <UploadImage
                    folder="covers"
                    urlImageCover={urlImage}
                    filenameCover={filenameCover}
                    setUrlImageCover={setUrlImage}
                    setfilenameCover={setFilenameCover}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Row style={{ margin: 30 }}>
          <ButtonsCustom
            handleClick={() => {
              navigate(-1);
            }}
            handleSubmit={() => {
              handleSubmit();
            }}
          />
        </Row>
      </Form>
    </div>
  );
};

export default NewNotificationsForm;
