import { LiteracyInput } from "../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: LiteracyInput = {
  title: "",
  content: [],
  header_color: "",
  copyright: "",
  cover: "",
  publish: 1,
};

export interface ContentLiteracy {
  image: string;
  text: string;
  contentType: number;
  contentLeft: string;
  contentRigth: string;
  type: string;
  order: number;
}
