import { Roles } from "../../../__generated__/gql-types/globalTypes";

interface RolesProps {
  name: string;
  value: Roles;
}
const role: RolesProps[] = [
  {
    name: "Alumno",
    value: Roles.student,
  },
  {
    name: "Profesor",
    value: Roles.teacher,
  },
  {
    name: "Coordinador",
    value: Roles.coordinator,
  },
  {
    name: "Escuela",
    value: Roles.school,
  },
  {
    name: "Admin",
    value: Roles.admin,
  },
];

export default role;
