import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetUserByIdBackOffice, GetUserByIdBackOfficeVariables } from "../__generated__/gql-types/GetUserByIdBackOffice";

const UseGetUserByIdBackOffice = () => {
  const client = useApolloClient();

  return useCallback(
    async (variables: GetUserByIdBackOfficeVariables) => {
      const result: ApolloQueryResult<GetUserByIdBackOffice> = await client.query({
        query: GET_USER_BY_ID_BACKOFFICE,
        fetchPolicy: "no-cache",
        variables,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getUserByIdBackOffice;
    },
    [client]
  );
};

export default UseGetUserByIdBackOffice;

const GET_USER_BY_ID_BACKOFFICE = gql`
  query GetUserByIdBackOffice($userId: ID!) {
    getUserByIdBackOffice(userId: $userId) {
      id
      email
      email_parent
      first_name
      last_name
      role
      urlQR
      schools {
        id
        name
      }
      username
      user_grade
      visible_spanish_language
      visible_english_language
    }
  }
`;
