import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { updateNotificationVariables } from "../__generated__/gql-types/updateNotification";

const UseUpdateNotification = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: updateNotificationVariables) => {
      return await client.mutate({
        mutation: UPDATE_NOTIFICATION,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default UseUpdateNotification;

const UPDATE_NOTIFICATION = gql`
  mutation updateNotification(
    $notificationInput: NotificationInput
    $updateNotificationId: ID!
  ) {
    updateNotification(
      notificationInput: $notificationInput
      id: $updateNotificationId
    ) {
      id
      title
      message
      media
      role
      school_id
      created_at
      updated_at
      deleted_at
    }
  }
`;
