import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLOTHES_AND_ACCESSORIES } from "../store/selectors";
import { notification } from "antd";
import UseClothesAndAccessories from "../api/useGetClothesAndAccessories";
import useDeleteClothesAndAccessories from "../api/useDeleteClothesAndAccessories";
import { getClothesAndAccessoriesBackOffice_getClothesAndAccessoriesBackOffice } from "../__generated__/gql-types/getClothesAndAccessoriesBackOffice";

export const useClothesAndAccessories = () => {
  const dispatch = useDispatch();
  const clothesAndAccessoriesSelector = useSelector(CLOTHES_AND_ACCESSORIES);
  const [loading, setLoading] = useState(false);
  const [clothesAndAccessories, setClothesAndAccessories] = useState<
    getClothesAndAccessoriesBackOffice_getClothesAndAccessoriesBackOffice[]
  >([]);

  const deleteClothesAndAccessoriesMutation = useDeleteClothesAndAccessories();
  const getClothesAndAccessories = UseClothesAndAccessories();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (clothesAndAccessoriesSelector?.length > 0) {
        setClothesAndAccessories(clothesAndAccessoriesSelector);
      } else {
        const clothesAndAccessoriesResponse = await getClothesAndAccessories();
        const clothesAndAccessoriesData = clothesAndAccessoriesResponse?.sort(
          (a, b) => Number(b?.id) - Number(a?.id)
        );

        clothesAndAccessoriesData &&
          setClothesAndAccessories(clothesAndAccessoriesData);
        dispatch({
          type: "data/setClothesAndAccessories",
          payload: clothesAndAccessoriesData,
        });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const deleteClothesAndAccessories = async (
    idClothesAndAccessories: string
  ) => {
    setLoading(true);
    try {
      const response = await deleteClothesAndAccessoriesMutation({
        deleteClothesAndAccessoriesId: idClothesAndAccessories.toString(),
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "La ropa o accesorio fue eliminada con exito!",
          placement: "top",
          duration: 3,
        });
        let updatedClothesAndAccessories = clothesAndAccessories.filter(
          (clothesAndAccessorie) =>
            clothesAndAccessorie.id?.toString() !== idClothesAndAccessories
        );
        setClothesAndAccessories(updatedClothesAndAccessories);
        dispatch({
          type: "data/setClothesAndAccessories",
          payload: updatedClothesAndAccessories,
        });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInfo();
    };
    fetchData();

    return () => {};
  }, []);

  return { loading, clothesAndAccessories, deleteClothesAndAccessories };
};
