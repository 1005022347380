import { notification } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ESSAYS } from "../store/selectors";
import UseGetRedactions from "../api/useGetRedactions";
import { GetRedactions_getRedactions } from "../__generated__/gql-types/GetRedactions";
import useDeleteEssay from "../api/useDeleteEssay";

export const useEssays = () => {
  const dispatch = useDispatch();
  const essaysSelector = useSelector(ESSAYS);
  const [loading, setLoading] = useState(false);
  const [essays, setEssays] = useState<GetRedactions_getRedactions[]>([]);

  const deleteEssayMutation = useDeleteEssay();
  const getEssays = UseGetRedactions();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (essaysSelector?.length > 0) {
        setEssays(essaysSelector);
      } else {
        const essaysResponse = await getEssays();
        const essaysData = essaysResponse?.sort(
          (a, b) => Number(b.id) - Number(a.id)
        );

        essaysData && setEssays(essaysData);
        dispatch({ type: "data/setEssays", payload: essaysData });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const deleteEssay = async (idEssay: string) => {
    setLoading(true);
    try {
      const response = await deleteEssayMutation({
        redactionId: idEssay.toString(),
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "El ensayo fue eliminado con exito!",
          placement: "top",
          duration: 3,
        });
        let updatedEssays = essays.filter((essay) => essay.id !== idEssay);
        setEssays(updatedEssays);
        dispatch({ type: "data/setEssays", payload: updatedEssays });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInfo();
    };
    fetchData();

    return () => {};
  }, []);

  return { loading, essays, deleteEssay };
};
