import React, { useEffect } from "react";
import { QuestionInput } from "../../../../__generated__/gql-types/globalTypes";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { QuestionIterationComponent } from "./QuestionIteration/QuestionIterationComponent";

interface QuestionTypeSelecProps {
  quiz_id: string | undefined;
  questions: QuestionInput[];
  setQuestions: React.Dispatch<React.SetStateAction<QuestionInput[]>>;
}

export const QuestionTypeSelect = ({
  quiz_id,
  questions,
  setQuestions,
}: QuestionTypeSelecProps) => {
  useEffect(() => {
    if (!quiz_id && questions.length === 0) {
      setQuestions([
        ...questions,
        {
          question: "",
          image_aspect: "",
          type_id: 0,
          order: questions.length,
          image: "",
          answers: [],
          dAndDQuestion: [],
          matchQuestion: [],
          relationQuestion: [],
        },
      ]);
    }
  }, [questions, setQuestions]);

  const handleAddQuestion = () => {
    if (!quiz_id) {
      setQuestions([
        ...questions,
        {
          question: "",
          image_aspect: "",
          type_id: 0,
          order: questions.length,
          image: "",
          answers: [],
          dAndDQuestion: [],
          matchQuestion: [],
          relationQuestion: [],
        },
      ]);
    } else {
      setQuestions([
        ...questions,
        {
          id: null,
          question: "",
          image_aspect: "",
          type_id: 0,
          order: questions.length,
          image: "",
          answers: [],
          dAndDQuestion: [],
          matchQuestion: [],
          relationQuestion: [],
        },
      ]);
    }
  };

  const handleRemoveQuestion = (index: number) => {
    let updatedQuestions = questions.filter((q, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  return (
    <>
      {questions.map((question, indexQuestion) => {
        return (
          <QuestionIterationComponent
            quiz_id={quiz_id}
            keyGenerator={`${question.id}_${indexQuestion}_${new Date()}`}
            question={question}
            questions={questions}
            setQuestions={setQuestions}
            indexQuestion={indexQuestion}
            buttonAddNewQuestion={
              <span>
                <a
                  style={{ fontSize: 22, padding: 10 }}
                  onClick={handleAddQuestion}
                >
                  <PlusCircleOutlined></PlusCircleOutlined>
                </a>
              </span>
            }
            buttonDeleteQuestion={
              <span>
                <a
                  style={{ fontSize: 22, padding: 10 }}
                  onClick={() => {
                    handleRemoveQuestion(indexQuestion);
                  }}
                >
                  <DeleteOutlined></DeleteOutlined>
                </a>
              </span>
            }
          />
        );
      })}
    </>
  );
};
