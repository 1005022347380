import React, { FC, useState } from "react";
import ReactPlayer from "react-player";

import styles from "./videoplayer.module.css";

interface VideoPlayerProps {
  url: string;
  title: string;
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ url, title }) => {
  return (
    <div className={styles.container}>
      <React.Fragment>
        <ReactPlayer
          width="350"
          height="200"
          url={url}
          controls={true}
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e: { preventDefault: () => any }) =>
            e.preventDefault()
          }
        />
      </React.Fragment>
    </div>
  );
};
