import { useDispatch, useSelector } from "react-redux";
import { SUBJECTS } from "../store/selectors";
import { useEffect, useState } from "react";
import { GetSubjects_getSubjects } from "../__generated__/gql-types/GetSubjects";
import UseGetSubjects from "../api/useGetSubjects";
import useDeleteSubject from "../api/useDeleteSubject";
import { notification } from "antd";

export const useSubject = () => {
  const dispatch = useDispatch();
  const subjectSelector = useSelector(SUBJECTS);
  const [loading, setLoading] = useState(false);
  const [subjects, setSubjects] = useState<GetSubjects_getSubjects[]>([]);

  const getSubjects = UseGetSubjects();
  const deleteSubjectMutation = useDeleteSubject();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (subjectSelector?.length > 0) {
        setSubjects(subjectSelector);
      } else {
        const subjectsResponse = await getSubjects();
        const subjectData = subjectsResponse?.filter(
          (subject: any) => subject?.deleted_at === null
        );

        subjectData && setSubjects(subjectData);
        dispatch({ type: "data/setSubjects", payload: subjectData });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const deleteSubject = async (idSubject: string) => {
    setLoading(true);
    try {
      const response = await deleteSubjectMutation({
        deleteSubjectId: idSubject,
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "La asignatura fue eliminada con exito!",
          placement: "top",
          duration: 3,
        });
        setLoading(false);
      }
    } catch {
      setLoading(false);
      notification["error"]({
        message: "Eliminación Erronea",
        description: "La asignatura no pudo ser eliminada!",
        placement: "top",
        duration: 3,
      });
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return {
    loading,
    subjects,
    deleteSubject,
  };
};
