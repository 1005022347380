import { FC } from "react";
import { Link } from "react-router-dom";
import styles from "./exams.module.css";
import { ExamsProps } from "./Exams.types";
import { Button, Space, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/lib/table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const eliminarExamen = (id: number) => {};

const columns: ColumnsType<ExamsProps> = [
  {
    title: "id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "  Título",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Pasos",
    dataIndex: "steps",
    key: "steps",
  },
  {
    title: "Asignatura",
    dataIndex: "course",
    key: "course",
  },
  {
    title: "Grado",
    dataIndex: "grade",
    key: "grade",
  },
  {
    title: "Eje(s)",
    dataIndex: "eje",
    key: "eje",
  },
  {
    title: "Tema(s)",
    dataIndex: "theme",
    key: "theme",
  },

  {
    title: "Action",
    key: "action",
    render: (record) => {
      const records = record;
      return (
        <Space size="middle">
          <Link to={`/exams/${record.id}`} state={records}>
            <Tooltip placement="top" title={"Editar"}>
              <EditOutlined />
            </Tooltip>
          </Link>
          <a
            onClick={() => {
              eliminarExamen(record.id);
            }}
          >
            <Tooltip placement="top" title={"Eliminar"}>
              <DeleteOutlined />
            </Tooltip>
          </a>
        </Space>
      );
    },
  },
];

const data: ExamsProps[] = [
  {
    id: "1",
    title: "¡A cocinar con el microondas!",
    steps: ["undefined"],
    course: "Ciencias",
    grade: "5",
    eje: ["Materia, energía e interacciones"],
    theme: ["Energía"],
  },
  {
    id: "1",
    title: "¡A escena!",
    steps: ["undefined"],
    course: "Lengua",
    grade: "6",
    eje: ["Literatura "],
    theme: ["Adaptar un cuento como obra de teatro"],
  },
  {
    id: "1",
    title: "¡A organizar el reglamento!",
    steps: ["undefined"],
    course: "Lengua",
    grade: "7",
    eje: ["Participación social"],
    theme: ["Energía"],
  },
];

const Exams: FC = () => {
  return (
    <div className={styles.container}>
      <h1>Exámenes</h1>
      <div className={styles.examsContent}>
        <Link to="/exams/new">
          <Button type="primary" danger>
            Nuevo Examen
          </Button>
        </Link>
      </div>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default Exams;
