import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getClothesAndAccessoriesBackOffice } from "../__generated__/gql-types/getClothesAndAccessoriesBackOffice";

const UseGetClothesAndAccessories = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getClothesAndAccessoriesBackOffice> =
      await client.query({
        query: GET_CLOTHES_AND_ACCESSORIES,
        fetchPolicy: "no-cache",
      });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getClothesAndAccessoriesBackOffice;
  }, [client]);
};

export default UseGetClothesAndAccessories;

const GET_CLOTHES_AND_ACCESSORIES = gql`
  query getClothesAndAccessoriesBackOffice {
    getClothesAndAccessoriesBackOffice {
      id
      name
      type
      clasification
      publish
    }
  }
`;
