import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { QuizInput } from "../__generated__/gql-types/globalTypes";

const UseCreateQuizzes = () => {
  const client = useApolloClient();
  return useCallback(
    async (quizInput: QuizInput) => {
      return await client.mutate({
        mutation: CREATE_QUIZZES,
        fetchPolicy: "no-cache",
        variables: { quizInput },
      });
    },
    [client]
  );
};

export default UseCreateQuizzes;

const CREATE_QUIZZES = gql`
  mutation CreateQuizz($quizInput: QuizInput) {
    createQuiz(quizInput: $quizInput) {
      id
      title
      deleted_at
      publish
      url
    }
  }
`;
