import React, { FC, useEffect, useState } from "react";
import type { ColumnsType } from "antd/lib/table";
import { Button, Input, Modal, Space, Table } from "antd";
import {
  GetClassrooms_getClassrooms,
  GetClassrooms_getClassrooms_group,
} from "../../__generated__/gql-types/GetClassrooms";
import { Link } from "react-router-dom";
import styles from "./Classroom.module.css";
import { SearchOutlined } from "@ant-design/icons";
import { GetClassrooms_getClassrooms_program } from "../../__generated__/gql-types/GetClassrooms";
import { useClassroom } from "../../hooks/useClassroom";

const { Search } = Input;

const Classroom: FC = () => {
  const { deleteCourse, loading: isLoading, classroom } = useClassroom();
  const [dataSource, setdataSource] = useState<
    GetClassrooms_getClassrooms[] | null
  >([]);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [idClassroom, setIdClassroom] = useState<string>("");

  const showModal = (id: string) => {
    setIsModalVisible(true);
    setIdClassroom(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    await deleteCourse(idClassroom);
    setIsModalVisible(false);
  };

  const columns: ColumnsType<GetClassrooms_getClassrooms> = [
    {
      align: "center",
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      align: "center",
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      align: "center",
      title: "Grado",
      dataIndex: "program",
      key: "program",
      render: (program: GetClassrooms_getClassrooms_program) => {
        return (
          <div className={styles.textDraw}>
            {program && program.grade && program.grade?.name
              ? program.grade?.name
              : ""}
          </div>
        );
      },
    },
    {
      align: "center",
      title: "Grupo",
      dataIndex: "group",
      key: "group",
      render: (group: GetClassrooms_getClassrooms_group) => {
        return <div className={styles.textDraw}>{group?.name ?? ""}</div>;
      },
    },
    {
      align: "center",
      title: "Acciones",
      key: "acciones",
      render: (_, record) => {
        const curso = record;
        return (
          <Space size={15} style={{ fontSize: 19 }}>
            <Link to={`/assignClassroom/${record.id}`} state={curso}>
              <Button icon={<SearchOutlined />}>Editar</Button>
            </Link>
            <Button
              onClick={() => {
                showModal(record.id);
              }}
              danger
            >
              Eliminar
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    setdataSource(classroom);
  }, [classroom]);

  const onSearch = (value: string) => {
    if (value === "") {
      setdataSource(classroom);
    } else {
      if (dataSource) {
        const filteredData: GetClassrooms_getClassrooms[] = dataSource.filter(
          (data) => data.name?.includes(value)
        );
        setdataSource(filteredData);
      }
    }
  };

  const onSearchId = (value: string) => {
    if (value === "") {
      setdataSource(classroom);
    } else {
      if (dataSource) {
        const filteredData: GetClassrooms_getClassrooms[] = dataSource.filter(
          (data) => data.id === value
        );
        setdataSource(filteredData);
      }
    }
  };

  return (
    <div className={styles.container}>
      <h1>Classroom</h1>
      <div className={styles.newClassroomContainer}>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por id"
            onSearch={onSearchId}
            enterButton
          />
        </Space>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por nombre"
            onSearch={onSearch}
            enterButton
          />
        </Space>
        <Button
          style={{
            float: "right",
            background: "#f96654",
            color: "#fff",
          }}
          danger
        >
          <Link to="/assignClassroom/new">Nueva clase</Link>
        </Button>
      </div>

      <Table
        columns={columns}
        pagination={{ position: ["bottomRight"] }}
        dataSource={dataSource ?? []}
        loading={isLoading}
        rowKey={(record) => record.id}
      />
      <Modal
        title="Eliminar Clase"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>¿Desea eliminar la clase?</p>
      </Modal>
    </div>
  );
};

export default Classroom;
