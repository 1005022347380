import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { DeleteRedactionVariables } from "../__generated__/gql-types/DeleteRedaction";

const useDeleteEssay = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: DeleteRedactionVariables) => {
      return await client.mutate({
        mutation: DELETE_ESSAY,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useDeleteEssay;

const DELETE_ESSAY = gql`
  mutation DeleteRedaction($redactionId: ID!) {
    deleteRedaction(redactionId: $redactionId) {
      id
    }
  }
`;
