import { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./quizzForm.module.css";
import { Buttons } from "../..";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Col, notification, Row, Select } from "antd";
import UseCreateQuizzes from "../../../api/useCreateQuizzes";
import UseUpdateQuizz from "../../../api/useUpdateQuizzes";
import { NewQuizzesSchema, initialValuesObj } from "./QuizzesForm.types";
import { GetSubjects_getSubjects } from "../../../__generated__/gql-types/GetSubjects";
import { GetAxes_getAxes } from "../../../__generated__/gql-types/GetAxes";
import UseGetQuizById from "../../../api/useGetQuizById";
import { GetThemes_getThemes } from "../../../__generated__/gql-types/GetThemes";
import { QuizInput } from "../../../__generated__/gql-types/globalTypes";
import { QuestionInput } from "./../../../__generated__/gql-types/globalTypes";
import {
  errorNotification,
  sortQuestionsAndAnswers,
  successNotification,
  updatedNotification,
} from "./utils";
import { ActivityIndicator } from "../../ActivityIndicator";
import { UploadImageFile } from "../../UploadFile";
import { QuestionTypeSelect } from "./QuestionComponents/QuestionTypeSelect";
import { FileImageCoverProps } from "../QuizzesForm/utils";
import { useAxes } from "../../../hooks/useAxes";
import { useThemes } from "../../../hooks/useThemes";
import { useSubject } from "../../../hooks/useSubject";
import { useDispatch } from "react-redux";
import { useQuizzes } from "../../../hooks/useQuizzes";
import { GetQuizzes_getQuizzes } from "../../../__generated__/gql-types/GetQuizzes";

const { Option } = Select;

const NewQuizzesForm: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const quiz_id = location?.state as string;
  const getQuizById = UseGetQuizById(quiz_id);
  const createQuizz = UseCreateQuizzes();
  const updateQuizz = UseUpdateQuizz();
  const { axes, loading: loadingAxes } = useAxes();
  const { themes: theme, loading: loadingThemes } = useThemes();
  const { subjects, loading: loadingSubjects } = useSubject();
  const { quizzes } = useQuizzes();

  const [loading, setloading] = useState(true);

  const [initialValues, setInitialValues] =
    useState<QuizInput>(initialValuesObj);

  const [publish, setPublish] = useState<boolean>(false);
  const [questions, setQuestions] = useState<QuestionInput[]>([]);
  const [filenameCover, setfilenameCover] = useState<FileImageCoverProps>({
    id: "",
    ext: "",
  });
  const [urlImageCover, setUrlImageCover] = useState<string>("");

  const fetchQuizInfo = async () => {
    try {
      if (quiz_id) {
        const data = await getQuizById();

        if (data?.id) {
          setPublish(data.publish ? true : false);
          setInitialValues({
            title: data.title ?? "",
            axes: data.axes ?? [],
            themes: data.themes ?? [],
            publish: data.publish ?? 0,
            filename: data.filename ?? null,
            subject_id: data.subject_id ?? 0,
          });

          data?.filename &&
            setfilenameCover({
              id: data.filename!.split(".")[0],
              ext: data.filename!.split(".")[1],
            });

          await sortQuestionsAndAnswers(data.questions, setQuestions);
        }
      }

      setTimeout(() => {
        setloading(false);
      }, 1500);
    } catch (error: any) {
      setloading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchQuizInfo();
  }, [
    loading,
    loadingAxes,
    loadingSubjects,
    loadingThemes,
    sortQuestionsAndAnswers,
    setQuestions,
  ]);

  const handleSubmit = async (formValues: QuizInput) => {
    const updatedQuestions = questions.map((question) => {
      if (question.type_id === 7) {
        let updatedAnswersSnap = question.answers
          ?.filter((a) => a.correct)
          .concat(question.answers?.filter((a) => !a.correct));

        let updatedAnswers = updatedAnswersSnap?.map((ans, index) => ({
          ...ans,
          order: index,
        }));
        question.answers = updatedAnswers;
        return question;
      } else {
        return question;
      }
    });

    let Quiz: QuizInput = {
      ...formValues,
      filename:
        filenameCover && filenameCover.id !== ""
          ? `${filenameCover.id}.${filenameCover.ext}`
          : "",
      title: initialValues.title,
      questions: updatedQuestions,
      publish: publish ? 1 : 0,
      subject_id: Number(formValues.subject_id),
      themes: initialValues.themes,
      axes: initialValues.axes,
    };
    // console.log({ Quiz });
    // return;
    setloading(true);
    try {
      if (quiz_id && quiz_id.length > 0) {
        const updateResponse = await updateQuizz({
          quizInput: Quiz,
          updateQuizId: `${quiz_id}`,
        });
        updateResponse && updatedNotification();

        let index = quizzes.findIndex(
          (quiz) => quiz.id === updateResponse.data.updateQuiz.id
        );
        let quizUpdated = [...quizzes];

        quizUpdated[index] = updateResponse.data
          .updateQuiz as unknown as GetQuizzes_getQuizzes;
        dispatch({ type: "data/setQuizzes", payload: quizUpdated });
        setloading(false);
        return navigate(-1);
      } else {
        const createResponse = await createQuizz(Quiz);
        createResponse && successNotification();
        let newQuizzes = [];
        let newQuiz = createResponse.data
          .createQuiz as unknown as GetQuizzes_getQuizzes;
        newQuizzes = [newQuiz, ...quizzes];
        dispatch({ type: "data/setQuizzes", payload: newQuizzes });

        setloading(false);
        return navigate(-1);
      }
    } catch (error) {
      console.log({ error });
      setloading(false);
      errorNotification();
    }
  };

  const handleChangeAxes = (values: string[]) => {
    const axesValues = values!.map((value) => {
      let id = value.split("-")[1] !== undefined ? value.split("-")[0] : null;
      let name =
        value.split("-")[1] !== undefined ? value.split("-")[1] : value;

      const axe = axes!.find((axis) => axis?.id === id);

      if (axe) {
        return axe;
      } else {
        return {
          id,
          name,
        };
      }
    });

    initialValues.axes = axesValues;
    setInitialValues(initialValues);
  };

  const handleChangeThemes = (values: string[]) => {
    const themesValues = values!.map((value) => {
      let id = value.split("-")[1] !== undefined ? value.split("-")[0] : null;
      let name =
        value.split("-")[1] !== undefined ? value.split("-")[1] : value;

      const selectedTheme = theme!.find((th) => th?.id === value);

      if (selectedTheme) {
        return selectedTheme;
      } else {
        return {
          id,
          name,
        };
      }
    });

    initialValues.themes = themesValues;
    setInitialValues(initialValues);
  };

  const axeSelectChildren: React.ReactNode[] = [];
  axes?.forEach((axe: GetAxes_getAxes, index: number) => {
    axeSelectChildren.push(
      <Option value={`${axe?.id}-${axe.name}`} key={index + `${new Date()}`}>
        {axe?.name}
      </Option>
    );
  });

  if (loading || loadingAxes || loadingSubjects || loadingThemes) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      {quiz_id && quiz_id.length > 0 ? (
        <h1>Editar Quiz </h1>
      ) : (
        <h1>Nuevo Quizzes </h1>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={NewQuizzesSchema}
        onSubmit={handleSubmit}
        validate={() => {
          const errors: any = {};
          return errors;
        }}
      >
        {({ errors, handleReset, touched, values }) => {
          return (
            <Form className={styles.formContainer}>
              <Row>
                <Col span={11}>
                  <label htmlFor="asignatura">Asignatura</label>
                  <Field
                    id="subject_id"
                    name="subject_id"
                    as="select"
                    defaultValue={Number(values.subject_id)}
                    className={
                      errors.subject_id && touched.subject_id
                        ? styles.formFieldsError
                        : styles.formFieldSelect
                    }
                  >
                    <option disabled value="">
                      Seleccionar
                    </option>
                    {subjects?.map(
                      (subject: GetSubjects_getSubjects, index: number) => {
                        return (
                          <option
                            value={subject?.id}
                            key={index + `${new Date()}`}
                          >
                            {subject?.name}
                          </option>
                        );
                      }
                    )}
                  </Field>
                  <ErrorMessage
                    name="subject_id"
                    component="div"
                    className={styles.error}
                  />
                </Col>

                <Col offset={1} span={7}>
                  <label htmlFor="asignatura">Título</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    name="title"
                    title="Titulo"
                    type="text"
                    className={styles.formFields}
                    value={initialValues.title}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        title: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col offset={1} span={4}>
                  <div style={{ marginTop: 25, fontSize: 18 }}>
                    <label htmlFor="publish">Publicado:</label>
                    <input
                      type="checkbox"
                      name="publish"
                      style={{ marginLeft: 10 }}
                      checked={publish}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPublish(e.target.checked);
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <UploadImageFile
                title="Cover"
                className={`${styles.uploadFile}`}
                typeFile="quiz"
                folder="covers"
                widthCover={300}
                heightCover={200}
                image={initialValues.filename ?? ""}
                filenameCover={filenameCover}
                setfilenameCover={setfilenameCover}
                urlImageCover={urlImageCover}
                setUrlImageCover={setUrlImageCover}
              />

              <label htmlFor="axes">Eje(s)</label>
              <Select
                mode="tags"
                className={styles.formFieldSelect}
                onChange={handleChangeAxes}
                defaultValue={
                  quiz_id && quiz_id.length > 0
                    ? initialValues.axes?.map((axe, index: number) => {
                        return `${axe.id}-${axe.name}`;
                      })
                    : undefined
                }
              >
                {axeSelectChildren}
              </Select>
              <ErrorMessage
                name="axes"
                component="div"
                className={styles.error}
              />

              <label htmlFor="theme">Tema(s)</label>
              <Select
                mode="tags"
                className={styles.formFieldSelect}
                defaultValue={
                  quiz_id && quiz_id.length > 0
                    ? initialValues.themes?.map((theme) => {
                        return `${theme.id}-${theme.name}`;
                      })
                    : undefined
                }
                onChange={handleChangeThemes}
              >
                {theme?.map((theme: GetThemes_getThemes, index: number) => {
                  return (
                    <Option
                      value={`${theme?.id}-${theme?.name}`}
                      key={index + `${new Date()}`}
                    >
                      {theme?.name}
                    </Option>
                  );
                })}
              </Select>

              <QuestionTypeSelect
                quiz_id={quiz_id}
                questions={questions}
                setQuestions={setQuestions}
              />

              <Buttons
                handleClick={() => {
                  handleReset();
                  navigate(-1);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NewQuizzesForm;
