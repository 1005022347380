import { notification } from "antd";

export const successNotification = () =>
  notification["success"]({
    message: "Creación Exitosa",
    description:
      "Actualizando Lecciones de tipo Páginas de Libro, en unos momentos verás los resultados.",
    placement: "top",
    duration: 3,
  });

export const errorNotification = () =>
  notification["error"]({
    message: "Error",
    description: "Se ha producido un error, vuelva a intentar!",
    placement: "top",
    duration: 3,
  });

export const updatedNotification = () =>
  notification["success"]({
    message: "Modificación Exitosa",
    description: "La Lección fue modificada con éxito!",
    placement: "top",
    duration: 3,
  });
