import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { deleteLiteracyVariables } from "../__generated__/gql-types/deleteLiteracy";

const useDeleteLiteracy = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: deleteLiteracyVariables) => {
      return await client.mutate({
        mutation: DLETE_LITERACY,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useDeleteLiteracy;

const DLETE_LITERACY = gql`
  mutation deleteLiteracy($literacyId: ID!) {
    deleteLiteracy(literacy_id: $literacyId) {
      content {
        image
        order
        text
        type
      }
      cover
      id
      publish
      title
    }
  }
`;
