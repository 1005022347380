import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { createClothesAndAccessoriesVariables } from "../__generated__/gql-types/createClothesAndAccessories";

const UseCreateClothesAndAccessories = () => {
  const client = useApolloClient();
  return useCallback(
    async (literacyInput: createClothesAndAccessoriesVariables) => {
      return await client.mutate({
        mutation: CREATE_CLOTHES_AND_ACCESSORIES,
        fetchPolicy: "no-cache",
        variables: literacyInput,
      });
    },
    [client]
  );
};

export default UseCreateClothesAndAccessories;

const CREATE_CLOTHES_AND_ACCESSORIES = gql`
  mutation createClothesAndAccessories(
    $clothesAndAccessoriesInput: ClothesAndAccessoriesInput
  ) {
    createClothesAndAccessories(
      clothesAndAccessoriesInput: $clothesAndAccessoriesInput
    ) {
      id
      name
      type
      clasification
      z_index
      price
      publish
      rigel_item_img
      rigel_thumbnail_img
      spica_item_img
      spica_thumbnail_img
      colmillu_item_img
      colmillu_thumbnail_img
      polux_item_img
      polux_thumbnail_img
      shaula_item_img
      shaula_thumbnail_img
      tatooine_item_img
      tatooine_thumbnail_img
      created_at
      updated_at
      deleted_at
    }
  }
`;
