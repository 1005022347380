import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./programs.module.css";
import { Button, Space, Table, Modal, notification, Input } from "antd";
import type { ColumnsType } from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import { GetPrograms_getPrograms } from "../../__generated__/gql-types/GetPrograms";
import { usePrograms } from "../../hooks/usePrograms";

const { Search } = Input;

const Programs: FC = () => {
  const { deleteProgram, loading: isLoading, programs } = usePrograms();
  const [dataSource, setdataSource] = useState<
    GetPrograms_getPrograms[] | null
  >([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [idProgram, setIdProgram] = useState<string>("");

  const showModal = (id: string) => {
    setIsModalVisible(true);
    setIdProgram(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      await deleteProgram(idProgram);
      setIsModalVisible(false);
    } catch {
      notification["error"]({
        message: "Eliminación Erronea",
        description: "El programa no pudo ser eliminado!",
        placement: "top",
        duration: 3,
      });
    }
  };

  const columns: ColumnsType<GetPrograms_getPrograms> = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "  Título",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Asignatura",
      dataIndex: "subject",
      key: "subject",
      render: (subject) => {
        return <>{subject?.name}</>;
      },
    },
    {
      title: "Grado",
      dataIndex: "grade",
      key: "grade",
      render: (grade) => {
        return <div>{grade?.name}</div>;
      },
    },

    {
      title: "Action",
      key: "action",
      render: (record) => {
        const records = record;
        return (
          <Space size="middle">
            <Link to={`/programs/${record.id}`} state={records}>
              <Button icon={<SearchOutlined />}>Editar</Button>
            </Link>
            <Button
              onClick={() => {
                showModal(record.id);
              }}
              danger
            >
              Eliminar
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    if (value === "") {
      setdataSource(programs);
    } else {
      if (dataSource) {
        const filteredData: GetPrograms_getPrograms[] = dataSource.filter(
          (data) => data.title?.includes(value)
        );
        setdataSource(filteredData);
      }
    }
  };

  useEffect(() => {
    setdataSource(programs);
  }, [programs]);

  return (
    <div className={styles.container}>
      <h1>Programas</h1>
      <div className={styles.programsContent}>
        <Space direction="vertical">
          <Search
            placeholder="Buscar por título"
            onSearch={onSearch}
            enterButton
          />
        </Space>
        <Link to="/programs/new">
          <Button type="primary" danger>
            Nuevo Programa
          </Button>
        </Link>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource ?? []}
        loading={isLoading}
        rowKey={(record) => record.id}
      />
      <Modal
        title="Eliminar Programa"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>¿Desea eliminar el programa?</p>
      </Modal>
    </div>
  );
};

export default Programs;
