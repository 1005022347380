import React, { ChangeEvent, useEffect } from "react";
import { QuestionInput } from "../../../../../__generated__/gql-types/globalTypes";
import { Col, Row } from "antd";
import styles from "../../quizzForm.module.css";
import { Field } from "formik";

interface AnswersFillTheBlankComponentProps {
  question: QuestionInput;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => void;
}

export const AnswersFillTheBlankComponent = ({
  question,
  handleChange,
}: AnswersFillTheBlankComponentProps) => {
  useEffect(() => {}, [question]);

  return (
    <>
      {question?.answers?.map((answer, index: number) => {
        return (
          <Col key={`${index}_${new Date()}`}>
            <Row style={{ marginLeft: 20, marginTop: 10 }}>
              <label htmlFor="steps">Respuesta # {index}</label>
            </Row>
            <Row style={{ marginLeft: 20 }}>
              <Col span={10}>
                <Field
                  id="answer"
                  name="answer"
                  type="text"
                  defaultValue={answer?.answer ?? ""}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleChange(event, index);
                  }}
                  className={styles.formFieldSelect}
                ></Field>
              </Col>
            </Row>
          </Col>
        );
      })}
    </>
  );
};
