import { Navigate } from "react-router-dom";
import { ActivityIndicator } from "../components/ActivityIndicator";
import { useAuth } from "../hooks/useAuth";

interface PrivateRouteProps {
  isAuthenticated: boolean;
  children: JSX.Element;
}

export const PrivateRoute = ({
  children,
  isAuthenticated,
}: PrivateRouteProps) => {
  const { loading } = useAuth();
  
  if (loading) {
    return <ActivityIndicator />;
  } else if (isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
};
