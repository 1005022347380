import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AICONVERSATIONS } from "../store/selectors";
import { notification } from "antd";
import UseGetAIConversations from "../api/useGetAIConversations";
import useDeleteAIConversation from "../api/useDeleteAIConversation";
import { getAIConversations_getAIConversations } from "../__generated__/gql-types/getAIConversations";

export const useAIConversations = () => {
  const dispatch = useDispatch();
  const aiConversationsSelector = useSelector(AICONVERSATIONS);
  const [loading, setLoading] = useState(false);
  const [aiConversations, setAIConversations] = useState<
    getAIConversations_getAIConversations[]
  >([]);

  const deleteAIConversationMutation = useDeleteAIConversation();
  const getAiConversations = UseGetAIConversations();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (aiConversationsSelector?.length > 0) {
        setAIConversations(aiConversationsSelector);
      } else {
        const aiConversationsResponse = await getAiConversations();
        const aiConversationsData = aiConversationsResponse?.sort(
          (a, b) => Number(b?.id) - Number(a?.id)
        );

        aiConversationsData && setAIConversations(aiConversationsData);
        dispatch({
          type: "data/setAIConversations",
          payload: aiConversationsData,
        });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const deleteAIConversation = async (idAIConversations: string) => {
    setLoading(true);
    try {
      const response = await deleteAIConversationMutation({
        aiconversationId: Number(idAIConversations),
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "La conversación fue eliminada con exito!",
          placement: "top",
          duration: 3,
        });
        let updatedAIConversation = aiConversations.filter(
          (aiConversation) => aiConversation.id !== Number(idAIConversations)
        );
        setAIConversations(updatedAIConversation);
        dispatch({
          type: "data/setAIConversations",
          payload: updatedAIConversation,
        });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInfo();
    };
    fetchData();

    return () => {};
  }, []);

  return { loading, aiConversations, deleteAIConversation };
};
