/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Roles {
  admin = "admin",
  coordinator = "coordinator",
  school = "school",
  student = "student",
  teacher = "teacher",
}

export interface AIConversationsInput {
  title?: string | null;
  publish?: number | null;
  level_cerf?: string | null;
  voice?: string | null;
  character_for_role_play?: string | null;
  card_image?: string | null;
  cover_image?: string | null;
  avatar_image?: string | null;
  section_1?: SectionAIInput[] | null;
  section_2?: SectionAIInput[] | null;
}

export interface AnswerInput {
  id?: string | null;
  answer?: string | null;
  image?: string | null;
  image_aspect?: string | null;
  correct?: number | null;
  order?: number | null;
  question_id?: number | null;
}

export interface AudioDataInput {
  activity_number?: number | null;
  text?: string | null;
  audio_file?: string | null;
  order?: number | null;
}

export interface AxesInput {
  id?: string | null;
  name?: string | null;
  videos?: VideoInput[] | null;
  courses?: CourseInput[] | null;
  lessons?: LessonInput[] | null;
  quizzes?: QuizInput[] | null;
}

export interface BlockContainerInput {
  image?: string | null;
  text?: string | null;
  coincidence?: number | null;
  totalItems?: number | null;
}

export interface BlockInput {
  id?: string | null;
  title?: string | null;
  publish?: number | null;
  description?: string | null;
  order_by?: number | null;
  grade_id?: number | null;
  subject_id?: number | null;
  program_id?: number | null;
}

export interface BlockItemsInput {
  image?: string | null;
  text?: string | null;
  coincidence?: number | null;
}

export interface ClassroomId {
  id?: number | null;
}

export interface ClassroomInput {
  name?: string | null;
  program_id?: number | null;
  group_id?: number | null;
  teachers?: (UserId | null)[] | null;
  school_id?: number | null;
}

export interface ClothesAndAccessoriesInput {
  id?: number | null;
  name?: string | null;
  type?: string | null;
  clasification?: string | null;
  z_index?: number | null;
  price?: number | null;
  publish?: number | null;
  rigel_item_img?: string | null;
  rigel_thumbnail_img?: string | null;
  spica_item_img?: string | null;
  spica_thumbnail_img?: string | null;
  colmillu_item_img?: string | null;
  colmillu_thumbnail_img?: string | null;
  polux_item_img?: string | null;
  polux_thumbnail_img?: string | null;
  shaula_item_img?: string | null;
  shaula_thumbnail_img?: string | null;
  tatooine_item_img?: string | null;
  tatooine_thumbnail_img?: string | null;
}

export interface ContentLiteracyInput {
  type?: string | null;
  contentType?: number | null;
  image?: string | null;
  contentLeft?: string | null;
  contentRigth?: string | null;
  text?: string | null;
  order?: number | null;
}

export interface CourseInput {
  id?: string | null;
  order_by?: number | null;
  block_id?: number | null;
  subject_id?: number | null;
  grade_id?: number | null;
  featured?: number | null;
  publish?: number | null;
  nem?: boolean | null;
  program_2017?: boolean | null;
  program_2425?: boolean | null;
  english?: boolean | null;
  reading?: boolean | null;
  hide_course_for_students?: boolean | null;
  type?: string | null;
  title?: string | null;
  normalizedTitle?: string | null;
  description?: string | null;
  initial_evaluation?: number | null;
  programs?: ProgramInput[] | null;
  lessons?: LessonInput[] | null;
  axes?: AxesInput[] | null;
  themes?: ThemeInput[] | null;
  tags?: TagInput[] | null;
  week?: number | null;
  week_2017?: number | null;
  week_2425?: number | null;
  week_english?: number | null;
  week_reading?: number | null;
  sep_book_pages?: string | null;
  sep_book_pages_2017?: string | null;
  sep_book_pages_2425?: string | null;
  sep_book_pages_english?: string | null;
  sep_book_pages_reading?: string | null;
  threemester?: number | null;
  threemester_2017?: number | null;
  threemester_2425?: number | null;
  threemester_english?: number | null;
  threemester_reading?: number | null;
  month?: number | null;
}

export interface CreateManyUsersInput {
  input?: (UserInput | null)[] | null;
}

export interface DAndDQuestionInput {
  id?: string | null;
  blockItems?: BlockItemsInput[] | null;
  blockContainer?: BlockContainerInput[] | null;
  question_id?: number | null;
}

export interface Excluded_axes_id {
  id?: number | null;
}

export interface Excluded_themes_id {
  id?: number | null;
}

export interface GroupInput {
  id?: string | null;
  name?: string | null;
  grade_id?: number | null;
  publish?: number | null;
  users?: UserId[] | null;
  school_id?: number | null;
}

export interface LessonContentInput {
  id?: string | null;
  lesson_id?: number | null;
  quizzes?: QuizInput[] | null;
  videos?: VideoInput[] | null;
  redaction_id?: number | null;
  literacy_id?: number | null;
  listening_id?: number | null;
  pdf?: string | null;
  slide?: string | null;
  responses_pdf?: string | null;
  aiconversations_id?: number | null;
}

export interface LessonInput {
  id?: string | null;
  title?: string | null;
  description?: string | null;
  cover?: string | null;
  week?: number | null;
  sep_book_pages?: string | null;
  order_by?: number | null;
  order_reading?: number | null;
  sep_name?: string | null;
  nem?: boolean | null;
  drive_link?: string | null;
  publish?: number | null;
  grade_id?: number | null;
  subject_id?: number | null;
  featured?: number | null;
  axes?: AxesInput[] | null;
  themes?: ThemeInput[] | null;
  tags?: TagInput[] | null;
  lesson_content?: LessonContentInput | null;
  status?: string | null;
  lesson_type?: string | null;
  learnings?: string | null;
}

export interface ListeningInput {
  title?: string | null;
  song?: AudioDataInput | null;
  listening1?: AudioDataInput | null;
  listening2?: AudioDataInput | null;
  listening3?: AudioDataInput | null;
}

export interface LiteracyInput {
  title?: string | null;
  header_color?: string | null;
  copyright?: string | null;
  cover?: string | null;
  publish?: number | null;
  content?: (ContentLiteracyInput | null)[] | null;
}

export interface MatchQuestionInput {
  id?: string | null;
}

export interface NotificationInput {
  title?: string | null;
  message?: string | null;
  media?: string | null;
  role?: string | null;
  fileId?: string | null;
  school_id?: number | null;
}

export interface ProgramInput {
  id?: string | null;
  title?: string | null;
  description?: string | null;
  publish?: number | null;
  order_by?: number | null;
  subject_id?: number | null;
  grade_id?: number | null;
  courses?: CourseInput[] | null;
  blocks?: BlockInput[] | null;
}

export interface QuestionInput {
  id?: string | null;
  question?: string | null;
  image?: string | null;
  image_aspect?: string | null;
  paragraph?: string | null;
  order?: number | null;
  type_id?: number | null;
  quizzes?: QuizInput[] | null;
  answers?: AnswerInput[] | null;
  relationQuestion?: RelationQuestionInput[] | null;
  matchQuestion?: MatchQuestionInput[] | null;
  dAndDQuestion?: DAndDQuestionInput[] | null;
}

export interface QuizInput {
  id?: string | null;
  subject_id?: number | null;
  title?: string | null;
  filename?: string | null;
  publish?: number | null;
  order?: number | null;
  themes?: ThemeInput[] | null;
  axes?: AxesInput[] | null;
  questions?: QuestionInput[] | null;
  videos?: VideoInput[] | null;
  lesson_content?: LessonContentInput[] | null;
  quiz_type?: string | null;
}

export interface RedactionInput {
  title: string;
  needs_evidence?: boolean | null;
  question: string;
  rubrics: RubricObjInput[];
}

export interface RelationQuestionBlockInput {
  image?: string | null;
  text?: string | null;
  coincidence?: number | null;
}

export interface RelationQuestionInput {
  id?: string | null;
  block1?: (RelationQuestionBlockInput | null)[] | null;
  block2?: (RelationQuestionBlockInput | null)[] | null;
  question_id?: number | null;
}

export interface RubricObjInput {
  rubric: string;
  order: number;
}

export interface SchoolInput {
  id?: string | null;
  cct?: string | null;
  name?: string | null;
  city?: string | null;
  classroomId?: (ClassroomId | null)[] | null;
  demo?: boolean | null;
  show_sep_pages?: boolean | null;
  show_grades_on_base_100?: boolean | null;
  excluded_themes?: (Excluded_themes_id | null)[] | null;
  excluded_axes?: (Excluded_axes_id | null)[] | null;
  view_literacy?: boolean | null;
  view_english?: boolean | null;
  view_project_generator?: boolean | null;
  view_2425?: boolean | null;
  view_NEM_student?: boolean | null;
  view_2017_student?: boolean | null;
  view_2425_student?: boolean | null;
  view_dynamics?: boolean | null;
  view_shop?: boolean | null;
  view_reading_spanish?: boolean | null;
  view_reading_english?: boolean | null;
  is_active?: boolean | null;
  view_library_english?: boolean | null;
  view_library_spanish?: boolean | null;
}

export interface SectionAIInput {
  question?: string | null;
  order?: number | null;
}

export interface SubjectInput {
  id?: string | null;
  code?: string | null;
  name?: string | null;
  color_class?: string | null;
  publish?: number | null;
  programs?: ProgramInput[] | null;
  quizzes?: QuizInput[] | null;
  courses?: CourseInput[] | null;
  lessons?: LessonInput[] | null;
  blocks?: BlockInput[] | null;
}

export interface TagInput {
  id?: string | null;
  name?: string | null;
  lessons?: LessonInput[] | null;
  courses?: CourseInput[] | null;
}

export interface ThemeInput {
  id?: string | null;
  name?: string | null;
  videos?: VideoInput[] | null;
  courses?: CourseInput[] | null;
  lessons?: LessonInput[] | null;
  quizzes?: QuizInput[] | null;
}

export interface UserId {
  id?: number | null;
}

export interface UserInput {
  id?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  username?: string | null;
  email?: string | null;
  user_grade?: number[] | null;
  visible_spanish_language?: boolean | null;
  visible_english_language?: boolean | null;
  show_popup_notification?: boolean | null;
  password?: string | null;
  email_parent?: string | null;
  groups?: GroupInput[] | null;
  schools?: SchoolInput[] | null;
  role?: Roles | null;
  avatar?: string | null;
  teacher_password?: string | null;
  student_id?: number | null;
}

export interface VideoInput {
  id?: string | null;
  title?: string | null;
  file_id?: string | null;
  description?: string | null;
  image_cover?: string | null;
  order?: number | null;
  publish?: number | null;
  themes?: ThemeInput[] | null;
  axes?: AxesInput[] | null;
  metadata?: any | null;
  quizzes?: (VideoQuizQuizzesInput | null)[] | null;
  microcontent?: string | null;
  key_learnings?: string | null;
}

export interface VideoQuizQuizzesInput {
  quiz_id?: number | null;
  time?: number | null;
  time_out?: number | null;
}

export interface assignSchoolClassroomsInput {
  name?: string | null;
  program_ids?: (number | null)[] | null;
  teachers?: (UserId | null)[] | null;
  students?: (UserId | null)[] | null;
  school_id?: number | null;
  group_label?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
