import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { updateGroupClassroomVariables } from "../__generated__/gql-types/updateGroupClassroom";

const UseUpdateGroup = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: updateGroupClassroomVariables) => {
      return await client.mutate({
        mutation: UPDATE_GROUP,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseUpdateGroup;

const UPDATE_GROUP = gql`
  mutation updateGroupClassroom($updateGroupId: ID!, $groupInput: GroupInput) {
    updateGroup(id: $updateGroupId, groupInput: $groupInput) {
      id
      name
    }
  }
`;
