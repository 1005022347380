import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { DeleteQuizVariables } from "../__generated__/gql-types/DeleteQuiz";

const useDeleteQuiz = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: DeleteQuizVariables) => {
      return await client.mutate({
        mutation: DELETE_QUIZ,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useDeleteQuiz;

const DELETE_QUIZ = gql`
  mutation DeleteQuiz($deleteQuizId: ID!) {
    deleteQuiz(id: $deleteQuizId) {
      id
      title
    }
  }
`;
