import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Button, Col, notification, Row } from "antd";
import styles from "./uploadFile.module.css";
import { ActivityIndicator } from "../ActivityIndicator";
import { FileImageCoverProps } from "../Forms/VideosForm/NewVideosForm";
import { REACT_APP_URL_IMAGES } from "../../utils/constants";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import { environment } from "../../configuration/environment";
import { PDFViewer } from "react-view-pdf/lib";

interface UploadPdfFileProps {
  uploadType: string;
  className: string;
  typeFile: string;
  folder: string;
  existsPdfUrl: boolean;
  pdfCoverExists?: string | undefined;
  widthCover?: number;
  heightCover?: number;
  filenameCover?: FileImageCoverProps;
  setfilenameCover?: Dispatch<SetStateAction<FileImageCoverProps>>;
}

export const UploadPdfFile: FC<UploadPdfFileProps> = ({
  uploadType,
  typeFile,
  folder,
  existsPdfUrl,
  pdfCoverExists = "",
  widthCover = 300,
  heightCover = 300,
  filenameCover,
  setfilenameCover,
}) => {
  const { token } = useAuth();
  const [loading, setloading] = useState(false);
  const [urlPdf, seturlPdf] = useState<string>("");
  const [pdfchanged, setpdfchanged] = useState(false);

  useEffect(() => {
    if (existsPdfUrl) {
      seturlPdf(`${REACT_APP_URL_IMAGES}${folder}/${pdfCoverExists}`);
    }
    let destructUrl: string[] = pdfCoverExists.split("/");

    setfilenameCover &&
      setfilenameCover({
        id: destructUrl[destructUrl.length - 1].split(".")[0],
        ext: destructUrl[destructUrl.length - 1].split(".")[1],
      });
  }, []);

  const completeUpload = async (file: File) => {
    setloading(true);
    try {
      const response = await axios.post(
        `${environment.backendUrl}/urlpdf`,
        {
          folder,
          extension: file.type.split("/")[1],
        },
        {
          headers: {
            token,
          },
        }
      );

      let id: string = response.data.url
        .split("/")
        [response.data.url.split("/").length - 1].split("?X-Amz-Algorithm")[0]
        .split(".")[0];
      let ext: string = response.data.url
        .split("/")
        [response.data.url.split("/").length - 1].split("?X-Amz-Algorithm")[0]
        .split(".")[1];

      await axios.put(response.data.url, file, {
        headers: {
          "content-type": file.type,
        },
      });

      setfilenameCover &&
        setfilenameCover({
          id,
          ext,
        });

      // let urlPdfInBrowser = URL.createObjectURL(file);
      seturlPdf(`${environment.urlImages}${folder}/${id}.${ext}`);
      setTimeout(() => {
        setloading(false);
      }, 2000);
    } catch (error: any) {
      notification["error"]({
        message: "Error",
        description: "La pdfn no pudo ser subida, por favor, reintente.",
        placement: "top",
        duration: 3,
      });
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    let file: File = e.target.files[0] as unknown as File;

    setloading(true);
    setpdfchanged(true);
    if (file.size > 0) {
      await completeUpload(file);
    } else {
      notification["error"]({
        message: "Error",
        description: "La pdfn no pudo ser subida, por favor, reintente.",
        placement: "top",
        duration: 3,
      });
    }

    setTimeout(() => {
      setloading(false);
    }, 1000);
  };

  return (
    <div className={styles.container}>
      <label htmlFor={`url_${typeFile}`}>{uploadType}</label>
      <input type="file" onChange={handleFileChange} />
      <p>Formato soportado: .pdf</p>
      <Col
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <ActivityIndicator size="small" classSmall={styles.activity} />
        ) : (
          <div style={{ padding: 20, display: "flex", flexDirection: "row" }}>
            {!existsPdfUrl && null}
            {existsPdfUrl && !pdfchanged && urlPdf && (
              <div style={{ width: 500, height: 300 }}>
                <Button
                  style={{ color: "white", backgroundColor: "red" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setfilenameCover && setfilenameCover({ id: "", ext: "" });
                    seturlPdf("");
                    setpdfchanged(true);
                  }}
                >
                  Eliminar PDF
                </Button>
                <PDFViewer url={urlPdf} onLoadError={console.log} />
              </div>
            )}
            {pdfchanged && !urlPdf && <></>}
            {pdfchanged && urlPdf && (
              <div style={{ width: 500, height: 300 }}>
                <PDFViewer url={urlPdf} onLoadError={console.log} />
                <Button type="primary">X</Button>
              </div>
            )}
          </div>
        )}
      </Col>
    </div>
  );
};
