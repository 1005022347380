import { useDispatch, useSelector } from "react-redux";
import { GRADES } from "../store/selectors";
import { useEffect, useState } from "react";
import { GetGrade_getGrades } from "../__generated__/gql-types/GetGrade";
import useGetGrade from "../api/useGetGrade";
import { notification } from "antd";

export const useGrades = () => {
  const dispatch = useDispatch();
  const gradesSelector = useSelector(GRADES);
  const [loading, setLoading] = useState(false);
  const [grades, setGrades] = useState<GetGrade_getGrades[]>([]);

  const getGrades = useGetGrade();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (gradesSelector?.length > 0) {
        setGrades(gradesSelector);
      } else {
        const gradesData = await getGrades();
        gradesData && setGrades(gradesData);
        dispatch({ type: "data/setGrades", payload: gradesData });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información de grados no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return {
    grades,
    loading,
  };
};
