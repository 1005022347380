import { FC } from "react";
import { Field, ErrorMessage } from "formik";
import styles from "./inputField.module.css";

interface InputFieldProps {
  defaultValue?: string;
  errors: any;
  touched?: any;
  name: string;
  title?: string;
  type?: string;
  validate?: any;
  placeholder?: string;
  disabled?: boolean;
  value?: any;
  onChange?: (e: any) => void;
}

const InputField: FC<InputFieldProps> = ({
  errors,
  touched,
  name,
  title,
  type,
  validate,
  placeholder,
  defaultValue,
  disabled,
  value,
  onChange,
}) => {
  return (
    <div>
      <label htmlFor={name}>{title}</label>
      <Field
        id={name}
        name={name}
        type={type}
        validate={validate}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        defaultValue={defaultValue ? defaultValue : undefined}
        className={
          errors[name] && touched[name]
            ? styles.formFieldsError
            : styles.formFields
        }
        onChange={onChange}
      />
      <ErrorMessage name={name} component="div" className={styles.error} />
    </div>
  );
};

export default InputField;
