import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetVideoById } from "../__generated__/gql-types/GetVideoById";

const UseGetVideoById = (videoId: string) => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetVideoById> = await client.query({
      query: GET_VIDEOS_BY_ID,
      fetchPolicy: "no-cache",
      variables: {
        videoId,
      },
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getVideoById;
  }, [client]);
};

export default UseGetVideoById;

const GET_VIDEOS_BY_ID = gql`
  query GetVideoById($videoId: ID) {
    getVideoById(videoId: $videoId) {
      id
      title
      file_id
      original_name
      microcontent
      key_learnings
      image_cover
      url
      imageUrl
      created_at
      updated_at
      themes {
        id
        name
      }
      axes {
        id
        name
      }
      metadata
      quizzes {
        quiz {
          id
          title
          filename
          questions {
            id
            question
            answers {
              id
              answer
            }
          }
        }
        time
        time_out
      }
      description
      publish
    }
  }
`;
