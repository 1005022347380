import React, { useMemo, useEffect, useState } from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import axios from "axios";
import { useAuth } from "../../hooks/useAuth";
import { apiGraphQLUrl, apiLuca } from "../../utils/constants";
import { createUploadLink } from "apollo-upload-client";

interface GraphqlProviderProp {
  children: React.ReactNode;
}

const GraphQLProvider: React.FC<GraphqlProviderProp> = ({ children }) => {
  const { token } = useAuth();

  const fetchInfo = async () => {
    await axios.get(`${apiLuca}/health`);
  };

  useEffect(() => {
    fetchInfo();
  }, [token]);

  const client = useMemo(() => {
    return new ApolloClient({
      link: createUploadLink({
        uri: `${apiGraphQLUrl}`,
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
      }),
      cache: new InMemoryCache({
        addTypename: false,
      }),
    });
  }, [token]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQLProvider;
