import React, { ChangeEvent, useEffect } from "react";
import { RelationQuestionInput } from "../../../../../__generated__/gql-types/globalTypes";
import styles from "./rq.module.css";
import { Field } from "formik";
import { Col } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

interface RelationWordsComponentProps {
  relationQuestion: RelationQuestionInput | null;
  indexRelationQuestion: number;
  typeId: number;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => void;
  handleAddRelationBlockQuestions: () => void;
  handleRemoveRelationBlockQuestions: () => void;
}

export const RelationWordsComponent = ({
  relationQuestion,
  indexRelationQuestion,
  typeId,
  handleChange,
  handleAddRelationBlockQuestions,
  handleRemoveRelationBlockQuestions,
}: RelationWordsComponentProps) => {
  useEffect(() => {}, [
    relationQuestion,
    handleChange,
    handleAddRelationBlockQuestions,
    handleRemoveRelationBlockQuestions,
  ]);

  const AddButton = ({ index }: { index: number }) => {
    if (
      relationQuestion?.block1 &&
      index === relationQuestion?.block1.length - 1 &&
      relationQuestion?.block1.length < 4
    ) {
      return (
        <span>
          <a
            style={{ fontSize: 22, padding: 10 }}
            onClick={(e: any) => {
              handleAddRelationBlockQuestions();
            }}
          >
            <PlusCircleOutlined></PlusCircleOutlined>
          </a>
        </span>
      );
    } else {
      return null;
    }
  };

  const DeleteButton = ({ index }: { index: number }) => {
    if (
      relationQuestion?.block1 &&
      relationQuestion?.block1.length - 1 === index &&
      index !== 0
    ) {
      return (
        <span>
          <a
            style={{ fontSize: 22, padding: 10 }}
            onClick={() => {
              handleRemoveRelationBlockQuestions();
            }}
          >
            <DeleteOutlined></DeleteOutlined>
          </a>
        </span>
      );
    } else {
      return null;
    }
  };
  return (
    <div className={styles.containerDADColumn}>
      <Col className={styles.columnBlockContainer}>
        {typeId === 6 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              width: "100%",
              marginBottom: "10px",
              // fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "140%",
              color: "#4C4F63",
            }}
          >
            Palabra clave
          </div>
        )}
        {relationQuestion?.block1?.map((block1, index) => {
          return (
            <div className={styles.rowColumnBlockItem}>
              <Field
                id={`block1_${index}`}
                name={`block1_${index}`}
                placeholder={`Palabra #${index}`}
                type="text"
                className={styles.formFieldSelect}
                defaultValue={block1?.text ?? ""}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChange(event, index);
                }}
              ></Field>
            </div>
          );
        })}
      </Col>
      <Col className={styles.columnBlockContainer}>
        {typeId === 6 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              width: "100%",
              marginBottom: "10px",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "140%",
              color: "#4C4F63",
            }}
          >
            Palabra relacionada
          </div>
        )}
        {relationQuestion?.block2?.map((block2, index) => {
          return (
            <div className={styles.rowColumnBlockItem}>
              <Field
                id={`block2_${index}`}
                name={`block2_${index}`}
                placeholder={
                  typeId === 6 ? `Palabra #${index}` : `Significado #${index}`
                }
                type="text"
                className={styles.formFieldSelect}
                defaultValue={block2?.text ?? ""}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChange(event, index);
                }}
              ></Field>
              <AddButton index={index} />
              <DeleteButton index={index} />
            </div>
          );
        })}
      </Col>
    </div>
  );
};
