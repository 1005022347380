import React, { FC, useEffect, useState } from "react";
import { Space, Table, Button, notification, Modal, Input } from "antd";
import type { ColumnsType } from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "./TableCurso.module.css";
import { useCourses } from "../../hooks/useCourses";
import {
  GetCoursesListBackOffice_getCoursesListBackOffice,
  GetCoursesListBackOffice_getCoursesListBackOffice_grade,
} from "../../__generated__/gql-types/GetCoursesListBackOffice";

const { Search } = Input;

const Curso: FC = () => {
  const { courses, loading: isLoading, deleteCourse } = useCourses();
  const [dataSource, setdataSource] = useState<
    GetCoursesListBackOffice_getCoursesListBackOffice[] | null
  >([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [idCourse, setIdProgram] = useState<string>("");

  useEffect(() => {
    setdataSource(courses);
  }, [courses]);

  const showModal = (id: string) => {
    setIsModalVisible(true);
    setIdProgram(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      await deleteCourse(idCourse);
      setIsModalVisible(false);
    } catch {
      notification["error"]({
        message: "Eliminación Erronea",
        description: "El Curso no pudo ser eliminado!",
        placement: "top",
        duration: 3,
      });
    }
  };

  const columns: ColumnsType<GetCoursesListBackOffice_getCoursesListBackOffice> =
    [
      {
        align: "center",
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        align: "center",
        title: "Titulo",
        dataIndex: "title",
        key: "title",
      },
      {
        align: "center",
        title: "Grado",
        dataIndex: "grade",
        key: "grade",
        render: (
          grade: GetCoursesListBackOffice_getCoursesListBackOffice_grade
        ) => {
          return <div className={styles.textDraw}>{grade?.name}</div>;
        },
      },
      {
        align: "center",
        title: "Acciones",
        key: "acciones",
        render: (_, record) => {
          const curso = record;
          return (
            <Space size={15} style={{ fontSize: 19 }}>
              <Link to={`/courses/${record.id}`} state={curso}>
                <Button icon={<SearchOutlined />}>Editar</Button>
              </Link>
              <Button
                onClick={() => {
                  showModal(record.id);
                }}
                danger
              >
                Eliminar
              </Button>
            </Space>
          );
        },
      },
    ];

  const onSearch = (value: string) => {
    if (value === "") {
      setdataSource(courses);
    } else {
      if (dataSource) {
        const filteredData: GetCoursesListBackOffice_getCoursesListBackOffice[] =
          dataSource.filter((data) => data.title?.includes(value));
        setdataSource(filteredData);
      }
    }
  };

  const onSearchId = (value: string) => {
    if (value === "") {
      setdataSource(courses);
    } else {
      if (dataSource) {
        const filteredData: GetCoursesListBackOffice_getCoursesListBackOffice[] =
          dataSource.filter((data) => data.id === value);
        setdataSource(filteredData);
      }
    }
  };

  return (
    <div className={styles.container}>
      <h1>Cursos</h1>
      <div className={styles.newCursoContainer}>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por id"
            onSearch={onSearchId}
            enterButton
          />
        </Space>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por título"
            onSearch={onSearch}
            enterButton
          />
        </Space>
        <Button
          style={{
            float: "right",
            background: "#f96654",
            color: "#fff",
          }}
          danger
        >
          <Link to="/courses/new">Nuevo curso</Link>
        </Button>
      </div>

      <Table
        columns={columns}
        pagination={{ position: ["bottomRight"] }}
        dataSource={dataSource ?? []}
        loading={isLoading}
        rowKey={(record) => record.id}
      />
      <Modal
        title="Eliminar Programa"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>¿Desea eliminar el programa?</p>
      </Modal>
    </div>
  );
};

export default Curso;
