import React, { ChangeEvent, useState } from "react";
import styles from "./superdemo.module.css";
import { Field, Form, Formik, FormikProps } from "formik";
import { Buttons } from "../../components";
import { errorNotification, successNotification } from "./utils";
import { ActivityIndicator } from "../../components/ActivityIndicator";
import UseSuperDemo from "../../api/useSuperDemo";

const SuperDemo = () => {
  const [initialValues, setInitialValues] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState<any[]>([]);
  const superDemo = UseSuperDemo();

  const handleSubmit = async (formValues: any) => {
    setLoading(true);
    try {
      const response = await superDemo({
        emailFrom: formValues.email,
      });
      response.data?.duplicateProf && setEmails(response?.data?.duplicateProf);
      response.data?.duplicateProf && successNotification();
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setLoading(false);
      errorNotification();
    }
  };

  if (loading) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      <h1>Super Demo</h1>
      <div className={styles.lessonsContent}></div>
      <div className={styles.inputGenerate}>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ errors, handleReset, touched, values }: FormikProps<any>) => {
            return (
              <Form className={styles.formContainer}>
                <label htmlFor="">
                  {" "}
                  Ingrese el usuario profesor que va a copiar
                </label>
                <Field
                  errors={errors}
                  touched={touched}
                  value={initialValues.email}
                  className={styles.formFields}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInitialValues({
                      ...initialValues,
                      email: e.target.value,
                    });
                  }}
                  name="email"
                  title="Email"
                  type="text"
                />

                <label htmlFor=""> Resultados finales:</label>
                <br />
                {emails.length > 0 &&
                  emails.map((user) => {
                    return (
                      <>
                        <div>
                          {user.email} | {user.role}
                        </div>
                        <br />
                      </>
                    );
                  })}

                <Buttons
                  handleClick={() => {
                    handleReset();
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default SuperDemo;
