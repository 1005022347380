import { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./subjectsForm.module.css";
import { Buttons, InputField } from "../..";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Tag, Checkbox, notification } from "antd";
import UseCreateSubject from "../../../api/useCreateSubject";
import UseUpdateSubject from "../../../api/useUpdateSubject";
import { SubjectsFormSchema, initialValuesObj } from "./SubjectsForm.types";
import { SubjectInput } from "../../../__generated__/gql-types/globalTypes";
import {
  errorNotification,
  successNotification,
  updatedNotification,
} from "./utils";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { GetSubjects_getSubjects } from "./../../../__generated__/gql-types/GetSubjects";
import { ActivityIndicator } from "../../ActivityIndicator";

const SubjectsForm: FC = () => {
  const [initialValues, setInitialValues] =
    useState<SubjectInput>(initialValuesObj);
  const [check, setCheck] = useState(initialValues.publish);
  const [loading, setLoading] = useState(false);
  const createSubject = UseCreateSubject();
  const updateSubject = UseUpdateSubject();

  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state as GetSubjects_getSubjects;

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (data && data.id) {
        setInitialValues({
          name: data.name ? data.name : "",
          code: data.code ? data.code : "",
          color_class: data.color_class ? data.color_class : "",
        });
        setCheck(data.publish);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const handleSubmit = async (values: SubjectInput) => {
    try {
      if (data?.id) {
        const response = await updateSubject({
          subjectInput: {
            name: values.name,
            code: values.code,
            color_class: values.color_class,
            publish: check,
          },
          updateSubjectId: data.id,
        });
        response.data.updateSubject.id && updatedNotification();
        return navigate(-1);
      }

      const createResponse = await createSubject({
        name: values.name,
        code: values.code,
        color_class: values.color_class,
        publish: check,
      });

      createResponse && successNotification();
      return navigate(-1);
    } catch (error) {
      errorNotification();
      return navigate(-1);
    }
  };

  const handleChangeCheckBox = (e: CheckboxChangeEvent) => {
    setCheck(e.target.checked ? 1 : 0);
  };

  if (loading) return <ActivityIndicator />;

  return (
    <div className={styles.container}>
      {data?.id ? (
        <h1>Editar Asignatura </h1>
      ) : (
        <h1>Nueva Asignatura </h1>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={SubjectsFormSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleReset, touched }) => {
          return (
            <Form className={styles.formContainer}>
              {/* Title */}
              <label htmlFor="course">Título</label>
              <Field
                errors={errors}
                touched={touched}
                name="name"
                title="Titulo"
                type="text"
                className={styles.formFields}
                value={initialValues.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setInitialValues({
                    ...initialValues,
                    name: e.target.value,
                  });
                }}
              />
              {/* Code */}
              <label htmlFor="course">Code</label>
              <Field
                errors={errors}
                touched={touched}
                name="code"
                title="Code"
                type="text"
                className={styles.formFields}
                value={initialValues.code}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setInitialValues({
                    ...initialValues,
                    code: e.target.value,
                  });
                }}
              />

              {/* Radio */}
              <div
                style={{ padding: "10px 0" }}
                role="group"
                aria-labelledby="my-radio-group"
              >
                <Field type="radio" name="color_class" value="red" />
                <Tag
                  color={"red"}
                  style={{ marginLeft: "5px", width: "20px", height: "20px" }}
                />
                <Field type="radio" name="color_class" value="blue" />
                <Tag
                  color={"blue"}
                  style={{ marginLeft: "5px", width: "20px", height: "20px" }}
                />
                <Field type="radio" name="color_class" value="green" />
                <Tag
                  color={"green"}
                  style={{ marginLeft: "5px", width: "20px", height: "20px" }}
                />
                <Field type="radio" name="color_class" value="yellow" />
                <Tag
                  color={"yellow"}
                  style={{ marginLeft: "5px", width: "20px", height: "20px" }}
                />
              </div>

              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={handleChangeCheckBox}
                  name="publish"
                  checked={check ? true : false}
                >
                  Publicado
                </Checkbox>
              </div>

              {/* Buttons */}
              <Buttons
                handleClick={() => {
                  handleReset();
                  navigate(-1);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SubjectsForm;
