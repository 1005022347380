import * as Yup from "yup";

export const initialValuesObj: any = {
  emailOrUsername: "",
  password: "",
};

export const LoginFormSchema = Yup.object().shape({
  emailOrUsername: Yup.string().required("El usuario es requerido."),
  password: Yup.string()
    .required("La password es requerida.")
    .min(6, "La longitud mínima de la contraseña es 6."),
});
