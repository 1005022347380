import { notification } from "antd";

export const successNotification = () =>
  notification["success"]({
    message: "Creación Exitosa",
    description: "La asignatura fue creado con éxito!",
    placement: "top",
    duration: 3,
  });

export const errorNotification = () =>
  notification["error"]({
    message: "Error",
    description: "Se ha producido un error, vuelva a intentar!",
    placement: "top",
    duration: 3,
  });

export const updatedNotification = () =>
  notification["success"]({
    message: "Modificación Exitosa",
    description: "La asignatura fue modificado con éxito!",
    placement: "top",
    duration: 3,
  });
