import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { updateLiteracyVariables } from "../__generated__/gql-types/updateLiteracy";

const UseUpdateLiteracy = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: updateLiteracyVariables) => {
      return await client.mutate({
        mutation: UPDATE_LITERACY,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseUpdateLiteracy;

const UPDATE_LITERACY = gql`
  mutation updateLiteracy($literacyId: ID!, $literacyInput: LiteracyInput) {
    updateLiteracy(literacy_id: $literacyId, literacyInput: $literacyInput) {
      content {
        type
        image
        text
        order
      }
      cover
      id
      publish
      title
    }
  }
`;
