import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { deleteNotificationVariables } from "../__generated__/gql-types/deleteNotification";

const useDeleteNotification = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: deleteNotificationVariables) => {
      return await client.mutate({
        mutation: DELETE_NOTIFICATION,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useDeleteNotification;

const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($deleteNotificationId: ID!) {
    deleteNotification(id: $deleteNotificationId) {
      id
      title
      message
      media
      role
      school_id
      created_at
      updated_at
      deleted_at
    }
  }
`;
