import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetThemes } from "../__generated__/gql-types/GetThemes";

const UseGetTheme = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetThemes> = await client.query({
      query: GET_THEME,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getThemes;
  }, [client]);
};

export default UseGetTheme;

const GET_THEME = gql`
  query GetThemes {
    getThemes {
      id
      name
    }
  }
`;
