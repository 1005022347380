import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { AIConversationsInput } from "../__generated__/gql-types/globalTypes";

const UseCreateAIConversations = () => {
  const client = useApolloClient();

  return useCallback(
    async (aiconversationInput: AIConversationsInput) => {
      return await client.mutate({
        mutation: CREATE_AICONVERSATIONS,
        fetchPolicy: "no-cache",
        variables: { aiconversationInput },
      });
    },
    [client]
  );
};

export default UseCreateAIConversations;

const CREATE_AICONVERSATIONS = gql`
  mutation createAIConversation($aiconversationInput: AIConversationsInput) {
    createAIConversation(aiconversationInput: $aiconversationInput) {
      id
      title
      publish
      level_cerf
      character_for_role_play
      card_image
      cover_image
      avatar_image
      section_1 {
        question
        order
      }
      section_2 {
        question
        order
      }
      created_at
      updated_at
      deleted_at
    }
  }
`;
