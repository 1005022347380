import { ClothesAndAccessoriesInput } from "../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: ClothesAndAccessoriesInput = {
  name: "",
  publish: 1,
  clasification: "",
  price: 0,
  z_index: 0,
  type: "",
  colmillu_item_img: "",
  colmillu_thumbnail_img: "",
  polux_item_img: "",
  polux_thumbnail_img: "",
  rigel_item_img: "",
  rigel_thumbnail_img: "",
  shaula_item_img: "",
  shaula_thumbnail_img: "",
  spica_item_img: "",
  spica_thumbnail_img: "",
  tatooine_item_img: "",
  tatooine_thumbnail_img: "",
};