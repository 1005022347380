import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getListeningById,
  getListeningByIdVariables,
} from "../__generated__/gql-types/getListeningById";

const UseGetListeningById = () => {
  const client = useApolloClient();

  return useCallback(
    async (variables: getListeningByIdVariables) => {
      const result: ApolloQueryResult<getListeningById> = await client.query({
        query: GET_LISTENING_BY_ID,
        fetchPolicy: "no-cache",
        variables,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getListeningById;
    },
    [client]
  );
};

export default UseGetListeningById;

const GET_LISTENING_BY_ID = gql`
  query getListeningById($listeningId: ID!) {
    getListeningById(listening_id: $listeningId) {
      id
      title
      song {
        activity_number
        text
        audio_file
        order
      }
      listening1 {
        activity_number
        text
        audio_file
        order
      }
      listening2 {
        activity_number
        text
        audio_file
        order
      }
      listening3 {
        activity_number
        text
        audio_file
        order
      }
      created_at
      updated_at
      deleted_at
    }
  }
`;
