import * as Yup from "yup";
import { QuizInput } from "../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: QuizInput = {
  title: "",
  axes: [],
  themes: [],
  subject_id: 1,
  filename: "",
  publish: 0,
  id: undefined,
  questions: null,
};

export const NewQuizzesSchema = Yup.object().shape({
  // title: Yup.string().required("El titulo requerido."),
  // subject_id: Yup.string().required("La asignatura es requerida."),
  // filename: Yup.string().required("El cover es requerido."),
});
