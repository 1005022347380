import { useDispatch, useSelector } from "react-redux";
import { SCHOOLS } from "../store/selectors";
import { useEffect, useState } from "react";
import { GetSchools_getSchools } from "../__generated__/gql-types/GetSchools";
import UseGetSchools from "../api/useGetSchools";
import useDeleteSchool from "../api/useDeleteSchool";
import { notification } from "antd";

export const useSchools = () => {
  const dispatch = useDispatch();
  const schoolsSelector = useSelector(SCHOOLS);
  const [loading, setLoading] = useState(false);
  const [schools, setSchools] = useState<GetSchools_getSchools[]>([]);

  const getSchools = UseGetSchools();
  const deleteSchoolMutation = useDeleteSchool();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (schoolsSelector?.length > 0) {
        setSchools(schoolsSelector);
      } else {
        const schoolsResponse = await getSchools();
        const schoolsData = schoolsResponse?.filter(
          (school: GetSchools_getSchools) => school?.deleted_at === null
        );

        schoolsData && setSchools(schoolsData);
        dispatch({ type: "data/setSchools", payload: schoolsData });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const deleteSchool = async (idSchool: string) => {
    setLoading(true);
    try {
      const response = await deleteSchoolMutation({
        deleteSchoolId: idSchool,
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "La escuela fue eliminada con exito!",
          placement: "top",
          duration: 3,
        });
      }
      setLoading(false);
    } catch {
      setLoading(false);
      notification["error"]({
        message: "Eliminación Erronea",
        description: "La escuela no pudo ser eliminada!",
        placement: "top",
        duration: 3,
      });
    }
  };

  useEffect(() => {
    fetchInfo();
  }, [schools]);

  return {
    loading,
    schools,
    deleteSchool,
  };
};
