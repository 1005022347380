import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetVideos_getVideos } from "../__generated__/gql-types/GetVideos";
import UseGetVideos from "../api/useGetVideos";
import { VIDEOS } from "../store/selectors";
import { notification } from "antd";
import { useAuth } from "./useAuth";
import UseDeleteVideo from "../api/useDeleteVideo";

export const useVideos = () => {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const videosSelector = useSelector(VIDEOS);
  const [loading, setloading] = useState(false);
  const [videos, setVideos] = useState<GetVideos_getVideos[]>([]);

  const getVideos = UseGetVideos();
  const deleteVideoMutation = UseDeleteVideo();

  const fetchInfo = async () => {
    setloading(true);
    try {
      if (videosSelector?.length > 0) {
        setVideos(videosSelector);
      } else {
        const videosRes = await getVideos();
        const videosData = videosRes
          ?.filter((video: GetVideos_getVideos) => video?.deleted_at === null)
          .sort(
            (a: GetVideos_getVideos, b: GetVideos_getVideos) =>
              Number(b?.id) - Number(a?.id)
          );

        videosData && setVideos(videosData);
        dispatch({ type: "data/setVideos", payload: videosData });
      }
      setloading(false);
    } catch (error: any) {
      console.log(error);
      setloading(false);
      notification["error"]({
        message: "Error",
        description:
          "Ocurrió un error o tu sesión expiró, por favor ingresa nuevamente.",
        placement: "top",
        duration: 3,
      });
      logout();
      throw new Error(error);
    }
  };

  const deleteVideo = async (idVideo: string) => {
    setloading(true);
    try {
      const response = await deleteVideoMutation({
        videoId: idVideo ?? "",
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "El video fue eliminado con éxito!",
          placement: "top",
          duration: 3,
        });
        let newVideos = videos.filter((video) => video.id !== idVideo);
        setVideos(newVideos);
        dispatch({ type: "data/setVideos", payload: newVideos });
      }
      setloading(false);
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, [videosSelector]);

  return {
    videos,
    loading,
    deleteVideo,
  };
};
