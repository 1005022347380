import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Space, Table, Tag, Modal, Input } from "antd";
import styles from "./subjects.module.css";
import { SearchOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/lib/table";
import { GetSubjects_getSubjects } from "../../__generated__/gql-types/GetSubjects";
import { useSubject } from "../../hooks/useSubject";

const { Search } = Input;

const Subjects: React.FC = () => {
  const { deleteSubject, loading: isLoading, subjects } = useSubject();
  const [dataSource, setdataSource] = useState<
    GetSubjects_getSubjects[] | null
  >([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [idSubject, setIdSubject] = useState<string>("");

  const showModal = (id: string) => {
    setIsModalVisible(true);
    setIdSubject(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    await deleteSubject(idSubject);
    setIsModalVisible(false);
  };

  useEffect(() => {
    setdataSource(subjects);
  }, [subjects]);

  const columns: ColumnsType<GetSubjects_getSubjects> = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Codigo",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Color",
      dataIndex: "color_class",
      key: "color_class",
      render: (_, { color_class }) => (
        <>
          <Tag
            color={color_class ?? ""}
            key={color_class}
            style={{ width: 40, height: 20 }}
          />
        </>
      ),
    },

    {
      title: "Accion",
      key: "action",
      render: (record) => {
        const records = record;
        return (
          <Space size="middle">
            <Link to={`/subjects/${record.id}`} state={records}>
              <Button icon={<SearchOutlined />}>Editar</Button>
            </Link>
            <Button
              onClick={() => {
                showModal(record.id);
              }}
              danger
            >
              Eliminar
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    if (value === "") {
      setdataSource(subjects);
    } else {
      if (dataSource) {
        const filteredData: GetSubjects_getSubjects[] = dataSource.filter(
          (data) => data.name?.includes(value)
        );
        setdataSource(filteredData);
      }
    }
  };

  return (
    <div className={styles.container}>
      <h1>Asignaturas</h1>
      <div className={styles.newSubjectsContainer}>
        <Space direction="vertical">
          <Search
            placeholder="Buscar por nombre"
            onSearch={onSearch}
            enterButton
          />
        </Space>
        <Link to="/subjects/new">
          <Button type="primary" danger>
            Nueva Asignatura
          </Button>
        </Link>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource ?? []}
        loading={isLoading}
        rowKey={(record) => record.id}
      />
      <Modal
        title="Eliminar Asignatura"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>¿Desea eliminar la asignatura?</p>
      </Modal>
    </div>
  );
};

export default Subjects;
