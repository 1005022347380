import { Col, Row } from "antd";
import React, { ChangeEvent, useEffect } from "react";
import { QuestionInput } from "../../../../../__generated__/gql-types/globalTypes";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Field } from "formik";
import styles from "../../quizzForm.module.css";

interface AnswerFillTheBlankDragAndDropComponentProps {
  question: QuestionInput;
  handleRemoveAnswer: () => void;
  handleAddAnswer: () => Promise<void>;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => void;
}

const AnswerFillTheBlankDragAndDropComponent = ({
  question,
  handleAddAnswer,
  handleChange,
  handleRemoveAnswer,
}: AnswerFillTheBlankDragAndDropComponentProps) => {
  useEffect(() => {}, [question]);

  const AddButton = ({ i }: { i: number }) => {
    if (question?.answers && question?.answers?.length - 1 === i) {
      return (
        <span>
          <a
            style={{ fontSize: 22, padding: 10 }}
            onClick={(e: any) => {
              handleAddAnswer();
            }}
          >
            <PlusCircleOutlined></PlusCircleOutlined>
          </a>
        </span>
      );
    } else {
      return null;
    }
  };

  const DeleteButton = ({ i }: { i: number }) => {
    if (question.answers!.length - 1 === i && i !== 0) {
      return (
        <span>
          <a
            style={{ fontSize: 22, padding: 10 }}
            onClick={() => {
              handleRemoveAnswer();
            }}
          >
            <DeleteOutlined></DeleteOutlined>
          </a>
        </span>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {question?.answers?.map((answer, index: number) => {
        return (
          <Col key={`${index}_${new Date()}`}>
            <Row style={{ marginLeft: 20, marginTop: 10 }}>
              <label htmlFor="steps">Respuesta # {index}</label>
              <AddButton i={index} />
              <DeleteButton i={index} />
            </Row>
            <Row style={{ marginLeft: 20 }}>
              <Col span={10}>
                <Field
                  id="answer"
                  name="answer"
                  type="text"
                  defaultValue={answer?.answer ?? ""}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleChange(event, index);
                  }}
                  className={styles.formFieldSelect}
                ></Field>
              </Col>
              <Col span={4} offset={1}>
                <label htmlFor="">Correcta</label>
                <input
                  style={{ fontSize: 60, marginLeft: 20 }}
                  type="checkbox"
                  defaultChecked={
                    question
                      ? question.answers![index].correct
                        ? true
                        : false
                      : undefined
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e, index);
                  }}
                  name="checkAnswer"
                />
              </Col>
            </Row>
          </Col>
        );
      })}
    </>
  );
};

export default AnswerFillTheBlankDragAndDropComponent;
