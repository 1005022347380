import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetVideos } from "../__generated__/gql-types/GetVideos";

const UseGetVideos = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetVideos> = await client.query({
      query: GET_VIDEOS,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getVideos;
  }, [client]);
};

export default UseGetVideos;

const GET_VIDEOS = gql`
  query GetVideos {
    getVideos {
      id
      title
      description
      publish
      themes {
        name
        id
      }
      axes {
        id
        name
      }
      metadata
      url
      file_id
      imageUrl
      created_at
      updated_at
      deleted_at
      quizzes {
      quiz {
        id
        title
        filename
        questions {
          id
          question
          answers {
            id
            answer
          }
        }
      }
    }
    }
  }
`;
