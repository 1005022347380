import React, { ChangeEvent, useEffect, useState } from "react";
import { Col, Select } from "antd";
import styles from "../../literacyForm.module.css";
import { ContentLiteracy } from "../../LiteracyForm.types";
import { UploadImageFile } from "../../../../UploadFile";

interface ContentIterationComponentProps {
  literacy_id: string | undefined;
  keyGenerator: string;
  buttonAddNewContent: JSX.Element;
  buttonDeleteContent: JSX.Element;
  item: ContentLiteracy;
  content: ContentLiteracy[];
  indexContent: number;
  setContent: React.Dispatch<React.SetStateAction<ContentLiteracy[]>>;
  handleChange: (
    event: ChangeEvent<HTMLTextAreaElement>,
    indexContent: number
  ) => void;
}

export const ContentIterationComponent = ({
  keyGenerator,
  buttonAddNewContent,
  buttonDeleteContent,
  item,
  content,
  indexContent,
  setContent,
  handleChange,
}: ContentIterationComponentProps) => {
  const [contentType, setContentType] = useState(["0"]);
  const [loadingLoadImage, setLoadingLoadImage] = useState<boolean>(false);
  const [urlImageContent, setUrlImageContent] = useState<string>("");

  const handleTypeSelected = async (values: string[]) => {
    setContentType(values);
    if (values[0] === "2") {
      content[indexContent].contentType = 2;
    } else if (values[0] === "3") {
      content[indexContent].contentType = 3;
    } else if (values[0] === "4") {
      content[indexContent].contentType = 1;
      content[indexContent].contentLeft = "image";
      content[indexContent].contentRigth = "text";
    } else if (values[0] === "5") {
      content[indexContent].contentType = 1;
      content[indexContent].contentLeft = "text";
      content[indexContent].contentRigth = "image";
    }
    setContent([...content]);
  };

  const fetchInfo = async () => {
    if (item.type === "text") {
      setContentType(["1"]);
    } else if (item.type === "image") {
      setContentType(["2"]);
    } else if (item.contentType === 0) {
      setContentType(["1"]);
    } else if (item.contentType === 2) {
      setContentType(["2"]);
    } else if (item.contentType === 3) {
      setContentType(["3"]);
    } else if (item.contentType === 1 && item.contentLeft === "image") {
      setContentType(["4"]);
    } else if (item.contentType === 1 && item.contentLeft === "text") {
      setContentType(["5"]);
    } else {
      setContentType(["0"]);
    }
  };

  const handleImageContent = (newImage: string) => {
    let splitImage = newImage.split("/");
    let newItem = {
      ...item,
      image: splitImage[splitImage.length - 1],
    };
    content[indexContent] = newItem;
    setContent([...content]);
  };

  useEffect(() => {
    fetchInfo();
  }, [setContentType, item, content, setContent]);

  return (
    <div
      key={keyGenerator}
      style={{
        display: "flex",
        flexDirection: "column",
        margin: 20,
        padding: 20,
        border: "1px solid gray",
        borderRadius: 20,
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
        <label htmlFor="theme">
          Seleciona el tipo de Contenido #{indexContent + 1}
        </label>
        {content.length - 1 === indexContent && buttonAddNewContent}
        {content.length > 1 && buttonDeleteContent}
      </div>

      <Select
        className={styles.formFieldSelect}
        style={{
          marginBottom: 15,
          padding: 5,
        }}
        value={contentType}
        onChange={handleTypeSelected}
        options={[
          { value: "0", label: "Seleccionar" },
          { value: "1", label: "1 - Una columna - Texto" },
          { value: "2", label: "2 - Una columna - Imagen grande" },
          { value: "3", label: "3 - Una columna - Imagen menos grande" },
          { value: "4", label: "4 - Dos columnas - Imagen Texto" },
          { value: "5", label: "5 - Dos columnas - Texto Imagen" },
        ]}
      />

      {contentType[0] === "1" && (
        <div>
          <textarea
            id={`content_${item.order}`}
            name="content"
            rows={10}
            className={styles.formFieldSelect}
            defaultValue={item.text}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              handleChange(event, indexContent);
            }}
          />
        </div>
      )}

      {contentType[0] === "2" && (
        <div>
          <UploadImageFile
            title={`Imagen grande tipo 2 #${indexContent}`}
            className={styles.uploadFile}
            typeFile="questions"
            folder="questions"
            widthCover={300}
            heightCover={200}
            index={indexContent}
            image={item.image ?? ""}
            loadingLoadImage={loadingLoadImage}
            setLoadingLoadImage={setLoadingLoadImage}
            urlImageQuestion={urlImageContent}
            handleImageQuestion={handleImageContent}
            setUrlImageQuestion={setUrlImageContent}
          />
        </div>
      )}

      {contentType[0] === "3" && (
        <div>
          <UploadImageFile
            title={`Imagen pequeña tipo 3 #${indexContent}`}
            className={styles.uploadFile}
            typeFile="questions"
            folder="questions"
            widthCover={300}
            heightCover={200}
            index={indexContent}
            image={item.image ?? ""}
            loadingLoadImage={loadingLoadImage}
            setLoadingLoadImage={setLoadingLoadImage}
            urlImageQuestion={urlImageContent}
            handleImageQuestion={handleImageContent}
            setUrlImageQuestion={setUrlImageContent}
          />
        </div>
      )}

      {contentType[0] === "4" && (
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Col span={4}>
            <UploadImageFile
              title={`Imagen cuadrada tipo 1  W 550 px , H 500 px #${indexContent}`}
              className={styles.uploadFile}
              typeFile="questions"
              folder="questions"
              widthCover={300}
              heightCover={200}
              index={indexContent}
              image={item.image ?? ""}
              loadingLoadImage={loadingLoadImage}
              setLoadingLoadImage={setLoadingLoadImage}
              urlImageQuestion={urlImageContent}
              handleImageQuestion={handleImageContent}
              setUrlImageQuestion={setUrlImageContent}
            />
          </Col>
          <Col span={16}>
            <textarea
              id={`content_${item.order}`}
              name="content"
              rows={10}
              className={styles.formFieldSelect}
              defaultValue={item.text}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                handleChange(event, indexContent);
              }}
            />
          </Col>
        </Col>
      )}

      {contentType[0] === "5" && (
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Col span={16}>
            <textarea
              id={`content_${item.order}`}
              name="content"
              rows={10}
              className={styles.formFieldSelect}
              defaultValue={item.text}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                handleChange(event, indexContent);
              }}
            />
          </Col>
          <Col>
            <UploadImageFile
              title={`Imagen cuadrada tipo 1 W 550 px , H 500 px #${indexContent}`}
              className={styles.uploadFile}
              typeFile="questions"
              folder="questions"
              widthCover={300}
              heightCover={200}
              index={indexContent}
              image={item.image ?? ""}
              loadingLoadImage={loadingLoadImage}
              setLoadingLoadImage={setLoadingLoadImage}
              urlImageQuestion={urlImageContent}
              handleImageQuestion={handleImageContent}
              setUrlImageQuestion={setUrlImageContent}
            />
          </Col>
        </Col>
      )}
    </div>
  );
};
