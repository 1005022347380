import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { LessonInput } from "../__generated__/gql-types/globalTypes";

const UseCreateLessons = () => {
  const client = useApolloClient();

  return useCallback(
    async (lessonInput: LessonInput) => {
      return await client.mutate({
        mutation: CREATE_LESSON,
        fetchPolicy: "no-cache",
        variables: { lessonInput },
      });
    },
    [client]
  );
};

export default UseCreateLessons;

const CREATE_LESSON = gql`
  mutation CreateLesson($lessonInput: LessonInput) {
    createLesson(lessonInput: $lessonInput) {
      id
      title
      deleted_at
      course_id
      nem
      grade {
        name
        id
      }
      tags {
        name
        id
      }
    }
  }
`;
