import { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./clothesAndAccessoriesForm.module.css";
import { Buttons } from "../..";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Col, notification, Row, Select } from "antd";
import { initialValuesObj } from "./ClothesAndAccessoriesForm.types";
import { ClothesAndAccessoriesInput } from "../../../__generated__/gql-types/globalTypes";
import {
  errorNotification,
  successNotification,
  updatedNotification,
} from "./utils";
import { ActivityIndicator } from "../../ActivityIndicator";
import { UploadImageFile } from "../../UploadFile";
import { FileImageCoverProps } from "../QuizzesForm/utils";
import { useDispatch } from "react-redux";
import UseGetClothesAndAccessoriesById from "../../../api/useGetClothesAndAccessoriesById";
import UseUpdateClothesAndAccessories from "../../../api/useUpdateClothesAndAccessories";
import UseCreateClothesAndAccessories from "../../../api/useCreateClothesAndAccessories";
import { useClothesAndAccessories } from "../../../hooks/useClothesAndAccessories";
import { getClothesAndAccessoriesBackOffice_getClothesAndAccessoriesBackOffice } from "../../../__generated__/gql-types/getClothesAndAccessoriesBackOffice";
import { DividerComponent } from "../../Divider/Divider";

const { Option } = Select;

const ClothesAndAccessoriesForm: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clothesAndAccessories, loading: isLoadingClothesAndAccessories } =
    useClothesAndAccessories();
  const clotheAndAccessories_id = location?.state as string;
  const getClothesAndAccessoriesById = UseGetClothesAndAccessoriesById();
  const createClothesAndAccessories = UseCreateClothesAndAccessories();
  const updateClothesAndAccessories = UseUpdateClothesAndAccessories();
  const [loading, setloading] = useState(true);
  const [initialValues, setInitialValues] =
    useState<ClothesAndAccessoriesInput>(initialValuesObj);
  const [publish, setPublish] = useState<boolean>(false);

  /* Imagenes para los diferentes avatars */

  // rigel
  const [rigelItemImg, setRigelItemImg] = useState<FileImageCoverProps>({
    id: "",
    ext: "",
  });
  const [urlRigelItemImageCover, setUrlRigelItemImageCover] =
    useState<string>("");
  const [rigelThumbnailImg, setRigelThumbnailImg] =
    useState<FileImageCoverProps>({
      id: "",
      ext: "",
    });
  const [urlRigelThumbnailImageCover, setUrlRigelThumbnailImageCover] =
    useState<string>("");

  // spica
  const [spicaItemImg, setSpicaItemImg] = useState<FileImageCoverProps>({
    id: "",
    ext: "",
  });
  const [urlSpicaItemImageCover, setUrlSpicaItemImageCover] =
    useState<string>("");
  const [spicaThumbnailImg, setSpicaThumbnailImg] =
    useState<FileImageCoverProps>({
      id: "",
      ext: "",
    });
  const [urlSpicaThumbnailImageCover, setUrlSpicaThumbnailImageCover] =
    useState<string>("");

  // colmillu
  const [colmilluItemImg, setColmilluItemImg] = useState<FileImageCoverProps>({
    id: "",
    ext: "",
  });
  const [urlColmilluItemImageCover, setUrlColmilluItemImageCover] =
    useState<string>("");
  const [colmilluThumbnailImg, setColmilluThumbnailImg] =
    useState<FileImageCoverProps>({
      id: "",
      ext: "",
    });
  const [urlColmilluThumbnailImageCover, setUrlColmilluThumbnailImageCover] =
    useState<string>("");

  // polux
  const [poluxItemImg, setPoluxItemImg] = useState<FileImageCoverProps>({
    id: "",
    ext: "",
  });
  const [urlPoluxItemImageCover, setUrlPoluxItemImageCover] =
    useState<string>("");
  const [poluxThumbnailImg, setPoluxThumbnailImg] =
    useState<FileImageCoverProps>({
      id: "",
      ext: "",
    });
  const [urlPoluxThumbnailImageCover, setUrlPoluxThumbnailImageCover] =
    useState<string>("");

  // shaula
  const [shaulaItemImg, setShaulaItemImg] = useState<FileImageCoverProps>({
    id: "",
    ext: "",
  });
  const [urlShaulaItemImageCover, setUrlShaulaItemImageCover] =
    useState<string>("");
  const [shaulaThumbnailImg, setShaulaThumbnailImg] =
    useState<FileImageCoverProps>({
      id: "",
      ext: "",
    });
  const [urlShaulaThumbnailImageCover, setUrlShaulaThumbnailImageCover] =
    useState<string>("");

  // tatooine
  const [tatooineItemImg, setTatooineItemImg] = useState<FileImageCoverProps>({
    id: "",
    ext: "",
  });
  const [urlTatooineItemImageCover, setUrlTatooineItemImageCover] =
    useState<string>("");
  const [tatooineThumbnailImg, setTatooineThumbnailImg] =
    useState<FileImageCoverProps>({
      id: "",
      ext: "",
    });
  const [urlTatooineThumbnailImageCover, setUrlTatooineThumbnailImageCover] =
    useState<string>("");

  /* ------------------------------------ */

  const fetchClothesAndAccessoriesInfo = async () => {
    try {
      if (clotheAndAccessories_id) {
        const data = await getClothesAndAccessoriesById({
          getClothesAndAccessoriesByIdId: clotheAndAccessories_id,
        });

        if (data?.id) {
          setPublish(data.publish ? true : false);
          setInitialValues({
            name: data.name ?? "",
            publish: data.publish ?? 1,
            clasification: data.clasification ?? "",
            price: data.price ?? 0,
            z_index: data.z_index ?? 0,
            type: data.type ?? "",
            colmillu_item_img: data.colmillu_item_img ?? "",
            colmillu_thumbnail_img: data.colmillu_thumbnail_img ?? "",
            polux_item_img: data.polux_item_img ?? "",
            polux_thumbnail_img: data.polux_thumbnail_img ?? "",
            rigel_item_img: data.rigel_item_img ?? "",
            rigel_thumbnail_img: data.rigel_thumbnail_img ?? "",
            shaula_item_img: data.shaula_item_img ?? "",
            shaula_thumbnail_img: data.shaula_thumbnail_img ?? "",
            spica_item_img: data.spica_item_img ?? "",
            spica_thumbnail_img: data.spica_thumbnail_img ?? "",
            tatooine_item_img: data.tatooine_item_img ?? "",
            tatooine_thumbnail_img: data.tatooine_thumbnail_img ?? "",
          });

          // rigel
          setRigelItemImg({
            id: data.rigel_item_img ? data.rigel_item_img.split(".")[0] : "",
            ext: data.rigel_item_img ? data.rigel_item_img.split(".")[1] : "",
          });

          setRigelThumbnailImg({
            id: data.rigel_thumbnail_img
              ? data.rigel_thumbnail_img.split(".")[0]
              : "",
            ext: data.rigel_thumbnail_img
              ? data.rigel_thumbnail_img.split(".")[1]
              : "",
          });

          // spica
          setSpicaItemImg({
            id: data.spica_item_img ? data.spica_item_img!.split(".")[0] : "",
            ext: data.spica_item_img ? data.spica_item_img!.split(".")[1] : "",
          });

          setSpicaThumbnailImg({
            id: data.spica_thumbnail_img
              ? data.spica_thumbnail_img!.split(".")[0]
              : "",
            ext: data.spica_thumbnail_img
              ? data.spica_thumbnail_img!.split(".")[1]
              : "",
          });

          // colmillu
          setColmilluItemImg({
            id: data.colmillu_item_img
              ? data.colmillu_item_img.split(".")[0]
              : "",
            ext: data.colmillu_item_img
              ? data.colmillu_item_img.split(".")[1]
              : "",
          });

          setColmilluThumbnailImg({
            id: data.colmillu_thumbnail_img
              ? data.colmillu_thumbnail_img.split(".")[0]
              : "",
            ext: data.colmillu_thumbnail_img
              ? data.colmillu_thumbnail_img.split(".")[1]
              : "",
          });

          // polux
          setPoluxItemImg({
            id: data.polux_item_img ? data.polux_item_img.split(".")[0] : "",
            ext: data.polux_item_img ? data.polux_item_img.split(".")[1] : "",
          });

          setPoluxThumbnailImg({
            id: data.polux_thumbnail_img
              ? data.polux_thumbnail_img.split(".")[0]
              : "",
            ext: data.polux_thumbnail_img
              ? data.polux_thumbnail_img.split(".")[1]
              : "",
          });

          // shaula
          setShaulaItemImg({
            id: data.shaula_item_img ? data.shaula_item_img.split(".")[0] : "",
            ext: data.shaula_item_img ? data.shaula_item_img.split(".")[1] : "",
          });

          setShaulaThumbnailImg({
            id: data.shaula_thumbnail_img
              ? data.shaula_thumbnail_img.split(".")[0]
              : "",
            ext: data.shaula_thumbnail_img
              ? data.shaula_thumbnail_img.split(".")[1]
              : "",
          });

          // tatooine
          setTatooineItemImg({
            id: data.tatooine_item_img
              ? data.tatooine_item_img.split(".")[0]
              : "",
            ext: data.tatooine_item_img
              ? data.tatooine_item_img.split(".")[1]
              : "",
          });

          setTatooineThumbnailImg({
            id: data.tatooine_thumbnail_img
              ? data.tatooine_thumbnail_img.split(".")[0]
              : "",
            ext: data.tatooine_thumbnail_img
              ? data.tatooine_thumbnail_img.split(".")[1]
              : "",
          });
        }
      }

      setTimeout(() => {
        setloading(false);
      }, 1000);
    } catch (error: any) {
      setloading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchClothesAndAccessoriesInfo();
  }, [loading, isLoadingClothesAndAccessories]);

  const handleSubmit = async (formValues: any) => {
    let ClothesAndAccessoriesInput: ClothesAndAccessoriesInput = {
      name: initialValues.name,
      price: initialValues.price,
      type: initialValues.type,
      clasification: initialValues.clasification,
      publish: publish ? 1 : 0,
      z_index: initialValues.z_index,
      colmillu_item_img:
        colmilluItemImg && colmilluItemImg.id !== ""
          ? `${colmilluItemImg.id}.${colmilluItemImg.ext}`
          : "",
      colmillu_thumbnail_img:
        colmilluThumbnailImg && colmilluThumbnailImg.id !== ""
          ? `${colmilluThumbnailImg.id}.${colmilluThumbnailImg.ext}`
          : "",
      polux_item_img:
        poluxItemImg && poluxItemImg.id !== ""
          ? `${poluxItemImg.id}.${poluxItemImg.ext}`
          : "",
      polux_thumbnail_img:
        poluxThumbnailImg && poluxThumbnailImg.id !== ""
          ? `${poluxThumbnailImg.id}.${poluxThumbnailImg.ext}`
          : "",
      rigel_item_img:
        rigelItemImg && rigelItemImg.id !== ""
          ? `${rigelItemImg.id}.${rigelItemImg.ext}`
          : "",
      rigel_thumbnail_img:
        rigelThumbnailImg && rigelThumbnailImg.id !== ""
          ? `${rigelThumbnailImg.id}.${rigelThumbnailImg.ext}`
          : "",
      shaula_item_img:
        shaulaItemImg && shaulaItemImg.id !== ""
          ? `${shaulaItemImg.id}.${shaulaItemImg.ext}`
          : "",
      shaula_thumbnail_img:
        shaulaThumbnailImg && shaulaThumbnailImg.id !== ""
          ? `${shaulaThumbnailImg.id}.${shaulaThumbnailImg.ext}`
          : "",
      spica_item_img:
        spicaItemImg && spicaItemImg.id !== ""
          ? `${spicaItemImg.id}.${spicaItemImg.ext}`
          : "",
      spica_thumbnail_img:
        spicaThumbnailImg && spicaThumbnailImg.id !== ""
          ? `${spicaThumbnailImg.id}.${spicaThumbnailImg.ext}`
          : "",
      tatooine_item_img:
        tatooineItemImg && tatooineItemImg.id !== ""
          ? `${tatooineItemImg.id}.${tatooineItemImg.ext}`
          : "",
      tatooine_thumbnail_img:
        tatooineThumbnailImg && tatooineThumbnailImg.id !== ""
          ? `${tatooineThumbnailImg.id}.${tatooineThumbnailImg.ext}`
          : "",
    };
    setloading(true);
    try {
      if (clotheAndAccessories_id) {
        console.log("ingresa update");
        const updateResponse = await updateClothesAndAccessories({
          clothesAndAccessoriesInput: ClothesAndAccessoriesInput,
          updateClothesAndAccessoriesId: `${clotheAndAccessories_id}`,
        });
        updateResponse && updatedNotification();

        let index = clothesAndAccessories.findIndex(
          (clotheAndAccessorie) =>
            clotheAndAccessorie.id ===
            updateResponse.data.updateClothesAndAccessories.id
        );
        console.log({ index });
        let clotheAndAccessorieUpdated = [...clothesAndAccessories];

        clotheAndAccessorieUpdated[index] = updateResponse.data
          .updateClothesAndAccessories as unknown as getClothesAndAccessoriesBackOffice_getClothesAndAccessoriesBackOffice;
        dispatch({
          type: "data/setClothesAndAccessories",
          payload: clotheAndAccessorieUpdated,
        });
        setloading(false);
        return navigate(-1);
      } else {
        const createResponse = await createClothesAndAccessories({
          clothesAndAccessoriesInput: ClothesAndAccessoriesInput,
        });
        createResponse && successNotification();
        let newLiteracies = [];
        let newClothesAndAccessories = createResponse.data
          .createClothesAndAccessories as unknown as getClothesAndAccessoriesBackOffice_getClothesAndAccessoriesBackOffice;
        newLiteracies = [newClothesAndAccessories, ...clothesAndAccessories];
        dispatch({
          type: "data/setClothesAndAccessories",
          payload: newLiteracies,
        });

        setloading(false);
        return navigate(-1);
      }
    } catch (error) {
      console.log({ error });
      setloading(false);
      errorNotification();
    }
  };

  if (loading || isLoadingClothesAndAccessories) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      {clotheAndAccessories_id && clotheAndAccessories_id.length > 0 ? (
        <h1>Editar Prenda</h1>
      ) : (
        <h1>Nueva Prenda</h1>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={() => {
          const errors: any = {};
          return errors;
        }}
      >
        {({ errors, handleReset, touched, values }) => {
          return (
            <Form className={styles.formContainer}>
              <Row>
                <Col span={14}>
                  <label htmlFor="asignatura">Nombre de la prenda</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    name="title"
                    title="Titulo"
                    type="text"
                    className={styles.formFields}
                    value={initialValues.name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        name: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col offset={1} span={4}>
                  <div style={{ marginTop: 25, fontSize: 18 }}>
                    <label htmlFor="publish">Publicado:</label>
                    <input
                      type="checkbox"
                      name="publish"
                      style={{ marginLeft: 10 }}
                      checked={publish}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPublish(e.target.checked);
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col span={10}>
                  <Select
                    className={styles.formFieldSelect}
                    value={initialValues.type}
                    onChange={(value: any) => {
                      setInitialValues({
                        ...initialValues,
                        type: value,
                      });
                    }}
                    options={[
                      { value: "costume", label: "Disfraz" },
                      { value: "clothes", label: "Ropa" },
                      { value: "accessories", label: "Accessorio" },
                    ]}
                  />
                </Col>
                <Col offset={1} span={10}>
                  {initialValues.type === "clothes" ? (
                    <Select
                      className={styles.formFieldSelect}
                      value={initialValues.clasification}
                      onChange={(value: any) => {
                        setInitialValues({
                          ...initialValues,
                          clasification: value,
                        });
                      }}
                      options={[
                        { value: "suite", label: "Traje" },
                        { value: "top", label: "Parte Arriba" },
                        { value: "bottom", label: "Parte Abajo" },
                      ]}
                    />
                  ) : initialValues.type === "costume" ? (
                    <Select
                      className={styles.formFieldSelect}
                      value={initialValues.clasification}
                      onChange={(value: any) => {
                        setInitialValues({
                          ...initialValues,
                          clasification: value,
                        });
                      }}
                      options={[{ value: "costume", label: "Disfraz" }]}
                    />
                  ) : (
                    <Select
                      className={styles.formFieldSelect}
                      value={initialValues.clasification}
                      onChange={(value: any) => {
                        setInitialValues({
                          ...initialValues,
                          clasification: value,
                        });
                      }}
                      options={[
                        { value: "glasses", label: "Gafas" },
                        { value: "hats", label: "Sombreros" },
                      ]}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col span={10}>
                  <label htmlFor="price">Valor prenda</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    name="price"
                    title="Price"
                    type="number"
                    className={styles.formFields}
                    value={initialValues.price ?? 0}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        price: Number(e.target.value),
                      });
                    }}
                  />
                </Col>
                <Col offset={1} span={10}>
                  <label htmlFor="z-index">Z value</label>
                  <Field
                    errors={errors}
                    touched={touched}
                    name="z-index"
                    title="z-index"
                    type="number"
                    className={styles.formFields}
                    value={initialValues.z_index ?? 0}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        z_index: Number(e.target.value),
                      });
                    }}
                  />
                </Col>
              </Row>

              <DividerComponent />

              <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>RIGEL</div>
                </Col>
                <Col span={8}>
                  <UploadImageFile
                    title="Image Rigel"
                    className={`${styles.uploadFile}`}
                    typeFile="cover"
                    folder="covers"
                    widthCover={200}
                    heightCover={200}
                    image={initialValues.rigel_item_img ?? ""}
                    filenameCover={rigelItemImg}
                    setfilenameCover={setRigelItemImg}
                    urlImageCover={urlRigelItemImageCover}
                    setUrlImageCover={setUrlRigelItemImageCover}
                  />
                </Col>
                <Col span={8}>
                  <UploadImageFile
                    title="Thumbnail Rigel"
                    className={`${styles.uploadFile}`}
                    typeFile="cover"
                    folder="covers"
                    widthCover={200}
                    heightCover={200}
                    image={initialValues.rigel_thumbnail_img ?? ""}
                    filenameCover={rigelThumbnailImg}
                    setfilenameCover={setRigelThumbnailImg}
                    urlImageCover={urlRigelThumbnailImageCover}
                    setUrlImageCover={setUrlRigelThumbnailImageCover}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>SPICA</div>
                </Col>
                <Col span={8}>
                  <UploadImageFile
                    title="Image Spica"
                    className={`${styles.uploadFile}`}
                    typeFile="cover"
                    folder="covers"
                    widthCover={200}
                    heightCover={200}
                    image={initialValues.spica_item_img ?? ""}
                    filenameCover={spicaItemImg}
                    setfilenameCover={setSpicaItemImg}
                    urlImageCover={urlSpicaItemImageCover}
                    setUrlImageCover={setUrlSpicaItemImageCover}
                  />
                </Col>
                <Col span={8}>
                  <UploadImageFile
                    title="Thumbnail Spica"
                    className={`${styles.uploadFile}`}
                    typeFile="cover"
                    folder="covers"
                    widthCover={200}
                    heightCover={200}
                    image={initialValues.spica_thumbnail_img ?? ""}
                    filenameCover={spicaThumbnailImg}
                    setfilenameCover={setSpicaThumbnailImg}
                    urlImageCover={urlSpicaThumbnailImageCover}
                    setUrlImageCover={setUrlSpicaThumbnailImageCover}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>COLMILLU</div>
                </Col>
                <Col span={8}>
                  <UploadImageFile
                    title="Image Colmillu"
                    className={`${styles.uploadFile}`}
                    typeFile="cover"
                    folder="covers"
                    widthCover={200}
                    heightCover={200}
                    image={initialValues.colmillu_item_img ?? ""}
                    filenameCover={colmilluItemImg}
                    setfilenameCover={setColmilluItemImg}
                    urlImageCover={urlColmilluItemImageCover}
                    setUrlImageCover={setUrlColmilluItemImageCover}
                  />
                </Col>
                <Col span={8}>
                  <UploadImageFile
                    title="Thumbnail Colmillu"
                    className={`${styles.uploadFile}`}
                    typeFile="cover"
                    folder="covers"
                    widthCover={200}
                    heightCover={200}
                    image={initialValues.colmillu_thumbnail_img ?? ""}
                    filenameCover={colmilluThumbnailImg}
                    setfilenameCover={setColmilluThumbnailImg}
                    urlImageCover={urlColmilluThumbnailImageCover}
                    setUrlImageCover={setUrlColmilluThumbnailImageCover}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>POLUX</div>
                </Col>
                <Col span={8}>
                  <UploadImageFile
                    title="Image Polux"
                    className={`${styles.uploadFile}`}
                    typeFile="cover"
                    folder="covers"
                    widthCover={200}
                    heightCover={200}
                    image={initialValues.polux_item_img ?? ""}
                    filenameCover={poluxItemImg}
                    setfilenameCover={setPoluxItemImg}
                    urlImageCover={urlPoluxItemImageCover}
                    setUrlImageCover={setUrlPoluxItemImageCover}
                  />
                </Col>
                <Col span={8}>
                  <UploadImageFile
                    title="Thumbnail Polux"
                    className={`${styles.uploadFile}`}
                    typeFile="cover"
                    folder="covers"
                    widthCover={200}
                    heightCover={200}
                    image={initialValues.polux_thumbnail_img ?? ""}
                    filenameCover={poluxThumbnailImg}
                    setfilenameCover={setPoluxThumbnailImg}
                    urlImageCover={urlPoluxThumbnailImageCover}
                    setUrlImageCover={setUrlPoluxThumbnailImageCover}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>SHAULA</div>
                </Col>
                <Col span={8}>
                  <UploadImageFile
                    title="Image Shaula"
                    className={`${styles.uploadFile}`}
                    typeFile="cover"
                    folder="covers"
                    widthCover={200}
                    heightCover={200}
                    image={initialValues.shaula_item_img ?? ""}
                    filenameCover={shaulaItemImg}
                    setfilenameCover={setShaulaItemImg}
                    urlImageCover={urlShaulaItemImageCover}
                    setUrlImageCover={setUrlShaulaItemImageCover}
                  />
                </Col>
                <Col span={8}>
                  <UploadImageFile
                    title="Thumbnail Shaula"
                    className={`${styles.uploadFile}`}
                    typeFile="cover"
                    folder="covers"
                    widthCover={200}
                    heightCover={200}
                    image={initialValues.shaula_thumbnail_img ?? ""}
                    filenameCover={shaulaThumbnailImg}
                    setfilenameCover={setShaulaThumbnailImg}
                    urlImageCover={urlShaulaThumbnailImageCover}
                    setUrlImageCover={setUrlShaulaThumbnailImageCover}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>TATOOINE</div>
                </Col>
                <Col span={8}>
                  <UploadImageFile
                    title="Image Tatooine"
                    className={`${styles.uploadFile}`}
                    typeFile="cover"
                    folder="covers"
                    widthCover={200}
                    heightCover={200}
                    image={initialValues.tatooine_item_img ?? ""}
                    filenameCover={tatooineItemImg}
                    setfilenameCover={setTatooineItemImg}
                    urlImageCover={urlTatooineItemImageCover}
                    setUrlImageCover={setUrlTatooineItemImageCover}
                  />
                </Col>
                <Col span={8}>
                  <UploadImageFile
                    title="Thumbnail Tatooine"
                    className={`${styles.uploadFile}`}
                    typeFile="cover"
                    folder="covers"
                    widthCover={200}
                    heightCover={200}
                    image={initialValues.tatooine_thumbnail_img ?? ""}
                    filenameCover={tatooineThumbnailImg}
                    setfilenameCover={setTatooineThumbnailImg}
                    urlImageCover={urlTatooineThumbnailImageCover}
                    setUrlImageCover={setUrlTatooineThumbnailImageCover}
                  />
                </Col>
              </Row>

              <Buttons
                handleClick={() => {
                  handleReset();
                  navigate(-1);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ClothesAndAccessoriesForm;
