import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Col, Image, notification, Row } from "antd";
import styles from "./uploadFile.module.css";
import { ActivityIndicator } from "../ActivityIndicator";
import { FileImageCoverProps } from "../Forms/VideosForm/NewVideosForm";
import { REACT_APP_URL_IMAGES } from "../../utils/constants";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import { environment } from "../../configuration/environment";

interface UploadFileImageProps {
  title: string;
  typeFile: string;
  className: string;
  folder: string;
  questionTypeId?: number | null | undefined;
  widthCover?: number;
  heightCover?: number;
  filenameCover?: FileImageCoverProps;
  urlImageCover?: string;
  loadingLoadImage?: boolean;
  image?: string;
  imageAnswers?: string[];
  index?: number;
  urlImageQuestion?: string;
  urlImageAnswers?: string[];
  setimageAnswers?: React.Dispatch<React.SetStateAction<string[]>>;
  handleImageQuestion?: (newImage: string) => void;
  setLoadingLoadImage?: React.Dispatch<React.SetStateAction<boolean>>;
  setUrlImageQuestion?: React.Dispatch<React.SetStateAction<string>>;
  setUrlImageAnswers?: React.Dispatch<React.SetStateAction<string[]>>;
  setfilenameCover?: Dispatch<SetStateAction<FileImageCoverProps>>;
  setUrlImageCover?: React.Dispatch<SetStateAction<string>>;
  handleImageAnswers?: (newImage: string, indexAnswer: number) => void;
}

export const UploadImageFile = ({
  title,
  typeFile,
  folder,
  className,
  questionTypeId,
  widthCover = 300,
  heightCover = 300,
  filenameCover,
  image,
  index,
  imageAnswers,
  loadingLoadImage,
  urlImageQuestion,
  urlImageAnswers,
  urlImageCover,
  setimageAnswers,
  setUrlImageCover,
  setfilenameCover,
  handleImageQuestion,
  handleImageAnswers,
  setLoadingLoadImage,
  setUrlImageQuestion,
  setUrlImageAnswers,
}: UploadFileImageProps) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  let actualIndex = index || 0;

  const sortInfo = async () => {
    setLoading(true);
    if (
      (typeFile === "quiz" || typeFile === "cover") &&
      image &&
      image.length > 0
    ) {
      setUrlImageCover &&
        setUrlImageCover(`${REACT_APP_URL_IMAGES}${folder}/${image}`);
    }
    if (typeFile === "questions" && image && image.length > 0) {
      setUrlImageQuestion &&
        setUrlImageQuestion(`${REACT_APP_URL_IMAGES}${folder}/${image}`);
    }
    actualIndex = index || 0;
    if (
      typeFile === "answers" &&
      actualIndex >= 0 &&
      imageAnswers && // [file.id, file.id]
      urlImageAnswers // [url, url]
    ) {
      if (actualIndex >= 0) {
        urlImageAnswers[
          actualIndex
        ] = `${REACT_APP_URL_IMAGES}${folder}/${image}`;
        setUrlImageAnswers && setUrlImageAnswers([...urlImageAnswers]);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    sortInfo();
  }, []);

  useEffect(() => {}, [setLoading, loading]);

  const completeUpload = async (file: File) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${environment.backendUrl}/urlimage`,
        {
          folder,
          extension: file.type.split("/")[1],
        },
        {
          headers: {
            token,
          },
        }
      );

      let id: string = response.data.url
        .split("/")
        [response.data.url.split("/").length - 1].split("?X-Amz-Algorithm")[0]
        .split(".")[0];
      let ext: string = response.data.url
        .split("/")
        [response.data.url.split("/").length - 1].split("?X-Amz-Algorithm")[0]
        .split(".")[1];

      await axios.put(response.data.url, file, {
        headers: {
          "content-type": file.type,
        },
      });

      if (typeFile === "quiz" || typeFile === "cover") {
        setfilenameCover &&
          setfilenameCover({
            id,
            ext,
          });
        setUrlImageCover &&
          setUrlImageCover(`${REACT_APP_URL_IMAGES}${folder}/${id}.${ext}`);
      }

      if (typeFile === "questions") {
        setUrlImageQuestion &&
          setUrlImageQuestion(`${REACT_APP_URL_IMAGES}${folder}/${id}.${ext}`);
        handleImageQuestion &&
          handleImageQuestion(`${REACT_APP_URL_IMAGES}${folder}/${id}.${ext}`);
      }

      // let actualIndex = index || 0;
      if (typeFile === "answers" && imageAnswers && urlImageAnswers) {
        if (actualIndex >= 0) {
          imageAnswers[actualIndex] = `${id}.${ext}`;
          setimageAnswers && setimageAnswers([...imageAnswers]);
          urlImageAnswers[
            actualIndex
          ] = `${REACT_APP_URL_IMAGES}${folder}/${id}.${ext}`;
          setUrlImageAnswers && setUrlImageAnswers([...urlImageAnswers]);
        }
        handleImageAnswers &&
          handleImageAnswers(
            `${REACT_APP_URL_IMAGES}${folder}/${id}.${ext}`,
            actualIndex
          );
      }

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description: "La imagen no pudo ser subida, por favor, reintente.",
        placement: "top",
        duration: 3,
      });
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    let file: File = e.target.files[0] as unknown as File;

    setLoading(true);
    if (file.size > 0) {
      await completeUpload(file);
    } else {
      notification["error"]({
        message: "Error",
        description: "La imagen no pudo ser subida, por favor, reintente.",
        placement: "top",
        duration: 3,
      });
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div className={styles.container}>
      <label htmlFor={`url_${typeFile}`}>{title}</label>
      <input type="file" onChange={handleFileChange} />
      <p>Formato soportado: .jpeg/.jpg - Peso máximo: 5 MB</p>
      <Col
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <ActivityIndicator size="small" classSmall={styles.activity} />
        ) : typeFile === "questions" &&
          urlImageQuestion &&
          urlImageQuestion.length > 0 ? (
          <Row style={{ padding: 20 }}>
            <Image
              src={urlImageQuestion}
              width={widthCover}
              height={heightCover}
            />
          </Row>
        ) : (typeFile === "quiz" || typeFile === "cover") &&
          filenameCover?.id !== "" &&
          urlImageCover &&
          urlImageCover.length > 0 ? (
          <Row style={{ padding: 20 }}>
            <Image
              src={urlImageCover}
              width={widthCover}
              height={heightCover}
            />
          </Row>
        ) : typeFile === "answers" &&
          imageAnswers &&
          imageAnswers[actualIndex] !== "" &&
          urlImageAnswers &&
          urlImageAnswers[actualIndex] != "" ? (
          <Row style={{ padding: 20 }}>
            <Image
              src={urlImageAnswers[actualIndex]}
              width={widthCover}
              height={heightCover}
            />
          </Row>
        ) : null}
      </Col>
    </div>
  );
};
