import { Routes, Route } from "react-router-dom";
import {
  Login,
  Users,
  NewUsers,
  Curso,
  NewCourse,
  Lessons,
  NewLessons,
  Quizzes,
  NewQuiz,
  Exams,
  NewExams,
  Videos,
  Subjects,
  NewSubjects,
  NewVideos,
  Schools,
  NewSchools,
  Programs,
  NewPrograms,
  Classroom,
  NewClassroom,
  GenerateContent,
  BulkCreate,
  SuperDemo,
  BulkUpdateBookPages,
  BulkInfoVideos,
  Redactions,
  NewRedaction,
  Literacy,
  NewLiteracy,
  BulkRedactionsCreate,
  AIConversations,
  NewAIConversations,
  Shop,
  NewClothesAndAccessories,
  Listening,
  NewListening,
  Notification,
  NewNotification,
} from "./../pages";
import { PrivateRoute } from "./privateRoutes";
import { useAuth } from "../hooks/useAuth";

const LucaRoutes = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="/videos"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<Videos></Videos>}
          />
        }
      />
      <Route
        path="/videos/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewVideos></NewVideos>}
          />
        }
      />
      <Route
        path="/videos/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewVideos></NewVideos>}
          />
        }
      />

      <Route
        path="/courses"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<Curso />}
          />
        }
      />
      <Route
        path="/courses/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewCourse />}
          />
        }
      />
      <Route
        path="/courses/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewCourse />}
          />
        }
      />

      <Route
        path="/quizzes"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<Quizzes />}
          />
        }
      />
      <Route
        path="/quizzes/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewQuiz />}
          />
        }
      />
      <Route
        path="/quizzes/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewQuiz />}
          />
        }
      />
      <Route
        path="/essays"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<Redactions />}
          />
        }
      />
      <Route
        path="/essays/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewRedaction />}
          />
        }
      />
      <Route
        path="/essays/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewRedaction />}
          />
        }
      />

      <Route
        path="/listening"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<Listening />}
          />
        }
      />
      <Route
        path="/listening/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewListening />}
          />
        }
      />
      <Route
        path="/listening/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewListening />}
          />
        }
      />

      <Route
        path="/notifications"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<Notification />}
          />
        }
      />
      <Route
        path="/notifications/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewNotification />}
          />
        }
      />
      <Route
        path="/notifications/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewNotification />}
          />
        }
      />

      <Route
        path="/literacy"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<Literacy />}
          />
        }
      />
      <Route
        path="/literacy/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewLiteracy />}
          />
        }
      />
      <Route
        path="/literacy/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewLiteracy />}
          />
        }
      />

      <Route
        path="/users"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<Users />}
          />
        }
      />
      <Route
        path="/users/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewUsers />}
          />
        }
      />
      <Route
        path="/users/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewUsers />}
          />
        }
      />

      <Route
        path="/assignClassroom"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<Classroom />}
          />
        }
      />
      <Route
        path="/assignClassroom/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewClassroom />}
          />
        }
      />
      <Route
        path="/assignClassroom/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewClassroom />}
          />
        }
      />

      <Route
        path="/lessons"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<Lessons />}
          />
        }
      />
      <Route
        path="/lessons/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewLessons />}
          />
        }
      />
      <Route
        path="/lessons/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewLessons />}
          />
        }
      />

      {/* Exams */}
      <Route
        path="/exams"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<Exams />}
          />
        }
      />
      <Route
        path="/exams/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewExams />}
          />
        }
      />
      <Route
        path="/exams/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewExams />}
          />
        }
      />

      <Route
        path="/subjects"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<Subjects />}
          />
        }
      />
      <Route
        path="/subjects/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewSubjects />}
          />
        }
      />
      <Route
        path="/subjects/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewSubjects />}
          />
        }
      />

      <Route
        path="/schools"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<Schools />}
          />
        }
      />
      <Route
        path="/schools/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewSchools />}
          />
        }
      />
      <Route
        path="/schools/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewSchools />}
          />
        }
      />

      <Route
        path="/programs"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<Programs />}
          />
        }
      />
      <Route
        path="/programs/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewPrograms />}
          />
        }
      />
      <Route
        path="/programs/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewPrograms />}
          />
        }
      />
      <Route
        path="/aiConversation"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<AIConversations />}
          />
        }
      />
      <Route
        path="/aiConversation/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewAIConversations />}
          />
        }
      />
      <Route
        path="/aiConversation/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewAIConversations />}
          />
        }
      />
      <Route
        path="/generate-content"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<GenerateContent />}
          />
        }
      />
      <Route
        path="/bulk-create"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<BulkCreate />}
          />
        }
      />
      <Route
        path="/bulk-info-videos"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<BulkInfoVideos />}
          />
        }
      />
      <Route
        path="/bulk-redactions-create"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<BulkRedactionsCreate />}
          />
        }
      />
      <Route
        path="/bulk-update-book-pages"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<BulkUpdateBookPages />}
          />
        }
      />
      <Route
        path="/super-demo"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<SuperDemo />}
          />
        }
      />
      <Route
        path="/clothes-and-accessories"
        element={
          <PrivateRoute isAuthenticated={isAuthenticated} children={<Shop />} />
        }
      />
      <Route
        path="/clothes-and-accessories/new"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewClothesAndAccessories />}
          />
        }
      />
      <Route
        path="/clothes-and-accessories/:id"
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            children={<NewClothesAndAccessories />}
          />
        }
      />
    </Routes>
  );
};

export default LucaRoutes;
