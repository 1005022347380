import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  VIDEOS,
  COURSES,
  QUIZZES,
  LESSONS,
  PROGRAMS,
  USERS,
  SUBJECTS,
  SCHOOLS,
  AXES,
  THEMES,
  TAGS,
  CLASSROOMS,
  ESSAYS,
  LITERACIES,
  AICONVERSATIONS,
  CLOTHES_AND_ACCESSORIES,
  LISTENINGS,
  NOTIFICATIONS,
} from "../store/selectors";

import UseGetVideos from "../api/useGetVideos";
import UseGetQuizzesListBackOffice from "../api/useGetQuizzesListBackOffice";
import UseGetPrograms from "../api/UseGetPrograms";
import UseGetUsers from "../api/useGetUsers";
import UseGetSubjects from "../api/useGetSubjects";
import UseGetSchools from "../api/useGetSchools";
import UseGetAxes from "../api/useGetAxes";
import UseGetTheme from "../api/useGetTheme";
import UseGetTags from "../api/useGetTags";
import UseGetClassroom from "../api/useGetClassroom";
import useGetGrade from "../api/useGetGrade";
import UseGetLessonsListBackOffice from "../api/useGetLessonsListBackOffice";
import UseGetCoursesListBackOffice from "../api/useGetCoursesListBackOffice";
import UseGetRedactions from "../api/useGetRedactions";
import UseGetLiteracies from "../api/useGetLiteracies";
import UseGetAIConversations from "../api/useGetAIConversations";
import UseGetClothesAndAccessories from "../api/useGetClothesAndAccessories";

import { GetVideos_getVideos } from "../__generated__/gql-types/GetVideos";
import { GetCourses_getCourses } from "../__generated__/gql-types/GetCourses";
import { GetQuizzes_getQuizzes } from "../__generated__/gql-types/GetQuizzes";
import { GetLessons_getLessons } from "../__generated__/gql-types/GetLessons";
import { GetPrograms_getPrograms } from "../__generated__/gql-types/GetPrograms";
import { GetUsers_getUsers } from "../__generated__/gql-types/GetUsers";
import { GetSubjects_getSubjects } from "../__generated__/gql-types/GetSubjects";
import { GetSchools_getSchools } from "../__generated__/gql-types/GetSchools";
import { GetAxes_getAxes } from "../__generated__/gql-types/GetAxes";
import { GetThemes_getThemes } from "../__generated__/gql-types/GetThemes";
import { GetTags_getTags } from "../__generated__/gql-types/GetTags";
import { GetClassrooms_getClassrooms } from "../__generated__/gql-types/GetClassrooms";
import { GetGrade_getGrades } from "../__generated__/gql-types/GetGrade";
import { GetCoursesListBackOffice_getCoursesListBackOffice } from "../__generated__/gql-types/GetCoursesListBackOffice";
import { getLessonsListBackOffice_getLessonsListBackOffice } from "../__generated__/gql-types/getLessonsListBackOffice";
import { GetRedactions_getRedactions } from "../__generated__/gql-types/GetRedactions";
import { getLiteracies_getLiteracies } from "../__generated__/gql-types/getLiteracies";
import { getAIConversations_getAIConversations } from "../__generated__/gql-types/getAIConversations";
import { getClothesAndAccessoriesBackOffice_getClothesAndAccessoriesBackOffice } from "../__generated__/gql-types/getClothesAndAccessoriesBackOffice";
import UseGetListenings from "../api/useGetListenings";
import { getListenings_getListenings } from "../__generated__/gql-types/getListenings";
import { getNotifications_getNotifications } from "../__generated__/gql-types/getNotifications";
import UseGetNotifications from "../api/useGetNotificationss";

export const useData = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // selectors
  const essays: GetRedactions_getRedactions[] = useSelector(ESSAYS);
  const videos: GetVideos_getVideos[] = useSelector(VIDEOS);
  const courses: GetCourses_getCourses[] = useSelector(COURSES);
  const quizzes: GetQuizzes_getQuizzes[] = useSelector(QUIZZES);
  const lessons: getLessonsListBackOffice_getLessonsListBackOffice[] =
    useSelector(LESSONS);
  const programs: GetPrograms_getPrograms[] = useSelector(PROGRAMS);
  const users: GetUsers_getUsers[] = useSelector(USERS);
  const subjects: GetSubjects_getSubjects[] = useSelector(SUBJECTS);
  const schools: GetSchools_getSchools[] = useSelector(SCHOOLS);
  const axes: GetAxes_getAxes[] = useSelector(AXES);
  const themes: GetThemes_getThemes[] = useSelector(THEMES);
  const tags: GetTags_getTags[] = useSelector(TAGS);
  const classrooms: GetClassrooms_getClassrooms[] = useSelector(CLASSROOMS);
  const literacies: getLiteracies_getLiteracies[] = useSelector(LITERACIES);
  const listenings: getListenings_getListenings[] = useSelector(LISTENINGS);
  const aiconversations: getAIConversations_getAIConversations[] =
    useSelector(AICONVERSATIONS);
  const clothesAndAccessories: getClothesAndAccessoriesBackOffice_getClothesAndAccessoriesBackOffice[] =
    useSelector(CLOTHES_AND_ACCESSORIES);
  const notifications: getNotifications_getNotifications[] =
    useSelector(NOTIFICATIONS);

  // apis
  const getVideos = UseGetVideos();
  const getCourses = UseGetCoursesListBackOffice();
  const getQuizzes = UseGetQuizzesListBackOffice();
  const getLessons = UseGetLessonsListBackOffice();
  const getPrograms = UseGetPrograms();
  const getUsers = UseGetUsers();
  const getSubjects = UseGetSubjects();
  const getSchools = UseGetSchools();
  const getAxes = UseGetAxes();
  const getThemes = UseGetTheme();
  const getTags = UseGetTags();
  const getClassrooms = UseGetClassroom();
  const getGrades = useGetGrade();
  const getEssays = UseGetRedactions();
  const getLiteracies = UseGetLiteracies();
  const getListenings = UseGetListenings();
  const getAIConversations = UseGetAIConversations();
  const getGetClothesAndAccessories = UseGetClothesAndAccessories();
  const getNotifications = UseGetNotifications();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (videos?.length === 0) {
        let videosData = await getVideos();
        videosData &&
          videosData.sort(
            (a: GetVideos_getVideos, b: GetVideos_getVideos) =>
              Number(b?.id) - Number(a?.id)
          );
        dispatch({ type: "data/setVideos", payload: videosData });
      }
      if (courses.length === 0) {
        let coursesData = await getCourses();
        coursesData?.sort(
          (
            a: GetCoursesListBackOffice_getCoursesListBackOffice,
            b: GetCoursesListBackOffice_getCoursesListBackOffice
          ) => Number(b?.id) - Number(a?.id)
        );
        dispatch({ type: "data/setCourses", payload: coursesData });
      }
      if (essays?.length === 0) {
        let essaysData = await getEssays();
        essaysData &&
          essaysData.sort(
            (a: GetRedactions_getRedactions, b: GetRedactions_getRedactions) =>
              Number(b?.id) - Number(a?.id)
          );
        dispatch({ type: "data/setEssays", payload: essaysData });
      }
      if (listenings?.length === 0) {
        let listeningsData = await getListenings();
        listeningsData &&
          listeningsData.sort(
            (a: getListenings_getListenings, b: getListenings_getListenings) =>
              Number(b?.id) - Number(a?.id)
          );
        dispatch({ type: "data/setListenings", payload: listeningsData });
      }
      if (literacies?.length === 0) {
        let literaciesData = await getLiteracies();
        literaciesData &&
          literaciesData.sort(
            (a: getLiteracies_getLiteracies, b: getLiteracies_getLiteracies) =>
              Number(b?.id) - Number(a?.id)
          );
        dispatch({ type: "data/setLiteracies", payload: literaciesData });
      }
      if (quizzes.length === 0) {
        let quizzesData = await getQuizzes();
        quizzesData?.sort(
          (a: GetQuizzes_getQuizzes, b: GetQuizzes_getQuizzes) =>
            Number(b?.id) - Number(a?.id)
        );
        dispatch({ type: "data/setQuizzes", payload: quizzesData });
      }
      if (lessons.length === 0) {
        let lessonsData = await getLessons();
        lessonsData?.sort(
          (a: GetLessons_getLessons, b: GetLessons_getLessons) =>
            Number(b?.id) - Number(a?.id)
        );
        dispatch({ type: "data/setLessons", payload: lessonsData });
      }
      if (notifications.length === 0) {
        let notificationsData = await getNotifications();
        notificationsData?.sort(
          (a: getNotifications_getNotifications, b: getNotifications_getNotifications) =>
            Number(b?.id) - Number(a?.id)
        );
        dispatch({ type: "data/setNotifications", payload: notificationsData });
      }
      if (programs.length === 0) {
        let programsData = await getPrograms();
        programsData?.sort(
          (a: GetPrograms_getPrograms, b: GetPrograms_getPrograms) =>
            Number(b?.id) - Number(a?.id)
        );
        dispatch({ type: "data/setPrograms", payload: programsData });
      }
      if (users.length === 0) {
        let usersData = await getUsers();
        usersData?.sort(
          (a: GetUsers_getUsers, b: GetUsers_getUsers) =>
            Number(b?.id) - Number(a?.id)
        );
        dispatch({ type: "data/setUsers", payload: usersData });
      }
      if (subjects.length === 0) {
        let subjectsData = await getSubjects();
        subjectsData?.sort(
          (a: GetSubjects_getSubjects, b: GetSubjects_getSubjects) =>
            Number(b?.id) - Number(a?.id)
        );
        dispatch({ type: "data/setSubjects", payload: subjectsData });
      }
      if (aiconversations.length === 0) {
        let aiConversationsData = await getAIConversations();
        aiConversationsData?.sort(
          (
            a: getAIConversations_getAIConversations,
            b: getAIConversations_getAIConversations
          ) => Number(b?.id) - Number(a?.id)
        );
        dispatch({
          type: "data/setAIConversations",
          payload: aiConversationsData,
        });
      }
      if (schools.length === 0) {
        let schoolsData = await getSchools();
        schoolsData?.sort(
          (a: GetSchools_getSchools, b: GetSchools_getSchools) =>
            Number(b?.id) - Number(a?.id)
        );
        dispatch({ type: "data/setSchools", payload: schoolsData });
      }
      if (axes.length === 0) {
        let axesData = await getAxes();
        axesData?.sort(
          (a: GetAxes_getAxes, b: GetAxes_getAxes) =>
            Number(b?.id) - Number(a?.id)
        );
        dispatch({ type: "data/setAxes", payload: axesData });
      }
      if (themes.length === 0) {
        let themesData = await getThemes();
        themesData?.sort(
          (a: GetThemes_getThemes, b: GetThemes_getThemes) =>
            Number(b?.id) - Number(a?.id)
        );
        dispatch({ type: "data/setThemes", payload: themesData });
      }
      if (tags.length === 0) {
        let tagsData = await getTags();
        tagsData?.sort(
          (a: GetTags_getTags, b: GetTags_getTags) =>
            Number(b?.id) - Number(a?.id)
        );
        dispatch({ type: "data/setTags", payload: tagsData });
      }
      if (classrooms.length === 0) {
        let classroomData = await getClassrooms();
        classroomData?.sort(
          (a: GetClassrooms_getClassrooms, b: GetClassrooms_getClassrooms) =>
            Number(b?.id) - Number(a?.id)
        );
        dispatch({ type: "data/setClassroom", payload: classroomData });
      }
      if (videos?.length === 0) {
        let gradesData = await getGrades();
        gradesData &&
          gradesData.sort(
            (a: GetGrade_getGrades, b: GetGrade_getGrades) =>
              Number(b?.id) - Number(a?.id)
          );
        dispatch({ type: "data/setGrades", payload: gradesData });
      }
      if (clothesAndAccessories?.length === 0) {
        let clothesAndAccessoriesData = await getGetClothesAndAccessories();
        clothesAndAccessoriesData &&
          clothesAndAccessoriesData.sort(
            (
              a: getClothesAndAccessoriesBackOffice_getClothesAndAccessoriesBackOffice,
              b: getClothesAndAccessoriesBackOffice_getClothesAndAccessoriesBackOffice
            ) => Number(b?.id) - Number(a?.id)
          );
        dispatch({
          type: "data/setClothesAndAccessories",
          payload: clothesAndAccessoriesData,
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return {
    loading,
    videos,
    courses,
    quizzes,
    lessons,
    programs,
    users,
    subjects,
    schools,
    axes,
    themes,
    tags,
    classrooms,
  };
};
