import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NOTIFICATIONS } from "../store/selectors";
import { notification } from "antd";
import { getNotifications_getNotifications } from "../__generated__/gql-types/getNotifications";
import useDeleteNotification from "../api/useDeleteNotification";
import UseGetNotifications from "../api/useGetNotificationss";

export const useNotifications = () => {
  const dispatch = useDispatch();
  const notificationsSelector = useSelector(NOTIFICATIONS);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState<
    getNotifications_getNotifications[]
  >([]);

  const deleteNotificationMutation = useDeleteNotification();
  const getNotifications = UseGetNotifications();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (notificationsSelector?.length > 0) {
        setNotifications(notificationsSelector);
      } else {
        const notificationsResponse = await getNotifications();
        const notificationsData = notificationsResponse?.sort(
          (a, b) => Number(b?.id) - Number(a?.id)
        );

        notificationsData && setNotifications(notificationsData);
        dispatch({ type: "data/setNotifications", payload: notificationsData });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const deleteNotification = async (idNotification: string) => {
    setLoading(true);
    try {
      const response = await deleteNotificationMutation({
        deleteNotificationId: idNotification.toString(),
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "La notificación fue eliminada con exito!",
          placement: "top",
          duration: 3,
        });
        let updatedNotifications = notifications.filter(
          (notification) => notification.id !== idNotification
        );
        setNotifications(updatedNotifications);
        dispatch({
          type: "data/setNotifications",
          payload: updatedNotifications,
        });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInfo();
    };
    fetchData();

    return () => {};
  }, []);

  return { loading, notifications, deleteNotification };
};
