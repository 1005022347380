import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { CourseInput } from "../__generated__/gql-types/globalTypes";

const UseCreateCourses = () => {
  const client = useApolloClient();

  return useCallback(
    async (courseInput: CourseInput) => {
      return await client.mutate({
        mutation: CREATE_COURSES,
        fetchPolicy: "no-cache",
        variables: { courseInput },
      });
    },
    [client]
  );
};

export default UseCreateCourses;

const CREATE_COURSES = gql`
  mutation CreateCourse($courseInput: CourseInput) {
    createCourse(courseInput: $courseInput) {
      id
      lessons {
        title
        id
        order_by
      }
      sep_book_pages
      week
      nem
      hide_course_for_students
      threemester
      month
      axes {
        name
        id
      }
      themes {
        name
        id
      }
      deleted_at
      title
      description
      type
      publish
      subject {
        name
        id
      }
      grade {
        name
        id
      }
    }
  }
`;
