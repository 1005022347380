import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetAxes } from "../__generated__/gql-types/GetAxes";

const UseGetAxes = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetAxes> = await client.query({
      query: GET_AXES,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }
    return result.data.getAxes;
  }, [client]);
};

export default UseGetAxes;

const GET_AXES = gql`
  query GetAxes {
    getAxes {
      name
      id
    }
  }
`;
