import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetSchools } from "../__generated__/gql-types/GetSchools";

const UseGetSchools = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetSchools> = await client.query({
      query: GET_SCHOOLS,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getSchools;
  }, [client]);
};

export default UseGetSchools;

const GET_SCHOOLS = gql`
  query GetSchools {
    getSchools {
      id
      demo
      cct
      name
      city
      deleted_at
      excluded_themes {
        id
        name
      }
      excluded_axes {
        id
        name
      }
    }
  }
`;
