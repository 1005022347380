import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { DuplicateProf, DuplicateProfVariables } from "../__generated__/gql-types/DuplicateProf";

const UseSuperDemo = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: DuplicateProfVariables) => {
      return await client.mutate<DuplicateProf>({
        mutation: DUPLICATE_PROF,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseSuperDemo;

const DUPLICATE_PROF = gql`
  mutation DuplicateProf($emailFrom: String) {
    duplicateProf(emailFrom: $emailFrom) {
      email
      role
    }
  }
`;
