import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Space, Table, Modal, Input } from "antd";
import styles from "./schools.module.css";
import { SearchOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/lib/table";
import { GetSchools_getSchools } from "../../__generated__/gql-types/GetSchools";
import { useSchools } from "../../hooks/useSchools";

const { Search } = Input;

const Schools: React.FC = () => {
  const { deleteSchool, loading: isLoading, schools } = useSchools();
  const [dataSource, setdataSource] = useState<GetSchools_getSchools[] | null>(
    []
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [idSchool, setIdSchool] = useState<string>("");

  const showModal = (id: string) => {
    setIsModalVisible(true);
    setIdSchool(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    await deleteSchool(idSchool);
    setIsModalVisible(false);
  };

  useEffect(() => {
    setdataSource(schools);
  }, [schools]);

  const columns: ColumnsType<GetSchools_getSchools> = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "CCT",
      dataIndex: "cct",
      key: "cct",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ciudad",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Demo",
      key: "demo",
      dataIndex: "demo",
      render: (bool) => {
        if (bool && bool === true) {
          return "✔";
        }
        return "❌";
      },
    },
    {
      title: "Accion",
      key: "action",
      render: (record) => {
        const records = record;
        return (
          <Space size="middle">
            <Link to={`/schools/${record.id}`} state={records}>
              <Button icon={<SearchOutlined />}>Editar</Button>
            </Link>
            <Button
              onClick={() => {
                showModal(record.id);
              }}
              danger
            >
              Eliminar
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    if (value === "") {
      setdataSource(schools);
    } else {
      if (dataSource) {
        const filteredData: GetSchools_getSchools[] = dataSource.filter(
          (data) => data.name?.includes(value)
        );
        setdataSource(filteredData);
      }
    }
  };

  return (
    <div className={styles.container}>
      <h1>Escuelas</h1>
      <div className={styles.newSchoolsContainer}>
        <Space direction="vertical">
          <Search
            placeholder="Buscar por nombre"
            onSearch={onSearch}
            enterButton
          />
        </Space>
        <Link to="/schools/new">
          <Button type="primary" danger>
            Nueva Escuela
          </Button>
        </Link>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={dataSource ?? []}
        loading={isLoading}
      />
      <Modal
        title="Eliminar Escuela"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>¿Desea eliminar la escuela?</p>
      </Modal>
    </div>
  );
};

export default Schools;
