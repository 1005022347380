import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { updateClothesAndAccessoriesVariables } from "../__generated__/gql-types/updateClothesAndAccessories";

const UseUpdateClothesAndAccessories = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: updateClothesAndAccessoriesVariables) => {
      return await client.mutate({
        mutation: UPDATE_CLOTHES_AND_ACCESSORIES,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseUpdateClothesAndAccessories;

const UPDATE_CLOTHES_AND_ACCESSORIES = gql`
  mutation updateClothesAndAccessories(
    $updateClothesAndAccessoriesId: ID!
    $clothesAndAccessoriesInput: ClothesAndAccessoriesInput
  ) {
    updateClothesAndAccessories(
      id: $updateClothesAndAccessoriesId
      clothesAndAccessoriesInput: $clothesAndAccessoriesInput
    ) {
      id
      name
      type
      clasification
      z_index
      price
      publish
      rigel_item_img
      rigel_thumbnail_img
      spica_item_img
      spica_thumbnail_img
      colmillu_item_img
      colmillu_thumbnail_img
      polux_item_img
      polux_thumbnail_img
      shaula_item_img
      shaula_thumbnail_img
      tatooine_item_img
      tatooine_thumbnail_img
      created_at
      updated_at
      deleted_at
    }
  }
`;
