import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetRedactionById } from "../__generated__/gql-types/GetRedactionById";

const UseGetRedactionById = (redactionId: string) => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetRedactionById> = await client.query({
      query: GET_REDACTION_BY_ID,
      fetchPolicy: "no-cache",
      variables: {
        redactionId,
      },
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getRedactionById;
  }, [client]);
};

export default UseGetRedactionById;

const GET_REDACTION_BY_ID = gql`
  query GetRedactionById($redactionId: ID) {
    getRedactionById(redactionId: $redactionId) {
      id
      title
      needs_evidence
      question
      rubrics {
        rubric
        order
      }
    }
  }
`;
