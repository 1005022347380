import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { DeleteVideoVariables } from "../__generated__/gql-types/DeleteVideo";

const UseDeleteVideo = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: DeleteVideoVariables) => {
      return await client.mutate({
        mutation: DELETE_VIDEO,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseDeleteVideo;

const DELETE_VIDEO = gql`
  mutation DeleteVideo($videoId: ID!) {
    deleteVideo(videoId: $videoId) {
      id
      deleted_at
    }
  }
`;
