import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getNotifications } from "../__generated__/gql-types/getNotifications";

const UseGetNotifications = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getNotifications> = await client.query({
      query: GET_NOTIFICATIONS,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getNotifications;
  }, [client]);
};

export default UseGetNotifications;

const GET_NOTIFICATIONS = gql`
  query getNotifications {
    getNotifications {
      id
      title
      message
      media
      role
      school_id
      created_at
      updated_at
      deleted_at
    }
  }
`;
