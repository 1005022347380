import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getLessonsListBackOffice } from "../__generated__/gql-types/getLessonsListBackOffice";

const UseGetLessonsListBackOffice = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getLessonsListBackOffice> =
      await client.query({
        query: GET_LESSONS_BY_DATE,
        fetchPolicy: "no-cache",
      });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getLessonsListBackOffice;
  }, [client]);
};

export default UseGetLessonsListBackOffice;

const GET_LESSONS_BY_DATE = gql`
  query getLessonsListBackOffice {
    getLessonsListBackOffice {
      id
      title
      deleted_at
      course_id
      grade {
        name
        id
      }
      lesson_type
    }
  }
`;
