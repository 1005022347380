import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Col, Row, Checkbox, Select, notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Buttons } from "../..";
import styles from "./newVideos.module.css";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  successNotification,
  updatedNotification,
  errorNotification,
  videoQuizErrorNotification,
  videoQuizSuccessNotification,
} from "./utils";
import { initialValuesObj, NewVideosSchema } from "./NewVideos.types";
import { VideoInput } from "../../../__generated__/gql-types/globalTypes";
import UseCreateVideo from "../../../api/useCreateVideos";
import UseUpdateVideo from "../../../api/useUpdateVideos";
import UseGetVideoById from "../../../api/useGetVideoById";
import { GetAxes_getAxes } from "../../../__generated__/gql-types/GetAxes";
import { ActivityIndicator } from "../../ActivityIndicator";
import { GetVideoById_getVideoById } from "../../../__generated__/gql-types/GetVideoById";
import { UploadVideoFile, UploadImageFile } from "../../UploadFile";
import { QuizToVideoForm } from "./QuizToVideoForm";
import { PlusCircleTwoTone } from "@ant-design/icons";
import { useAxes } from "../../../hooks/useAxes";
import { useThemes } from "../../../hooks/useThemes";
import { useQuizzes } from "../../../hooks/useQuizzes";
import { useDispatch } from "react-redux";
import { useVideos } from "../../../hooks/useVideos";
import { GetVideos_getVideos } from "../../../__generated__/gql-types/GetVideos";

const { Option } = Select;

export interface IQuizVideo {
  id?: number;
  quiz_id: number;
  time: number;
  time_out: number;
}

export interface FileImageCoverProps {
  id: string;
  ext: string;
}

const NewVideosForm: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const video_id = location?.state as string;
  const [videoById, setvideoById] = useState<GetVideoById_getVideoById | null>(
    null
  );
  const dispatch = useDispatch();

  const createVideo = UseCreateVideo();
  const updateVideo = UseUpdateVideo();
  const getVideoById = UseGetVideoById(video_id);

  const { axes, loading: loadingAxes } = useAxes();
  const { themes, loading: loadingThemes } = useThemes();
  const { quizzes } = useQuizzes();
  const { videos } = useVideos();

  const [initialValues, setInitialValues] =
    useState<VideoInput>(initialValuesObj);

  const [loading, setloading] = useState(false);
  const [quizVideos, setQuizVideos] = useState<IQuizVideo[]>([]);
  const [quizVideosList, setQuizVideosList] = useState<IQuizVideo[]>([]);
  const [alreadyAdded, setAlreadyAdded] = useState<boolean>(false);

  const [filenameCover, setfilenameCover] = useState<FileImageCoverProps>({
    id: "",
    ext: "",
  });
  const [urlImageCover, setUrlImageCover] = useState<string>("");

  const [videoFile, setVideoFile] = useState<string>("");

  const [time, setTime] = useState<string>("");
  const [size, setSize] = useState(0);

  async function fetchInfo() {
    setloading(true);
    try {
      if (video_id) {
        const videoResponse = await getVideoById();
        setvideoById(videoResponse);
        setInitialValues({
          title: videoResponse?.title ?? "",
          description: videoResponse?.description ?? "",
          axes: videoResponse?.axes ?? [],
          themes: videoResponse?.themes ?? [],
          publish: videoResponse?.publish ?? 0,
          quizzes: (videoResponse?.quizzes as any) ?? [],
          image_cover: videoResponse?.image_cover ?? null,
          file_id: videoResponse?.file_id ?? "",
          key_learnings: videoResponse?.key_learnings ?? "",
          microcontent: videoResponse?.microcontent ?? "",
        });

        if (quizVideos.length === 0 && videoResponse && videoResponse.quizzes) {
          let videosQuizzes: IQuizVideo[] = videoResponse?.quizzes?.map(
            (quizEntity) => {
              return {
                quiz_id: Number(quizEntity.quiz?.id),
                time: Number(quizEntity.time),
                time_out: Number(quizEntity.time_out),
              };
            }
          );

          setQuizVideos(videosQuizzes);
        }
        videoResponse?.url && setSize(videoResponse?.metadata?.size);
        videoResponse?.imageUrl &&
          setfilenameCover({
            id:
              videoResponse
                ?.imageUrl!.split("/")
                [videoResponse?.imageUrl!.split("/").length - 1].split(
                  "."
                )[0] ?? "",
            ext:
              videoResponse
                ?.imageUrl!.split("/")
                [videoResponse?.imageUrl!.split("/").length - 1].split(
                  "."
                )[1] ?? "jpg",
          });
        setVideoFile(initialValues?.file_id ?? "");
        let time = (videoResponse?.metadata?.duration / 60)
          .toFixed(2)
          .toString()
          .replace(".", ":");
        setTime(time ?? "");
        // await resolveSteps();
      }

      setloading(false);
    } catch (error: any) {
      setloading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  }

  const handleChangeThemes = (values: string[]) => {
    const themesValues = values!.map((value) => {
      const selectedTheme = themes!.find((th) => th?.id === value);
      if (selectedTheme) {
        return selectedTheme;
      } else {
        return {
          id: null,
          name: value,
        };
      }
    });
    initialValues.themes = themesValues;
    setInitialValues(initialValues);
  };

  const handleChangeAxes = (values: string[]) => {
    const axesValues = values!.map((value) => {
      const axe = axes!.find((axis) => axis?.id === value);
      if (axe) {
        return axe;
      } else {
        return {
          id: null,
          name: value!,
        };
      }
    });

    initialValues.axes = axesValues;
    setInitialValues(initialValues);
  };

  const handleCheckBox = (e: CheckboxChangeEvent) => {
    setInitialValues({ ...initialValues, publish: e.target.checked ? 1 : 0 });
  };

  const handleSubmit = async (formValues: VideoInput) => {
    let definitiveTime = (time: string): number => {
      if (time.includes(":")) {
        let newTime = Number(time.replace(":", "."));
        return Math.ceil(newTime / 0.016667);
      } else {
        let newTime = Number(time);
        return Math.ceil(newTime / 0.016667);
      }
    };

    let metadata = {
      clip: {
        mime: "video/mp4",
        extension: "mp4",
      },
      size: size,
      cover: {
        mime: "image/jpeg",
        extension: "jpg",
      },
      video: {
        mime: "video/mp4",
        extension: "mp4",
      },
      width: 1280,
      height: 720,
      duration: definitiveTime(time),
    };

    const formInputs: VideoInput = {
      ...formValues,
      file_id: videoFile,
      metadata,
      image_cover: `${filenameCover.id}.${filenameCover.ext}`,
      publish: formValues.publish ? 1 : 0,
      microcontent: initialValues.microcontent,
      key_learnings: initialValues.key_learnings,
      quizzes:
        quizVideosList.length > 0
          ? quizVideosList.map((quizVideo) => {
              return {
                quiz_id: Number(quizVideo.quiz_id),
                time: Number(quizVideo.time),
                time_out: Number(quizVideo.time_out),
              };
            })
          : undefined,
    };

    // console.log({ formInputs });
    // return;
    setloading(true);
    try {
      if (video_id?.length > 0) {
        const response = await updateVideo({
          videoInput: formInputs,
          videoId: video_id,
        });
        response.data && updatedNotification();
        let index = videos.findIndex(
          (video) => response.data.updateVideo.id === video.id
        );
        let videosUpdated = [...videos];
        videosUpdated[index] = response.data
          .updateVideo as unknown as GetVideos_getVideos;

        dispatch({ type: "data/setVideos", payload: videosUpdated });

        setloading(false);
        return navigate(-1);
      } else {
        const createResponse = await createVideo(formInputs);
        createResponse.data && successNotification();
        let newArrayVideos = [];
        let newVideo = createResponse.data
          .createVideo as unknown as GetVideos_getVideos;
        newArrayVideos = [newVideo, ...videos];
        dispatch({ type: "data/setVideos", payload: newArrayVideos });
        setloading(false);
        return navigate(-1);
      }
    } catch (error) {
      console.log("ingresa error");
      errorNotification();
      setloading(false);
    }
  };

  //QuizVideo handlers

  const handleAddQuizVideo = () => {
    setQuizVideos([
      ...quizVideos,
      { id: Math.ceil(Math.random() * 8521), quiz_id: 0, time: 0, time_out: 0 },
    ]);
  };

  const handleRemoveQuizVideo = (index: number) => {
    const list = [...quizVideos];
    const result = list.filter((_, i) => {
      return _.id !== index;
    });
    setQuizVideos(result);
  };

  const handleQuizChange = (
    e: { target: { value: number } },
    index: number
  ) => {
    const list = [...quizVideos];
    list[index].quiz_id = Number(e.target.value);
    setQuizVideos(list);
  };

  const handleTimeChange = (e: any, index: number) => {
    const list = [...quizVideos];
    list[index].time = e.target.value;
    setQuizVideos(list);
  };
  const handleTimeOutChange = (e: any, index: number) => {
    const list = [...quizVideos];
    list[index].time_out = e.target.value;
    setQuizVideos(list);
  };

  const handleSubmitQuiz = (index: number) => {
    const quizAlreadyAdded = quizVideosList.some(
      (quiz) => quiz.quiz_id === quizVideos[index].quiz_id
    );
    if (quizAlreadyAdded) {
      setAlreadyAdded(true);
      return videoQuizErrorNotification(`Ese quiz ya fue agregado`);
    }
    setQuizVideosList([...quizVideosList, quizVideos[index]]);
    delete quizVideosList[index]?.id;
    videoQuizSuccessNotification();
  };

  useEffect(() => {
    fetchInfo();
  }, [axes, themes, quizzes, loadingAxes, loadingThemes, quizzes]);

  useEffect(() => {}, [alreadyAdded]);

  if (loading || loadingAxes || loadingThemes) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      <h1>Nuevo Video </h1>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={NewVideosSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleReset, touched }) => {
          return (
            <Form className={styles.formContainer}>
              <>
                <Row style={{ marginTop: 10, marginBottom: 30 }}>
                  <Col span={videoById?.id ? 11 : 22}>
                    {/* IMAGE */}
                    <UploadImageFile
                      title="Cover"
                      className={styles.uploadFile}
                      typeFile="cover"
                      folder="covers"
                      widthCover={300}
                      heightCover={200}
                      image={`${filenameCover.id}.${filenameCover.ext}`}
                      filenameCover={filenameCover}
                      setfilenameCover={setfilenameCover}
                      urlImageCover={urlImageCover}
                      setUrlImageCover={setUrlImageCover}
                    />
                  </Col>
                  {videoById?.id ? (
                    <Col
                      span={11}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* VIDEO */}
                      <UploadVideoFile
                        className={styles.uploadFile}
                        uploadType="Video"
                        folder="luca2-backend"
                        typeFile="video"
                        videoTitle={videoById?.title!}
                        videoUrl={videoById?.url!}
                        videoFile={videoFile}
                        setVideoFile={setVideoFile}
                        size={size}
                        setSize={setSize}
                      />
                    </Col>
                  ) : (
                    <Col
                      span={11}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* VIDEO */}
                      <UploadVideoFile
                        className={styles.uploadFile}
                        uploadType="Video"
                        folder="luca2-backend"
                        typeFile="video"
                        videoTitle={""}
                        videoUrl={""}
                        videoFile={videoFile}
                        setVideoFile={setVideoFile}
                        size={size}
                        setSize={setSize}
                      />
                    </Col>
                  )}
                </Row>

                <label htmlFor="">Tiempo de video (Por ejemplo: 5:14)</label>
                <Field
                  errors={errors}
                  touched={touched}
                  value={time}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setTime(e.target.value);
                  }}
                  className={styles.formFields}
                  name="time"
                  title="Time"
                  type="text"
                />

                <label htmlFor="">Título</label>
                <Field
                  errors={errors}
                  touched={touched}
                  value={initialValues.title}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInitialValues({
                      ...initialValues,
                      title: e.target.value,
                    });
                  }}
                  className={styles.formFields}
                  name="title"
                  title="Titulo"
                  type="text"
                />

                <label htmlFor="">Descripción</label>
                <Field
                  errors={errors}
                  touched={touched}
                  name="description"
                  title="Descripcion"
                  as="textarea"
                  value={initialValues.description}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInitialValues({
                      ...initialValues,
                      description: e.target.value,
                    });
                  }}
                  maxLength={100}
                  minLength={6}
                  className={styles.description}
                />

                <label htmlFor="">Microcontenidos</label>
                <Field
                  errors={errors}
                  touched={touched}
                  value={initialValues.microcontent}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInitialValues({
                      ...initialValues,
                      microcontent: e.target.value,
                    });
                  }}
                  className={styles.formFields}
                  name="microcontent"
                  title="Microcontenidos"
                  type="text"
                />

                <label htmlFor="">Aprendizajes claves</label>
                <Field
                  errors={errors}
                  touched={touched}
                  name="description"
                  title="Descripcion"
                  as="textarea"
                  value={initialValues.key_learnings}
                  className={styles.description}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInitialValues({
                      ...initialValues,
                      key_learnings: e.target.value,
                    });
                  }}
                />

                <label htmlFor="axes">Eje(s)</label>
                <Select
                  mode="tags"
                  defaultValue={
                    videoById
                      ? videoById.axes?.map((axe: { name: any }) => {
                          return `${axe.name}`;
                        })
                      : undefined
                  }
                  className={styles.formFieldSelect}
                  onChange={handleChangeAxes}
                >
                  {axes?.map((axe: GetAxes_getAxes, index: number) => {
                    if (axe?.id) {
                      return (
                        <Option value={axe?.id} key={index + `${new Date()}`}>
                          {axe?.name}
                        </Option>
                      );
                    }
                  })}
                </Select>

                <label htmlFor="theme">Tema(s)</label>
                <Select
                  mode="tags"
                  className={styles.formFieldSelect}
                  defaultValue={
                    videoById?.id
                      ? videoById.themes?.map((theme: { name: any }) => {
                          return `${theme.name}`;
                        })
                      : undefined
                  }
                  onChange={handleChangeThemes}
                >
                  {themes?.map((theme, index: number) => {
                    if (theme?.id) {
                      return (
                        <Option value={theme?.id} key={index + `${new Date()}`}>
                          {theme?.name}
                        </Option>
                      );
                    }
                  })}
                </Select>
                <div style={{ marginTop: 20 }}>
                  <label>Agregar Quiz Intravideo </label>
                  <span>
                    <PlusCircleTwoTone
                      className={styles.icons}
                      twoToneColor="blue"
                      onClick={() => {
                        handleAddQuizVideo();
                      }}
                    />{" "}
                  </span>
                  {quizVideos.length &&
                    quizVideos.map((quizVideo, index) => {
                      return (
                        <QuizToVideoForm
                          key={quizVideo.id}
                          index={index}
                          quizVideo={quizVideo}
                          handleRemove={handleRemoveQuizVideo}
                          handleQuizChange={handleQuizChange}
                          handleTimeChange={handleTimeChange}
                          handleTimeOutChange={handleTimeOutChange}
                          handleSubmit={handleSubmitQuiz}
                          quizVideos={quizVideos}
                          quizVideosList={quizVideosList}
                          quizzes={quizzes!}
                        ></QuizToVideoForm>
                      );
                    })}
                </div>

                <div className={styles.publish}>
                  <label htmlFor="">Publicar:</label>
                  <Checkbox
                    checked={initialValues.publish === 1 ? true : false}
                    onChange={handleCheckBox}
                    style={{ marginLeft: 10 }}
                  />
                </div>

                <Buttons
                  handleClick={() => {
                    handleReset();
                    navigate(-1);
                  }}
                />
              </>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NewVideosForm;
