import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LISTENINGS } from "../store/selectors";
import { notification } from "antd";
import UseGetListenings from "../api/useGetListenings";
import { getListenings_getListenings } from "../__generated__/gql-types/getListenings";
import useDeleteListening from "../api/useDeleteListening";

export const useListening = () => {
  const dispatch = useDispatch();
  const listeningSelector = useSelector(LISTENINGS);
  const [loading, setLoading] = useState(false);
  const [listenings, setListenings] = useState<getListenings_getListenings[]>(
    []
  );

  const deleteListeningMutation = useDeleteListening();
  const getListenings = UseGetListenings();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (listeningSelector?.length > 0) {
        setListenings(listeningSelector);
      } else {
        const listeningsResponse = await getListenings();
        const listeningsData = listeningsResponse?.sort(
          (a, b) => Number(b?.id) - Number(a?.id)
        );

        listeningsData && setListenings(listeningsData);
        dispatch({ type: "data/setListenings", payload: listeningsData });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const deleteListening = async (idListening: string) => {
    setLoading(true);
    try {
      const response = await deleteListeningMutation({
        listeningId: idListening.toString(),
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "El audio fue eliminado con exito!",
          placement: "top",
          duration: 3,
        });
        let updatedListenings = listenings.filter(
          (listening) => listening.id !== idListening
        );
        setListenings(updatedListenings);
        dispatch({ type: "data/setListenings", payload: updatedListenings });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInfo();
    };
    fetchData();

    return () => {};
  }, []);

  return { loading, listenings, deleteListening };
};
