import { FC } from "react";
import { Button } from "antd";
import styles from "./buttons.module.css";

interface ButtonsProps {
  handleClick: () => void;
  handleSubmit?: () => void;
}

const Buttons: FC<ButtonsProps> = ({ handleClick }) => {
  return (
    <div className={styles.formBtn}>
      <Button htmlType="button" className={styles.btn} onClick={handleClick}>
        Cancelar
      </Button>
      <Button type="primary" htmlType="submit" className={styles.btn}>
        Guardar
      </Button>
    </div>
  );
};

export const ButtonsCustom: FC<ButtonsProps> = ({ handleClick, handleSubmit }) => {
  return (
    <div className={styles.formBtn}>
      <Button htmlType="button" className={styles.btn} onClick={handleClick}>
        Cancelar
      </Button>
      <Button type="primary" onClick={handleSubmit} className={styles.btn}>
        Guardar
      </Button>
    </div>
  );
};

export default Buttons;
