import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UpdateClassroomVariables } from "../__generated__/gql-types/UpdateClassroom";

const UseUpdateClassroom = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: UpdateClassroomVariables) => {
      return await client.mutate({
        mutation: UPDATE_CLASSROOM,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseUpdateClassroom;

const UPDATE_CLASSROOM = gql`
  mutation UpdateClassroom($classroomId: ID!, $classroomInput: ClassroomInput) {
    updateClassroom(
      classroomId: $classroomId
      classroomInput: $classroomInput
    ) {
      id
      name
    }
  }
`;
