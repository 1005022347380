import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetQuizzesListBackOffice } from "../__generated__/gql-types/GetQuizzesListBackOffice";

const UseGetQuizzesListBackOffice = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetQuizzesListBackOffice> =
      await client.query({
        query: GET_QUIZZES,
        fetchPolicy: "no-cache",
      });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getQuizzesListBackOffice;
  }, [client]);
};

export default UseGetQuizzesListBackOffice;

const GET_QUIZZES = gql`
  query GetQuizzesListBackOffice {
    getQuizzesListBackOffice {
      id
      title
      publish
    }
  }
`;
