import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, FormikProps, Form } from "formik";
import { notification, Select } from "antd";

// apis
import useAssignClassroom from "../../../api/useAssignClassroom";
import UseGetPrograms from "../../../api/UseGetPrograms";
import UseGetSchools from "../../../api/useGetSchools";
import UseGetClassroomById from "../../../api/useGetClassroomById";
import UseGetUsersClassroomBySchoolId from "../../../api/useGetUsersClassroom";
import UseUpdateClassroom from "../../../api/useUpdateClassroom";
import UseUpdateGroup from "../../../api/useUpdateGroup";

// types
import { GetClassrooms_getClassrooms } from "../../../__generated__/gql-types/GetClassrooms";
import { updateGroupClassroomVariables } from "../../../__generated__/gql-types/updateGroupClassroom";
import {
  assignSchoolClassroomsInput,
  ClassroomInput,
  GroupInput,
  UserId,
} from "../../../__generated__/gql-types/globalTypes";
import { GetPrograms_getPrograms } from "../../../__generated__/gql-types/GetPrograms";
import { GetSchools_getSchools } from "../../../__generated__/gql-types/GetSchools";
import { GetUserBySchoolId_getUserBySchoolId } from "../../../__generated__/gql-types/GetUserBySchoolId";
import { getClassroomByIdBackOffice_getClassroomByIdBackOffice } from "../../../__generated__/gql-types/getClassroomByIdBackOffice";
import { initialValuesObj } from "./ClassroomForm.types";

// styles
import styles from "./classroomForm.module.css";

// components
import { ActivityIndicator } from "../../ActivityIndicator";
import { Buttons } from "../../Buttons";
import { errorNotification, successNotification } from "./utils";
import { UpdateClassroomVariables } from "../../../__generated__/gql-types/UpdateClassroom";

const { Option } = Select;

const ClassroomForm: FC = () => {
  // utils
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const data = location.state as GetClassrooms_getClassrooms;
  const groups: string[] = ["A", "B", "C", "D", "E", "F"];

  // api
  const programsData = UseGetPrograms();
  const schoolsData = UseGetSchools();
  const usersBySchoolId = UseGetUsersClassroomBySchoolId();
  const getClassroomById = UseGetClassroomById(data?.id);
  const assignClassroom = useAssignClassroom();
  const updateClassroom = UseUpdateClassroom();
  const updateGroup = UseUpdateGroup();

  // states
  const [initialValues, setinitialValues] =
    useState<assignSchoolClassroomsInput>(initialValuesObj);
  const [classroomData, setclassroomData] =
    useState<getClassroomByIdBackOffice_getClassroomByIdBackOffice | null>(null);
  const [programs, setprograms] = useState<GetPrograms_getPrograms[] | null>(
    []
  );
  const [schools, setSchools] = useState<GetSchools_getSchools[] | null>([]);
  const [teachers, setTeachers] = useState<
    GetUserBySchoolId_getUserBySchoolId[]
  >([]);
  const [students, setStudents] = useState<
    GetUserBySchoolId_getUserBySchoolId[]
  >([]);

  // global selected states
  const [schoolSelected, setSchoolSelected] = useState<number | null>(null);
  const [programsSelected, setProgramsSelected] = useState<number[] | null>(
    null
  );
  const [teachersSelected, setTeachersSelected] = useState<UserId[] | null>(
    null
  );
  const [studentsSelected, setStudentsSelected] = useState<UserId[] | null>(
    null
  );

  const fetchInfo = async () => {
    setLoading(true);
    try {
      const programsInfo = await programsData();
      const schoolsInfo = await schoolsData();

      let programsSort = programsInfo?.sort((a, b) =>
        a.title!.localeCompare(b.title!)
      );
      let schoolsSort = schoolsInfo?.sort((a, b) =>
        a.name!.localeCompare(b.name!)
      );

      setprograms(programsSort ?? []);
      setSchools(schoolsSort ?? []);

      setStudents([]);
      setTeachers([]);

      if (data?.id) {
        const responseClassroomData = await getClassroomById();
        setclassroomData(responseClassroomData);
        setSchoolSelected(responseClassroomData?.school_id ?? null);

        let studentsInClassroom: UserId[] | undefined =
          responseClassroomData?.group?.users?.map((student) => {
            return {
              id: student.id,
            } as unknown as UserId;
          });

        let teachersInClassroom: UserId[] | undefined =
          responseClassroomData?.teachers?.map((teacher) => {
            return {
              id: teacher.id,
            } as unknown as UserId;
          });

        setinitialValues({
          group_label: data.group?.name,
          program_ids: [Number(data.program?.id)],
          school_id: responseClassroomData?.school_id ?? undefined,
          students: studentsInClassroom ?? undefined,
          teachers: teachersInClassroom ?? undefined,
        });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const getUsersBySchoolIdAndOrder = async (schoolId: number) => {
    try {
      let studentsArray: GetUserBySchoolId_getUserBySchoolId[] = [];
      let teachersArray: GetUserBySchoolId_getUserBySchoolId[] = [];
      if (schoolSelected) {
        const usersInfo = await usersBySchoolId(schoolId);

        usersInfo?.map((user) => {
          if (user?.role === "teacher") {
            teachersArray.push(user);
          }
          if (user?.role === "student") {
            studentsArray.push(user);
          }
        });

        setStudents(studentsArray);
        setTeachers(teachersArray);
      } else {
        setStudents([]);
        setTeachers([]);
      }
    } catch (error: any) {
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  useEffect(() => {
    getUsersBySchoolIdAndOrder(Number(schoolSelected));
  }, [schoolSelected]);

  const handleChangeSchool = async (values: string[]) => {
    setLoadingUsers(true);
    if (values.length === 0) {
      setStudents([]);
      setTeachers([]);
      setSchoolSelected(null);
      setinitialValues({ ...initialValues, school_id: 3 });
      setLoadingUsers(false);
    } else {
      try {
        let schoolId = Number(values[0].split("-")[0]);
        setSchoolSelected(schoolId);
        initialValues.school_id = schoolId;
        setinitialValues(initialValues);
        await getUsersBySchoolIdAndOrder(Number(schoolId));

        setLoadingUsers(false);
      } catch (error: any) {
        setLoadingUsers(false);
        notification["error"]({
          message: "Error",
          description:
            "La información no pudo ser cargada, por favor notifica éste error!",
          placement: "top",
          duration: 3,
        });
        throw new Error(error);
      }
    }
  };

  const handleChangeTeacher = (values: string[]) => {
    let valuesTeachersId: UserId[] = values.map((teacher) => {
      return { id: Number(teacher.split("-")[0]) } as UserId;
    });
    console.log({ valuesTeachersId });
    setTeachersSelected(valuesTeachersId);
    setinitialValues({ ...initialValues, teachers: valuesTeachersId });
  };

  const handleChangeStudents = (values: string[]) => {
    let valuesStudentsId: UserId[] = values.map((student) => {
      return { id: Number(student.split("-")[0]) } as UserId;
    });
    setStudentsSelected(valuesStudentsId);
    setinitialValues({ ...initialValues, students: valuesStudentsId });
  };

  const handleChangePrograms = (values: string[]) => {
    let programsId = values.map((program) => Number(program.split("-")[0]));
    setProgramsSelected(programsId);
    setinitialValues({ ...initialValues, program_ids: programsId });
  };

  const handleChangeGroupLabel = (values: string[]) => {
    setinitialValues({
      ...initialValues,
      group_label: values[0],
    });
  };

  const defaultValueGroup = () => {
    if (data?.group) return [data?.group?.name] as string[];
    else return undefined;
  };

  const defaultValuePrograms = () => {
    let arrayPrograms = programs?.map((program) => {
      if (data?.program!.id === program.id)
        return `${program.id}-${program.title}`;
      else return "";
    });

    if (arrayPrograms?.length === 0) return undefined;
    return arrayPrograms?.filter((program) => program.length > 0);
  };

  const defaultValueSchool = () => {
    return classroomData?.school_id
      ? [`${classroomData.school?.id}-${classroomData.school?.name}`]
      : undefined;
  };

  const defaultValueTeachers = () => {
    return classroomData?.teachers && classroomData.teachers.length > 0
      ? classroomData?.teachers?.map(
          (teacher) =>
            `${teacher.id}-${teacher.first_name} ${teacher.last_name}`
        )
      : undefined;
  };

  const defaultValueStudents = () => {
    return classroomData?.group &&
      classroomData.group.users &&
      classroomData.group.users.length > 0
      ? classroomData.group.users.map(
          (student) =>
            `${student.id}-${student.first_name} ${student.last_name}`
        )
      : undefined;
  };

  const handleSubmit = async (values: any) => {
    let classroomInput: assignSchoolClassroomsInput;
    if (values.students!.length > 0 && data?.id) {
      classroomInput = {
        ...values,
        students: values.students!.map((student: any) => {
          return {
            id: student,
          };
        }),
        teachers: values.teachers!.map((teacher: any) => {
          return {
            id: teacher,
          };
        }),
      };
    } else {
      classroomInput = {
        ...values,
        students: values.students!.map((student: any) => {
          return student;
        }),
        teachers: values.teachers!.map((teacher: any) => {
          return teacher;
        }),
      };
    }

    try {
      setLoading(true);
      if (data?.id) {
        let updateGroupInput: GroupInput = {
          users:
            values.students.length === 0
              ? []
              : values.students!.map((student: any) => {
                  return {
                    id: student.id ? Number(student.id) : Number(student),
                  };
                }),
          school_id: values.school_id,
          name: values.group_label,
        };

        let updateGroupInputVariables: updateGroupClassroomVariables = {
          updateGroupId: classroomData!.group_id!.toString(),
          groupInput: updateGroupInput,
        };

        let responseGroup = await updateGroup(updateGroupInputVariables);

        if (responseGroup.data) {
          let initialClassroomGroupName =
            classroomData!.name?.split("Grupo ")[0];
          let nameClassroomUpdated =
            initialClassroomGroupName +
            "Grupo " +
            values.group_label +
            " " +
            classroomData!.school?.name;

          let teacherValues =
            values.teachers.length === 0
              ? []
              : values.teachers!.map((teacher: any) => {
                  console.log({ TEACHER: teacher });
                  return {
                    id:
                      typeof teacher !== "number" || Number.isNaN(teacher)
                        ? Number(teacher.id)
                        : Number(teacher),
                  };
                });

          let updateClassroomInput: ClassroomInput = {
            teachers: teacherValues,
            program_id: values.program_ids[0],
            name: nameClassroomUpdated,
          };

          let updateClassroomInputVariables: UpdateClassroomVariables = {
            classroomId: classroomData!.id,
            classroomInput: updateClassroomInput,
          };

          let responseClassroom = await updateClassroom(
            updateClassroomInputVariables
          );

          responseGroup?.data &&
            responseClassroom?.data &&
            successNotification();
          return navigate(-1);
        } else {
          setLoading(false);
          errorNotification();
        }
      } else {
        let response = await assignClassroom(classroomInput);
        setLoading(false);
        response && successNotification();
        return navigate(-1);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification();
    }
  };

  if (loading) return <ActivityIndicator />;

  return (
    <div className={styles.container}>
      {data?.id ? <h1>Editar Clase </h1> : <h1>Nueva Clase </h1>}
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ errors, handleReset, touched, values }: FormikProps<any>) => {
          return (
            <Form className={styles.formContainer}>
              {/* Group Lavel */}
              <label htmlFor="course">Seleccione el Grupo</label>
              <Select
                mode="tags"
                className={styles.formFieldSelect}
                onChange={handleChangeGroupLabel}
                defaultValue={defaultValueGroup()}
              >
                {groups.map((g, index: number) => {
                  return (
                    <Option value={`${g}`} key={`${index}-${new Date()}`}>
                      {g}
                    </Option>
                  );
                })}
              </Select>

              {/* Programs Select */}
              <label htmlFor="school">Elija los programas</label>
              <Select
                mode="multiple"
                className={styles.formFieldSelect}
                onChange={handleChangePrograms}
                defaultValue={defaultValuePrograms()}
              >
                {programs?.map(
                  (program: GetPrograms_getPrograms, index: number) => {
                    return (
                      <Option
                        value={`${program.id}-${program.title}`}
                        key={`${index}-${new Date()}`}
                      >
                        {program.title}
                      </Option>
                    );
                  }
                )}
              </Select>

              {/* School Select */}
              <label htmlFor="school">Escuela (sólo 1)</label>
              <Select
                mode="multiple"
                className={styles.formFieldSelect}
                onChange={handleChangeSchool}
                defaultValue={defaultValueSchool()}
              >
                {schools?.map(
                  (school: GetSchools_getSchools, index: number) => {
                    return (
                      <Option
                        value={`${school.id}-${school.name}`}
                        key={`${index}-${new Date()}`}
                      >
                        {school.name}
                      </Option>
                    );
                  }
                )}
              </Select>

              {loadingUsers && <ActivityIndicator />}

              {schoolSelected && !loadingUsers && (
                <>
                  <label htmlFor="school">Seleccione el Profesor</label>
                  <Select
                    mode="multiple"
                    className={styles.formFieldSelect}
                    onChange={handleChangeTeacher}
                    defaultValue={defaultValueTeachers()}
                  >
                    {teachers?.map(
                      (
                        teacher: GetUserBySchoolId_getUserBySchoolId,
                        index: number
                      ) => {
                        return (
                          <Option
                            value={`${teacher.id}-${teacher.first_name} ${teacher.last_name}`}
                            key={`${index}-${new Date()}`}
                          >
                            {teacher.first_name} {teacher.last_name}
                          </Option>
                        );
                      }
                    )}
                  </Select>

                  <label htmlFor="school">Seleccione los alumnos</label>
                  <Select
                    mode="multiple"
                    className={styles.formFieldSelect}
                    onChange={handleChangeStudents}
                    defaultValue={defaultValueStudents()}
                  >
                    {students?.map(
                      (
                        student: GetUserBySchoolId_getUserBySchoolId,
                        index: number
                      ) => {
                        return (
                          <Option
                            value={`${student.id}-${student.first_name} ${student.last_name}`}
                            key={`${index}-${new Date()}`}
                          >
                            {student.first_name} {student.last_name}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </>
              )}

              <Buttons
                handleClick={() => {
                  handleReset();
                  navigate(-1);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ClassroomForm;
