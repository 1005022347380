import * as Yup from "yup";
import { assignSchoolClassroomsInput } from "../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: assignSchoolClassroomsInput = {
  name: "",
  group_label: null,
  program_ids: [],
  school_id: 3,
  teachers: [],
};
