import * as Yup from "yup";
import { LessonInput } from "../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: LessonInput = {
  sep_book_pages: "",
  title: "",
  description: "",
  axes: [],
  themes: [],
  subject_id: 1,
  lesson_type: "videolesson",
  grade_id: 1,
  drive_link: "",
  publish: 0,
  sep_name: "",
  lesson_content: {
    quizzes: [],
    videos: [],
    redaction_id: null,
    pdf: "",
    responses_pdf: "",
  },
};

// export const NewLessonsSchema = Yup.object().shape({
//   title: Yup.string().required("El título es requerido."),
//   description: Yup.string().required("La descripción es requerida."),
// });
