import { useDispatch, useSelector } from "react-redux";
import { USERS } from "../store/selectors";
import { useEffect, useState } from "react";
import { GetUsers_getUsers } from "../__generated__/gql-types/GetUsers";
import UseGetUsers from "../api/useGetUsers";
import UseDeleteUser from "../api/useDeleteUser";
import { notification } from "antd";

export const useUsers = () => {
  const dispatch = useDispatch();
  const userSelector = useSelector(USERS);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<GetUsers_getUsers[]>([]);

  const getUsers = UseGetUsers();
  const deleteUserMutation = UseDeleteUser();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (userSelector?.length > 0) {
        setUsers(userSelector);
      } else {
        const usersResponse = await getUsers();
        const usersData = usersResponse?.sort(
          (a, b) => Number(b.id) - Number(a.id)
        );

        usersData && setUsers(usersData);
        dispatch({ type: "data/setUsers", payload: usersData });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const deleteUser = async (idUser: string) => {
    setLoading(true);
    try {
      const response = await deleteUserMutation({
        deleteUserId: idUser,
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "El usuario fue eliminado con exito!",
          placement: "top",
          duration: 3,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      notification["error"]({
        message: "Eliminación Erronea",
        description: "El usuario no pudo ser eliminado!",
        placement: "top",
        duration: 3,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInfo();
    };
    fetchData();

    return () => {};
  }, []);

  return {
    loading,
    users,
    deleteUser,
  };
};
