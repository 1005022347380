const courses = [
  {
    // id: 1,
    name: "Matemáticas",
  },
  {
    // id: 2,
    name: "Ciencias",
  },
  {
    // id: 3,
    name: "Lengua",
  },
];

export default courses;
