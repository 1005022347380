import { RootState } from "./reducers";

// AUTH
export const AUTH = (store: RootState) => store.auth;
export const TOKEN = (store: RootState) => store.auth.token;
export const USER_AUTH = (store: RootState) => store.auth.user;

// Data
export const LUCA_DATA = (store: RootState) => store.data;
export const VIDEOS = (store: RootState) => store.data.videos;
export const COURSES = (store: RootState) => store.data.courses;
export const QUIZZES = (store: RootState) => store.data.quizzes;
export const LESSONS = (store: RootState) => store.data.lessons;
export const PROGRAMS = (store: RootState) => store.data.programs;
export const USERS = (store: RootState) => store.data.users;
export const SUBJECTS = (store: RootState) => store.data.subjects;
export const SCHOOLS = (store: RootState) => store.data.schools;
export const AXES = (store: RootState) => store.data.axes;
export const THEMES = (store: RootState) => store.data.themes;
export const TAGS = (store: RootState) => store.data.tags;
export const CLASSROOMS = (store: RootState) => store.data.classrooms;
export const GRADES = (store: RootState) => store.data.grades;
export const ESSAYS = (store: RootState) => store.data.essays;
export const LITERACIES = (store: RootState) => store.data.literacies;
export const LISTENINGS = (store: RootState) => store.data.listenings;
export const AICONVERSATIONS = (store: RootState) => store.data.aiConversations;
export const CLOTHES_AND_ACCESSORIES = (store: RootState) => store.data.clothesAndAccessories;
export const NOTIFICATIONS = (store: RootState) => store.data.notifications;
