import * as Yup from "yup";
import {
  Roles,
  UserInput,
} from "./../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: UserInput = {
  first_name: "",
  last_name: "",
  username: "",
  email: "",
  password: "",
  schools: [{ id: "" }],
  email_parent: "",
  user_grade: [],
  visible_english_language: false,
  visible_spanish_language: false,
  role: Roles.student,
};

export const UsersFormSchema = Yup.object().shape({
  first_name: Yup.string().required("El nombre es requerido."),
  last_name: Yup.string().required("El apellido es requerido."),
  email: Yup.string()
    .email("El email es inválido.")
    .required("El correo electrónico es requerido."),
  username: Yup.string().min(
    4,
    "El nombre de usuario debe contener como mínimo 4 caracteres."
  ),
});
