import { FC, useEffect, useState } from "react";
import { notification, Row, Select } from "antd";
import styles from "./programsForm.module.css";
import { Buttons, InputField } from "../..";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import { NewProgramsSchema, initialValuesObj } from "./ProgramsForm.types";
import { GetSubjects_getSubjects } from "../../../__generated__/gql-types/GetSubjects";
import { GetGrade_getGrades } from "../../../__generated__/gql-types/GetGrade";
import UseCreatePrograms from "../../../api/useCreatePrograms";
import UseUpdatePrograms from "../../../api/useUpdatePrograms";
import {
  errorNotification,
  successNotification,
  updatedNotification,
} from "./utils";
import { GetPrograms_getPrograms } from "../../../__generated__/gql-types/GetPrograms";
import { ProgramInput } from "./../../../__generated__/gql-types/globalTypes";
import { ActivityIndicator } from "../../ActivityIndicator";
import { useSubject } from "../../../hooks/useSubject";
import { useGrades } from "../../../hooks/useGrades";
import { usePrograms } from "../../../hooks/usePrograms";
import { useDispatch } from "react-redux";

const ProgramsForm: FC = () => {
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const { loading: isLoadingSubject, subjects } = useSubject();
  const { loading: isLoadingGrades, grades } = useGrades();
  const { loading: isLoadingPrograms, programs } = usePrograms();
  const [initialValues, setInitialValues] =
    useState<ProgramInput>(initialValuesObj);
  const createPrograms = UseCreatePrograms();
  const updatePrograms = UseUpdatePrograms();
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state as GetPrograms_getPrograms;

  const fetchInfo = async () => {
    setloading(true);
    try {
      if (data && data.id) {
        setInitialValues({
          title: data.title ?? "",
          description: data.description ?? "",
          subject_id: Number(data.subject!.id) ?? 2,
          grade_id: Number(data.grade!.id) ?? 1,
        });
      }

      setloading(false);
    } catch (error: any) {
      setloading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const handleSubmit = async (values: ProgramInput) => {
    let Programs: ProgramInput = {
      ...values,
      subject_id: Number(values.subject_id),
      grade_id: Number(values.grade_id),
    };

    setloading(true);
    try {
      if (data?.id) {
        const response = await updatePrograms({
          programInput: Programs,
          updateProgramId: data.id,
        });
        response.data.updateProgram && updatedNotification();

        let index = programs.findIndex((program) => program.id === data.id);
        let programsUpdated = [...programs];
        programsUpdated[index] = response.data
          .updateProgram as unknown as GetPrograms_getPrograms;
        dispatch({ type: "data/setPrograms", payload: programsUpdated });
        setloading(false);
        return navigate(-1);
      } else {
        const createResponse = await createPrograms(Programs);
        createResponse.data && successNotification();
        let newPrograms = [];
        let newProgram = createResponse.data
          .createProgram as unknown as GetPrograms_getPrograms;
        newPrograms = [newProgram, ...programs];
        dispatch({ type: "data/setPrograms", payload: newPrograms });
        setloading(false);
        return navigate(-1);
      }
    } catch (error) {
      setloading(false);
      errorNotification();
    }
  };

  if (loading || isLoadingSubject || isLoadingGrades) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      <h1>Nuevo Programa </h1>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={NewProgramsSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleReset, touched }) => {
          return (
            <Form className={styles.formContainer}>
              <InputField
                errors={errors}
                touched={touched}
                name="title"
                title="Título"
                value={initialValues.title}
                onChange={(e) => {
                  setInitialValues({
                    ...initialValues,
                    title: e.target.value,
                  });
                }}
                type="text"
              />

              <label htmlFor="course">Descripción</label>
              <Row>
                <Field
                  style={{ width: "100%" }}
                  rows="4"
                  as="textarea"
                  name="description"
                  id="description"
                ></Field>
              </Row>
              <ErrorMessage
                name="description"
                component="div"
                className={styles.error}
              />

              <label htmlFor="course">Asignatura</label>
              <Field
                id="subject_id"
                name="subject_id"
                as="select"
                className={
                  errors.subject_id && touched.subject_id
                    ? styles.formFieldsError
                    : styles.formFieldSelect
                }
              >
                <option disabled value="">
                  Seleccionar
                </option>
                {subjects?.map(
                  (subject: GetSubjects_getSubjects | null, index: number) => {
                    return (
                      <option value={subject?.id} key={index}>
                        {subject?.name}
                      </option>
                    );
                  }
                )}
              </Field>
              <ErrorMessage
                name="subject_id"
                component="div"
                className={styles.error}
              />

              <label htmlFor="grade">Grado</label>
              <Field
                id="grade_id"
                name="grade_id"
                as="select"
                className={
                  errors.subject_id && touched.subject_id
                    ? styles.formFieldsError
                    : styles.formFieldSelect
                }
              >
                <option disabled value="">
                  Seleccionar
                </option>
                {grades?.map(
                  (grade: GetGrade_getGrades | null, index: number) => {
                    return (
                      <option value={grade?.id} key={index}>
                        {grade?.name}
                      </option>
                    );
                  }
                )}
              </Field>
              <ErrorMessage
                name="school"
                component="div"
                className={styles.error}
              />

              {/* <label htmlFor="courses">Curso(s)</label>
              <Select
                mode="multiple"
                className={styles.formFieldSelect}
                defaultValue={
                  data
                    ? data.courses?.map((course) => {
                        return `${course.id}-${course.title}`;
                      })
                    : undefined
                }
                onChange={handleChangeCourse}
              >
                {courses?.map(
                  (course: GetCourses_getCourses | null, index: number) => {
                    return (
                      <option
                        value={course?.id + "-" + course?.title}
                        key={index}
                      >
                        {course?.title}
                      </option>
                    );
                  }
                )}
              </Select> */}

              <Buttons
                handleClick={() => {
                  handleReset();
                  navigate(-1);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProgramsForm;
