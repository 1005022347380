import { useCallback } from "react";
import { GetQuizById } from "../__generated__/gql-types/GetQuizById";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

const UseGetQuizById = (quizId: string) => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetQuizById> = await client.query({
      query: GET_QUIZZES_BY_ID,
      fetchPolicy: "no-cache",
      variables: {
        quizId,
      },
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getQuizById;
  }, [client]);
};

export default UseGetQuizById;

const GET_QUIZZES_BY_ID = gql`
  query GetQuizById($quizId: ID!) {
    getQuizById(quizId: $quizId) {
      id
      title
      filename
      publish
      subject_id
      url
      themes {
        id
        name
      }
      axes {
        id
        name
      }
      questions {
        id
        question
        image
        image_aspect
        paragraph
        type_id
        order
        url
        answers {
          id
          answer
          image
          image_aspect
          correct
          order
          question_id
          url
          created_at
          updated_at
          deleted_at
        }
        relationQuestion {
          id
          block1 {
            image
            text
            coincidence
          }
          block2 {
            image
            text
            coincidence
          }
          question_id
          created_at
          updated_at
        }
        dAndDQuestion {
          id
          blockItems {
            image
            text
            coincidence
          }
          blockContainer {
            image
            text
            coincidence
            totalItems
          }
          question_id
          created_at
          updated_at
        }
      }
      deleted_at
      updated_at
    }
  }
`;
