import { useDispatch, useSelector } from "react-redux";
import { CLASSROOMS } from "../store/selectors";
import { useEffect, useState } from "react";
import { GetClassrooms_getClassrooms } from "../__generated__/gql-types/GetClassrooms";
import useDeleteClassroom from "../api/useDeleteClassroom";
import UseGetClassroom from "../api/useGetClassroom";
import { notification } from "antd";

export const useClassroom = () => {
  const dispatch = useDispatch();
  const assignClassroomSelector = useSelector(CLASSROOMS);
  const [loading, setLoading] = useState(false);
  const [classroom, setClassroom] = useState<GetClassrooms_getClassrooms[]>([]);

  const deleteClassroomMutation = useDeleteClassroom();
  const getClassrooms = UseGetClassroom();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (assignClassroomSelector?.length > 0) {
        setClassroom(assignClassroomSelector);
      } else {
        const classroomResponse = await getClassrooms();
        const classroomData = classroomResponse
          ?.filter((classroom) => classroom?.deleted_at === null)
          .sort((a, b) => Number(b.id) - Number(a.id));

        classroomData && setClassroom(classroomData);
        dispatch({ type: "data/setClassroom", payload: classroomData });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const deleteCourse = async (idClassroom: string) => {
    setLoading(true);
    try {
      const response = await deleteClassroomMutation({
        classroomId: idClassroom,
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "El Curso fue eliminado con exito!",
          placement: "top",
          duration: 3,
        });
      }
      let updatedClassroom = classroom.filter(
        (clroom) => clroom.id !== idClassroom
      );
      setClassroom(updatedClassroom);
      dispatch({ type: "data/setClassroom", payload: updatedClassroom });
      setLoading(false);
    } catch {
      setLoading(false);
      notification["error"]({
        message: "Eliminación Erronea",
        description: "El Curso no pudo ser eliminado!",
        placement: "top",
        duration: 3,
      });
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return {
    deleteCourse,
    classroom,
    loading,
  };
};
