import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { DeleteClassroomVariables } from '../__generated__/gql-types/DeleteClassroom';

const useDeleteClassroom = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: DeleteClassroomVariables) => {
      return await client.mutate({
        mutation: DELETE_CLASSROOM,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useDeleteClassroom;

const DELETE_CLASSROOM = gql`
  mutation DeleteClassroom($classroomId: ID!) {
    deleteClassroom(classroomId: $classroomId) {
      id
      name
    }
  }
`;
