import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { deleteAIConversationVariables } from "../__generated__/gql-types/deleteAIConversation";

const useDeleteProgram = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: deleteAIConversationVariables) => {
      return await client.mutate({
        mutation: DELETE_AICONVERSATIONS,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useDeleteProgram;

const DELETE_AICONVERSATIONS = gql`
  mutation deleteAIConversation($aiconversationId: Int) {
    deleteAIConversation(aiconversation_id: $aiconversationId) {
      id
      title
      publish
      level_cerf
      character_for_role_play
      card_image
      cover_image
      avatar_image
      section_1 {
        question
        order
      }
      section_2 {
        question
        order
      }
      created_at
      updated_at
      deleted_at
    }
  }
`;
