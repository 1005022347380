import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { Col, notification } from "antd";
import styles from "./uploadFile.module.css";
import { ActivityIndicator } from "../ActivityIndicator";
import { REACT_APP_URL_IMAGES } from "../../utils/constants";
import { useAuth } from "../../hooks/useAuth";
import { environment } from "../../configuration/environment";
import { AudioData } from "../Forms/ListeningForm/ListeningForm.types";

interface FileAudioProps {
  id: string;
  ext: string;
}

interface UploadAudioProps {
  folder: string;
  content: {
    audioData: AudioData;
    file: FileAudioProps;
  };
  setContent: React.Dispatch<
    React.SetStateAction<{
      audioData: AudioData;
      file: FileAudioProps;
    }>
  >;
}

export const UploadAudio: FC<UploadAudioProps> = ({
  folder,
  content,
  setContent,
}) => {
  const { token } = useAuth();
  const [loading, setloading] = useState(false);
  const [urlAudio, setUrlAudio] = useState<string>("");

  useEffect(() => {
    if (content.file.id) {
      setUrlAudio(
        `${REACT_APP_URL_IMAGES}${folder}/${content.file.id}.${content.file.ext}`
      );
    }
  }, []);

  const completeUpload = async (file: File) => {
    setloading(true);
    try {
      const response = await axios.post(
        `${environment.backendUrl}/urlpdf`,
        {
          folder,
          extension: file.type.split("/")[1],
        },
        {
          headers: {
            token,
          },
        }
      );

      let id: string = response.data.url
        .split("/")
        [response.data.url.split("/").length - 1].split("?X-Amz-Algorithm")[0]
        .split(".")[0];
      let ext: string = response.data.url
        .split("/")
        [response.data.url.split("/").length - 1].split("?X-Amz-Algorithm")[0]
        .split(".")[1];

      await axios.put(response.data.url, file, {
        headers: {
          "content-type": file.type,
        },
      });

      setContent({
        audioData: {
          ...content.audioData,
          audio_file: `${id}.${file.type.split("/")[1]}`,
        },
        file: {
          id: id,
          ext: file.type.split("/")[1],
        },
      });

      setUrlAudio(`${environment.urlImages}${folder}/${id}.${ext}`);
      setTimeout(() => {
        setloading(false);
      }, 2000);
    } catch (error: any) {
      notification["error"]({
        message: "Error",
        description: "La pdfn no pudo ser subida, por favor, reintente.",
        placement: "top",
        duration: 3,
      });
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    let file: File = e.target.files[0] as unknown as File;

    setloading(true);
    if (file.size > 0) {
      await completeUpload(file);
    } else {
      notification["error"]({
        message: "Error",
        description: "La pdfn no pudo ser subida, por favor, reintente.",
        placement: "top",
        duration: 3,
      });
    }

    setTimeout(() => {
      setloading(false);
    }, 1000);
  };

  return (
    <div className={styles.containerAudio}>
      <Col
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          marginRight: 20,
        }}
      >
        <label>Audio</label>
        <input type="file" onChange={handleFileChange} />
        <p>Formato soportado: mp3 o wav</p>
        {loading && (
          <ActivityIndicator size="small" classSmall={styles.activity} />
        )}
      </Col>
      <Col style={{ display: "flex", flexDirection: "row" }}>
        {!content.file && null}
        {urlAudio && (
          <div>
            <audio controls>
              <source
                src={urlAudio}
                type={`audio/${content.file.ext}`}
              ></source>
            </audio>
          </div>
        )}
      </Col>
    </div>
  );
};
