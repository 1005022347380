import styles from "./listeningForm.module.css";

import { useDispatch } from "react-redux";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Api
import UseGetListeningById from "../../../api/useGetListeningById";
import UseCreateListening from "../../../api/useCreateListening";
import UseUpdateListening from "../../../api/useUpdateListening";

// Hooks & Utils
import { useListening } from "../../../hooks/useListening";
import {
  errorNotification,
  successNotification,
  updatedNotification,
} from "./utils";

// Components
import Input from "antd/lib/input/Input";
import { Col, notification, Row, Form } from "antd";
import { ButtonsCustom } from "../../Buttons/Buttons";
import { ActivityIndicator } from "../../ActivityIndicator";
import { ContentIterationComponent } from "./ContentIterationComponent";

// Gen Types
import { AudioData, initialValuesObj } from "./ListeningForm.types";
import { ListeningInput } from "../../../__generated__/gql-types/globalTypes";
import { getListenings_getListenings } from "../../../__generated__/gql-types/getListenings";

interface FileAudioProps {
  id: string;
  ext: string;
}

interface ProcessedAudioData {
  audioData: AudioData;
  file: FileAudioProps;
}

const initialValueAudio = {
  audioData: {
    activity_number: 0,
    audio_file: "",
    order: 0,
    text: "",
  },
  file: {
    ext: "",
    id: "",
  },
};

const NewListeningForm: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { listenings, loading: isLoadingListening } = useListening();
  const listening_id = location?.state as string;
  const getListeningById = UseGetListeningById();
  const createListening = UseCreateListening();
  const updateListening = UseUpdateListening();

  const [loading, setloading] = useState(true);
  const [initialValues, setInitialValues] =
    useState<ListeningInput>(initialValuesObj);

  const [song, setSong] = useState<{
    audioData: AudioData;
    file: FileAudioProps;
  }>(initialValueAudio);

  const [listening1, setListening1] = useState<{
    audioData: AudioData;
    file: FileAudioProps;
  }>(initialValueAudio);
  const [listening2, setListening2] = useState<{
    audioData: AudioData;
    file: FileAudioProps;
  }>(initialValueAudio);

  const [listening3, setListening3] = useState<{
    audioData: AudioData;
    file: FileAudioProps;
  }>(initialValueAudio);

  const content = [
    {
      type: "song",
      content: song,
      setContent: setSong,
    },
    {
      type: "listening1",
      content: listening1,
      setContent: setListening1,
    },
    {
      type: "listening2",
      content: listening2,
      setContent: setListening2,
    },
    {
      type: "listening3",
      content: listening3,
      setContent: setListening3,
    },
  ];

  const processAudioData = (audioData: AudioData) => ({
    audioData: {
      activity_number: audioData.activity_number || 0,
      audio_file: audioData.audio_file || "",
      order: audioData.order || 0,
      text: audioData.text || "",
    },
    file: {
      id: audioData.audio_file ? audioData.audio_file.split(".")[0] : "",
      ext: audioData.audio_file ? audioData.audio_file.split(".")[1] : "",
    },
  });

  const processListeningData = (
    listeningData: AudioData | null,
    setListening: (listeningData: ProcessedAudioData) => void
  ) => {
    if (listeningData) {
      setListening(processAudioData(listeningData));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setInitialValues({
      ...initialValues,
      title: e.target.value,
    });

  const handleSubmit = async () => {
    let listening: ListeningInput = {
      title: initialValues.title,
      song: song.audioData,
      listening1: listening1.audioData,
      listening2: listening2.audioData,
      listening3: listening3.audioData,
    };
    setloading(true);
    try {
      if (listening_id && listening_id.length > 0) {
        const updateResponse = await updateListening({
          listeningInput: listening,
          listeningId: `${listening_id}`,
        });
        updateResponse && updatedNotification();

        let index = listenings.findIndex(
          (listening) => listening.id === updateResponse.data.updateListening.id
        );
        let listeningUpdated = [...listenings];

        listeningUpdated[index] = updateResponse.data
          .updateListening as unknown as getListenings_getListenings;
        dispatch({ type: "data/setListenings", payload: listeningUpdated });
        setloading(false);
        return navigate(-1);
      } else {
        const createResponse = await createListening({
          listeningInput: listening,
        });
        createResponse && successNotification();
        let newListenings = [];
        let newListening = createResponse.data
          .createListening as unknown as getListenings_getListenings;
        newListenings = [newListening, ...listenings];
        dispatch({ type: "data/setListenings", payload: newListenings });

        setloading(false);
        return navigate(-1);
      }
    } catch (error) {
      console.log({ error });
      setloading(false);
      errorNotification();
    }
  };

  const fetchListeningInfo = async () => {
    try {
      if (listening_id) {
        const data = await getListeningById({ listeningId: listening_id });

        if (data?.id) {
          setInitialValues({
            title: data.title ?? "",
            listening1: data.listening1 ?? null,
            listening2: data.listening2 ?? null,
            listening3: data.listening3 ?? null,
            song: data.song ?? null,
          });

          if (data.song) {
            setSong(processAudioData(data.song));
          }
          processListeningData(data.listening1, setListening1);
          processListeningData(data.listening2, setListening2);
          processListeningData(data.listening3, setListening3);
        }
      }

      setTimeout(() => {
        setloading(false);
      }, 1000);
    } catch (error: any) {
      setloading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchListeningInfo();
  }, [loading, isLoadingListening]);

  if (loading || isLoadingListening) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      {listening_id && listening_id.length > 0 ? (
        <h1>Editar Audio</h1>
      ) : (
        <h1>Nuevo Audio</h1>
      )}
      <Form className={styles.formContainer}>
        <Row>
          <Col span={12} style={{ marginTop: 10, marginLeft: 30 }}>
            <label htmlFor="asignatura">Título</label>
            <Input
              type="text"
              name="title"
              title="Titulo"
              onChange={handleChange}
              style={{ borderRadius: 5 }}
              value={initialValues.title!}
            />
          </Col>
        </Row>
        {content.map((element) => {
          const { type, content, setContent } = element;
          return (
            <ContentIterationComponent
              type={type}
              content={content}
              setContent={setContent}
            />
          );
        })}

        <ButtonsCustom
          handleSubmit={handleSubmit}
          handleClick={() => navigate(-1)}
        />
      </Form>
    </div>
  );
};

export default NewListeningForm;
