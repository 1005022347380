import React, { ChangeEvent, useState, useEffect } from "react";
import { Col, Row, Select } from "antd";
import { ErrorMessage, Field } from "formik";
import styles from "../../quizzForm.module.css";
import { QuestionInput } from "../../../../../__generated__/gql-types/globalTypes";
import { UploadImageFile } from "../../../../UploadFile";
import { FileImageCoverProps } from "../../utils";
import AIResponse from "./AIResponse";

interface AIQuestion {
  question: QuestionInput;
  index: number;
  loadingLoadImage: boolean;
  urlImageQuestion: string;
  urlImageAnswers: string[];
  imageAnswers: string[];
  selectContent: string;
  handleRemoveAnswer: () => void;
  handleAddAnswer: () => Promise<void>;
  handleImageQuestion: (newImage: string) => void;
  handleImageAnswers: (newImage: string, indexAnswer: number) => void;
  setLoadingLoadImage: React.Dispatch<React.SetStateAction<boolean>>;
  setUrlImageQuestion: React.Dispatch<React.SetStateAction<string>>;
  setUrlImageAnswers: React.Dispatch<React.SetStateAction<string[]>>;
  setimageAnswers: React.Dispatch<React.SetStateAction<string[]>>;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => void;
  setSelectContent: React.Dispatch<React.SetStateAction<string>>;
}

export const AIQuestion = ({
  question,
  index,
  loadingLoadImage,
  urlImageQuestion,
  imageAnswers,
  urlImageAnswers,
  selectContent,
  setSelectContent,
  handleAddAnswer,
  handleRemoveAnswer,
  handleImageQuestion,
  setLoadingLoadImage,
  setUrlImageQuestion,
  setimageAnswers,
  setUrlImageAnswers,
  handleImageAnswers,
  handleChange,
}: AIQuestion) => {
  return (
    <div>
      <Col
        key={`${index}_${new Date()}`}
        style={{
          backgroundColor: "#E6E7EF",
          borderRadius: 20,
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 10,
        }}
      >
        <div style={{ marginBottom: 10, marginTop: 5 }}>
          <label htmlFor={`questions_${index}`}>Pregunta</label>
        </div>
        <Row>
          <Field
            id={`questions_${index}`}
            name="question"
            type="text"
            className={styles.formFieldSelect}
            defaultValue={question?.question ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleChange(event, index);
            }}
          ></Field>
          <ErrorMessage
            name="question"
            component="div"
            className={styles.error}
          />
        </Row>
        <Row>
          <Select
            className={styles.formFieldSelect}
            style={{
              marginBottom: 15,
            }}
            value={selectContent}
            onChange={(value) => {
              setSelectContent(value);
            }}
            options={[
              { value: "1", label: "Imagen" },
              { value: "2", label: "Párrafo" },
            ]}
          />
          {selectContent === "1" ? (
            <UploadImageFile
              title={`Imagen AI Response`}
              className={styles.uploadFile}
              questionTypeId={question.type_id}
              typeFile="questions"
              folder="questions"
              widthCover={300}
              heightCover={200}
              index={index}
              image={question.image ?? ""}
              loadingLoadImage={loadingLoadImage}
              setLoadingLoadImage={setLoadingLoadImage}
              urlImageQuestion={urlImageQuestion}
              handleImageQuestion={handleImageQuestion}
              setUrlImageQuestion={setUrlImageQuestion}
            />
          ) : (
            <>
              <Field
                id={`paragraph_${index}`}
                name="paragraph"
                type="text"
                component="textarea"
                rows="10"
                className={styles.formFieldSelect}
                defaultValue={question?.paragraph ?? ""}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChange(event, index);
                }}
              ></Field>
              <ErrorMessage
                name="question"
                component="div"
                className={styles.error}
              />
            </>
          )}
        </Row>
        <AIResponse
          handleAddAnswer={handleAddAnswer}
          handleChange={handleChange}
          handleRemoveAnswer={handleRemoveAnswer}
          question={question}
          imageAnswers={imageAnswers}
          setimageAnswers={setimageAnswers}
          urlImageAnswers={urlImageAnswers}
          setUrlImageAnswers={setUrlImageAnswers}
          handleImageAnswers={handleImageAnswers}
        />
      </Col>
    </div>
  );
};
