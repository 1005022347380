import React, { ChangeEvent, useState } from "react";
import styles from "./bulkUpdateBookPages.module.css";
import { Field, Form, Formik, FormikProps } from "formik";
import { Buttons } from "../../components";
import { errorNotification, successNotification } from "./utils";
import { ActivityIndicator } from "../../components/ActivityIndicator";
import useBulkUpdateBookPages from "../../api/useBulkUpdateBookPages";

const BulkUpdateBookPages = () => {
  const [initialValues, setInitialValues] = useState({
    link: "",
  });
  const [loading, setLoading] = useState(false);
  const bulkUpdateBookPages = useBulkUpdateBookPages();

  const handleSubmit = async (formValues: any) => {
    setLoading(true);
    try {
      const response = await bulkUpdateBookPages({
        sheetUrl: formValues.link,
      });

      response.data && successNotification();
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setLoading(false);
      errorNotification();
    }
  };

  if (loading) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      <h1>Bulk Update Book Pages</h1>
      <div className={styles.lessonsContent}></div>
      <div className={styles.inputGenerate}>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ errors, handleReset, touched, values }: FormikProps<any>) => {
            return (
              <Form className={styles.formContainer}>
                <label htmlFor=""> Ingrese el link de spreadsheet</label>
                <Field
                  errors={errors}
                  touched={touched}
                  value={initialValues.link}
                  className={styles.formFields}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInitialValues({
                      ...initialValues,
                      link: e.target.value,
                    });
                  }}
                  name="link"
                  title="Link"
                  type="text"
                />

                <Buttons
                  handleClick={() => {
                    handleReset();
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default BulkUpdateBookPages;
