import React from "react";
import { notification } from "antd";
import { Navigate } from "react-router-dom";
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator";
import { LoginComponent } from "../../components/LoginComponent";
import { useAuth } from "../../hooks/useAuth";

const Login: React.FC = () => {
  const { error, token, loading, user, logout } = useAuth();

  if (loading) {
    return <ActivityIndicator />;
  }

  if (error && error.length > 0) {
    notification["error"]({
      message: "Error",
      description: "Usuario o contraseña incorrectos!!",
      placement: "top",
      duration: 3,
    });
    return <LoginComponent />;
  }

  if (token) {
    if (user?.role === "admin") {
      return <Navigate to="/videos" replace />;
    } else {
      logout();
      notification["error"]({
        message: "Acceso denegado",
        description: "Debes ser administrador para acceder al back-office.",
        placement: "top",
        duration: 3,
      });
      return <Navigate to="/" replace />;
    }
  }

  return <div>Login</div>;
};

export default Login;
