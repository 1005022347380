import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import {
  CreateRedaction,
  CreateRedactionVariables,
} from "../__generated__/gql-types/CreateRedaction";

const UseCreateRedactions = () => {
  const client = useApolloClient();
  return useCallback(
    async (redactionInput: CreateRedactionVariables) => {
      return await client.mutate<CreateRedaction>({
        mutation: CREATE_REDACTIONS,
        fetchPolicy: "no-cache",
        variables: redactionInput,
      });
    },
    [client]
  );
};

export default UseCreateRedactions;

const CREATE_REDACTIONS = gql`
  mutation CreateRedaction($redactionInput: RedactionInput) {
    createRedaction(redactionInput: $redactionInput) {
      title
      question
      id
      deleted_at
      created_at
      rubrics {
        order
        rubric
      }
    }
  }
`;
