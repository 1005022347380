import { ListeningInput } from "../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: ListeningInput = {
  title: "",
  song: null,
  listening1: null,
  listening2: null,
};

export interface AudioData {
  text: string | null;
  activity_number: number | null;
  audio_file: string | null;
  order: number | null;
}
