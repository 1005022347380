import React, { ChangeEvent, useEffect } from "react";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ContentIterationComponent } from "./ContentIteration/ContentIterationComponent";
import { ContentLiteracy } from "../LiteracyForm.types";

interface ContentTypeSelecProps {
  literacy_id: string | undefined;
  content: ContentLiteracy[];
  setContent: React.Dispatch<React.SetStateAction<ContentLiteracy[]>>;
}

export const ContentTypeSelect = ({
  literacy_id,
  content,
  setContent,
}: ContentTypeSelecProps) => {
  useEffect(() => {
    if (!literacy_id && content.length === 0) {
      setContent([
        ...content,
        {
          image: "",
          order: 0,
          contentType: 0,
          contentLeft: "",
          contentRigth: "",
          text: "",
          type: "",
        },
      ]);
    }
  }, [content, setContent]);

  const handleAddContent = () => {
    setContent([
      ...content,
      {
        image: "",
        text: "",
        contentLeft: "",
        contentRigth: "",
        type: "",
        order: content.length,
        contentType: 0,
      },
    ]);
  };

  const handleRemoveContent = (index: number) => {
    let updatedContents = content.filter((q, i) => i !== index);
    setContent(updatedContents);
  };

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
    indexContent: number
  ) => {
    content[indexContent].text = event.target.value;
  };

  return (
    <>
      {content.map((item, indexContent) => {
        return (
          <div key={indexContent}>
            <ContentIterationComponent
              literacy_id={literacy_id}
              keyGenerator={`${item.order}_${indexContent}_${new Date()}`}
              item={item}
              content={content}
              setContent={setContent}
              indexContent={indexContent}
              handleChange={handleChange}
              buttonAddNewContent={
                <span>
                  <a
                    style={{ fontSize: 22, padding: 10 }}
                    onClick={handleAddContent}
                  >
                    <PlusCircleOutlined></PlusCircleOutlined>
                  </a>
                </span>
              }
              buttonDeleteContent={
                <span>
                  <a
                    style={{ fontSize: 22, padding: 10 }}
                    onClick={() => {
                      handleRemoveContent(indexContent);
                    }}
                  >
                    <DeleteOutlined></DeleteOutlined>
                  </a>
                </span>
              }
            />
          </div>
        );
      })}
    </>
  );
};
