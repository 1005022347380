import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getNotificationByIdVariables, getNotificationById } from '../__generated__/gql-types/getNotificationById';

const UseGetNotificationById = () => {
  const client = useApolloClient();

  return useCallback(
    async (variables: getNotificationByIdVariables) => {
      const result: ApolloQueryResult<getNotificationById> =
        await client.query({
          query: GET_NOTIFICATIONS_BY_ID,
          fetchPolicy: "no-cache",
          variables,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getNotificationById;
    },
    [client]
  );
};

export default UseGetNotificationById;

const GET_NOTIFICATIONS_BY_ID = gql`
  query getNotificationById($getNotificationByIdId: ID!) {
    getNotificationById(id: $getNotificationByIdId) {
      id
      title
      message
      media
      role
      url
      fileId
      school_id
      created_at
      updated_at
      deleted_at
    }
  }
`;
