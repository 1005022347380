import React from "react";
import { Input, Space } from "antd";
import { GetUsers_getUsers } from "../../__generated__/gql-types/GetUsers";

const { Search } = Input;

interface Props {
  data: GetUsers_getUsers[];
  dataSource: GetUsers_getUsers[];
  setdataSource: React.Dispatch<
    React.SetStateAction<GetUsers_getUsers[] | null>
  >;
}

const InputSearch: React.FC<Props> = ({ data, dataSource, setdataSource }) => {
  const onSearch = (value: string) => {
    if (value === "") {
      setdataSource(data);
    } else {
      if (dataSource) {
        const filteredData: GetUsers_getUsers[] = dataSource.filter((data) =>
          data.email?.includes(value)
        );
        setdataSource(filteredData);
      }
    }
  };

  return (
    <Space direction="vertical">
      <Search placeholder="Buscar por email" onSearch={onSearch} enterButton />
    </Space>
  );
};

export default InputSearch;
