import { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./aiConversationsForm.module.css";
import { Buttons } from "../..";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Col, notification, Row, Select } from "antd";
import { initialValuesObj } from "./AIConversationsForm.types";
import {
  AIConversationsInput,
  SectionAIInput,
} from "../../../__generated__/gql-types/globalTypes";
import {
  FileImageCoverProps,
  errorNotification,
  successNotification,
  updatedNotification,
} from "./utils";
import { ActivityIndicator } from "../../ActivityIndicator";
import { useDispatch } from "react-redux";
import UseGetAIConversationsById from "../../../api/useGetAIConversationsById";
import UseUpdateAIConversation from "../../../api/useUpdateAIConversation";
import UseCreateAIConversations from "../../../api/useCreateAIConversations";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAIConversations } from "../../../hooks/useAIConversations";
import { getAIConversations_getAIConversations } from "../../../__generated__/gql-types/getAIConversations";
import { UploadImageFile } from "../../UploadFile";

const AIConversationsForm: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const aiconversations_id = location?.state as string;
  const getAIConversationById = UseGetAIConversationsById();
  const createAIConversation = UseCreateAIConversations();
  const updateAIConversation = UseUpdateAIConversation();
  const { aiConversations, loading: isLoadingAIConversations } =
    useAIConversations();
  const [loading, setloading] = useState(true);
  const [initialValues, setInitialValues] =
    useState<AIConversationsInput>(initialValuesObj);
  const [publish, setPublish] = useState<boolean>(true);
  const [section1, setSection1] = useState<SectionAIInput[]>([]);
  const [section2, setSection2] = useState<SectionAIInput[]>([]);
  const [filenameCover, setfilenameCover] = useState<FileImageCoverProps>({
    id: "",
    ext: "",
  });
  const [filenameAvatar, setfilenameAvatar] = useState<FileImageCoverProps>({
    id: "",
    ext: "",
  });
  const [filenameCard, setfilenameCard] = useState<FileImageCoverProps>({
    id: "",
    ext: "",
  });
  const [urlImageCover, setUrlImageCover] = useState<string>("");
  const [urlImageAvatar, setUrlImageAvatar] = useState<string>("");
  const [urlImageCard, setUrlImageCard] = useState<string>("");

  const fetchEssayInfo = async () => {
    try {
      if (aiconversations_id) {
        const data = await getAIConversationById({
          aiconversationId: Number(aiconversations_id),
        });

        if (data?.id) {
          setInitialValues({
            title: data?.title ?? "",
            avatar_image: data?.avatar_image ?? "",
            character_for_role_play: data?.character_for_role_play ?? "",
            cover_image: data?.cover_image ?? "",
            card_image: data?.card_image ?? "",
            voice: data?.voice ?? "",
            level_cerf: data?.level_cerf ?? "",
            publish: data.publish ? data.publish : 0,
            section_1: data?.section_1 ?? [],
            section_2: data?.section_2 ?? [],
          });
          data?.section_1 && setSection1(data.section_1);
          data?.section_2 && setSection2(data.section_2);

          setfilenameCover({
            id: data.cover_image!.split(".")[0],
            ext: data.cover_image!.split(".")[1],
          });

          setfilenameAvatar({
            id: data.avatar_image!.split(".")[0],
            ext: data.avatar_image!.split(".")[1],
          });

          setfilenameCard({
            id: data.card_image!.split(".")[0],
            ext: data.card_image!.split(".")[1],
          });
        }
      } else {
        setSection1([
          {
            order: 0,
            question: "",
          },
        ]);
        setSection2([
          {
            order: 0,
            question: "",
          },
        ]);
      }

      setloading(false);
    } catch (error: any) {
      setloading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchEssayInfo();
  }, [loading]);

  const handleSubmit = async (formValues: AIConversationsInput) => {
    let AIConversationsInput: AIConversationsInput = {
      ...formValues,
      title: initialValues.title,
      character_for_role_play: initialValues.character_for_role_play,
      card_image:
        filenameCard && filenameCard.id !== ""
          ? `${filenameCard.id}.${filenameCard.ext}`
          : "",
      avatar_image:
        filenameAvatar && filenameAvatar.id !== ""
          ? `${filenameAvatar.id}.${filenameAvatar.ext}`
          : "",
      cover_image:
        filenameCover && filenameCover.id !== ""
          ? `${filenameCover.id}.${filenameCover.ext}`
          : "",
      level_cerf: initialValues.level_cerf,
      publish: publish ? 1 : 0,
      section_1: section1,
      section_2: section2,
    };
    // console.log({ AIConversationsInput });
    // return;
    setloading(true);
    try {
      if (aiconversations_id) {
        const updateResponse = await updateAIConversation({
          aiconversationId: Number(aiconversations_id),
          aiconversationInput: AIConversationsInput,
        });
        updateResponse && updatedNotification();

        let index = aiConversations.findIndex(
          (aiConversation) =>
            Number(aiConversation.id) ===
            Number(updateResponse.data?.updateAIConversation.id)
        );
        let aiConversationsUpdated = [...aiConversations];

        aiConversationsUpdated[index] = updateResponse.data
          ?.updateAIConversation as unknown as getAIConversations_getAIConversations;
        dispatch({
          type: "data/setAIConversations",
          payload: aiConversationsUpdated,
        });
        setloading(false);
        return navigate(-1);
      } else {
        const createResponse = await createAIConversation(AIConversationsInput);
        createResponse && successNotification();
        let newAIConversations = [];
        let newAIConversation = createResponse.data
          ?.createAIConversation as unknown as getAIConversations_getAIConversations;
        newAIConversations = [newAIConversation, ...aiConversations];
        dispatch({
          type: "data/setAIConversations",
          payload: newAIConversations,
        });

        setloading(false);
        return navigate(-1);
      }
    } catch (error) {
      console.log({ error });
      setloading(false);
      errorNotification();
    }
  };

  if (loading || isLoadingAIConversations) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      {aiconversations_id && aiconversations_id.length > 0 ? (
        <h1>Editar Ensayo </h1>
      ) : (
        <h1>Nuevo Ensayo </h1>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={() => {
          const errors: any = {};
          return errors;
        }}
      >
        {({ errors, handleReset, touched, values }) => {
          return (
            <Col>
              <Form className={styles.formContainer}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginTop: 20,
                    padding: 20,
                    border: "1px solid gray",
                    borderRadius: 10,
                  }}
                >
                  <Row>
                    <Col span={7}>
                      <label htmlFor="title">Título</label>
                      <Field
                        errors={errors}
                        touched={touched}
                        name="title"
                        title="Titulo"
                        type="text"
                        className={styles.formFields}
                        value={initialValues.title}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setInitialValues({
                            ...initialValues,
                            title: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col offset={1} span={4}>
                      <div style={{ marginTop: 25, fontSize: 18 }}>
                        <label htmlFor="publish">Publicado:</label>
                        <input
                          type="checkbox"
                          name="publish"
                          style={{ marginLeft: 10 }}
                          checked={publish}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setPublish(e.target.checked);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <label htmlFor="title">Nivel CEFR</label>
                    <Field
                      errors={errors}
                      touched={touched}
                      name="level_cerf"
                      title="Level CEFR"
                      type="text"
                      className={styles.formFields}
                      value={initialValues.level_cerf}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setInitialValues({
                          ...initialValues,
                          level_cerf: e.target.value,
                        });
                      }}
                    />
                  </Row>
                  <Row>
                    <label htmlFor="title">Personaje para "Role play"</label>
                    <Field
                      errors={errors}
                      touched={touched}
                      name="character_for_role_play"
                      title="Personaje para 'Role play'"
                      type="text"
                      className={styles.formFields}
                      value={initialValues.character_for_role_play}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setInitialValues({
                          ...initialValues,
                          character_for_role_play: e.target.value,
                        });
                      }}
                    />
                  </Row>
                  <Col span={8}>
                    <label htmlFor="theme" style={{ marginTop: 10 }}>
                      Voz
                    </label>
                    <Select
                      value={initialValues.voice ?? "female"}
                      className={styles.formFieldSelect}
                      style={{ marginBottom: 20 }}
                      onChange={(value: string) => {
                        setInitialValues({
                          ...initialValues,
                          voice: value,
                        });
                      }}
                      options={[
                        { value: "female", label: "Femenina" },
                        { value: "male", label: "Masculina" },
                      ]}
                    />
                  </Col>
                  <Row>
                    <Col span={7}>
                      <UploadImageFile
                        title="Carta dosificación (horizontal)"
                        className={`${styles.uploadFile}`}
                        typeFile="quiz"
                        folder="covers"
                        widthCover={300}
                        heightCover={200}
                        image={initialValues.card_image ?? ""}
                        filenameCover={filenameCard}
                        setfilenameCover={setfilenameCard}
                        urlImageCover={urlImageCard}
                        setUrlImageCover={setUrlImageCard}
                      />
                    </Col>
                    <Col span={7}>
                      <UploadImageFile
                        title="Cover (escena principal, vertical)"
                        className={`${styles.uploadFile}`}
                        typeFile="quiz"
                        folder="covers"
                        widthCover={300}
                        heightCover={200}
                        image={initialValues.cover_image ?? ""}
                        filenameCover={filenameCover}
                        setfilenameCover={setfilenameCover}
                        urlImageCover={urlImageCover}
                        setUrlImageCover={setUrlImageCover}
                      />
                    </Col>
                    <Col span={7}>
                      <UploadImageFile
                        title="Imagen avatar"
                        className={`${styles.uploadFile}`}
                        typeFile="quiz"
                        folder="covers"
                        widthCover={300}
                        heightCover={200}
                        image={initialValues.avatar_image ?? ""}
                        filenameCover={filenameAvatar}
                        setfilenameCover={setfilenameAvatar}
                        urlImageCover={urlImageAvatar}
                        setUrlImageCover={setUrlImageAvatar}
                      />
                    </Col>
                  </Row>
                </Row>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 20,
                    padding: 20,
                    border: "1px solid gray",
                    borderRadius: 20,
                  }}
                >
                  <div style={{ fontSize: 20, marginBottom: 20 }}>
                    Sección 1
                  </div>
                  {section1 &&
                    section1.map((question, index) => (
                      <div key={`${index}_${new Date().getDate()}`}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                          }}
                        >
                          <label
                            htmlFor="title"
                            style={{
                              marginBottom: 15,
                            }}
                          >
                            {`Pregunta ${index + 1}`}
                          </label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignContent: "center",
                            }}
                          >
                            {section1 &&
                              section1.length - 1 === index &&
                              section1.length < 4 && (
                                <ButtonAddNew
                                  section={section1}
                                  setSection={setSection1}
                                />
                              )}
                            {section1 && section1.length > 1 && (
                              <ButtonDelete
                                section={section1}
                                setSection={setSection1}
                                index={index}
                              />
                            )}
                          </div>
                        </div>

                        <Field
                          style={{ width: "100%" }}
                          rows="4"
                          type="text"
                          name={`section1_${index}`}
                          id={`section1_${index}`}
                          className={styles.formFields}
                          defaultValue={question?.question}
                          onChange={(e: any) => {
                            section1[index].question = e.target.value;
                            setSection1(section1);
                          }}
                        />
                      </div>
                    ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 20,
                    padding: 20,
                    border: "1px solid gray",
                    borderRadius: 20,
                  }}
                >
                  <div style={{ fontSize: 20, marginBottom: 20 }}>
                    Sección 2
                  </div>
                  {section2 &&
                    section2.map((question, index) => (
                      <div key={`${index}_${new Date().getDate()}`}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                          }}
                        >
                          <label
                            htmlFor="title"
                            style={{
                              marginBottom: 15,
                            }}
                          >
                            {`Pregunta ${index + 1}`}
                          </label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignContent: "center",
                            }}
                          >
                            {section2 &&
                              section2.length - 1 === index &&
                              section2.length < 4 && (
                                <ButtonAddNew
                                  section={section2}
                                  setSection={setSection2}
                                />
                              )}
                            {section2 && section2.length > 1 && (
                              <ButtonDelete
                                section={section2}
                                setSection={setSection2}
                                index={index}
                              />
                            )}
                          </div>
                        </div>

                        <Field
                          style={{ width: "100%" }}
                          rows="4"
                          type="text"
                          name={`section2_${index}`}
                          id={`section2_${index}`}
                          className={styles.formFields}
                          defaultValue={question?.question}
                          onChange={(e: any) => {
                            section2[index].question = e.target.value;
                            setSection2(section2);
                          }}
                        />
                      </div>
                    ))}
                </div>
                <Buttons
                  handleClick={() => {
                    handleReset();
                    navigate(-1);
                  }}
                />
              </Form>
            </Col>
          );
        }}
      </Formik>
    </div>
  );
};

const ButtonAddNew = ({
  section,
  setSection,
}: {
  section: SectionAIInput[];
  setSection: (p: any) => void;
}) => {
  const handleAdd = () => {
    const newSection = [...section];
    const lastOrder =
      newSection.length > 0 ? newSection[newSection.length - 1].order : 0;
    newSection.push({ order: Number(lastOrder) + 1, question: "" });
    setSection(newSection);
  };
  return (
    <span>
      <a
        style={{ fontSize: 22, padding: 10 }}
        onClick={() => {
          handleAdd();
        }}
      >
        <PlusCircleOutlined></PlusCircleOutlined>
      </a>
    </span>
  );
};

const ButtonDelete = ({
  section,
  setSection,
  index,
}: {
  section: SectionAIInput[];
  setSection: (p: any) => void;
  index: number;
}) => {
  const handleDelete = (index: number) => {
    const updatedRubrics = section.filter((_section, i) => i !== index);
    setSection(updatedRubrics);
  };
  return (
    <span>
      <a
        style={{ fontSize: 22, padding: 10 }}
        onClick={() => {
          handleDelete(index);
        }}
      >
        <DeleteOutlined></DeleteOutlined>
      </a>
    </span>
  );
};

export default AIConversationsForm;
