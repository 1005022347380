import React, { ChangeEvent, useEffect } from "react";
import { QuestionInput } from "../../../../../__generated__/gql-types/globalTypes";
import styles from "./rq.module.css";
import { Col, Row } from "antd";
import { ErrorMessage, Field } from "formik";
import { RelationWordsComponent } from "./RelationWordsComponent";

interface RelationQuestionProps {
  index: number;
  typeId: number;
  question: QuestionInput;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => void;
  handleAddRelationBlockQuestions: () => void;
  handleRemoveRelationBlockQuestions: () => void;
}

export const RelationQuestion = ({
  index,
  question,
  typeId,
  handleChange,
  handleAddRelationBlockQuestions,
  handleRemoveRelationBlockQuestions,
}: RelationQuestionProps) => {
  useEffect(() => {}, [question]);

  return (
    <Col
      key={`${index}_${new Date()}`}
      style={{
        backgroundColor: "#E6E7EF",
        borderRadius: 20,
        paddingRight: 30,
        paddingLeft: 30,
        paddingTop: 10,
      }}
    >
      <div style={{ marginBottom: 10, marginTop: 5 }}>
        <label htmlFor={`questions_${index}`}>Pregunta {index}</label>
      </div>
      <Row>
        <Field
          id={`questions_${index}`}
          name="question"
          type="text"
          className={styles.formFieldSelect}
          defaultValue={question?.question ?? ""}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleChange(event, index);
          }}
        ></Field>
        <ErrorMessage
          name="question"
          component="div"
          className={styles.error}
        />
      </Row>
      {question.relationQuestion?.map(
        (relationQuestion, indexRelationQuestion) => {
          return (
            <RelationWordsComponent
              indexRelationQuestion={indexRelationQuestion}
              relationQuestion={relationQuestion}
              typeId={typeId}
              handleChange={handleChange}
              handleAddRelationBlockQuestions={handleAddRelationBlockQuestions}
              handleRemoveRelationBlockQuestions={
                handleRemoveRelationBlockQuestions
              }
            />
          );
        }
      )}
    </Col>
  );
};
