import {
  Space,
  Table,
  Button,
  notification,
  Modal,
  Row,
  Typography,
  Input,
} from "antd";
import type { ColumnsType } from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "./aiConversations.module.css";
import { FC, useEffect, useState } from "react";
import { useAIConversations } from "../../hooks/useAIConversations";
import { getAIConversations_getAIConversations } from "../../__generated__/gql-types/getAIConversations";

const { Text } = Typography;
const { Search } = Input;

const AIConversations: FC = () => {
  const {
    aiConversations,
    loading: isLoading,
    deleteAIConversation,
  } = useAIConversations();
  const [dataSource, setdataSource] = useState<
    getAIConversations_getAIConversations[] | null
  >([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [idAIConversation, setIdAIConversation] = useState<string>("");

  useEffect(() => {
    setdataSource(aiConversations);
  }, [aiConversations]);

  const showModal = (id: string) => {
    setIsModalVisible(true);
    setIdAIConversation(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      await deleteAIConversation(idAIConversation);
      setIsModalVisible(false);
    } catch {
      notification["error"]({
        message: "Eliminación Erronea",
        description: "La conversación no pudo ser eliminada!",
        placement: "top",
        duration: 3,
      });
    }
  };

  const columns: ColumnsType<getAIConversations_getAIConversations> = [
    {
      align: "center",
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    { align: "center", title: "Título", dataIndex: "title", key: "title" },
    {
      title: "Publicado",
      dataIndex: "publish",
      key: "publish",
      render: (record) => {
        return (
          <>
            <Row style={{ marginTop: 5 }}>
              {record === 1 ? (
                <Text type="success">Publicado</Text>
              ) : (
                <Text type="danger">No publicado</Text>
              )}
            </Row>
          </>
        );
      },
    },
    {
      align: "center",
      title: "Acciones",
      key: "acciones",
      render: (_, record) => {
        return (
          <Space size={15} style={{ fontSize: 19 }}>
            <Link to={`/aiConversation/${record.id}`} state={record.id}>
              <Button icon={<SearchOutlined />}>Editar</Button>
            </Link>
            <Button
              onClick={() => {
                record && record.id && showModal(record.id.toString());
              }}
              danger
            >
              Eliminar
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    if (value === "") {
      setdataSource(aiConversations);
    } else {
      if (dataSource) {
        const filteredData: getAIConversations_getAIConversations[] =
          dataSource.filter((data) => data.title?.includes(value));
        setdataSource(filteredData);
      }
    }
  };

  const onSearchId = (value: string) => {
    if (value === "") {
      setdataSource(aiConversations);
    } else {
      if (dataSource) {
        const filteredData: getAIConversations_getAIConversations[] =
          dataSource.filter((data) => data.id === Number(value));
        setdataSource(filteredData);
      }
    }
  };

  return (
    <div className={styles.container}>
      <h1>Conversaciones IA</h1>
      <div className={styles.newQuizzesContainer}>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por id"
            onSearch={onSearchId}
            enterButton
          />
        </Space>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por título"
            onSearch={onSearch}
            enterButton
          />
        </Space>
        <Button type="primary" danger>
          <Link to="/aiConversation/new">Nueva Conversación</Link>
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource ?? []}
        loading={isLoading}
        rowKey={(record) => (record && record.id ? record.id : "")}
      />
      <Modal
        title="Eliminar Lectoescritura"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>¿Desea eliminar la conversación?</p>
      </Modal>
    </div>
  );
};

export default AIConversations;
