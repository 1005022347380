import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { GetCoursesListBackOffice } from "../__generated__/gql-types/GetCoursesListBackOffice";

const UseGetCoursesListBackOffice = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<GetCoursesListBackOffice> =
      await client.query({
        query: GET_COURSES,
        fetchPolicy: "no-cache",
      });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getCoursesListBackOffice;
  }, [client]);
};

export default UseGetCoursesListBackOffice;

const GET_COURSES = gql`
  query GetCoursesListBackOffice {
    getCoursesListBackOffice {
      id
      title
      grade {
        name
        id
      }
    }
  }
`;
