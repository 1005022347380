import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UpdateUserVariables } from "../__generated__/gql-types/UpdateUser";

const UseUpdateUser = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: UpdateUserVariables) => {
      return await client.mutate({
        mutation: UPDATE_USER,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseUpdateUser;

const UPDATE_USER = gql`
  mutation UpdateUser($userInput: UserInput, $updateUserId: ID, $active: Boolean) {
    updateUser(userInput: $userInput, id: $updateUserId, active: $active) {
      id
      email
      first_name
      last_name
      role
      created_at
      schools {
        name
        id
      }
    }
  }
`;
