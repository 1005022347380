import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { DeleteCourseVariables } from "../__generated__/gql-types/DeleteCourse";

const useDeleteCourse = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: DeleteCourseVariables) => {
      return await client.mutate({
        mutation: DELETE_COURSE,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default useDeleteCourse;

const DELETE_COURSE = gql`
  mutation DeleteCourse($deleteCourseId: ID!) {
    deleteCourse(id: $deleteCourseId) {
      id
      title
      deleted_at
    }
  }
`;
