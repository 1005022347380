import {
  Space,
  Table,
  Button,
  notification,
  Modal,
  Row,
  Typography,
  Input,
} from "antd";
import type { ColumnsType } from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "./shop.module.css";
import { FC, useEffect, useState } from "react";
import { useClothesAndAccessories } from "../../hooks/useClothesAndAccessories";
import { getClothesAndAccessoriesBackOffice_getClothesAndAccessoriesBackOffice } from "../../__generated__/gql-types/getClothesAndAccessoriesBackOffice";

const { Text } = Typography;
const { Search } = Input;

const Shop: FC = () => {
  const {
    clothesAndAccessories,
    loading: isLoading,
    deleteClothesAndAccessories,
  } = useClothesAndAccessories();
  const [dataSource, setdataSource] = useState<
    | getClothesAndAccessoriesBackOffice_getClothesAndAccessoriesBackOffice[]
    | null
  >([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [idLiteracy, setIdLiteracy] = useState<string>("");

  useEffect(() => {
    setdataSource(clothesAndAccessories);
  }, [clothesAndAccessories]);

  const showModal = (id: string) => {
    setIsModalVisible(true);
    setIdLiteracy(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      await deleteClothesAndAccessories(idLiteracy);
      setIsModalVisible(false);
    } catch {
      notification["error"]({
        message: "Eliminación Erronea",
        description: "El quiz no pudo ser eliminado!",
        placement: "top",
        duration: 3,
      });
    }
  };

  const columns: ColumnsType<getClothesAndAccessoriesBackOffice_getClothesAndAccessoriesBackOffice> =
    [
      {
        align: "center",
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: 50,
      },
      { align: "center", title: "Nombre", dataIndex: "name", key: "name" },
      {
        align: "center",
        title: "Tipo",
        dataIndex: "type",
        key: "type",
        render: (record) => {
          return (
            <div>
              {record === "clothes"
                ? "ROPA"
                : record === "accessories"
                ? "ACCESORIO"
                : "DISFRAZ"}
            </div>
          );
        },
      },
      {
        align: "center",
        title: "Clasificación",
        dataIndex: "clasification",
        key: "clasification",
        render: (record) => {
          return (
            <div>
              {record === "suite"
                ? "TRAJE"
                : record === "top"
                ? "PARTE ARRIBA"
                : record === "bottom"
                ? "PARTE ABAJO"
                : record === "costume"
                ? "DISFRAZ"
                : record === "glasses"
                ? "GAFAS"
                : "SOMBREROS"}
            </div>
          );
        },
      },
      {
        title: "Publicado",
        dataIndex: "publish",
        key: "publish",
        width: 50,
        render: (record) => {
          return (
            <>
              <Row style={{ marginTop: 5 }}>
                {record === 1 ? (
                  <Text type="success">Publicado</Text>
                ) : (
                  <Text type="danger">No publicado</Text>
                )}
              </Row>
            </>
          );
        },
      },
      {
        align: "center",
        title: "Acciones",
        key: "acciones",
        render: (_, record) => {
          return (
            <Space size={15} style={{ fontSize: 19 }}>
              <Link
                to={`/clothes-and-accessories/${record.id}`}
                state={record.id}
              >
                <Button icon={<SearchOutlined />}>Editar</Button>
              </Link>
              <Button
                onClick={() => {
                  record && record.id && showModal(record.id.toString());
                }}
                danger
              >
                Eliminar
              </Button>
            </Space>
          );
        },
      },
    ];

  const onSearch = (value: string) => {
    if (value === "") {
      setdataSource(clothesAndAccessories);
    } else {
      if (dataSource) {
        const filteredData: getClothesAndAccessoriesBackOffice_getClothesAndAccessoriesBackOffice[] =
          dataSource.filter((data) => data.name?.includes(value));
        setdataSource(filteredData);
      }
    }
  };

  const onSearchId = (value: string) => {
    if (value === "") {
      setdataSource(clothesAndAccessories);
    } else {
      if (dataSource) {
        const filteredData: getClothesAndAccessoriesBackOffice_getClothesAndAccessoriesBackOffice[] =
          dataSource.filter((data) => data.id?.toString() === value);
        setdataSource(filteredData);
      }
    }
  };

  return (
    <div className={styles.container}>
      <h1>Lista de Prendas</h1>
      <div className={styles.newQuizzesContainer}>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por id"
            onSearch={onSearchId}
            enterButton
          />
        </Space>
        <Space direction="vertical" style={{ marginRight: 20 }}>
          <Search
            placeholder="Buscar por título"
            onSearch={onSearch}
            enterButton
          />
        </Space>
        <Button type="primary" danger>
          <Link to="/clothes-and-accessories/new">Añadir nueva prenda</Link>
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource ?? []}
        loading={isLoading}
        rowKey={(record) => (record && record.id ? record.id : "")}
      />
      <Modal
        title="Eliminar Prenda"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>¿Desea eliminar la lectoescritura?</p>
      </Modal>
    </div>
  );
};

export default Shop;
