import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetPrograms_getPrograms } from "../__generated__/gql-types/GetPrograms";
import { PROGRAMS } from "../store/selectors";
import UseDeletePrograms from "../api/useDeleteProgram";
import UseGetPrograms from "../api/UseGetPrograms";
import { notification } from "antd";

export const usePrograms = () => {
  const dispatch = useDispatch();
  const programsSelector = useSelector(PROGRAMS);
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState<GetPrograms_getPrograms[]>([]);

  const deleteProgramMutation = UseDeletePrograms();
  const getPrograms = UseGetPrograms();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (programsSelector?.length > 0) {
        setPrograms(programsSelector);
      } else {
        const programsResponse = await getPrograms();
        const programsData = programsResponse
          ?.filter((program) => program?.deleted_at === null)
          .sort((a, b) => Number(b.id) - Number(a.id));

        programsData && setPrograms(programsData);
        dispatch({ type: "data/setPrograms", payload: programsData });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const deleteProgram = async (idProgram: string) => {
    setLoading(true);
    try {
      const response = await deleteProgramMutation({
        deleteProgramId: idProgram,
      });
      if (response.data) {
        let updatedPrograms = programs.filter(
          (program) => program.id !== idProgram
        );
        setPrograms(updatedPrograms);
        dispatch({ type: "data/setPrograms", payload: updatedPrograms });
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "El programa fue eliminado con exito!",
          placement: "top",
          duration: 3,
        });
      }
      setLoading(false);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInfo();
    };
    fetchData();

    return () => {};
  }, []);

  return {
    loading,
    programs,
    deleteProgram,
  };
};
