import * as Yup from "yup";
import { SubjectInput } from "./../../../__generated__/gql-types/globalTypes";

export const initialValuesObj: SubjectInput = {
  name: "",
  code: "",
  color_class: "",
  publish: 0,
};

export const SubjectsFormSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido."),
  code: Yup.string().required("El código es requerido."),
  color_class: Yup.string().required("El color es requerido."),
});
