import { FC, useEffect, useState } from "react";
import styles from "./schoolForm.module.css";
import { Buttons } from "../..";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import UseCreateSchool from "../../../api/useCreateSchool";
import UseUpdateSchool from "../../../api/useUpdateSchool";
import { GetSchools_getSchools } from "./../../../__generated__/gql-types/GetSchools";
import { SchoolsFormSchema, initialValuesObj } from "./SchoolForm.types";
import { SchoolInput } from "../../../__generated__/gql-types/globalTypes";
import {
  errorNotification,
  successNotification,
  updatedNotification,
} from "./utils";
import { ActivityIndicator } from "../../ActivityIndicator";
import { Checkbox, notification, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { GetAxes_getAxes } from "../../../__generated__/gql-types/GetAxes";
import { GetThemes_getThemes } from "../../../__generated__/gql-types/GetThemes";
import { useAxes } from "../../../hooks/useAxes";
import { useThemes } from "../../../hooks/useThemes";
import UseGetSchoolById from "../../../api/useGetSchoolById";
import { useSchools } from "../../../hooks/useSchools";
import { useDispatch } from "react-redux";
import { GetSchoolById_getSchoolById } from "../../../__generated__/gql-types/GetSchoolById";

const { Option } = Select;

const SchoolsForm: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading: loadingSchools, schools } = useSchools();
  const school = location.state as GetSchoolById_getSchoolById;
  const createSchool = UseCreateSchool();
  const updateSchool = UseUpdateSchool();
  const navigate = useNavigate();
  const getSchoolById = UseGetSchoolById(school?.id);

  const [loading, setloading] = useState(false);
  const [selectsLoading, setSelectsLoading] = useState(false);
  const [initialValues, setInitialValues] =
    useState<SchoolInput>(initialValuesObj);
  const [checks, setChecks] = useState({
    demo: false,
    showSepPages: true,
    showGradesOnBase100: false,
  });
  const { axes, loading: loadingAxes } = useAxes();
  const { themes, loading: loadingThemes } = useThemes();

  const fetchInfo = async () => {
    setloading(true);
    try {
      if (school?.id) {
        const response = await getSchoolById();

        setChecks({
          demo: !!response?.demo,
          showSepPages: !!response?.show_sep_pages,
          showGradesOnBase100: !!response?.show_grades_on_base_100,
        });
        setInitialValues({
          cct: response?.cct ?? "",
          name: response?.name ?? "",
          city: response?.city ?? "",
          demo: response?.demo,
          show_sep_pages: response?.show_sep_pages,
          show_grades_on_base_100: response?.show_grades_on_base_100,
          view_english: response?.view_english,
          view_literacy: response?.view_literacy,
          view_2425: response?.view_2425,
          view_NEM_student: response?.view_NEM_student,
          view_2017_student: response?.view_2017_student,
          view_2425_student: response?.view_2425_student,
          view_project_generator: response?.view_project_generator,
          view_reading_spanish: response?.view_reading_spanish,
          view_reading_english: response?.view_reading_english,
          view_dynamics: response?.view_dynamics,
          view_library_english: response?.view_library_english,
          view_library_spanish: response?.view_library_spanish,
          view_shop: response?.view_shop,
          is_active: response?.is_active,
          excluded_axes:
            response?.excluded_axes?.map((axis: any) => {
              return { id: Number(axis.id) };
            }) ?? [],
          excluded_themes:
            response?.excluded_themes?.map((theme: any) => {
              return { id: Number(theme.id) };
            }) ?? [],
        });
      }
      setloading(false);
      setSelectsLoading(true);
      setSelectsLoading(false);
    } catch (error: any) {
      setloading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const handleChangeCheckBox = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    const unChecked = !e.target.checked;
    const demo = e.target.name === "Demo";
    const showSepPages = e.target.name === "showSepPages";
    const showGradesOnBase100 = e.target.name === "showGradesOnBase100";

    setChecks(
      checked && demo
        ? { ...checks, demo: true }
        : checked && showSepPages
        ? { ...checks, showSepPages: true }
        : checked && showGradesOnBase100
        ? { ...checks, showGradesOnBase100: true }
        : unChecked && demo
        ? { ...checks, demo: false }
        : unChecked && showSepPages
        ? { ...checks, showSepPages: false }
        : unChecked && showGradesOnBase100
        ? { ...checks, showGradesOnBase100: false }
        : checks
    );
  };

  const handleChangeAxes = (values: string[]) => {
    const axesValues = values!.map((value) => {
      let id = value.split("-")[1] !== undefined ? value.split("-")[0] : null;
      let name =
        value.split("-")[1] !== undefined ? value.split("-")[1] : value;

      const axe = axes!.find((axis) => axis?.id === id);

      if (axe) {
        return axe;
      } else {
        return {
          id,
          name,
        };
      }
    });

    initialValues.excluded_axes = axesValues.map((axis) => {
      return {
        id: Number(axis.id),
      };
    });
    setInitialValues(initialValues);
  };

  const handleChangeThemes = (values: string[]) => {
    const themesValues = values!.map((value) => {
      let id = value.split("-")[1] !== undefined ? value.split("-")[0] : null;
      let name =
        value.split("-")[1] !== undefined ? value.split("-")[1] : value;

      const selectedTheme = themes!.find((th) => th?.id === value);

      if (selectedTheme) {
        return selectedTheme;
      } else {
        return {
          id,
          name,
        };
      }
    });

    initialValues.excluded_themes = themesValues.map((theme) => {
      return {
        id: Number(theme.id),
      };
    });
    setInitialValues(initialValues);
  };

  const handleSubmit = async (values: SchoolInput) => {
    setloading(true);
    try {
      if (school?.id) {
        const schoolInputCreate: SchoolInput = {
          cct: values.cct,
          name: values.name,
          city: values.city,
          demo: checks.demo,
          show_sep_pages: checks.showSepPages,
          show_grades_on_base_100: checks.showGradesOnBase100,
          excluded_axes: initialValues.excluded_axes,
          excluded_themes: initialValues.excluded_themes,
          view_english: initialValues.view_english,
          view_literacy: initialValues.view_literacy,
          view_2425: initialValues.view_2425,
          view_NEM_student: initialValues.view_NEM_student,
          view_2017_student: initialValues.view_2017_student,
          view_2425_student: initialValues.view_2425_student,
          view_project_generator: initialValues.view_project_generator,
          view_reading_spanish: initialValues.view_reading_spanish,
          view_reading_english: initialValues.view_reading_english,
          view_shop: initialValues.view_shop,
          view_dynamics: initialValues.view_dynamics,
          view_library_english: initialValues.view_library_english,
          view_library_spanish: initialValues.view_library_spanish,
          is_active: initialValues.is_active,
        };
        const response = await updateSchool({
          schoolInput: schoolInputCreate,
          updateSchoolId: school.id,
        });
        response.data.updateSchool.id && updatedNotification();

        let index = schools.findIndex(
          (school) => school.id === response.data.updateSchool.id
        );
        let schoolsUpdated = [...schools];
        schoolsUpdated[index] = response.data
          .updateSchool as unknown as GetSchools_getSchools;
        dispatch({ type: "data/setSchools", payload: schoolsUpdated });

        setloading(false);
        return navigate(-1);
      } else {
        values.demo = checks.demo;
        values.show_sep_pages = checks.showSepPages;
        values.show_grades_on_base_100 = checks.showGradesOnBase100;
        if (!values.excluded_axes?.length || !values.excluded_themes?.length) {
          values.excluded_axes = undefined;
          values.excluded_themes = undefined;
        }

        const schoolInput: SchoolInput = {
          cct: values.cct,
          name: values.name,
          city: values.city,
          demo: checks.demo,
          show_sep_pages: checks.showSepPages,
          show_grades_on_base_100: checks.showGradesOnBase100,
          excluded_axes: initialValues.excluded_axes,
          excluded_themes: initialValues.excluded_themes,
          view_english: initialValues.view_english,
          view_literacy: initialValues.view_literacy,
          view_2425: initialValues.view_2425,
          view_NEM_student: initialValues.view_NEM_student,
          view_2017_student: initialValues.view_2017_student,
          view_2425_student: initialValues.view_2425_student,
          view_project_generator: initialValues.view_project_generator,
          view_reading_spanish: initialValues.view_reading_spanish,
          view_reading_english: initialValues.view_reading_english,
          view_shop: initialValues.view_shop,
          view_dynamics: initialValues.view_dynamics,
          view_library_english: initialValues.view_library_english,
          view_library_spanish: initialValues.view_library_spanish,
          is_active: initialValues.is_active,
        };

        const createResponse = await createSchool(schoolInput);
        createResponse && successNotification();

        let newSchools = [];
        let newSchool = createResponse.data
          .createSchool as unknown as GetSchools_getSchools;
        newSchools = [newSchool, ...schools];
        dispatch({ type: "data/setSchools", payload: newSchools });

        setloading(false);
        return navigate(-1);
      }
    } catch (error) {
      setloading(false);
      errorNotification();
    }
  };

  const axeSelectChildren: React.ReactNode[] = [];
  axes?.forEach((axe: GetAxes_getAxes, index: number) => {
    axeSelectChildren.push(
      <Option value={`${axe?.id}-${axe.name}`} key={index + `${new Date()}`}>
        {axe?.name}
      </Option>
    );
  });

  if (loading || loadingSchools || loadingAxes || loadingThemes) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      {school?.id ? <h1>Editar Escuela </h1> : <h1>Nueva Escuela </h1>}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={SchoolsFormSchema}
        onSubmit={handleSubmit}
      >
        {({ handleReset }) => {
          return (
            <Form className={styles.formContainer}>
              {/* CCT */}
              <label htmlFor={"cct"}>{"CCT"}</label>
              <Field
                className={styles.formFields}
                id={"cct"}
                name={"cct"}
                type={"text"}
              ></Field>
              <ErrorMessage
                name={"cct"}
                component="div"
                className={styles.error}
              />

              {/* Name */}
              <label htmlFor={"name"}>{"Nombre"}</label>
              <Field
                className={styles.formFields}
                id={"name"}
                name={"name"}
                type={"text"}
              ></Field>
              <ErrorMessage
                name={"name"}
                component="div"
                className={styles.error}
              />

              {/* City */}
              <label htmlFor={"city"}>{"Ciudad"}</label>
              <Field
                className={styles.formFields}
                id={"city"}
                name={"city"}
                type={"text"}
              ></Field>
              <ErrorMessage
                name={"city"}
                component="div"
                className={styles.error}
              />

              <label htmlFor="axes">Eje(s) Excluído(s)</label>
              <Select
                mode="tags"
                className={styles.formFieldSelect}
                onChange={handleChangeAxes}
                defaultValue={
                  school?.id
                    ? school.excluded_axes?.map((axe, index: number) => {
                        return `${axe.id}-${axe.name}`;
                      })
                    : undefined
                }
              >
                {axeSelectChildren}
              </Select>
              <ErrorMessage
                name="axes"
                component="div"
                className={styles.error}
              />

              <label htmlFor="theme">Tema(s) Excluído(s)</label>
              <Select
                mode="tags"
                className={styles.formFieldSelect}
                defaultValue={
                  school?.id
                    ? school.excluded_themes?.map((theme) => {
                        return `${theme.id}-${theme.name}`;
                      })
                    : undefined
                }
                onChange={handleChangeThemes}
              >
                {themes?.map((theme: GetThemes_getThemes, index: number) => {
                  return (
                    <Option
                      value={`${theme?.id}-${theme?.name}`}
                      key={index + `${new Date()}`}
                    >
                      {theme?.name}
                    </Option>
                  );
                })}
              </Select>

              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={handleChangeCheckBox}
                  name="Demo"
                  checked={checks.demo}
                >
                  Demo
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) => {
                    setInitialValues({
                      ...initialValues,
                      is_active: e.target.checked,
                    });
                  }}
                  name="showEnglish"
                  defaultChecked={
                    typeof initialValues.is_active === "boolean"
                      ? initialValues.is_active
                      : false
                  }
                >
                  Activo
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={handleChangeCheckBox}
                  name="showSepPages"
                  checked={checks.showSepPages}
                >
                  Mostrar Páginas SEP
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={handleChangeCheckBox}
                  name="showGradesOnBase100"
                  checked={checks.showGradesOnBase100}
                >
                  Mostrar Calificaciones en Base 100
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) => {
                    setInitialValues({
                      ...initialValues,
                      view_literacy: e.target.checked,
                    });
                  }}
                  name="showLiteracy"
                  defaultChecked={
                    typeof initialValues.view_literacy === "boolean"
                      ? initialValues.view_literacy
                      : false
                  }
                >
                  Mostrar Lectoescritura (prof)
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) => {
                    setInitialValues({
                      ...initialValues,
                      view_english: e.target.checked,
                    });
                  }}
                  name="showEnglish"
                  defaultChecked={
                    typeof initialValues.view_english === "boolean"
                      ? initialValues.view_english
                      : false
                  }
                >
                  Mostrar Inglés (prof)
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) => {
                    setInitialValues({
                      ...initialValues,
                      view_2425: e.target.checked,
                    });
                  }}
                  name="showEnglish"
                  defaultChecked={
                    typeof initialValues.view_2425 === "boolean"
                      ? initialValues.view_2425
                      : false
                  }
                >
                  Mostrar 2024/2025 (prof)
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) => {
                    setInitialValues({
                      ...initialValues,
                      view_NEM_student: e.target.checked,
                    });
                  }}
                  name="showEnglish"
                  defaultChecked={
                    typeof initialValues.view_NEM_student === "boolean"
                      ? initialValues.view_NEM_student
                      : false
                  }
                >
                  Mostrar NEM (alumno)
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) => {
                    setInitialValues({
                      ...initialValues,
                      view_2017_student: e.target.checked,
                    });
                  }}
                  name="showEnglish"
                  defaultChecked={
                    typeof initialValues.view_2017_student === "boolean"
                      ? initialValues.view_2017_student
                      : false
                  }
                >
                  Mostrar 2017 (alumno)
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) => {
                    setInitialValues({
                      ...initialValues,
                      view_2425_student: e.target.checked,
                    });
                  }}
                  name="showEnglish"
                  defaultChecked={
                    typeof initialValues.view_2425_student === "boolean"
                      ? initialValues.view_2425_student
                      : false
                  }
                >
                  Mostrar 2024/2025 (alumno)
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) => {
                    setInitialValues({
                      ...initialValues,
                      view_project_generator: e.target.checked,
                    });
                  }}
                  name="showEnglish"
                  defaultChecked={
                    typeof initialValues.view_project_generator === "boolean"
                      ? initialValues.view_project_generator
                      : false
                  }
                >
                  Mostrar Generador de Proyectos
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) => {
                    setInitialValues({
                      ...initialValues,
                      view_shop: e.target.checked,
                    });
                  }}
                  name="showEnglish"
                  defaultChecked={
                    typeof initialValues.view_shop === "boolean"
                      ? initialValues.view_shop
                      : false
                  }
                >
                  Mostrar Tienda Luca
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) => {
                    setInitialValues({
                      ...initialValues,
                      view_reading_spanish: e.target.checked,
                    });
                  }}
                  name="show_reading_spanish"
                  defaultChecked={
                    typeof initialValues.view_reading_spanish === "boolean"
                      ? initialValues.view_reading_spanish
                      : false
                  }
                >
                  Mostrar Lecturas español (esto afecta a toda la escuela, tanto profe como alumno)
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) => {
                    setInitialValues({
                      ...initialValues,
                      view_reading_english: e.target.checked,
                    });
                  }}
                  name="show_reading_english"
                  defaultChecked={
                    typeof initialValues.view_reading_english === "boolean"
                      ? initialValues.view_reading_english
                      : false
                  }
                >
                  Mostrar Lecturas inglés (esto afecta a toda la escuela, tanto profe como alumno)
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) => {
                    setInitialValues({
                      ...initialValues,
                      view_library_spanish: e.target.checked,
                    });
                  }}
                  name="show_library_spanish"
                  defaultChecked={
                    typeof initialValues.view_library_spanish === "boolean"
                      ? initialValues.view_library_spanish
                      : false
                  }
                >
                  Mostrar Biblioteca español (alumno)
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) => {
                    setInitialValues({
                      ...initialValues,
                      view_library_english: e.target.checked,
                    });
                  }}
                  name="show_library_english"
                  defaultChecked={
                    typeof initialValues.view_library_english === "boolean"
                      ? initialValues.view_library_english
                      : false
                  }
                >
                  Mostrar Biblioteca inglés (alumno)
                </Checkbox>
              </div>
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e: CheckboxChangeEvent) => {
                    setInitialValues({
                      ...initialValues,
                      view_dynamics: e.target.checked,
                    });
                  }}
                  name="show_view_dynamics"
                  defaultChecked={
                    typeof initialValues.view_dynamics === "boolean"
                      ? initialValues.view_dynamics
                      : false
                  }
                >
                  Mostrar Dinámicas Manipulativos
                </Checkbox>
              </div>
              {/* Buttons */}
              <Buttons
                handleClick={() => {
                  handleReset();
                  navigate(-1);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SchoolsForm;
