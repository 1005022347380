import { notification } from 'antd';

export const validateCover = (value: string) => {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/\.(gif|jpg|jpeg|tiff|png)$/i.test(value)) {
    error = "No es una imagen";
  }
  return error;
};

export const validateVideo = (value: string) => {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/\.(mp4)$/i.test(value)) {
    error = "No es un video";
  }
  return error;
};


export const successNotification = () => notification["success"]({
  message: "Creación Exitosa",
  description: "El Video fue creado con éxito!",
  placement: "top",
  duration: 3,
})


export const errorNotification = () => notification["error"]({
  message: "Error",
  description: "Se ha producido un error, vuelva a intentar!",
  placement: "top",
  duration: 3,
});

export const updatedNotification = () => notification["success"]({
  message: "Modificación Exitosa",
  description: "El Video fue modificado con éxito!",
  placement: "top",
  duration: 3,
})

export const videoQuizSuccessNotification = () => notification["success"]({
  message: "Agregar Quiz intra-video",
  description: "El Quiz fue agregado con éxito!",
  placement: "top",
  duration: 3,
});

export const videoQuizErrorNotification = (message:any) => notification["error"]({
  message: "Error",
  description: message,
  placement: "top",
  duration: 3,
});