import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { DeleteUserVariables } from "../__generated__/gql-types/DeleteUser";

const UseDeleteUser = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: DeleteUserVariables) => {
      return await client.mutate({
        mutation: DELETE_USER,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};

export default UseDeleteUser;

const DELETE_USER = gql`
  mutation DeleteUser($deleteUserId: ID) {
    deleteUser(id: $deleteUserId) {
      id
      deleted_at
    }
  }
`;
