import { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./redactionForm.module.css";
import { Buttons } from "../..";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Col, notification, Row, Select } from "antd";
import { initialValuesObj } from "./RedactionsForm.types";
import {
  QuestionInput,
  RedactionInput,
  RubricObjInput,
} from "../../../__generated__/gql-types/globalTypes";
import {
  errorNotification,
  successNotification,
  updatedNotification,
} from "./utils";
import { ActivityIndicator } from "../../ActivityIndicator";
import { useDispatch } from "react-redux";
import { useEssays } from "../../../hooks/useEssays";
import UseGetRedactionById from "../../../api/useGetRedactionById";
import UseUpdateRedactions from "../../../api/useUpdateRedactions";
import { GetRedactions_getRedactions } from "../../../__generated__/gql-types/GetRedactions";
import UseCreateRedactions from "../../../api/useCreateRedactions";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";

const NewRedactionsForm: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const essay_id = location?.state as string;
  const getEssayById = UseGetRedactionById(essay_id);
  const createEssay = UseCreateRedactions();
  const updateEssay = UseUpdateRedactions();
  const { essays } = useEssays();
  const [loading, setloading] = useState(true);
  const [initialValues, setInitialValues] =
    useState<RedactionInput>(initialValuesObj);
  const [publish, setPublish] = useState<boolean>(false);
  const [rubricas, setRubricas] = useState<RubricObjInput[]>([]);

  const fetchEssayInfo = async () => {
    try {
      if (essay_id) {
        const data = await getEssayById();

        if (data?.id) {
          setInitialValues({
            title: data.title ?? "",
            question: data.question ?? "",
            needs_evidence:
              typeof data.needs_evidence === "boolean"
                ? data.needs_evidence
                : false,
            rubrics: data.rubrics ?? [],
          });
          data.rubrics && setRubricas(data.rubrics);
        }
      } else {
        setRubricas([
          {
            order: 1,
            rubric: "",
          },
        ]);
      }

      setloading(false);
    } catch (error: any) {
      setloading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchEssayInfo();
  }, [loading]);

  const handleSubmit = async (formValues: RedactionInput) => {
    const fixRubrics = rubricas.map((rubric, index) => {
      return {
        rubric: rubric.rubric,
        order: index + 1,
      };
    });
    let Essay: RedactionInput = {
      ...formValues,
      title: initialValues.title,
      question: initialValues.question,
      needs_evidence: initialValues.needs_evidence,
      rubrics: fixRubrics,
    };
    // console.log({ Essay });
    // return;
    setloading(true);
    try {
      if (essay_id && essay_id.length > 0) {
        const updateResponse = await updateEssay({
          redactionInput: Essay,
          redactionId: `${essay_id}`,
        });
        updateResponse && updatedNotification();

        let index = essays.findIndex((essay) => essay.id === updateResponse);
        let essayUpdated = [...essays];

        essayUpdated[index] = updateResponse.data
          ?.updateRedaction as unknown as GetRedactions_getRedactions;
        dispatch({ type: "data/setEssays", payload: essayUpdated });
        setloading(false);
        return navigate(-1);
      } else {
        const createResponse = await createEssay({
          redactionInput: Essay,
        });
        createResponse && successNotification();
        let newEssays = [];
        let newEssay = createResponse.data
          ?.createRedaction as unknown as GetRedactions_getRedactions;
        newEssays = [newEssay, ...essays];
        dispatch({ type: "data/setEssays", payload: newEssays });

        setloading(false);
        return navigate(-1);
      }
    } catch (error) {
      console.log({ error });
      setloading(false);
      errorNotification();
    }
  };

  if (loading) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.container}>
      {essay_id && essay_id.length > 0 ? (
        <h1>Editar Ensayo </h1>
      ) : (
        <h1>Nuevo Ensayo </h1>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={() => {
          const errors: any = {};
          return errors;
        }}
      >
        {({ errors, handleReset, touched, values }) => {
          return (
            <Col>
              <Form className={styles.formContainer}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 20,
                    padding: 20,
                    border: "1px solid gray",
                  }}
                >
                  <Col span={7}>
                    <label htmlFor="title">Título</label>
                    <Field
                      errors={errors}
                      touched={touched}
                      name="title"
                      title="Titulo"
                      type="text"
                      className={styles.formFields}
                      value={initialValues.title}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setInitialValues({
                          ...initialValues,
                          title: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col offset={1} span={4}>
                    <div style={{ marginTop: 25, fontSize: 18 }}>
                      <label htmlFor="publish">Publicado:</label>
                      <input
                        type="checkbox"
                        name="publish"
                        style={{ marginLeft: 10 }}
                        checked={publish}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setPublish(e.target.checked);
                        }}
                      />
                    </div>
                    <div style={{ marginTop: 25, fontSize: 18 }}>
                      <label htmlFor="need_evidence">Evidencia:</label>
                      <input
                        type="checkbox"
                        name="need_evidence"
                        style={{ marginLeft: 10 }}
                        checked={
                          typeof initialValues.needs_evidence === "boolean"
                            ? initialValues.needs_evidence
                            : false
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setInitialValues({
                            ...initialValues,
                            needs_evidence: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 20,
                    padding: 20,
                    border: "1px solid gray",
                    borderRadius: 20,
                  }}
                >
                  <label
                    htmlFor="title"
                    style={{
                      marginBottom: 15,
                    }}
                  >
                    Pregunta
                  </label>
                  <Field
                    style={{ width: "100%" }}
                    rows="4"
                    as="textarea"
                    name="question"
                    id="question"
                    className={styles.formFields}
                    defaultValue={initialValues.question}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInitialValues({
                        ...initialValues,
                        question: e.target.value,
                      });
                    }}
                  />
                </div>
                {rubricas &&
                  rubricas.map((rubrica, index) => (
                    <div
                      key={`${index}_${new Date().getDate()}`}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 20,
                        padding: 20,
                        border: "1px solid gray",
                        borderRadius: 20,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "center",
                        }}
                      >
                        <label
                          htmlFor="title"
                          style={{
                            marginBottom: 15,
                          }}
                        >
                          {`Rubrica ${index + 1}`}
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                          }}
                        >
                          {rubricas &&
                            rubricas.length - 1 === index &&
                            rubricas.length < 5 && (
                              <ButtonAddNew
                                rubricas={rubricas}
                                setRubricas={setRubricas}
                              />
                            )}
                          {rubricas && rubricas.length > 1 && (
                            <ButtonDelete
                              rubricas={rubricas}
                              setRubricas={setRubricas}
                              index={index}
                            />
                          )}
                        </div>
                      </div>

                      <Field
                        style={{ width: "100%" }}
                        rows="4"
                        as="textarea"
                        name={`rubrica_${index}`}
                        id={`rubrica_${index}`}
                        className={styles.formFields}
                        defaultValue={rubrica?.rubric}
                        onChange={(e: any) => {
                          rubricas[index].rubric = e.target.value;
                          setRubricas(rubricas);
                        }}
                      />
                    </div>
                  ))}
                <Buttons
                  handleClick={() => {
                    handleReset();
                    navigate(-1);
                  }}
                />
              </Form>
            </Col>
          );
        }}
      </Formik>
    </div>
  );
};

const ButtonAddNew = ({
  rubricas,
  setRubricas,
}: {
  rubricas: RubricObjInput[];
  setRubricas: (p: any) => void;
}) => {
  const handleAdd = () => {
    const newRubrics = [...rubricas];
    const lastOrder =
      newRubrics.length > 0 ? newRubrics[newRubrics.length - 1].order : 0;
    newRubrics.push({ order: lastOrder + 1, rubric: "" });
    setRubricas(newRubrics);
  };
  return (
    <span>
      <a
        style={{ fontSize: 22, padding: 10 }}
        onClick={() => {
          handleAdd();
        }}
      >
        <PlusCircleOutlined></PlusCircleOutlined>
      </a>
    </span>
  );
};

const ButtonDelete = ({
  rubricas,
  setRubricas,
  index,
}: {
  rubricas: RubricObjInput[];
  setRubricas: (p: any) => void;
  index: number;
}) => {
  const handleDelete = (index: number) => {
    const updatedRubrics = rubricas.filter((_rubric, i) => i !== index);
    setRubricas(updatedRubrics);
  };
  return (
    <span>
      <a
        style={{ fontSize: 22, padding: 10 }}
        onClick={() => {
          handleDelete(index);
        }}
      >
        <DeleteOutlined></DeleteOutlined>
      </a>
    </span>
  );
};

export default NewRedactionsForm;
