import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { ProgramInput } from "../__generated__/gql-types/globalTypes";

const UseCreatePrograms = () => {
  const client = useApolloClient();
  return useCallback(
    async (programInput: ProgramInput) => {
      return await client.mutate({
        mutation: CREATE_PROGRAMS,
        fetchPolicy: "no-cache",
        variables: { programInput },
      });
    },
    [client]
  );
};

export default UseCreatePrograms;

const CREATE_PROGRAMS = gql`
  mutation CreateProgram($programInput: ProgramInput) {
    createProgram(programInput: $programInput) {
      id
      title
      description
      subject {
        name
        id
      }
      grade {
        name
        id
      }
      deleted_at
    }
  }
`;
