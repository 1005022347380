import { FC, useState, useEffect } from "react";
import type { ColumnsType } from "antd/lib/table";
import {
  Space,
  Table,
  Button,
  Image,
  Row,
  Typography,
  Modal,
  Input,
  notification,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./videos.module.css";
import { Link } from "react-router-dom";
import { GetVideos_getVideos } from "../../__generated__/gql-types/GetVideos";
import { useVideos } from "../../hooks/useVideos";

const { Text } = Typography;
const { Search } = Input;

const Videos: FC = () => {
  const { loading: isLoading, videos: videosData, deleteVideo } = useVideos();
  const [dataSource, setdataSource] = useState<GetVideos_getVideos[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [idVideo, setIdVideo] = useState<string>("");

  const showModal = (id: string) => {
    setIsModalVisible(true);
    setIdVideo(id);
  };

  useEffect(() => {
    setdataSource(videosData);
  }, [videosData]);

  const columns: ColumnsType<GetVideos_getVideos> = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Captura",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (image) => {
        return <Image width={200} height={100} src={image} alt={image} />;
      },
    },
    {
      title: "Publicado",
      dataIndex: "publish",
      key: "publish",
      render: (record) => {
        return (
          <>
            <Row style={{ marginTop: 5 }}>
              {record === 1 ? (
                <Text type="success">Publicado</Text>
              ) : (
                <Text type="danger">No publicado</Text>
              )}
            </Row>
          </>
        );
      },
    },
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "VideoQuiz",
      dataIndex: "quizzes",
      key: "quizzes",
      render: (quizzes) => {
        return (
          <>
            <Row style={{ marginTop: 5 }}>
              {quizzes?.length > 0 ? (
                <Text type="success">✔</Text>
              ) : (
                <Text type="danger">❌</Text>
              )}
            </Row>
          </>
        );
      },
    },
    {
      title: "Tema(s)",
      dataIndex: "themes",
      key: "themes",
      render: (theme) => {
        return theme.map((tema: { name: string }, index: number) => {
          return (
            <div key={index}>
              {index + 1}. {tema.name}
            </div>
          );
        });
      },
    },
    {
      title: "Descripcion",
      dataIndex: "description",
      key: "description",
    },
    {
      align: "center",
      title: "Acciones",
      key: "acciones",
      render: (_, record) => {
        return (
          <Space size={15} style={{ fontSize: 19 }}>
            <Link to={`/videos/${record.id}`} state={record.id}>
              <Button icon={<SearchOutlined />}>Editar</Button>{" "}
            </Link>
            <Button
              onClick={() => {
                showModal(record.id);
              }}
              danger
            >
              Eliminar
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    if (value === "") {
      setdataSource(videosData);
    } else {
      if (dataSource) {
        const filteredData: GetVideos_getVideos[] = dataSource.filter((data) =>
          data.title?.includes(value)
        );
        setdataSource(filteredData);
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      await deleteVideo(idVideo);
      setIsModalVisible(false);
    } catch {
      setIsModalVisible(false);
      notification["error"]({
        message: "Eliminación Erronea",
        description: "El video no pudo ser eliminado!",
        placement: "top",
        duration: 3,
      });
    }
  };

  return (
    <div className={styles.container}>
      <h1>Videos</h1>
      <div className={styles.newVideosContainer}>
        <Space direction="vertical">
          <Search
            placeholder="Buscar por título"
            onSearch={onSearch}
            enterButton
          />
        </Space>
        <Button type="primary" danger>
          <Link to="/videos/new">Nuevo video</Link>
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        rowKey={(record) => record.id}
        pagination={{ position: ["bottomRight"] }}
      />
      <Modal
        title="Eliminar Video"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>¿Desea eliminar el video?</p>
      </Modal>
    </div>
  );
};

export default Videos;
