import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Col, Image, notification, Row } from "antd";
import styles from "./uploadFile.module.css";
import { ActivityIndicator } from "../ActivityIndicator";
import { FileImageCoverProps } from "../Forms/VideosForm/NewVideosForm";
import { REACT_APP_URL_IMAGES } from "../../utils/constants";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import { environment } from "../../configuration/environment";

interface UploadFileImageProps {
  folder: string;
  widthCover?: number;
  heightCover?: number;
  filenameCover: FileImageCoverProps;
  urlImageCover: string;
  setfilenameCover: Dispatch<SetStateAction<FileImageCoverProps>>;
  setUrlImageCover: React.Dispatch<SetStateAction<string>>;
}

export const UploadImage: React.FC<UploadFileImageProps> = ({
  folder,
  widthCover = 300,
  heightCover = 300,
  filenameCover,
  urlImageCover,
  setUrlImageCover,
  setfilenameCover,
}: UploadFileImageProps) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);

  const sortInfo = async () => {
    setLoading(true);
    if (filenameCover.id) {
      setUrlImageCover(
        `${REACT_APP_URL_IMAGES}${folder}/${filenameCover.id}.${filenameCover.ext}`
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    sortInfo();
  }, []);

  useEffect(() => {}, [setLoading, loading]);

  const completeUpload = async (file: File) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${environment.backendUrl}/urlimage`,
        {
          folder,
          extension: file.type.split("/")[1],
        },
        {
          headers: {
            token,
          },
        }
      );

      let id: string = response.data.url
        .split("/")
        [response.data.url.split("/").length - 1].split("?X-Amz-Algorithm")[0]
        .split(".")[0];
      let ext: string = response.data.url
        .split("/")
        [response.data.url.split("/").length - 1].split("?X-Amz-Algorithm")[0]
        .split(".")[1];

      await axios.put(response.data.url, file, {
        headers: {
          "content-type": file.type,
        },
      });

      setfilenameCover({
        id,
        ext,
      });
      setUrlImageCover(`${REACT_APP_URL_IMAGES}${folder}/${id}.${ext}`);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description: "La imagen no pudo ser subida, por favor, reintente.",
        placement: "top",
        duration: 3,
      });
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    let file: File = e.target.files[0] as unknown as File;

    setLoading(true);
    if (file.size > 0) {
      await completeUpload(file);
    } else {
      notification["error"]({
        message: "Error",
        description: "La imagen no pudo ser subida, por favor, reintente.",
        placement: "top",
        duration: 3,
      });
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  if (loading) {
    return <ActivityIndicator size="small" classSmall={styles.activity} />;
  }

  return (
    <div className={styles.container}>
      <div>
        <label>Selecciona una imagen a subir</label>
        <input type="file" onChange={handleFileChange} />
        <p>Formato soportado: .jpeg/.jpg - Peso máximo: 5 MB</p>
      </div>
      {urlImageCover.length > 0 && (
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row style={{ margin: 30 }}>
            <Image
              src={urlImageCover}
              width={widthCover}
              height={heightCover}
            />
          </Row>
        </Col>
      )}
    </div>
  );
};
