import { notification } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QUIZZES } from "../store/selectors";
import useDeleteQuiz from "../api/useDeleteQuiz";
import { GetQuizzes_getQuizzes } from "../__generated__/gql-types/GetQuizzes";
import UseGetQuizzesListBackOffice from "../api/useGetQuizzesListBackOffice";

export const useQuizzes = () => {
  const dispatch = useDispatch();
  const quizzesSelector = useSelector(QUIZZES);
  const [loading, setLoading] = useState(false);
  const [quizzes, setQuizzes] = useState<GetQuizzes_getQuizzes[]>([]);

  const deleteQuizMutation = useDeleteQuiz();
  // const getQuizzes = UseGetQuizzes();
  const getQuizzes = UseGetQuizzesListBackOffice();

  const fetchInfo = async () => {
    setLoading(true);
    try {
      if (quizzesSelector?.length > 0) {
        setQuizzes(quizzesSelector);
      } else {
        const quizzesResponse = await getQuizzes();
        const quizzesData = quizzesResponse?.sort(
          (a, b) => Number(b.id) - Number(a.id)
        );

        quizzesData && setQuizzes(quizzesData);
        dispatch({ type: "data/setQuizzes", payload: quizzesData });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification["error"]({
        message: "Error",
        description:
          "La información no pudo ser cargada, por favor notifica éste error!",
        placement: "top",
        duration: 3,
      });
      throw new Error(error);
    }
  };

  const deleteQuiz = async (idQuiz: string) => {
    setLoading(true);
    try {
      const response = await deleteQuizMutation({
        deleteQuizId: idQuiz.toString(),
      });
      if (response.data) {
        notification["success"]({
          message: "Eliminación Exitosa",
          description: "El quiz fue eliminado con exito!",
          placement: "top",
          duration: 3,
        });
        let updatedQuizzes = quizzes.filter((quiz) => quiz.id !== idQuiz);
        setQuizzes(updatedQuizzes);
        dispatch({ type: "data/setQuizzes", payload: updatedQuizzes });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInfo();
    };
    fetchData();

    return () => {};
  }, []);

  return { loading, quizzes, deleteQuiz };
};
