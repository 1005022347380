import { useDispatch, useSelector } from "react-redux";
import { THEMES } from "../store/selectors";
import { useEffect, useState } from "react";
import UseGetTheme from "../api/useGetTheme";
import { GetThemes_getThemes } from "../__generated__/gql-types/GetThemes";

export const useThemes = () => {
  const dispatch = useDispatch();
  const themesSelector = useSelector(THEMES);
  const [loading, setLoading] = useState(true);
  const [themes, setThemes] = useState<GetThemes_getThemes[]>([]);
  const getThemes = UseGetTheme();

  const fetchInfo = async () => {
    try {
      if (themesSelector?.length > 0) {
        setThemes(themesSelector);
      } else {
        const themesResponse = await getThemes();
        const themesData = themesResponse?.sort(
          (a: GetThemes_getThemes, b: GetThemes_getThemes) =>
            Number(b?.id) - Number(a?.id)
        );
        themesData && setThemes(themesData);
        dispatch({ type: "data/setThemes", payload: themesData });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return {
    themes,
    loading,
  };
};
